import React, { useState, useEffect } from "react";
import { useSelector, useDispatch, batch } from "react-redux";

import TitlebarComponent from "../../titlebar.component";
import { format } from "date-fns/esm";

// material ui
import {
  Grid,
  makeStyles,
  Theme,
  createStyles,
  CardContent,
  Card,
  CardActions,
  Button,
  Snackbar,
  CircularProgress,
  TextField, MenuItem
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

// ag gridsystemet
import { AgToolbarButton } from "../../agGridUtils";
import { AgGridReact } from "ag-grid-react";
import { GridReadyEvent, FirstDataRenderedEvent, SelectionChangedEvent, GridApi, ColumnApi } from "ag-grid-community";
import {
  actionToggleFilterView,
  actionSelectClient,
  actionSelectDepartment,
  actionSelectCampaignType,
  actionSelectCampaign,
  actionSelectCompany,
//   actionSelectStartDate,
  actionSelectEndDate,
} from "../../redux/reducers/shell";
import AgTransferStatusCell from "./orders/agTransferStatusCell.component";

import { loadOrder, loadOrder2, IOrder, getOrders, getHeaders } from "./orders/orders.api";
// import { getOrders, getHeaders } from "./orders/orders2.api";
import { IOrderHeader } from "../../redux/reducers/reports/orders";
import OrderDetails from "./orders/detailsnew.component";

import ClientSelector from "../shell/clientselector.component";
import CompanySelector from "../shell/companyselector.component";
import DepartmentSelector from "../shell/departmentselector.component";
import PeriodSelector from "../shell/periodselector.component";
import CampaignTypeSelector from "../shell/campaigntypeselector.component";
import CampaignSelector from "../shell/campaignselector.component";

// theme for AdGridReact
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorMessage: {
      color: "#ff0000",
    },
    flexGrow: {
      flexGrow: 1,
    },
    actions: {
      "& .form-group": {
        minWidth: 200,
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      "& button": {
        //marginTop: 24,
      },
    },
    agGrid: {
      "& .aggrid-size": {
        minHeight: 500,
        height: "calc(100vh - 460px)",
        width: "100%",
      },
    },
  })
);
// for knappene over tabellen
const toolbarProps = {
  title: "",
  rightAlign: false,
  disabled: false,
  icon: "",
  onClick: () => {},
};
const marginStyles = {
  // margin: '24px 0 0 0',
};

type Header = {
  headerName: string;
  field: string;
  hide: boolean;
  searchable: boolean;
  datatype?: string;
};

export default function OrdersNewComponent() {
  const [results, setResults] = useState([]);
  const [showDetailsOpen, setShowDetailsOpen] = useState(false);
  const [headers, setHeaders] = useState<Header[]>([]);

  // redux for selection menyen
  const dispatch = useDispatch();
  const selectedClient = useSelector((state) => state.shell.selectedClient);
  const selectedCompany = useSelector((state) => state.shell.selectedCompany);
  const selectedDepartment = useSelector((state) => state.shell.selectedDepartment);
  const selectedCampaignType = useSelector((state) => state.shell.selectedCampaignType);
  const selectedCampaign = useSelector((state) => state.shell.selectedCampaign);
  const selectedStartDate = useSelector((state) => state.shell.selectedStartDate);
  const selectedEndDate = useSelector((state) => state.shell.selectedEndDate);
  const defaultEndDate = new Date();

  // for tabellen
  const classes = useStyles();
  const [gridAPI, setGridAPI] = useState<GridApi>();
  const [columnAPI, setColumnAPI] = useState<ColumnApi>();
  const hideFilters = useSelector((state) => state.shell.hideFilters);

  // standard variabler
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMsg, setAlertMsg] = useState({ msg: "", severity: false });
  const [errorMsg, setErrorMsg] = useState("");

  // ordre valgt og skal vises
  const [orderDetails, setorderDetails] = useState<IOrder | undefined>();
  const [orderDetails2, setorderDetails2] = useState<IOrder | undefined>();

  // quick order id lookup
  const [orderId, setOrderId] = useState("");

  // antall ordre som skal lastes inn, default 500
  const [orderNumber, setOrderNumber] = useState(500);

  const orderHeadersToColumnsDefs = (header: IOrderHeader) => {
    // denne bygger opp feltene til columnDefs
    const colDef: any = {
      headerName: header.display_name,
      field: header.field_name,
      hide: !header.visible,
      // filterFramework: ReportSearchFilter,
    };

    switch (header.datatype) {
      case "int":
        colDef.type = "numericColumn";
        break;
      case "decimal":
        colDef.type = "numericColumn";
        colDef.valueGetter = (params) => {
          if (!params.data) {
            return;
          }

          let value = params.data[params.colDef.field];
          if (!value || value.length === 0) {
            return;
          }

          if (value.startsWith(".")) {
            value = "0" + value;
          }

          try {
            value = parseFloat(value).toFixed(2);
          } catch (e) {
            value = "??";
          }

          return value;
        };
        break;
      case "datetime":
        colDef.valueGetter = (params) => {
          if (!params.data) {
            return;
          }

          let value = params.data[params.colDef.field];
          if (!value || value.length === 0) {
            return;
          }

          value = format(new Date(value.replace(/\s/, "T")), "yyyy-MM-dd HH:mm");
          return value;
        };
        break;
      case "transfer_status":
        colDef.cellRenderer = "agTransferStatusCell";
        colDef.valueGetter = (params) => {
          if (!params.data) {
            return;
          }
          return parseInt(params.data.transferred);
        };
        break;
      case "details":
        colDef.cellRenderer = "agCellButton";
        break;
    }

    return colDef;
  };

  useEffect(() => {
    // henter rå headers til tabellen
    // noen clients har custom felter som må hentes dynamisk. feks skandiaenergi
    const reOrderHeaders = (orderHeaders) => {
      if (orderHeaders.length > 0) {
        const tempHeaders: Header[] = [];

        // kjører gjennom alle headers, og formaterer det til columnDef formatet
        orderHeaders.map((o, i) => tempHeaders.push(orderHeadersToColumnsDefs(o)));
        // legger til Details feltet, det er knappen for å åpne order details
        tempHeaders.push(
          orderHeadersToColumnsDefs({
            display_name: "Soundfile Confirmed",
            field_name: "soundfile_confirmed",
            datatype: "datetime",
            visible: true,
            searchable: true,
          })
        );
        tempHeaders.push(
          orderHeadersToColumnsDefs({
            display_name: "Details",
            field_name: "",
            datatype: "details",
            visible: true,
            searchable: false,
          })
        );
        

        setHeaders(tempHeaders);
      }
    };

    const clientId = selectedClient ? selectedClient.value : 0;
    // loadOrderHeaders(dispatch, clientId, orderReport);
    getHeaders(clientId, results)
      .then((res) => reOrderHeaders(res.data))
      .catch((err) => {
            setAlertMsg({ msg: "Could not fetch headers", severity: false});
            setAlertOpen(true);});
  }, [selectedClient, results]);

  const runReport = () => {
    // brukeren har klikket på knappen "run" for å hente data til tabellen
    let query = {
      client_id: selectedClient ? selectedClient.value : 0,
      company_id: selectedCompany ? selectedCompany.value : 0,
      from_date: format(selectedStartDate, "yyyy-MM-dd"),
      to_date: format(selectedEndDate, "yyyy-MM-dd"),
      per_page: orderNumber,
      page: 1,
      campaign_type_id: selectedCampaignType ? selectedCampaignType.value : 0,
      campaign_id: selectedCampaign ? selectedCampaign.value : 0,
      department_id: selectedDepartment ? selectedDepartment.value : 0,
      csvdownload: 0,
    };
    setLoading(true);
    getOrders(query)
      .then((res) => setResults(res.data.data))
      .catch((err) => {
        setAlertMsg({ msg: "Could not fetch orders", severity: true});
        setAlertOpen(true);})
      .finally(() => setLoading(false));
    // loadOrderReport(dispatch, query); // vi bruker ikke redux for dette lenger
  };

  // funksjoner for agGrid
  // Dette skjer når du klikker på en rad i tabellen, lagrer object med file id
  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const rows = event.api.getSelectedRows();
    if (!rows[0]) {
      return;
    }
    // TODO: setSelectedContact(rows[0]);
  };
  // søkefelt som søker i tabellen
  const handleQuickFilter = (event) => {
    gridAPI!.setQuickFilter(event.target.value);
  };
  // laster inn data om tabellen
  const onGridReady = (event: GridReadyEvent) => {
    setGridAPI(event.api);
    setColumnAPI(event.columnApi);
  };
  // 3 knapper over tabellen, refresh, og endre størrelse på kolonner
  const onAdjustColumnsContent = (event: Event) => {
    var allColumnIds: any = [];
    if (columnAPI) {
      const allColumns = columnAPI!.getAllColumns();
      allColumns!.forEach((column) => {
        const colDef = column.getColDef();
        if (!colDef.suppressAutoSize) {
          allColumnIds.push(column.getColId());
        }
      });
      columnAPI!.autoSizeColumns(allColumnIds);
    }
  };
  const onAdjustColumnsWindow = (event: Event) => {
    gridAPI!.sizeColumnsToFit();
  };
  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    event.api.sizeColumnsToFit();
  };
  const onExportToCsv = () => {
    if (gridAPI) {
      gridAPI.exportDataAsExcel();
    }
  };
  // tar bort grid når vi er ferdig, forhindre minnelekkasje?
  useEffect(() => {
    return () => {
      if (gridAPI) {
        gridAPI.destroy();
      }
    };
  }, [gridAPI]);
  // end funksjoner for agGrid
  const refreshTableOnDetailsClose = () => {
    setShowDetailsOpen(false)
    runReport()
  }

  // Dette tegner opp Open order knapp, og transferstatus ikoner i tabellen
  const frameworkComponents = {
    agCellButton: (props) => {
    //   return (
    //     <div
    //       className="ag-cell-button"
    //       onClick={() => {
    //         fetchOrder(props.data.order_id);
    //       }}
    //     >
    //       <i className="fas fa-fw fa-tag"></i> Open
    //     </div>
    //   );
        return <Button size="small" color="primary" variant="outlined" onClick={() => { fetchOrder(props.data.order_id); }} title="Open order"><i className="fas fa-fw fa-tag"></i> Open</Button>
    },
    agTransferStatusCell: AgTransferStatusCell,
  };

  // hent 1 ordre
  function fetchOrder(order_id) {
    setLoading(true);
    setErrorMsg("");

    loadOrder2(order_id)
      .then((res) => {
        if (res.status === "failed") {
          console.error(res.status);
          return;
        }
        setorderDetails2(res);
      })
      .catch((err) => {
        setorderDetails2(undefined); 
        setAlertMsg({ msg: "Could not fetch order", severity: true});
        setAlertOpen(true);
        console.error(err);
        setErrorMsg(err.toString());
    });
    loadOrder(order_id)
      .then((res) => {
        if (res.status === "failed") {
          console.error(res.status);
          return;
        }
        setorderDetails(res);
      })
      .catch((err) => {
        setorderDetails(undefined); 
        setAlertMsg({ msg: "Could not fetch order", severity: true});
        setAlertOpen(true);
        console.error(err);
        setErrorMsg(err.toString());
      })
      .then(() => setLoading(false))
      .finally(() => setShowDetailsOpen(true));
  }
  const resetState = () => {
    batch(() => {
      dispatch(actionSelectClient(null));
      dispatch(actionSelectCompany(null));
      dispatch(actionSelectDepartment(null));
      dispatch(actionSelectCampaignType(null));
      dispatch(actionSelectCampaign(null));
      //   dispatch(actionSelectStartDate(defaultStartDate));
      dispatch(actionSelectEndDate(defaultEndDate));
      setOrderId("");
    });
  };

  return (
    <>
      <TitlebarComponent
        title="Blocklists"
        description="View blocklist"
        breadcrumbs={[
          { title: "Dashboard", link: "/" },
          { title: "Blocklists", link: "/list_management/blocklist" },
        ]}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div
            style={{ color: "#ccc", width: 15 }}
            data-toggle="tooltip"
            data-original-title="Hide/Show filters"
            onClick={() => dispatch(actionToggleFilterView())}
          >
            {hideFilters ? (
              <i className="fas fa-fw fa-caret-square-right"></i>
            ) : (
              <i className="fas fa-fw fa-caret-square-left"></i>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={2} style={{ display: hideFilters ? "none" : "block" }}>
          <ClientSelector fullWidth variant="material" />
          <CompanySelector fullWidth variant="material" />

          <PeriodSelector defaultEndDate={defaultEndDate} fullWidth variant="material" />

          <DepartmentSelector fullWidth variant="material" />
          <CampaignTypeSelector fullWidth variant="material" />
          <CampaignSelector fullWidth variant="material" />
          <Grid item md={12}>
            <TextField select onChange={e => setOrderNumber(parseInt(e.target.value))} value={orderNumber} label="Max orders" fullWidth>
              <MenuItem value={500}>500</MenuItem>
              <MenuItem value={1000}>1000</MenuItem>
              <MenuItem value={1500}>1500</MenuItem>
              <MenuItem value={2000}>2000</MenuItem>
            </TextField>
          </Grid>

          <Grid item style={{textAlign: "right", paddingTop: "10px"}}>
            {loading ? <CircularProgress size={20} /> : null}
            <Button variant="outlined" onClick={() => resetState()} title="Reset selected choices">
              Reset
            </Button>
            &nbsp;
            <Button variant="contained" color="primary" onClick={() => runReport()} title="Fetch orders for selection">
              Run
            </Button>
          </Grid>
          <Grid item style={{textAlign: "right", paddingTop: "10px"}}>
            <TextField
              value={orderId}
              label="Order ID"
              fullWidth={true}
              onChange={(e) => {
                setOrderId(e.target.value);
              }}
              style={{ paddingBottom: "10px"}}
            ></TextField>
            <Button variant="outlined" onClick={() => fetchOrder(orderId)} disabled={orderId === ""} title="Fetch a single order from order id">
              Open order
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} md={hideFilters ? 12 : 10}>
          <Card>
            <CardActions disableSpacing className={classes.actions}>
              {/* Knappene over tabellen */}
              <div className={classes.flexGrow} />
              <input type="text" style={marginStyles} placeholder="Search" onChange={handleQuickFilter} />
              {AgToolbarButton({
                ...toolbarProps,
                title: "Export to CSV",
                icon: "fa-file-export",
                onClick: onExportToCsv,
              })}
              {AgToolbarButton({
                ...toolbarProps,
                title: "Reset Filter",
                icon: "fa-eraser",
                onClick: () => {
                  gridAPI?.setFilterModel(null);
                },
              })}

              {AgToolbarButton({
                ...toolbarProps,
                title: "Adjust columns to window",
                icon: "fa-columns",
                onClick: onAdjustColumnsWindow,
              })}

              {AgToolbarButton({
                ...toolbarProps,
                title: "Adjust columns to content",
                icon: "fa-text-width",
                onClick: onAdjustColumnsContent,
              })}
            </CardActions>
            {/* Selve tabellen */}
            <CardContent>
              <Grid container>
                <Grid item xs={12} className={classes.agGrid}>
                  <div className="ag-theme-balham aggrid-size">
                    <AgGridReact
                      rowData={results}
                      columnDefs={headers}
                      onGridReady={onGridReady}
                      onSelectionChanged={onSelectionChanged}
                      enableRangeSelection={false}
                      rowSelection="single"
                      // justerer kolonnebredden etter load
                      onFirstDataRendered={onFirstDataRendered}
                      sideBar={{
                        toolPanels: ["columns"],
                      }}
                      // pagination
                      pagination={true}
                      paginationPageSize={100}
                      cacheBlockSize={100}
                      cacheOverflowSize={2}
                      maxBlocksInCache={2}
                      defaultColDef={{
                        sortable: true,
                        resizable: true,
                        filter: true,
                        filterParams: {apply: true, newRowsAction: 'keep'}
                      }}
                      // custom knapper
                      frameworkComponents={frameworkComponents}
                      // selectable text
                      enableCellTextSelection={true}
                      ensureDomOrder={true}
                      
                    />
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* alert bar */}
      <Snackbar open={alertOpen} autoHideDuration={3000} onClose={() => setAlertOpen(false)}>
        <MuiAlert variant="filled" severity={alertMsg.severity ? "success" : "error"}>
          {alertMsg.msg}
        </MuiAlert>
      </Snackbar>

      {/* Klikk på åpne ordre så lastes OrderDetails */}
      <OrderDetails
        showDetailsOpen={showDetailsOpen}
        setShowDetailsOpen={setShowDetailsOpen}
        order={orderDetails}
        order2={orderDetails2}
        onClose={refreshTableOnDetailsClose}
        fetchOrder={fetchOrder}
      />
    </>
  );
}
