import React, { useState, useEffect } from "react";
import TitlebarComponent from "../../titlebar.component";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-enterprise/chartsModule";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { GridReadyEvent, ColumnApi, GridApi } from "ag-grid-community";
import {
  FirstDataRenderedEvent,
  RowDoubleClickedEvent,
  SelectionChangedEvent
} from "ag-grid-community/dist/lib/events";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import nb from "date-fns/locale/nb";
import "react-datepicker/dist/react-datepicker.css";
import "./maps/maps.scss";
import { AgToolbarButton } from "../../agGridUtils";
import EditSegmentComponent from "./maps/editsegment.component";
import Alert from "react-bootstrap/Alert";
import { useSelector, useDispatch, batch } from "react-redux";
import ClientSelector from "../shell/clientselector.component";
import CompanySelector from "../shell/companyselector.component";
import { actionSelectClient, actionSelectCompany, actionToggleFilterView, actionSelectStartDate, actionSelectEndDate } from "../../redux/reducers/shell";
import CampaignTypeSelector from "../shell/campaigntypeselector.component";
import CampaignSelector from "../shell/campaignselector.component";
import { useDebounce, useWindowSize } from "../../custom.hooks";
import { fetchMapSegments } from "./maps/maps.api";
import LargeMapComponent from "./maps/largemap.component";
import ExportResponsesComponent from "./maps/exportresponses.component";
import PeriodSelector from "../shell/periodselector.component";


declare var env: any;

interface MapsProps {
  history?: any;
}

registerLocale("nb", nb);
setDefaultLocale("nb");

const aggEnable = {
  aggFunc: "sum",
  enableValue: true,
  allowedAggFuncs: ["sum", "min", "max"],
  filter: "agNumberColumnFilter",
  cellStyle: { textAlign: "right" }
};

const defaultColdumnDefs = [
  { headerName: "Client", field: "client_name", rowGroup: false, enableRowGroup: true },
  { headerName: "Company", field: "company_name", rowGroup: false, enableRowGroup: true },
  { headerName: "Campaign Type", field: "campaign_type_name", rowGroup: false, enableRowGroup: true },
  { headerName: "Campaign", field: "campaign_name", rowGroup: false, enableRowGroup: true },
  { headerName: "Segment", field: "segment_name", rowGroup: false, enableRowGroup: true },
  //{headerName: "Area Name", field: "area_name", rowGroup: false},
  //{headerName: "Area Code", field: "area_code", rowGroup: false},
  { headerName: "Date From", field: "date_from", rowGroup: false, enableRowGroup: true },
  { headerName: "Date To", field: "date_to", rowGroup: false, enableRowGroup: true },
  { headerName: "Freeze Until", field: "freeze_date_to", rowGroup: false, enableRowGroup: true },
  { headerName: "Areas", field: "segment_areas", rowGroup: false, ...aggEnable },
  { headerName: "Addresses", field: "segment_coordinates", rowGroup: false, ...aggEnable },
  { headerName: "Blocked Addresses", field: "segment_blocked", rowGroup: false, ...aggEnable },
  { headerName: "Yes %", field: "outcome_1_pct", rowGroup: false, ...aggEnable, hide: false },
  { headerName: "Yes", field: "outcome_1", rowGroup: false, ...aggEnable, hide: true },
  //  { headerName: "Handled", field: "outcome_5", rowGroup: false, ...aggEnable},
  { headerName: "No", field: "outcome_2", rowGroup: false, ...aggEnable, hide: true },
  //  { headerName: "Loss", field: "outcome_6", rowGroup: false, ...aggEnable},
  //  { headerName: "Unhandled", field: "outcome_11", rowGroup: false, ...aggEnable},
  //  { headerName: "Closed", field: "outcome_12", rowGroup: false, ...aggEnable},
  //  { headerName: "Call Back", field: "outcome_3", rowGroup: false, ...aggEnable},
  //  { headerName: "Answer Machine", field: "outcome_4", rowGroup: false, ...aggEnable},
  //  { headerName: "Call Attempts", field: "outcome_7", rowGroup: false, ...aggEnable},
  //  { headerName: "Follow Ups", field: "outcome_8", rowGroup: false, ...aggEnable},
  { headerName: "Come Back", field: "outcome_9", rowGroup: false, ...aggEnable, hide: true },
  { headerName: "Not Home", field: "outcome_10", rowGroup: false, ...aggEnable, hide: true },
  { headerName: "Utilization %", field: "utilization_pct", rowGroup: false, ...aggEnable }
];

export default function MapsComponent(props: MapsProps) {
  const dispatch = useDispatch();
  const selectedClient = useSelector(state => state.shell.selectedClient);
  const selectedCompany = useSelector(state => state.shell.selectedCompany);
  const selectedCampaignType = useSelector(state => state.shell.selectedCampaignType);
  const selectedCampaign = useSelector(state => state.shell.selectedCampaign);
  const selectedStartDate = useSelector(state => state.shell.selectedStartDate);
  const selectedEndDate = useSelector(state => state.shell.selectedEndDate);
  const hideFilters = useSelector(state => state.shell.hideFilters);

  const [reportQuery, setReportQuery] = useState<any>(null);
  const debouncedReportQuery = useDebounce(reportQuery, 400);
  const windowSize = useWindowSize();

  const [gridAPI, setGridAPI] = useState<GridApi>();
  const [columnAPI, setColumnAPI] = useState<ColumnApi>();
  const [editSegment, setEditSegment] = useState({ show: false, segmentId: "0", segmentName: "" });

  const [apiMessage, setApiMessage] = useState<any>({ show: false, variant: "success", message: "" });
  const [apiAlertTimeout, setApiAlertTimeout] = useState<any>(null);

  const [columnDefs, setColumnDefs] = useState(defaultColdumnDefs);
  const [rowData, setRowData] = useState([]);

  const [showLargeMap, setShowLargeMap] = useState(false);
  const [showExportDialog, setShowExportDialog] = useState(false);

  const deleteMapSegment = async () => {
    const req = {
      segment_id: editSegment.segmentId
    };
    const res = await fetch(env._apiPath + "/campaign/segment", {
      method: "DELETE",
      credentials: "include",
      body: JSON.stringify(req),
      headers: { "Content-Type": "application/json" }
    });
    const json = await res.json();
    if (json.status !== "success") {
      setApiMessage({ variant: "danger", message: "Unable to remove segment: " + json.message, show: true });
      return;
    }

    setEditSegment({ show: false, segmentName: "", segmentId: "0" });
    runReport(reportQuery);
  };

  useEffect(() => {
    clearTimeout(apiAlertTimeout);
    setApiAlertTimeout(
      setTimeout(() => {
        setApiMessage({ ...apiMessage, show: false });
      }, 15000)
    );
  }, [apiMessage]);

  useEffect(() => {
    setReportQuery({
      dispatch: dispatch,
      selectedClient: selectedClient ? selectedClient.value : 0,
      selectedCompany: selectedCompany ? selectedCompany.value : 0,
      selectedStartDate: selectedStartDate,
      selectedEndDate: selectedEndDate,
      selectedCampaignType: selectedCampaignType ? selectedCampaignType.value : 0,
      selectedCampaign: selectedCampaign ? selectedCampaign.value : 0
    });
  }, [dispatch, selectedClient, selectedCompany, selectedStartDate, selectedEndDate, selectedCampaignType, selectedCampaign]);

  useEffect(() => runReport(debouncedReportQuery), [debouncedReportQuery]);

  useEffect(() => {
    if (gridAPI) {
      gridAPI.sizeColumnsToFit();
    }
  }, [gridAPI, windowSize]);

  const resetState = () => {
    batch(() => {
      dispatch(actionSelectClient(null));
      dispatch(actionSelectCompany(null));
      dispatch(actionSelectStartDate(getDefaultStartDate()));
      dispatch(actionSelectEndDate(getDefaultEndDate()));
      runReport(reportQuery);
    });
  };

  const runReport = repQuery => {
    if (!repQuery) {
      return;
    }
    fetchMapSegments(repQuery)
      .then(data => setRowData(data))
      .catch(err => {});
  };

  const onGridReady = (event: GridReadyEvent) => {
    setGridAPI(event.api);
    setColumnAPI(event.columnApi);
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    event.api.sizeColumnsToFit();
  };

  const onRowDoubleClicked = (event: RowDoubleClickedEvent) => {
    const segment = event.data;
    if (!segment) {
      return;
    }

    setEditSegment({ show: true, segmentId: segment.segment_id, segmentName: segment.segment_name });
  };

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const rows = event.api.getSelectedRows();
    const segment = rows[0];
    setEditSegment({ show: false, segmentId: segment.segment_id, segmentName: segment.segment_name });
  };

  const onRemoveSegment = () => {
    if (!window.confirm("Are you sure you want to remove '" + editSegment.segmentName + "'?")) {
      return;
    }

    deleteMapSegment();
  };

  const onOpenInMaps = () => {

    let mapHost = "https://maps.fiko.no";
    if (window.location.hostname.indexOf("-local") !== -1) {
      mapHost = "http://maps-local.fiko.no";
    }
    if (window.location.hostname.indexOf("-stage") !== -1) {
      mapHost = "https://maps-stage.fiko.no";
    }

    window.open(mapHost + "/admin/_/editor", "managerMapsAdmin");

     //setShowLargeMap(true);
  };

  const onExportResponses = () => {
    setShowExportDialog(true);
  };

  const toolbarProps = {
    title: "",
    rightAlign: false,
    disabled: false,
    icon: "",
    onClick: () => {}
  };

  return (
    <div>
      <TitlebarComponent
        title="Map Segments"
        description="Delivered map assignments"
        breadcrumbs={[
          { title: "Dashboard", link: "/" },
          { title: "Maps", link: "/listmanagement/maps" }
        ]}
      />

      <div className="widget-list">
        <Row>
          <Col className="widget-holder">
            <div className="widget-bg">
              <div className="widget-body">
                <Row>
                  <Col>{apiMessage.show ? <Alert variant={apiMessage.variant}>{apiMessage.message}</Alert> : null}</Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      style={{ color: "#ccc", width: 15 }}
                      data-toggle="tooltip"
                      data-original-title="Hide/Show filters"
                      onClick={() => dispatch(actionToggleFilterView())}
                    >
                      {hideFilters ? (
                        <i className="fas fa-fw fa-caret-square-right"></i>
                      ) : (
                        <i className="fas fa-fw fa-caret-square-left"></i>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={2} style={{ display: hideFilters ? "none" : "block" }}>
                    <ClientSelector />
                    <CompanySelector />

                    <PeriodSelector defaultStartDate={getDefaultStartDate()} defaultEndDate={getDefaultEndDate()}/>

                    <CampaignTypeSelector />
                    <CampaignSelector />

                    <div style={{ textAlign: "right", marginTop: 20 }}>
                      <Button onClick={resetState} size="sm" variant="secondary">
                        Reset
                      </Button>
                      &nbsp;
                      <Button onClick={() => runReport(reportQuery)} size="sm" variant="secondary">
                        Run
                      </Button>
                    </div>
                  </Col>
                  <Col>
                    <div className="ag-buttons">
                      {AgToolbarButton({
                        ...toolbarProps,
                        title: "Add Segment",
                        icon: "fa-plus",
                        onClick: () => {
                          setEditSegment({ show: true, segmentId: "0", segmentName: "" });
                        }
                      })}

                      {AgToolbarButton({
                        ...toolbarProps,
                        title: "Edit",
                        icon: "fa-edit",
                        onClick: () => {
                          const rows = gridAPI!.getSelectedRows();
                          if (!rows[0]) {
                            return;
                          }

                          setEditSegment({
                            show: true,
                            segmentId: rows[0].segment_id,
                            segmentName: rows[0].segment_name
                          });
                        },
                        disabled: editSegment.segmentId === "0"
                      })}

                      {AgToolbarButton({
                        ...toolbarProps,
                        title: "Remove",
                        icon: "fa-trash",
                        onClick: onRemoveSegment,
                        disabled: editSegment.segmentId === "0"
                      })}

                      {AgToolbarButton({
                        ...toolbarProps,
                        title: "Export Responses",
                        icon: "fa-poll-h",
                        onClick: onExportResponses,
                        disabled: editSegment.segmentId === "0"
                      })}

                      {AgToolbarButton({
                        ...toolbarProps,
                        title: "Open in Maps",
                        icon: "fa-globe-europe",
                        onClick: onOpenInMaps
                      })}

                      {AgToolbarButton({
                        ...toolbarProps,
                        title: "Export to Excel",
                        rightAlign: true,
                        icon: "fa-file-export",
                        onClick: () => {
                          gridAPI!.exportDataAsExcel();
                        }
                      })}

                      {AgToolbarButton({
                        ...toolbarProps,
                        title: "Adjust columns to content",
                        rightAlign: true,
                        icon: "fa-text-width",
                        onClick: () => {
                          var allColumnIds: any = [];
                          columnAPI!.getAllColumns().forEach(column => {
                            if (!column.getColDef().suppressAutoSize) {
                              allColumnIds.push(column.getColId());
                            }
                          });
                          columnAPI!.autoSizeColumns(allColumnIds);
                        }
                      })}

                      {AgToolbarButton({
                        ...toolbarProps,
                        title: "Adjust columns to window",
                        rightAlign: true,
                        icon: "fa-columns",
                        onClick: () => {
                          gridAPI!.sizeColumnsToFit();
                        }
                      })}

                      {AgToolbarButton({
                        ...toolbarProps,
                        title: "Refresh",
                        rightAlign: true,
                        icon: "fa-sync-alt",
                        onClick: () => {
                          runReport(reportQuery);
                        }
                      })}
                    </div>

                    <div className="ag-theme-balham" style={{ height: "800px", width: "100%" }}>
                      <AgGridReact
                        columnDefs={columnDefs}
                        rowData={rowData}
                        groupIncludeTotalFooter={false}
                        groupIncludeFooter={false}
                        groupMultiAutoColumn={true}
                        animateRows={true}
                        enableRangeSelection={false}
                        onSelectionChanged={onSelectionChanged}
                        rowSelection="multiple"
                        onGridReady={onGridReady}
                        onFirstDataRendered={onFirstDataRendered}
                        onRowDoubleClicked={onRowDoubleClicked}
                        sideBar={{
                          toolPanels: ["columns", "filters"]
                        }}
                        suppressAggFuncInHeader={true}
                        defaultColDef={{
                          sortable: true,
                          resizable: true,
                          filter: true
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <EditSegmentComponent
        show={editSegment.show}
        segmentId={editSegment.segmentId}
        onHide={() => {
          setEditSegment({ ...editSegment, show: false, segmentId: "0" });
          runReport(reportQuery);
        }}
        onError={msg => {
          setEditSegment({ ...editSegment, show: false, segmentId: "0" });
          alert(msg);
        }}
        onRemove={onRemoveSegment}
      />

      {showLargeMap ? <LargeMapComponent rowData={rowData} onClose={() => setShowLargeMap(false)} /> : null}
      <ExportResponsesComponent
        rowData={rowData}
        selectedSegment={editSegment}
        onHide={() => setShowExportDialog(false)}
        showDialog={showExportDialog}
      />
    </div>
  );

  function getDefaultEndDate() {
    let defaultEndDate = new Date();
    defaultEndDate.setMonth(defaultEndDate.getMonth() + 3);
    defaultEndDate.setDate(0);
    return defaultEndDate;
  }

  function getDefaultStartDate() {
    let startDate = new Date();
    startDate.setDate(1);
    return startDate;
  }
}
