import React, { useEffect, useState } from "react";
import { Route, useHistory, Redirect } from "react-router-dom";
import { loadLegacyPage } from "./modules/shell/shell.api";
import { useSelector, useDispatch } from "react-redux";

import DirectoriesComponent from "./modules/listmanagement/directories.component";
import DirectoryDetailsComponent from "./modules/listmanagement/directories/directorydetails.component";
import StatisticsComponent from "./modules/listmanagement/statistics.component";
import MapsComponent from "./modules/listmanagement/maps.component";
import OrdersComponent from "./modules/reports/orders.component";
import OrdersNewComponent from "./modules/reports/ordersnew.component";
import OrdersComponent2 from "./modules/reports/orders2.component";
import OrdersComponent3 from "./modules/reports/orders3.component";
import ReportOutboundComponent from "./modules/reports/reportoutbound.component";
import SalesReportComponent from "./modules/reports/salesreport.component";
import GetPageComponent from "./getpage.component";
import { useLocation } from "react-use";
import AdminGuard from "./adminguard.component";
import AdminComponent from "./modules/admin/admin.component";
import OrderRejectedReasons from "./modules/campaignmanagement/orderrejectedreasons.component";
import FormsComponent from "./modules/campaignmanagement/forms.component";
import OutcomesComponent from "./modules/campaignmanagement/outcomes.component";
import ElhubPlugin from "./modules/plugins/elhub.component";
import SmsComponent from "./modules/sms/sendsms.component";
import SmsCampaignComponent from "./modules/sms/campaign.component";
import UserLogReportComponent from "./modules/settings/userlogreport.component";
import ErrorMessagesComponent from "./modules/settings/errormessages.component";
import SoundfilesComponent from "./modules/handling/soundfiles.component";
import ActivityReportComponent from "./modules/reports/activityreport.component"
import MapsReportComponent from "./modules/reports/mapsreport.component";
import CallbackComponent from './modules/reports/callback.component'

declare var getPage: any;
declare var page: any;
declare var $: any;
declare var intervals: any;
declare var swal: any;
declare var mod_data: any;
declare var getUrlPath: any;
declare var pathList: any;
declare var Utility: any;

const exactRoutes = [
  { path: "/reports/outbound", component: ReportOutboundComponent },
  { path: "/reports/activityreport", component: ActivityReportComponent },
  { path: "/reports/mapsreport", component: MapsReportComponent },
  { path: "/reports/callbacks", component: CallbackComponent },
  // { path: "/reports/campaignsWithAgents", component: SalesReportComponent },

  { path: "/orders", component: OrdersComponent2 },
  { path: "/orders2", component: OrdersComponent2 },
  { path: "/orders/old", component: OrdersComponent },
  { path: "/orders3", component: OrdersComponent3 },
  { path: "/orders/new", component: OrdersNewComponent },

  { path: "/listmanagement/directories", component: DirectoriesComponent },
  { path: "/listmanagement/directories/:id", component: DirectoryDetailsComponent},
  { path: "/listmanagement/statistics", component: StatisticsComponent },
  { path: "/listmanagement/maps", component: MapsComponent },

  { path: "/campaignmanagement/offer/offerView/:formId", component: FormsComponent},
  { path: "/campaignmanagement/outcomeReason", component: OutcomesComponent},
  { path: "/campaignmanagement/offer/offerView/:formId/:selectTab", component: FormsComponent },
  { path: "/campaignmanagement/outcomeReason/:selectTab", component: OutcomesComponent},
  { path: "/campaignmanagement/orderrejectedreasons", component: OrderRejectedReasons},

  { path: "/sms/campaign", component: SmsCampaignComponent },
  { path: "/sms/sendsms", component: SmsComponent },

  { path: "/handling/soundfiles", component: SoundfilesComponent },
  
  { path: "/plugins/elhub", component: ElhubPlugin },
  { path: "/settings/userLogReport", component: UserLogReportComponent },
  { path: "/settings/errorMessages", component: ErrorMessagesComponent},
  { path: "/admin/control", component: AdminComponent }
];

export default function RoutesComponent() {
  const dispatch = useDispatch();
  const location = useLocation();
  const legacyHTML = useSelector(state => state.shell.legacy.html);
  const [redirectURL, setRedirectURL] = useState<null | string>(null);
  const history = useHistory();

  useEffect(() => {
    if (location && location.pathname) {
      console.log("Location updated", location.pathname);
      updatePage(location.pathname.substr(1).split("/"), false);
    }
  }, [location.pathname]);

  const updatePage = (array: string[], params = false) => {
    pathList = "";

    if (array[1] != getUrlPath()[1]) {
      mod_data.reset();
      page.clearJson();
    }

    if (params) {
      page.setJson = params;
    }

    /**
     * Save into page object for easy access
     */
    page.setP1 = array[0];
    page.setP2 = array[1];
    page.setP3 = array[2];
    page.setP4 = array[3];

    // If pushstat is supported
    if (array[0]) pathList += "/" + array[0];
    if (array[1]) pathList += "/" + array[1];
    if (array[2]) pathList += "/" + array[2];
    if (array[3]) pathList += "/" + array[3];

    var currentLocation = window.location.href.split("#");
    var decodedUri = decodeURI(currentLocation[1]);

    page.json = Utility.prototype.IsJsonString(decodedUri);

    if (page.getJson) localStorage.setItem("json", page.json);

    if (pathList == "") pathList = "/";

    page.appendToVisitedPage(pathList, true);
  };

  // Patch legacy getPage-function to our internal React-function
  // This gives us control over how legacy pages are loaded.
  getPage = (urlParts: string[], params = false, popstat, willNavigate = true) => {
    if (urlParts === undefined) {
      urlParts = [];
    }

    // Hide search
    $("#searchResultsModal").slideUp();

    /**
     * remove tooltips when changing pages
     */
    $(".tooltip").remove();

    /**
     * Reset intervals
     */
    intervals.resetIntervals();

    // Reset swal defaults if they  are present
    swal.mixin();

    updatePage(urlParts, params);

    let url = "";
    urlParts
      .filter(p => p != null)
      .forEach(p => {
        url = url + "/" + p;
      });
    setRedirectURL(url);
  };

  return (
    <>
      {redirectURL !== null ? <Redirect to={redirectURL} push /> : null}
      {exactRoutes.map((r, i) => (
        <Route key={i} path={r.path} exact component={r.component} />
      ))}

      {/*
			<Route path="/reports/outbound" exact component={ReportOutboundComponent} />
			<Route path="/reports/ordersreport2" exact component={OrdersComponent} />
			<Route path="/listmanagement/directories" exact component={DirectoriesComponent} />
			<Route path="/listmanagement/directories/:id" exact component={DirectoryDetailsComponent} />
			<Route path="/listmanagement/statistics" exact component={StatisticsComponent} />
			<Route path="/listmanagement/maps" exact component={MapsComponent} />
			*/}

      {/* Temporary confirmation route until we have more modules
			<Route path="/campaign_management/offer/offerViewConfirmation/:formId" exact component={ConfirmationComponent} />
			*/}
      <Route path="/" children={props => <GetPageComponent {...props} routes={exactRoutes} />} />
    </>
  );
}