import React, { useEffect } from "react";
import AdminGuard from "../../adminguard.component";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import JobsComponent from "./jobs/jobs.component";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}));

export default function AdminComponent() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <AdminGuard>
      <div className="row clearfix page-title" style={{ background: "none", padding: 0, height: 'auto'}}>
        <AppBar position="relative">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Job Queue" {...a11yProps(0)} />
            <Tab label="Autoload" {...a11yProps(1)} />
            <Tab label="Logs" {...a11yProps(2)} />
            <Tab label="Live Messages" {...a11yProps(3)} />
          </Tabs>
        </AppBar>
        { value === 0 ? <JobsComponent /> : null}

        <TabPanel value={value} index={1}>
          Autoloads / API tokens
        </TabPanel>
        <TabPanel value={value} index={2}>
          Search log_x
        </TabPanel>
        <TabPanel value={value} index={3}>
          Websocket client ={'>'} nats listener
        </TabPanel>
      </div>
    </AdminGuard>
  );
}
