import React from 'react';
declare var env: any;

export interface Segment  {
    segment_id: number,
    created_at: string,
    campaign_id: number,
    segment_name: string,
    state?: unknown,
    contacts: number,
    yes: number,
    no: number,
    lost: number,
    available: number,
    ready: number,
    ready_now: number,
    change_state_available: number,
    available_operations: [string]
}
export interface SegmentActivities {
    activity_id: string,
    activity_type_id: string,
    activity_type_name: string,
    campaign_id: string,
    segment_id: string,
    agent_id: string,
    fullname: string,
    login_name: string,
    team_id: string,
    customer_id: string,
    created_at: string,
    contact_id: string,
    firstname?: string,
    lastname?: string,
    street: string,
    city?: string,
    zip: string,
    mobile?: string,
    phone?: string,
    outcome_id: string,
    outcome_reason_id: string,
    outcome_name: string,
    outcome_reason_name: string,
}
export const fetchSegments = async (campaign_id: string) => {
    
    const res = await fetch(env._apiPath + 
        `/campaign/segments?campaign_id=${campaign_id}`, {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    const json = await res.json();

    if (json.status === "success") {
        return json;
    }

    return json;
}

export const fetchSegmentActivities = async (segment_id: string) => {
    const res = await fetch(env._apiPath + 
        `/reports/segmentActivities?segment_id=${segment_id}`, {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    const json = await res.json();

    if (json.status === "success") {
        return json;
    }

    return json;
}