import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { fetchErrorMessages, IErrorMessage } from './errormessages/errormessages.api';
import ClientSelector from "../shell/clientselector.component";
import CampaignSelector from "../shell/campaignselector.component";
import CompanySelector from "../shell/companyselector.component";
import DepartmentSelector from "../shell/departmentselector.component";
import './errormessages/errormessages.scss';

import TitlebarComponent from "../../titlebar.component";

// material ui
import { Grid, 
    makeStyles,Theme,createStyles,
    CardContent, Card, CardActions,
    Button, TextField,
    Dialog, DialogTitle, DialogContent,DialogActions,
    Snackbar, Paper, MenuItem
} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider  } from '@material-ui/pickers';

// ag gridsystemet
import { AgToolbarButton } from "../../agGridUtils";
import { AgGridReact } from "ag-grid-react";
import {
    GridReadyEvent,
    FirstDataRenderedEvent,
    SelectionChangedEvent,
    GridApi,
    ColumnApi,
} from "ag-grid-community";
import { actionToggleFilterView } from "../../redux/reducers/shell";
function formatDateTime(date) {
    var d = new Date(date.replace(/\s/, 'T')),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour = '' + d.getHours(),
        min = '' + d.getMinutes();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    if (hour.length < 2) 
        hour = '0' + hour;
    if (min.length < 2) 
        min = '0' + min;

    return ([day, month, year].join('.') + ' ' + hour + ':' + min);
}
// theme for AdGridReact
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorMessage: {
            color: '#ff0000'
        },
        flexGrow: {
            flexGrow: 1,
        },
        actions: {
            "& .form-group": {
                minWidth: 200,
                marginRight: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
            "& button": {
                //marginTop: 24,
            },
        },
        agGrid: {
            "& .aggrid-size": {
                minHeight: 500,
                height: "calc(100vh - 460px)",
                width: "100%",
            },
        },
    })
);
// for knappene over tabellen
const toolbarProps = {
    title: "",
    rightAlign: false,
    disabled: false,
    icon: "",
    onClick: () => { },
};
const marginStyles = {
    // margin: '24px 0 0 0',
 }

 const columnDefs = [
    { headerName: "Id", field: "log_error_id", sortable: true, filter: true,},
    { headerName: "Error", field: "log_error_name", sortable: true, filter: true},
    { headerName: "Created At", field: "created_at", sortable: true, filter: true,
        cellRenderer: (r) => {
            if (r.data.created_at) {
                return formatDateTime(r.data.created_at)
            }
            return '';
        }},
    { headerName: "Agent Id", field: "core_agent_id", sortable: true, filter: true},
    { headerName: "Campaign Id", field: "core_campaign_id", sortable: true, filter: true},
    { headerName: "Contact Id", field: "contact_id", sortable: true, filter: true},
    { headerName: "Session Id", field: "session_id", sortable: true, filter: true},
    { headerName: "OMS URL", field: "oms_url", sortable: true, filter: true},
    { headerName: "API URL", field: "api_url", sortable: true, filter: true},
    { headerName: "Script Node", field: "script_node", sortable: true, filter: true},
    // de under er hidden
    { headerName: "Updated At", field: "updated_at", sortable: true, filter: true, hide: true,
        cellRenderer: (r) => {
            if (r.data.updated_at) {
                return formatDateTime(r.data.updated_at)
            }
            return '';
        }},
    { headerName: "Deleted At", field: "deleted_at", sortable: true, filter: true, hide: true,
        cellRenderer: (r) => {
            if (r.data.deleted_at) {
                return formatDateTime(r.data.deleted_at)
            }
            return '';
        }},
];
const today = new Date();
const d = new Date();
const todayMinus14 = d.setDate(d.getDate()-14);

export default function ErrorMessagesComponent() {
    const [results, setResults] = useState<IErrorMessage[]>([]);
    const [showDialog, setShowDialog] = useState(false);
    const [searchObject, setSearchObject] = useState({
        from_date: formatDate(todayMinus14), 
        to_date: formatDate(today),
        client_id: null,
        campaign_id: null,
        company_id: null,
        department_id: null});

    const [fromDate, setFromDate] = useState<Date | number | null>(todayMinus14)
    const [toDate, setToDate] = useState<Date | null>(today)

    // const selectedCustomer = useSelector(state => state.shell.selectedCustomer);
    const selectedClient = useSelector(state => state.shell.selectedClient);
    const selectedCampaign = useSelector(state => state.shell.selectedCampaign);
    const selectedCompany = useSelector(state => state.shell.selectedCompany);
    const selectedDepartment = useSelector(state => state.shell.selectedDepartment);

    // for tabellen
    const dispatch = useDispatch();
    const classes = useStyles();
    const [gridAPI, setGridAPI] = useState<GridApi>();
    const [columnAPI, setColumnAPI] = useState<ColumnApi>();
    const hideFilters = useSelector((state) => state.shell.hideFilters);
    // standard variabler
    const [loading, setLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [alertMsg, setAlertMsg] = useState({msg: "",severity: false});
    const [errorMsg, setErrorMsg] = useState("");

    // funksjoner for agGrid
    // Dette skjer når du klikker på en rad i tabellen, lagrer object med file id
    const onSelectionChanged = (event: SelectionChangedEvent) => {
        const rows = event.api.getSelectedRows();
        if (!rows[0]) {
            return;
        }
        // TODO: setSelectedContact(rows[0]);
    };
    // søkefelt som søker i tabellen
    const handleQuickFilter = event => {
        gridAPI!.setQuickFilter(event.target.value);
    };
    // laster inn data om tabellen
    const onGridReady = (event: GridReadyEvent) => {
        setGridAPI(event.api);
        setColumnAPI(event.columnApi);
    };
    // 3 knapper over tabellen, refresh, og endre størrelse på kolonner
    const onAdjustColumnsContent = (event: Event) => {
        var allColumnIds: any = [];
        columnAPI!.getAllColumns().forEach((column) => {
            const colDef = column.getColDef();
            if (!colDef.suppressAutoSize) {
                allColumnIds.push(column.getColId());
            }
        });
        columnAPI!.autoSizeColumns(allColumnIds);
    };
    const onAdjustColumnsWindow = (event: Event) => {
        gridAPI!.sizeColumnsToFit();
    };
    const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
        event.api.sizeColumnsToFit();
    };
    const onExportToCsv = () => {
        if (gridAPI) {
        gridAPI.exportDataAsExcel();
        }
    };
    // end funksjoner for agGrid
    const getErrorMessages = () => {
        setLoading(true)
        fetchErrorMessages(searchObject)
        .then(res => setResults(res))
        .finally(() => setLoading(false))
        .catch(error => setErrorMsg(error))
    }
    const refreshTable = () => {
        getErrorMessages()
    }
    const resetForm = () => {
        setSearchObject({
            from_date: formatDate(todayMinus14), 
            to_date: formatDate(today),
            client_id: null,
            campaign_id: null,
            company_id: null,
            department_id: null})
    }
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return ([year, month, day].join('') );
    }
    useEffect(() => {
        if (selectedClient) 
        setSearchObject({...searchObject, client_id: selectedClient.value})
    },[selectedClient])
    useEffect(() => {
        if (selectedCampaign)
        setSearchObject({...searchObject, campaign_id: selectedCampaign.value})
    },[selectedCampaign])
    useEffect(() => {
        if (selectedCompany)
        setSearchObject({...searchObject, company_id: selectedCompany.value})
    },[selectedCompany])
    useEffect(() => {
        if (selectedDepartment)
        setSearchObject({...searchObject, department_id: selectedDepartment.value})
    },[selectedDepartment])
    useEffect(() => {
        setSearchObject({...searchObject, to_date: formatDate(toDate)})
    },[toDate])
    useEffect(() => {
        setSearchObject({...searchObject, from_date: formatDate(fromDate)})
    },[fromDate])

    useEffect(() => {
        getErrorMessages()
    },[])

    return (<>
    <TitlebarComponent
                title="ErrorMessages"
                description="View Error Messages"
                breadcrumbs={[
                    { title: "Dashboard", link: "/" },
                    { title: "ErrorMessages", link: "/settings/errorMessages" }
                ]}
            />
        <Grid container spacing={2}>
            
            <Grid item xs={12}>
                <div
                        style={{ color: "#ccc", width: 15 }}
                        data-toggle="tooltip"
                        data-original-title="Hide/Show filters"
                        onClick={() => dispatch(actionToggleFilterView())}
                    >
                        {hideFilters ? (
                            <i className="fas fa-fw fa-caret-square-right"></i>
                        ) : (
                                <i className="fas fa-fw fa-caret-square-left"></i>
                            )}
                    </div>
            </Grid>
            <Grid item xs={12} md={2} style={{ display: hideFilters ? "none" : "block" }}>
                <ClientSelector />
                <CompanySelector />
                <div className={ `camSel ${ selectedClient ? null : 'disabled'}`}>
                <CampaignSelector /></div>
                <div className={ `depSel ${ selectedCompany ? null : 'disabled'}`}>
                <DepartmentSelector /></div>
                <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="fromDate">
                        <KeyboardDatePicker
                            className="datePicker"
                            disableToolbar
                            variant="inline"
                            format="dd.MM.yyyy"
                            margin="normal"
                            id="date-picker-inline"
                            label="Date From"
                            value={fromDate}
                            onChange={(e) => setFromDate(e)}
                            KeyboardButtonProps={{
                                'aria-label': 'change from date',
                            }}
                            />
                        </div>
                        <div className="toDate">
                        <KeyboardDatePicker
                            className="datePicker"
                            disableToolbar
                            variant="inline"
                            format="dd.MM.yyyy"
                            margin="normal"
                            id="date-picker-inline2"
                            label="Date To"
                            value={toDate}
                            onChange={(e) => setToDate(e)}
                            KeyboardButtonProps={{
                                'aria-label': 'change to date',
                            }}
                            />
                        </div>
                    </MuiPickersUtilsProvider>
                </div>
                <div className="errSearchButtons">
                    <Button
                        className="errSearchButton"
                        color="primary"
                        variant="outlined"
                        onClick={() => resetForm()}>Reset Form</Button>
                    <Button
                        className="errSearchButton"
                        color="primary"
                        variant="contained"
                        onClick={() => getErrorMessages()}>Search</Button>
                </div>
            </Grid>
            <Grid item xs={12} md={hideFilters ? 12 : 10}>
            <Card>
            <CardActions disableSpacing className={classes.actions}>
                {/* Knappene over tabellen */}
                <div className={classes.flexGrow} />
                <input 
                    type="text" 
                    style={marginStyles}
                    placeholder="Search" 
                    onChange={handleQuickFilter}
                />
                {AgToolbarButton({
                    ...toolbarProps,
                    title: "Export to CSV",
                    icon: "fa-sync-alt",
                    onClick: refreshTable,
                })}
                {AgToolbarButton({
                    ...toolbarProps,
                    title: "Export to CSV",
                    icon: "fa-file-export",
                    onClick: onExportToCsv,
                })}

                {AgToolbarButton({
                    ...toolbarProps,
                    title: "Adjust columns to window",
                    icon: "fa-columns",
                    onClick: onAdjustColumnsWindow,
                })}

                {AgToolbarButton({
                    ...toolbarProps,
                    title: "Adjust columns to content",
                    icon: "fa-text-width",
                    onClick: onAdjustColumnsContent,
                })}
            </CardActions>
            {/* Selve tabellen */}
            <CardContent >
                <Grid container>
                <Grid item xs={12} className={classes.agGrid}>
                    <div className="ag-theme-balham aggrid-size">
                        <AgGridReact
                            rowData={results}
                            columnDefs={columnDefs}  
                            onGridReady={onGridReady}
                            onSelectionChanged={onSelectionChanged}
                            enableRangeSelection={false}
                            rowSelection="single"
                            // justerer kolonnebredden etter load
                            onFirstDataRendered={onFirstDataRendered}
                            sideBar={{
                                toolPanels: ["columns"],
                            }}
                            // pagination
                            pagination={true}
                            paginationPageSize={100}
                            cacheBlockSize={100}
                            cacheOverflowSize={2}
                            maxBlocksInCache={2}
                            defaultColDef={{
                                sortable: true,
                                resizable: true,
                                filter: true,
                            }}
                            enableCellTextSelection={true}
                            ensureDomOrder={true}
                        />
                    </div>
                </Grid>
                </Grid>
            
            </CardContent>  
            </Card>
            </Grid>
        </Grid>
        <Dialog fullWidth maxWidth="md" open={showDialog} onClose={() => setShowDialog(false)}>
            <DialogTitle>Add contact</DialogTitle>
            <DialogContent></DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowDialog(false)}>
                        Cancel
                    </Button>
            </DialogActions>
        </Dialog>
        {/* alert bar */}
        <Snackbar 
            open={alertOpen} 
            autoHideDuration={3000} 
            onClose={() => setAlertOpen(false)}>
            <MuiAlert 
                variant="filled"
                severity={ alertMsg.severity ? "success" : "error"}>{alertMsg.msg}
            </MuiAlert>
        </Snackbar>
    </>)
}