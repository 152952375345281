import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { actionSelectLogUser, actionSelectLogType } from "../../redux/reducers/shell";
import { MenuItem, TextField } from "@material-ui/core";

/*  Brukes til settings/userLogReport 
    Stefan Skorpen 26.10.20     
*/

/*
interface IUserSelectorProps {
    user_id: number,
    email: string,
    fullname: string,
    user_type: string,
    last_seen: Date,
    last_seen_minutes: number,
    profile_picture: string,
    online: number,
    client_access: number,
    company_access: number,
    department_access: number,
    accepted: number,
  }
*/

export default function UserSelector() {
    const dispatch = useDispatch();
    const orgUsers = useSelector(state => state.shell.orgUsers);
    const selectedUser = useSelector(state => state.shell.selectedLogUser);
    const logType = useSelector(state => state.shell.receivedLogTypes);
    const selectedLogType = useSelector(state => state.shell.selectedLogType);

    function handleChange(e) {
        if (e.target.value == "") {
          dispatch(actionSelectLogUser(undefined));
        }
        else {
          dispatch(actionSelectLogUser(e.target));
        }
      }
    function handleChangeLog(e) {
        if (e.target.value == "") {
            dispatch(actionSelectLogType(undefined));
        }
        else {
            dispatch(actionSelectLogType(e.target));
        }
    }

    return (<>
        <TextField 
          value={selectedUser ? selectedUser.value : ""}
          select 
          label="User"
          fullWidth
          onChange={handleChange}
          >
          <MenuItem value="">None</MenuItem>
          {orgUsers.map((user, key) => (
            <MenuItem key={key} value={user.user_id}>{user.fullname ? user.fullname : user.email}</MenuItem>
          ))}
        </TextField>
        <TextField 
          value={selectedLogType ? selectedLogType.value : ""}
          select 
          label="Logtype"
          fullWidth
          onChange={handleChangeLog}
          >
          <MenuItem value="">None</MenuItem>
          {logType.map((log, key) => (
            <MenuItem key={key} value={log.log_user_type_id}>{log.log_user_type_name}</MenuItem>
          ))}
        </TextField>
    </>
    );
}