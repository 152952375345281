import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import RejectReasonsAPI from "./rejectreasons.api";
import { actionStartAPI, actionEndAPI } from "../../../redux/reducers/shell";
import Form from "react-bootstrap/Form";

interface ICreateReasonPopupProps {
  open: boolean;
  onCancelAddReason: any;
  onAddReason: any;
  rejectType: any,
  editReason: IRejectReason;
}

export interface IRejectReason {
  reasonID: number | null;
  client: any;
  rejectType: any;
  description: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorMessage: {
      color: "#ff0000"
    }
  })
);

const rejectTypes = [
  { value: "1", label: "Cancellation" },
  { value: "2", label: "Regret" },
  { value: "3", label: "Undo Cancel" },
];

export default function CreateReasonPopup(props: ICreateReasonPopupProps) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [selectedRejectType, setSelectedRejectType] = useState(rejectTypes[0]);
  const [rejectDescription, setRejectDescription] = useState<string | undefined>();
  const [errorMessage, setErrorMessage] = useState("");
  const clients = useSelector(state => state.shell.clients);
  const [selectedClient, setSelectedClient] = useState();
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    const lEditMode: boolean = props.editReason && props.editReason.reasonID !== 0 ? true : false;

    setEditMode(lEditMode);

    if (lEditMode) {
      setRejectDescription(props.editReason ? props.editReason.description : "");

      const rejType = rejectTypes.find(r => r.value === (props.editReason ? props.editReason.rejectType : 0));
      console.log("sets reject type to: " + (rejType ? rejType : rejectTypes[0]));
      setSelectedRejectType(rejType ? rejType : rejectTypes[0]);

      const client = clients.find(
        c => c.value === (props.editReason && props.editReason.client ? props.editReason.client.client_id : 0)
      );

      setSelectedClient(client);
      return;
    }

    setRejectDescription("");

    if (props.rejectType !== undefined) {
      const rejType = rejectTypes.find(r => Number(r.value) === (props.rejectType ? props.rejectType.value : 0));
      setSelectedRejectType( rejType ? rejType : rejectTypes[0]);
    } else {
      setSelectedRejectType(rejectTypes[0]);
    }
    setSelectedClient(undefined);
  }, [props.open, props.editReason]);

  const onSelectRejectType = (opt: any) => {
    setSelectedRejectType(opt);
  };

  const onAddReason = (event: any) => {
    if (rejectDescription!.length === 0) {
      setErrorMessage("Description must not be empty.");
      return;
    }

    setErrorMessage("");

    const rejectReason: IRejectReason = {
      reasonID: null,
      client: selectedClient,
      rejectType: selectedRejectType.value,
      description: rejectDescription
    };

    if (editMode) {
      dispatch(actionStartAPI());
      RejectReasonsAPI.updateRejectedReason(
        props.editReason.reasonID,
        rejectReason.description,
        rejectReason.client ? rejectReason.client.value : null,
        rejectReason.rejectType
      )
        .then(() => {
          props.onAddReason(event, rejectReason);
        })
        .catch((e: any) => setErrorMessage("Error adding rejected reason: " + e))
        .finally(() => dispatch(actionEndAPI()));
      return;
    }

    dispatch(actionStartAPI());
    RejectReasonsAPI.createRejectedReason(
      rejectReason.description,
      rejectReason.client ? rejectReason.client.value : null,
      rejectReason.rejectType
    )
      .then(() => {
        props.onAddReason(event, rejectReason);
      })
      .catch((e: any) => setErrorMessage("Error adding rejected reason: " + e))
      .finally(() => dispatch(actionEndAPI()));
  };

  return (
    <Dialog open={props.open} onClose={props.onCancelAddReason} aria-labelledby="frm-add-reason">
      <DialogTitle id="frm-add-reason">{editMode ? "Edit Reason" : "Add Reason"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {editMode
            ? "The following properties of the rejected reason can be modified."
            : "Please enter a description of the new reject reason. Optionally limit the reason to a client."}
        </DialogContentText>

        <Form.Group controlId="frmClient">
          <Form.Label>Client</Form.Label>
          <Select
            value={selectedClient}
            onChange={(opt: any) => setSelectedClient(opt)}
            options={clients}
            isClearable={true}
          />
        </Form.Group>

        <Form.Group controlId="frmClient">
          <Form.Label>Rejected Type</Form.Label>
          <Select value={selectedRejectType} onChange={onSelectRejectType} options={rejectTypes} isClearable={true} />
        </Form.Group>

        <TextField
          autoFocus
          margin="dense"
          id="reason"
          label="Description"
          type="text"
          fullWidth
          value={rejectDescription}
          onChange={(e: any) => setRejectDescription(e.target.value)}
        />

        {errorMessage ? <DialogContentText className={classes.errorMessage}>{errorMessage}</DialogContentText> : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onCancelAddReason} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={onAddReason} color="primary" variant="contained">
          {editMode ? "Save" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
