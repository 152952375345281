import {
  IFormData,
  ICampaignData,
  ICategoryData,
  IDiscountData,
  IFormFieldData,
} from "../../../modules/campaignmanagement/forms/form.types";

const initialState = {
  confirmations: {
    availableConfirmationMethods: [],
    formConfirmations: [],
  },
  customerSenders: [],
  smsMergeFields: [],
  edit: {
    formData: {
      edited: false,
      form_id: 0,
      created_at: "",
      country_id: "",
      form_name: "",
      customer_id: 0,
      client_id: "",
      company_id: "",
      token: "",
      form_description: "",
      comment: "",
      form_start_date: "",
      _start_date: null,
      form_end_date: "",
      _end_date: null,
      created_by: "",
      removed_by: "",
      removed_at: "",
      summary: "",
      intro: "",
      b2b: "",
      customer_sender_id: "",
      use_order_headers: "",
      update_contact: "",
      logo_path: "",
      color_primary: "",
      color_secondary: "",
      agent_access_level: "",
      form_type_id: "",
      queue_details: "",
      enable_sms: "",
    },
    campaigns: [],
    categories: [],
    discounts: [],
    formFields: [],
  },
};

export const CAMPAIGN_FORMS_EDIT_RECEIVED_FORMDATA = "@sky/CAMPAIGN_FORMS_EDIT_RECEIVED_FORMDATA";
export const actionReceivedFormData = (formData: IFormData) => ({
  type: CAMPAIGN_FORMS_EDIT_RECEIVED_FORMDATA,
  formData,
});

export const CAMPAIGN_FORMS_EDIT_UPDATE_FORMDATA = "@sky/CAMPAIGN_FORMS_EDIT_UPDATE_FORMDATA";
export const actionUpdatedFormData = (formData: IFormData) => ({
  type: CAMPAIGN_FORMS_EDIT_UPDATE_FORMDATA,
  formData,
});

export const CAMPAIGN_FORMS_RECEIVED_CAMPAIGNS = "@sky/CAMPAIGN_FORMS_RECEIVED_CAMPAIGNS";
export const actionReceivedCampaigns = (campaigns: ICampaignData[]) => ({
  type: CAMPAIGN_FORMS_RECEIVED_CAMPAIGNS,
  campaigns,
});

export const CAMPAIGN_FORMS_RECEIVED_CATEGORIES = "@sky/CAMPAIGN_FORMS_RECEIVED_CATEGORIES";
export const actionReceivedCategories = (categories: ICategoryData[]) => ({
  type: CAMPAIGN_FORMS_RECEIVED_CATEGORIES,
  categories,
});

export const CAMPAIGN_FORMS_RECEIVED_DISCOUNTS = "@sky/CAMPAIGN_FORMS_RECEIVED_DISCOUNTS";
export const actionReceivedDiscounts = (discounts: IDiscountData[]) => ({
  type: CAMPAIGN_FORMS_RECEIVED_DISCOUNTS,
  discounts,
});

export const CAMPAIGN_FORMS_RECEIVED_FORMFIELDS = "@sky/CAMPAIGN_FORMS_RECEIVED_FORMFIELDS";
export const actionReceivedFormFields = (formFields: IFormFieldData[]) => ({
  type: CAMPAIGN_FORMS_RECEIVED_FORMFIELDS,
  formFields,
});

export const CAMPAIGN_FORMS_RECEIVED_CONFIRMATIONS = "@sky/CAMPAIGN_FORMS_RECEIVED_CONFIRMATIONS";
export const actionReceivedConfirmations = (confirmations: any) => ({
  type: CAMPAIGN_FORMS_RECEIVED_CONFIRMATIONS,
  confirmations,
});

export const CAMPAIGN_FORMS_RECEIVED_CONFIRMATION_DETAIL = "@sky/CAMPAIGN_FORMS_RECEIVED_CONFIRMATION_DETAIL";
export const actionReceivedConfirmationDetail = (confirmationDetail: any) => ({
  type: CAMPAIGN_FORMS_RECEIVED_CONFIRMATION_DETAIL,
  confirmationDetail,
});

export const CAMPAIGN_FORMS_RECEIVED_CUSTOMERSENDERS = "@sky/CAMPAIGN_FORMS_RECEIVED_CUSTOMERSENDERS";
export const actionReceivedCustomerSenders = (customerSenders: any) => ({
  type: CAMPAIGN_FORMS_RECEIVED_CUSTOMERSENDERS,
  customerSenders,
});

export const CAMPAIGN_FORMS_CONFIRMATION_FAILED = "@sky/CAMPAIGN_FORMS_CONFIRMATION_FAILED";
export const actionFormConfirmationFailed = (message, formId, confirmationMethodId, customerSenderId) => ({
  type: CAMPAIGN_FORMS_CONFIRMATION_FAILED,
  message,
  formId,
  confirmationMethodId,
  customerSenderId,
});

export const CAMPAIGN_FORMS_CONFIRMATIONUPDATE_FAILED = "@sky/CAMPAIGN_FORMS_CONFIRMATIONUPDATE_FAILED";
export const actionFormConfirmationUpdateFailed = (message, formConfirmationId, customerSenderId) => ({
  type: CAMPAIGN_FORMS_CONFIRMATIONUPDATE_FAILED,
  message,
  formConfirmationId,
  customerSenderId,
});

export const CAMPAIGN_FORMS_CONFIRMATIONDELETE_FAILED = "@sky/CAMPAIGN_FORMS_CONFIRMATIONDELETE_FAILED";
export const actionFormConfirmationDeleteFailed = (message, formConfirmationId) => ({
  type: CAMPAIGN_FORMS_CONFIRMATIONDELETE_FAILED,
  formConfirmationId,
});

export const CAMPAIGN_FORMS_CONFIRMATION_TEXT_FAILED = "@sky/CAMPAIGN_FORMS_CONFIRMATION_TEXT_FAILED";
export const actionFormConfirmationTextFailed = (
  message,
  formConfirmationId,
  confirmationTextTypeId,
  confirmationText
) => ({
  type: CAMPAIGN_FORMS_CONFIRMATION_TEXT_FAILED,
  message,
  formConfirmationId,
  confirmationTextTypeId,
  confirmationText,
});

export const CAMPAIGN_FORMS_CONFIRMATION_TEXTUPDATE_FAILED = "@sky/CAMPAIGN_FORMS_CONFIRMATION_TEXTUPDATE_FAILED";
export const actionFormConfirmationTextUpdateFailed = (message, formConfirmationTextId, confirmationText) => ({
  type: CAMPAIGN_FORMS_CONFIRMATION_TEXTUPDATE_FAILED,
  message,
  formConfirmationTextId,
  confirmationText,
});

export const CAMPAIGN_FORMS_CONFIRMATION_TEXTDELETE_FAILED = "@sky/CAMPAIGN_FORMS_CONFIRMATION_TEXTDELETE_FAILED";
export const actionFormConfirmationTextDeleteFailed = (message, formConfirmationTextId) => ({
  type: CAMPAIGN_FORMS_CONFIRMATION_TEXTDELETE_FAILED,
  formConfirmationTextId,
});

export const CAMPAIGN_FORMS_CHANGED_CONFIRMATION = "@sky/CAMPAIGN_FORMS_CHANGED_CONFIRMATION";
export const actionChangedConfirmation = (confirmation) => ({
  type: CAMPAIGN_FORMS_CHANGED_CONFIRMATION,
  confirmation,
});

export const CAMPAIGN_FORMS_RECEIVED_SMSMERGEFIELDS = "@sky/CAMPAIGN_FORMS_RECEIVED_SMSMERGEFIELDS";
export const actionReceivedSMSMergeFields = (smsMergeFields) => ({
  type: CAMPAIGN_FORMS_RECEIVED_SMSMERGEFIELDS,
  smsMergeFields,
});

export default function (state = initialState, action) {
  switch (action.type) {
    case CAMPAIGN_FORMS_EDIT_RECEIVED_FORMDATA:
      return {
        ...state,
        edit: {
          ...state.edit,
          formData: {
            ...action.formData,
            edited: false,
          },
        },
      };
    case CAMPAIGN_FORMS_EDIT_UPDATE_FORMDATA:
      return {
        ...state,
        edit: {
          ...state.edit,
          formData: {
            ...action.formData,
            edited: true,
          },
        },
      };
    case CAMPAIGN_FORMS_RECEIVED_CAMPAIGNS:
      return {
        ...state,
        edit: {
          ...state.edit,
          campaigns: action.campaigns,
        },
      };
    case CAMPAIGN_FORMS_RECEIVED_CATEGORIES:
      return {
        ...state,
        edit: {
          ...state.edit,
          categories: action.categories,
        },
      };
    case CAMPAIGN_FORMS_RECEIVED_DISCOUNTS:
      return {
        ...state,
        edit: {
          ...state.edit,
          discounts: action.discounts,
        },
      };
    case CAMPAIGN_FORMS_RECEIVED_FORMFIELDS:
      return {
        ...state,
        edit: {
          ...state.edit,
          formFields: action.formFields,
        },
      };
    case CAMPAIGN_FORMS_RECEIVED_CONFIRMATIONS:
      return {
        ...state,
        confirmations: {
          ...state.confirmations,
          availableConfirmationMethods: action.confirmations.available_confirmation_methods,
          formConfirmations: action.confirmations.form_confirmations,
        },
      };
    case CAMPAIGN_FORMS_CHANGED_CONFIRMATION:
      const formConfirmations = Object.assign([], state.confirmations.formConfirmations);
      formConfirmations.forEach((c: any) => {
        if (c.form_confirmation_id === action.confirmation.form_confirmation_id) {
          c.customer_sender_id = action.confirmation.customer_sender_id;
          c.customer_sender_name = action.confirmation.customer_sender_name;
          c.confirmation_text_type_id = action.confirmation.confirmation_text_type_id;
          c.confirmation_text_type_name = action.confirmation.confirmation_text_type_name;
          if (action.confirmation.confirmation_text_edit_id !== undefined) {
            c.confirmation_text_edit_id = action.confirmation.confirmation_text_edit_id;
          }
          c.confirmation_text = action.confirmation.confirmation_text;
          c.confirmDelete = action.confirmation.confirmDelete;
        }
      });
      return {
        ...state,
        confirmations: {
          ...state.confirmations,
          formConfirmations,
        },
      };
    case CAMPAIGN_FORMS_RECEIVED_SMSMERGEFIELDS:
      return { ...state, smsMergeFields: action.smsMergeFields };
    case CAMPAIGN_FORMS_RECEIVED_CUSTOMERSENDERS:
      return { ...state, customerSenders: action.customerSenders };
  }

  return state;
}
