import React, { useState, useEffect } from "react";
import Card from "@material-ui/core/Card";
import {
  CardContent,
  CardActions,
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import { ICampaignsProps, ICampaignData } from "./form.types";
import {
  GridApi,
  ColumnApi,
  GridReadyEvent,
  FirstDataRenderedEvent,
  Column,
  RowDoubleClickedEvent,
  SelectionChangedEvent
} from "ag-grid-community";
import { AgToolbarButton } from "../../../agGridUtils";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { useSelector, useDispatch } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import {
  getCampaigns,
  activateFormCampaign,
  connectCampaign,
  disconnectCampaign,
  addCampaignCPO
} from "./campaigns.api";
import { actionReceivedCampaigns, actionReceivedFormData } from "../../../redux/reducers/campaignmanagement/forms";
import CampaignSelector from "../../shell/campaignselector.component";
import { getFormData } from "./edit.api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexGrow: {
      flexGrow: 1
    },
    actions: {
      "& button.aggrid-btn": {
        //marginTop: 24
      }
    },
    agGrid: {
      display: "flex",
      flexDirection: "column",
      "& .aggrid-size": {
        minHeight: 500,
        //height: 500, //"calc(100vh - 460px)",
        flex: 1,
        width: "100%"
      }
    },
    dialogPaper: { overflow: "visible" }
  })
);

const toolbarProps = {
  title: "",
  rightAlign: false,
  disabled: false,
  icon: "",
  onClick: () => {}
};

export default function TabCampaignsComponent(props: ICampaignsProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const rowData = useSelector(state => state.forms.edit.campaigns);
  const selectedCampaign = useSelector(state => state.shell.selectedCampaign);
  const formData = useSelector(state => state.forms.edit.formData);

  // const [rowData, setRowData] = useState([]);
  const [gridAPI, setGridAPI] = useState<GridApi>();
  const [columnAPI, setColumnAPI] = useState<ColumnApi>();
  const [selectedRow, setSelectedRow] = useState<ICampaignData | null>(null);

  const [showConnectCampaign, setShowConnectCampaign] = useState(false);
  const [showDisconnectCampaign, setShowDisconnectCampaign] = useState(false);
  const [showAddCPO, setShowAddCPO] = useState(false);
  const [newCPO, setNewCPO] = useState("0");
  const [newCPOType, setNewCPOType] = useState("1");
  const [newCPODate, setNewCPODate] = useState<Date>(new Date());
  const [newCPOFormCampaignId, setNewCPOFormCampaignId] = useState(0);

  const columnDefs = [
    { headerName: "Campaign", field: "campaign_name" },
    {
      headerName: "Token",
      field: "token",
      onCellDoubleClicked: function(params) {
        // copyToClipboard(params.data.token);
      },
      tooltip: function(params) {
        return "Double click to copy token";
      }
    },
    {
      headerName: "CPO",
      field: "form_campaign_cpo.cpo",
      cellRenderer: function(params) {
        if (params.value) return params.value;
        else return "-";
      }
    },
    {
      headerName: "CPO Type",
      field: "form_campaign_cpo.cpo_type",
      cellRenderer: function(params) {
        if (params.value) {
          if (params.value == 1) return "Kr";
          else if (params.value == 2) return "%";
          else return "-";
        } else return "-";
      }
    },
    {
      headerName: "Edit CPO",
      cellRenderer: function(params) {
        if (params.data) return '<i class="fas fa-fw fa-hand-holding-usd"></i>';
      },
      cellClass: function(params) {
        return "btn btn-secondary ag-cell-btn ag-cell-tooltip";
      },
      onCellClicked: function(params) {
        if (params.data) {
          setShowAddCPO(true);
          setNewCPOFormCampaignId(params.data.form_campaign_id);
        }
      },
      tooltip: function(params) {
        if (params.data) {
          return "Click to edit CPO";
        }
      },
      suppressMenu: true,
      suppressFilter: true,
      suppressSorting: true,
      suppressResize: true
    },
    { headerName: "Created", field: "created_at" },
    {
      headerName: "Activate",
      field: "used_in_campaign",
      cellRenderer: function(params) {
        if (params.value == 1) return '<i class="far fa-fw fa-check-square"></i>';
        else if (params.value == -1) return '<i class="fas fa-fw fa-spin fa-spinner"></i>';
        else return '<i class="far fa-fw fa-square"></i>';
      },
      cellClass: function(params) {
        if (params.value == 0) return "btn btn-primary ag-cell-btn ag-cell-tooltip";
        else if (params.value == -1) return "text-center fs-14 text-warning";
        else return "text-center fs-14";
      },
      onCellClicked: function(params) {
        if (params.value == 0) {
          activateFormCampaign(params.data.form_campaign_id);
        }
      },
      tooltip: function(params) {
        if (params.value == 1) return "This campaign is active";
        else if (params.value == -1) return "This campaign is activating";
        else return "Click to activate this campaign";
      }
    },
    {
      headerName: "Test Form",
      field: "test_url",
      cellRenderer: function(params) {
        if (params.data) return '<i class="fas fa-fw fa-external-link-alt"></i>';
      },
      cellClass: function(params) {
        return "btn btn-default ag-cell-btn ag-cell-tooltip";
      },
      onCellClicked: function(params) {
        if (params.data) window.open(params.data.test_url, "_blank");
      },
      tooltip: function(params) {
        if (params.data) {
          return "Click to preview form";
        }
      },
      suppressMenu: true,
      suppressFilter: true,
      suppressSorting: true,
      suppressResize: true
    }
  ];

  useEffect(() => {
    getCampaigns(props.formId)
      .then((campaigns: ICampaignData[]) => dispatch(actionReceivedCampaigns(campaigns)))
      .catch(console.log);
    if (formData.form_id !== props.formId) {
      getFormData(props.formId).then(newFormData => {
        dispatch(actionReceivedFormData(newFormData));
      });
    }
  }, [props.formId, formData.form_id, dispatch]);

  const onGridReady = (event: GridReadyEvent) => {
    window.addEventListener("resize", () => setTimeout(() => event.api.sizeColumnsToFit()));
    event.api.sizeColumnsToFit();
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    event.api.sizeColumnsToFit();
  };

  const onAdjustColumnsContent = (event: Event) => {
    var allColumnIds: any = [];
        if (columnAPI) {
            const allColumns = columnAPI!.getAllColumns();
            allColumns!.forEach((column) => {
                const colDef = column.getColDef();
                if (!colDef.suppressAutoSize) {
                    allColumnIds.push(column.getColId());
                }
            });
            columnAPI!.autoSizeColumns(allColumnIds);
        }
  };

  const onAdjustColumnsWindow = (event: Event) => {
    gridAPI!.sizeColumnsToFit();
  };

  const onRefreshList = (event: Event) => {
    getCampaigns(props.formId)
      .then((campaigns: ICampaignData[]) => dispatch(actionReceivedCampaigns(campaigns)))
      .catch(console.log);
  };

  const onRowDoubleClicked = (event: RowDoubleClickedEvent) => {};

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const rows = event.api.getSelectedRows();
    if (!rows[0]) {
      return;
    }

    setSelectedRow(rows[0]);
  };

  const onConnectCampaign = () => {
    setShowConnectCampaign(false);
    connectCampaign(props.formId, selectedCampaign.value)
      .then(() => {
        getCampaigns(props.formId)
          .then((campaigns: ICampaignData[]) => dispatch(actionReceivedCampaigns(campaigns)))
          .catch(console.log);
      })
      .catch(error => console.log);
  };

  const onDisconnectCampaign = () => {
    disconnectCampaign(selectedRow!.form_campaign_id)
      .then(() => {
        getCampaigns(props.formId)
          .then((campaigns: ICampaignData[]) => dispatch(actionReceivedCampaigns(campaigns)))
          .catch(console.log);
      })
      .catch(console.log);
    setShowDisconnectCampaign(false);
  };

  const onAddCPO = () => {
    addCampaignCPO(newCPOFormCampaignId, newCPO, newCPOType, newCPODate)
      .then(() => {
        getCampaigns(props.formId)
          .then((campaigns: ICampaignData[]) => dispatch(actionReceivedCampaigns(campaigns)))
          .catch(console.log);
      })
      .catch(console.log);
    setShowAddCPO(false);
  };

  return (
    <Card>
      <CardActions disableSpacing className={classes.actions}>
        {AgToolbarButton({
          ...toolbarProps,
          title: "Connect Campaign",
          icon: "fa-plus",
          onClick: () => setShowConnectCampaign(true)
        })}

        {AgToolbarButton({
          ...toolbarProps,
          title: "Remove Connection",
          icon: "fa-trash",
          onClick: () => setShowDisconnectCampaign(true),
          disabled: selectedRow === null
        })}

        <div className={classes.flexGrow} />
        {AgToolbarButton({
          ...toolbarProps,
          title: "Refresh",
          icon: "fa-sync-alt",
          onClick: onRefreshList
        })}

        {AgToolbarButton({
          ...toolbarProps,
          title: "Adjust columns to window",
          icon: "fa-columns",
          onClick: onAdjustColumnsWindow
        })}

        {AgToolbarButton({
          ...toolbarProps,
          title: "Adjust columns to content",
          icon: "fa-text-width",
          onClick: onAdjustColumnsContent
        })}
      </CardActions>
      <CardContent>
        <Grid container>
          <Grid item md={12} className={classes.agGrid}>
            <div className="ag-theme-balham aggrid-size">
              <AgGridReact
                columnDefs={columnDefs}
                rowData={rowData}
                groupIncludeTotalFooter={false}
                groupIncludeFooter={false}
                groupMultiAutoColumn={true}
                animateRows={true}
                enableRangeSelection={false}
                onSelectionChanged={onSelectionChanged}
                rowSelection="multiple"
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                onRowDoubleClicked={onRowDoubleClicked}
                sideBar={{
                  toolPanels: ["columns", "filters"]
                }}
                suppressAggFuncInHeader={true}
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  filter: true
                }}
                getRowClass={params => {
                  if (params.data.used_in_campaign == 1) {
                    return "bg-success";
                  }
                }}
              />
            </div>
          </Grid>
        </Grid>

        <Dialog
          open={showDisconnectCampaign}
          onClose={() => setShowDisconnectCampaign(false)}
          aria-labelledby="alert-campdisconnect-title"
          aria-describedby="alert-campdisconnect-description"
        >
          <DialogTitle id="alert-campdisconnect-title">Remove Campaign Connection</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-campdisconnect-description">
              Are you sure you want to remove the campaign connection?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDisconnectCampaign(false)} color="secondary" autoFocus>
              No
            </Button>
            <Button onClick={onDisconnectCampaign} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={showConnectCampaign}
          onClose={() => setShowConnectCampaign(false)}
          aria-labelledby="alert-campconnect-title"
          aria-describedby="alert-campconnect-description"
          PaperProps={{ className: classes.dialogPaper }}
        >
          <DialogTitle id="alert-campconnect-title">Connect Campaign</DialogTitle>
          <DialogContent className={classes.dialogPaper}>
            <DialogContentText id="alert-campconnect-description">
              Select a campaign to connect to this form.
            </DialogContentText>
            <CampaignSelector clientId={formData.client_id} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowConnectCampaign(false)} color="secondary" autoFocus>
              Cancel
            </Button>
            <Button onClick={onConnectCampaign} color="primary">
              Connect
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={showAddCPO}
          onClose={() => setShowAddCPO(false)}
          aria-labelledby="alert-campconnect-title"
          aria-describedby="alert-campconnect-description"
          PaperProps={{ className: classes.dialogPaper }}
        >
          <DialogTitle id="alert-campconnect-title">Add new form CPO</DialogTitle>
          <DialogContent className={classes.dialogPaper}>
            <DialogContentText id="alert-campconnect-description">Enter new CPO for this campaign.</DialogContentText>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextField
                  label="CPO"
                  type="number"
                  fullWidth
                  value={newCPO}
                  onChange={e => setNewCPO(e.target.value)}
                />
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <InputLabel id="drop-cpotype">CPO Type</InputLabel>
                  <Select
                    id="drop-cpotype"
                    fullWidth
                    value={newCPOType}
                    onChange={e => setNewCPOType(e.target.value as string)}
                  >
                    <MenuItem value="1">Kr</MenuItem>
                    <MenuItem value="2">%</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: 20 }}>
              <Grid item md={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    variant="inline"
                    id="date-picker-end"
                    label="Date From"
                    value={newCPODate}
                    onChange={(date: any) => setNewCPODate(date)}
                    onError={console.log}
                    format="dd.MM.yyyy"
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowAddCPO(false)} color="secondary" autoFocus>
              Cancel
            </Button>
            <Button onClick={onAddCPO} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
}
