import React, { useState, useEffect } from "react";
import TitlebarComponent from "../../titlebar.component";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ClientSelector from "../shell/clientselector.component";
import { useSelector, useDispatch } from "react-redux";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { AgToolbarButton } from "../../agGridUtils";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-enterprise/chartsModule";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { GridReadyEvent, GridApi, ColumnApi } from "ag-grid-community";
import {
  FirstDataRenderedEvent,
  RowDoubleClickedEvent,
  SelectionChangedEvent
} from "ag-grid-community/dist/lib/events";
import { Grid, Card, CardContent, CardActions, makeStyles, Theme, createStyles, Button, CircularProgress, TextField, MenuItem,
  Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import CreateReasonPopup, { IRejectReason } from "./orderrejectedreasons/createreasonpopup.component";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import RejectReasonsAPI from "./orderrejectedreasons/rejectreasons.api";
import {    actionSelectClient, actionToggleFilterView } from "../../redux/reducers/shell";

const toolbarProps = {
  title: "",
  rightAlign: false,
  disabled: false,
  icon: "",
  onClick: () => {}
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexGrow: {
      flexGrow: 1
    },
    actions: {
      "& .form-group": {
        minWidth: 200,
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1)
      },
      "& button": {
        marginTop: 24
      }
    },
    agGrid: {
      "& .aggrid-size": {
        minHeight: 500,
        height: "calc(100vh - 460px)",
        width: "100%"
      }
    }
  })
);

export default function OrderRejectedReasons() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const selectedClient = useSelector(state => state.shell.selectedClient);
  const hideFilters = useSelector((state) => state.shell.hideFilters);

  const [showAddReason, setShowAddReason] = useState(false);
  const [confirmDeleteTarget, setConfirmDeleteTarget] = useState();
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const reasonType = 0
  const reasonTypes = [
    { value: 0, label: "All Types" },
    { value: 1, label: "Cancellation" },
    { value: 2, label: "Regret" },
    { value: 3, label: "Undo Cancel" },
  ];
  const [selectedReasonType, setSelectedReasonType] = useState<number | null>(reasonType);
  // const [selectedReasonType, setSelectedReasonType] = useState(reasonTypes[0]);

  const [editReason, setEditReason] = useState({ reasonID: 0, client: null, rejectType: "", description: "" });
  const columnDefs = [
    {
      headerName: "Client",
      field: "client",
      valueGetter: (r: any) => (r.data.client ? r.data.client.client_name : ""),
      rowGroup: false,
      enableRowGroup: false,
      hide: true
    },
    { headerName: "Description", field: "reason_name", rowGroup: false, enableRowGroup: false },
    {
      headerName: "Rejected Type",
      field: "rejected_type",
      rowGroup: false,
      enableRowGroup: false,
      width: 100,
      valueGetter: (ev: any) => {
        switch (ev.data.rejected_type) {
          case "1":
            return "Cancellation";
          case "2":
            return "Regret";
          case "3":
            return "Undo Cancel";
          }
      }
    }
  ];

  const [rowData, setRowData] = useState([]);
  const [gridAPI, setGridAPI] = useState<GridApi>();
  const [columnAPI, setColumnAPI] = useState<ColumnApi>();

  // useEffect(() => {
  //   RejectReasonsAPI.fetchRejectedReasons(
  //     selectedClient ? selectedClient.value : null,
  //     selectedReasonType.value ? selectedReasonType.value : null
  //   ).then(json => setRowData(json.data));
  // }, [setRowData, selectedClient, selectedReasonType]);

  function resetForm() {
    dispatch(actionSelectClient(undefined));
  }
  function runSearch() {
    setLoading(true);
    RejectReasonsAPI.fetchRejectedReasons(
      selectedClient ? selectedClient.value : null,
      selectedReasonType ? selectedReasonType : null
    ).then(json => setRowData(json.data))
    .finally(() => setLoading(false));

  }

  const clearEditReason = () => {
    gridAPI!.deselectAll();
    setEditReason({
      reasonID: 0,
      client: null,
      rejectType: "1",
      description: ""
    });
  };

  const onRemoveReason = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    toggleConfirmDeleteVisible();

    const rows = gridAPI!.getSelectedRows();
    if (!rows[0]) {
      return;
    }

    const reasonID = rows[0].reason_id;
    RejectReasonsAPI.deleteRejectedReason(reasonID).then(() =>
      RejectReasonsAPI.fetchRejectedReasons(
        selectedClient ? selectedClient.value : null,
        selectedReasonType ? selectedReasonType : null
      ).then(json => setRowData(json.data))
    );
  };

  const onEditReason = () => {
    setShowAddReason(true);
    const rows = gridAPI!.getSelectedRows();
    if (!rows[0]) {
      return;
    }
    setEditReason({
      reasonID: rows[0].reason_id,
      client: rows[0].client,
      rejectType: rows[0].rejected_type,
      description: rows[0].reason_name
    });
  };

  const onAdjustColumnsContent = (event: Event) => {
    var allColumnIds: any = [];
        if (columnAPI) {
            const allColumns = columnAPI!.getAllColumns();
            allColumns!.forEach((column) => {
                const colDef = column.getColDef();
                if (!colDef.suppressAutoSize) {
                    allColumnIds.push(column.getColId());
                }
            });
            columnAPI!.autoSizeColumns(allColumnIds);
        }
};

  // const onAdjustColumnsContent = (event: Event) => {
  //   var allColumnIds: any = [];
  //   columnAPI!.getAllColumns().forEach(column => {
  //     if (!column.colDef.suppressAutoSize) {
  //       allColumnIds.push(column.colId);
  //     }
  //   });
  //   columnAPI!.autoSizeColumns(allColumnIds);
  // };

  const onAdjustColumnsWindow = (event: Event) => {
    gridAPI!.sizeColumnsToFit();
  };

  const onRefreshList = (event: Event) => {
    RejectReasonsAPI.fetchRejectedReasons(
      selectedClient ? selectedClient.value : null,
      selectedReasonType ? selectedReasonType : null
    ).then(json => setRowData(json.data));
  };

  const onGridReady = (event: GridReadyEvent) => {
    setGridAPI(event.api);
    setColumnAPI(event.columnApi);
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    event.api.sizeColumnsToFit();
  };

  const onRowDoubleClicked = (event: RowDoubleClickedEvent) => {
    onEditReason();
  };

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const rows = event.api.getSelectedRows();
    if (!rows[0]) {
      return;
    }
    setEditReason({
      reasonID: rows[0].reason_id,
      client: rows[0].client,
      rejectType: rows[0].rejected_type,
      description: rows[0].reason_name
    });
  };

  const onCancelAddReason = () => {
    setShowAddReason(false);
  };

  const onAddReason = (event: any, reject: IRejectReason) => {
    setShowAddReason(false);
    RejectReasonsAPI.fetchRejectedReasons(
      selectedClient ? selectedClient.value : null,
      selectedReasonType ? selectedReasonType : null
    ).then(json => setRowData(json.data));
  };

  const toggleConfirmDeleteVisible = () => {
    setConfirmDeleteVisible(!confirmDeleteVisible);
  };

  return (
    <>
      <TitlebarComponent
        variant="material"
        title="Order Rejected Reasons"
        description=""
        breadcrumbs={[
          { title: "Dashboard", link: "/" },
          { title: "Campaigns", link: "/campaignmanagement" },
          { title: "Reasons", link: "/campaignmanagement/orderrejectedreasons" }
        ]}
      />
      <Grid container spacing={2}>
                <Grid item md={12}>
                    <div
                        style={{ color: "#ccc", width: 15 }}
                        data-toggle="tooltip"
                        data-original-title="Hide/Show filters"
                        onClick={() => dispatch(actionToggleFilterView())}
                    >
                        {hideFilters ? (
                            <i className="fas fa-fw fa-caret-square-right"></i>
                        ) : (
                                <i className="fas fa-fw fa-caret-square-left"></i>
                            )}
                    </div>
                </Grid>
                <Grid item md={2} style={{ display: hideFilters ? "none" : "block" }}>
                    <div style={{maxWidth: "270px"}}>
                      <ClientSelector variant="material" fullWidth/>

                      <TextField
                        value={selectedReasonType ? selectedReasonType : ""}
                        select 
                        label="Reject Type"
                        fullWidth
                        onChange={e => setSelectedReasonType(parseFloat(e.target.value))}
                        >
                        {reasonTypes.map((rType, key) => (
                          <MenuItem key={key} value={rType.value}>{rType.label}</MenuItem>
                        ))}
                      </TextField>

                      {/* <Form.Group controlId="frmClient">
                        <Form.Label>Reject Type</Form.Label>
                        <Select
                          value={selectedReasonType}
                          onChange={(opt: any) => setSelectedReasonType(opt)}
                          options={reasonTypes}
                          isClearable={false}
                        />
                      </Form.Group> */}
                    <div style={{ textAlign: "right", marginTop: 20 }}>
                            { loading ? <CircularProgress size={20}/> : null}
                            <Button onClick={resetForm} variant="outlined" color="primary">
                            Reset
                            </Button>
                            &nbsp;
                            <Button 
                            disabled={loading}
                            onClick={runSearch} 
                            variant="contained"
                            color="primary">
                            Search
                            </Button>
                        </div>
                    </div>
                </Grid>
      <Grid item md={hideFilters ? 12 : 10}>
      <Card>
        <CardActions disableSpacing className={classes.actions}>
          

          

          {AgToolbarButton({
            ...toolbarProps,
            title: "Add Reason",
            icon: "fa-plus",
            onClick: () => {
              clearEditReason();
              setShowAddReason(true);
            }
          })}

          {AgToolbarButton({
            ...toolbarProps,
            title: "Edit",
            icon: "fa-edit",
            onClick: onEditReason,
            disabled: editReason.reasonID === 0
          })}

          {AgToolbarButton({
            ...toolbarProps,
            title: "Remove",
            icon: "fa-trash",
            onClick: (event: any) => {
              setConfirmDeleteTarget(event.target);
              toggleConfirmDeleteVisible();
            },
            disabled: editReason.reasonID === 0
          })}
          {/* <Overlay show={confirmDeleteVisible} target={confirmDeleteTarget}>
            <Popover id="popover-delete">
              <Popover.Title as="h3">Confirm?</Popover.Title>
              <Popover.Content>
                <Row>
                  <Col>
                    <Button variant="contained" color="primary" onClick={onRemoveReason}>
                      Delete
                    </Button>
                  </Col>
                  <Col>
                    <Button variant="outlined" color="primary"onClick={() => toggleConfirmDeleteVisible()}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Popover.Content>
            </Popover>
          </Overlay> */}

          <div className={classes.flexGrow} />

          {AgToolbarButton({
            ...toolbarProps,
            title: "Refresh",
            icon: "fa-sync-alt",
            onClick: onRefreshList
          })}

          {AgToolbarButton({
            ...toolbarProps,
            title: "Adjust columns to window",
            icon: "fa-columns",
            onClick: onAdjustColumnsWindow
          })}

          {AgToolbarButton({
            ...toolbarProps,
            title: "Adjust columns to content",
            icon: "fa-text-width",
            onClick: onAdjustColumnsContent
          })}
        </CardActions>
        <CardContent>
          <Grid container>
            <Grid item md={12} className={classes.agGrid}>
              <div className="ag-theme-balham aggrid-size">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={rowData}
                  groupIncludeTotalFooter={false}
                  groupIncludeFooter={false}
                  groupMultiAutoColumn={true}
                  animateRows={true}
                  enableRangeSelection={false}
                  onSelectionChanged={onSelectionChanged}
                  rowSelection="multiple"
                  onGridReady={onGridReady}
                  onFirstDataRendered={onFirstDataRendered}
                  onRowDoubleClicked={onRowDoubleClicked}
                  sideBar={{
                    toolPanels: ["columns", "filters"]
                  }}
                  suppressAggFuncInHeader={true}
                  defaultColDef={{
                    sortable: true,
                    resizable: true,
                    filter: true
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
    </Grid>

      <CreateReasonPopup
        open={showAddReason}
        onCancelAddReason={onCancelAddReason}
        onAddReason={onAddReason}
        rejectType={selectedReasonType}
        editReason={editReason}
      />
      <Dialog fullWidth maxWidth="sm" open={confirmDeleteVisible} onClose={() => setConfirmDeleteVisible(false)}>
            <DialogTitle>Confirm delete</DialogTitle>
            <DialogContent>Are you sure you want to delete: "{editReason.description}"</DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setConfirmDeleteVisible(false)}>
                        Cancel
                    </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => onRemoveReason(e)}
                    >
                      Delete
                    </Button>
            </DialogActions>
        </Dialog>
    </>
  );
}
