import {
  actionReceivedClients,
  actionReceivedCompanies,
  actionStartAPI,
  actionEndAPI,
  actionReceivedCampaignTypes,
  actionReceivedCampaigns,
  actionReceivedDepartments,
  actionReceivedAdminDepartments,
  actionReceivedTeams,
  actionShellUnsetError,
  actionShellSetError,
  actionReceivedLegacyHTML,
  actionReceivedUserInfo,
  actionSetLoggedIn,
  actionReceivedModules,
  actionReceivedOrgUsers,
  actionReceivedCustomers,
  actionReceivedCurrentCustomer,
  actionReceivedCustomerPlatforms,
  actionReceivedAgents,
  actionReceivedOrderTypes,
  actionReceivedLogTypes
} from "../../redux/reducers/shell";
import config from "../../config";

declare var $: any;
declare var getPage: any;

var domain = window.location.hostname;
var parts = domain.substr(0,domain.indexOf('.'));
var apiPath = '';

if (parts.substr(domain.indexOf('-'),1) == '-') {
    var dtype = parts.substr(domain.indexOf('-')+1);
    if (dtype == 'local') {
        apiPath = 'http://api-' + dtype;
    } else {
        apiPath = 'https://api-' + dtype;
    }
} else {
        apiPath = 'https://api-prod';
}

apiPath = apiPath + domain.substr(domain.indexOf('.')) + '/manager';

const env = {
  _apiPath: apiPath // "https://api.fiko.no/manager"
};

/**
 * Encode an object as url query string parameters
 * - includes the leading "?" prefix
 * - example input — {key: "value", alpha: "beta"}
 * - example output — output "?key=value&alpha=beta"
 * - returns empty string when given an empty object
 */
function encodeQueryString(params) {
  const keys = Object.keys(params);
  return keys.length
    ? "?" + keys.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])).join("&")
    : "";
}

{/*
export function loadUserLogin3(dispatch, email: string, password: string) {
  const fetchUserLogin = async () => {
    const res = await fetch(env._apiPath + "/login", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ email, password, workspace: "mmg" }),
      headers: {
        "Content-Type": "application/json"
      }
    });
    const json = await res.json();
    if (json.status !== "success") {
      // dispatch(actionSetLoginErrorMessage(json.message));
      dispatch(actionSetLoggedIn(false));
      return;
    }
    loadUserInfo(dispatch);
    dispatch(actionSetLoggedIn(true)); 
  };
  fetchUserLogin();
}
*/}
export const loadUserLogin = async (dispatch, email: string, password: string) => {
  const res = await fetch(env._apiPath + "/login", {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({ email, password, workspace: "mmg" }),
    headers: {
      "Content-Type": "application/json"
    }
  });
  const json = await res.json();
  if (json.status !== "success") {
    // dispatch(actionSetLoginErrorMessage(json.message));
    dispatch(actionSetLoggedIn(false));
    return json;
  }
  loadUserInfo(dispatch);
  dispatch(actionSetLoggedIn(true));
  return json
};




/**
 * Update user info in shell.
 * @param dispatch redux
 */
export function loadUserInfo(dispatch) {
  dispatch(actionStartAPI());
  const fetchUserInfo = async () => {
    const res = await fetch(env._apiPath + "/account/user", {
      method: "GET",
      credentials: "include"
    });
    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status !== "success") {
      // dispatch(actionSetLoginErrorMessage(json.message))
      // TODO: Handle session timeouts properly ..
      dispatch(actionSetLoggedIn(false));
      return;
    }

    const userinfo = json.data;
    dispatch(actionReceivedUserInfo(userinfo));
    dispatch(actionSetLoggedIn(true));
  };

  fetchUserInfo();
}

/**
 * Update client list in shell. Used by many modules for filtering purposes.
 * @param dispatch redux
 */
export function loadShellClients(dispatch) {
  dispatch(actionStartAPI());
  const fetchClients = async () => {
    const res = await fetch(env._apiPath + "/customer/clients", {
      method: "GET",
      credentials: "include"
    });
    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status !== "success") {
      // dispatch(actionSetLoginErrorMessage(json.message))
      return;
    }

    const clients: any = [];
    for (const i in json.data) {
      const o = json.data[i];
      clients.push({
        value: o.client_id,
        label: o.client_name,
        prefix: o.prefix
      });
    }

    dispatch(actionReceivedClients(clients));
  };

  fetchClients();
}

/**
 * Update company list in shell.
 * @param dispatch redux
 */
export function loadShellCompanies(dispatch) {
  const fetchCompanies = async () => {
    dispatch(actionStartAPI());
    const res = await fetch(env._apiPath + "/companies", {
      method: "GET",
      credentials: "include"
    });
    const json = await res.json();
    console.log(json);
    dispatch(actionEndAPI());
    if (json.status !== "success") {
      // dispatch(actionSetLoginErrorMessage(json.message))
      return;
    }

    const companies: any = [];
    for (const i in json.data) {
      const o = json.data[i];
      companies.push({
        value: o.company_id,
        label: o.company_name
      });
    }
    dispatch(actionReceivedCompanies(companies));
  };

  fetchCompanies();
}

export function loadShellCampaignTypes(dispatch, clientId, companyId) {
  const apiFetcher = async () => {
    dispatch(actionStartAPI());
    const apiURL = env._apiPath + "/customer/campaigntypes";
    let params = "";
    if (clientId) {
      params += "/" + clientId;
    }

    if (companyId) {
      params += "?company_id=" + companyId;
    }

    const res = await fetch(apiURL + params, {
      method: "GET",
      credentials: "include"
    });
    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status !== "success") {
      // dispatch(actionSetLoginErrorMessage(json.message))
      return;
    }

    const result = json.data;
    dispatch(actionReceivedCampaignTypes(result));
  };
  apiFetcher();
}

export function loadShellCampaigns(dispatch, clientId, campaignTypeId, customerPlatformId) {
  const apiFetcher = async () => {
    dispatch(actionStartAPI());
    const apiURL = env._apiPath + "/customer/campaigns";
    const reqParams = {
      client_id: clientId ? clientId : 0,
      campaign_type_id: campaignTypeId ? campaignTypeId : 0,
      customer_platform_id: customerPlatformId ? customerPlatformId : 0
    };
    const params = encodeQueryString(reqParams);

    const res = await fetch(apiURL + params, {
      method: "GET",
      credentials: "include"
    });
    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status !== "success") {
      // dispatch(actionSetLoginErrorMessage(json.message))
      return;
    }

    const result = json.data;
    dispatch(actionReceivedCampaigns(result));
  };
  apiFetcher();
}

// This uses the getAdmin webservice in order to 
// restrict access in modules where this is apparently appropriate 
export function loadShellAdminDepartments(dispatch, companyId: number = 0, search: string = "") {
  const apiFetcher = async () => {
    dispatch(actionStartAPI());
    const apiURL = env._apiPath + "/adminDepartments";

    const reqParams = {
      company_id: companyId,
      search
    };
    const params = encodeQueryString(reqParams);

    const res = await fetch(apiURL + params, { method: "GET", credentials: "include" });
    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status !== "success") {
      // dispatch(actionSetLoginErrorMessage(json.message))
      return;
    }

    const result = json.data;
    dispatch(actionReceivedAdminDepartments(result));
  };
  apiFetcher();
}

export function loadShellDepartments(dispatch, companyId: number = 0, search: string = "") {
  const apiFetcher = async () => {
    dispatch(actionStartAPI());
    const apiURL = env._apiPath + "/departments";

    const reqParams = {
      company_id: companyId,
      search
    };
    const params = encodeQueryString(reqParams);

    const res = await fetch(apiURL + params, { method: "GET", credentials: "include" });
    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status !== "success") {
      // dispatch(actionSetLoginErrorMessage(json.message))
      return;
    }

    const result = json.data;
    dispatch(actionReceivedDepartments(result));
  };
  apiFetcher();
}

export function loadShellTeams(dispatch, companyId: number, departmentId: number) {
  const apiFetcher = async() => {
    dispatch(actionStartAPI());
    const apiURL = env._apiPath + "/company/teams";

    const reqParams = {
      company_id: companyId,
      department_id: departmentId
    };
    const params = encodeQueryString(reqParams);

    const res = await fetch(apiURL + params, { method: "GET", credentials: "include" });
    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status !== "success") {
      // dispatch(actionSetLoginErrorMessage(json.message))
      return;
    }

    const result = json.data;
    dispatch(actionReceivedTeams(result));
  }
  apiFetcher();
}

export function loadShellCustomerPlatforms(dispatch) {
  const apiFetcher = async() => {
    dispatch(actionStartAPI());
    const apiURL = env._apiPath + "/customerPlatforms";

    const res = await fetch(apiURL, { method: "GET", credentials: "include" });
    const json = await res.json();
    dispatch(actionEndAPI());
    if(json.status !== "success") {
      return;
    }

    const result = json.data;
    dispatch(actionReceivedCustomerPlatforms(result));
  }

  apiFetcher();
}

export function loadShellAgents(dispatch, companyId: number, departmentId: number, teamId: number) {
  if(!companyId || companyId === 0 || !departmentId || departmentId === 0) {
    dispatch(actionReceivedAgents([]));
    return;
  }
  
  const apiFetcher = async() => {
    dispatch(actionStartAPI());
    const apiURL = env._apiPath + "/customer/agents";

    const reqParams = {
      company_id: companyId,
      department_id: departmentId,
      team_id: teamId
    };
    const params = encodeQueryString(reqParams);

    const res = await fetch(apiURL + params, { method: "GET", credentials: "include" });
    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status !== "success") {
      return;
    }

    const result = json.data;
    dispatch(actionReceivedAgents(result));
  }

  apiFetcher();
}

export function loadShellOrderTypes(dispatch) {
  const apiFetcher = async() => {
    dispatch(actionStartAPI());
    const apiURL = env._apiPath + "/order/getOrderTypes";

    const res = await fetch(apiURL, { method: "GET", credentials: "include" });
    const json = await res.json();
    dispatch(actionEndAPI());
    if(json.status !== "success") {
      return;
    }

    const result = json.data;
    dispatch(actionReceivedOrderTypes(result));
  }

  apiFetcher();
}

/**
 * Updates the error state, and resets the state after timeout.
 *
 * TODO: Perhaps we should enqueue errors and show multiple?
 */
let shellErrorTimeout: any = 0;
export function showShellError(dispatch, variant: string, title: string, message: string, timeout: number | null = 5) {
  dispatch(actionShellSetError(variant, title, message));
  if (timeout && timeout !== 0) {
    clearTimeout(shellErrorTimeout);
    shellErrorTimeout = setTimeout(() => dispatch(actionShellUnsetError()), timeout * 1000);
  }
}

export function loadLegacyPage(dispatch, page: string) {
  const pageFetcher = async () => {
    const apiURL = "/assets/iApi/getPage.php";
    const reqParams = {};
    const pageSplit = page.substr(1).split("/");
    pageSplit.forEach((p, i) => (reqParams["pages[" + i + "]"] = p));
    const params = encodeQueryString(reqParams);

    const res = await fetch(apiURL + params, {
      method: "GET",
      credentials: "include"
    });
    const html = await res.text();

    switch (html) {
      case "0":
        window.location.reload();
        break;
      case "404":
        getPage(["404"]);
        break;
      case "1":
        getPage(["account", "manage"]);
        break;
      case "2":
        getPage();
        break;
      default:
        $("#legacyContainer").html(html);
    }

    /*
		if(response == '0') {
			location.reload();
			return;
		} else if(response == '404') {
			getPage(['404'])
		} else if(response == '1') {
			getPage(['account', 'manage']);
			return;
		} else if(response == '2') {
			getPage()
		} else {
			$('#pageContent').html(response);
			Account.prototype.getProfile();
		}
		*/

    const url = "/" + page[0] + "/" + page[1];
    dispatch(actionReceivedLegacyHTML({ __html: html, url }));
  };
  pageFetcher();
}

export function clearLegacyPage(dispatch) {
  $("#legacyContainer").empty();
  dispatch(actionReceivedLegacyHTML(null));
}

export function loadModules(dispatch) {
  dispatch(actionStartAPI());
  const fetchModules = async () => {
    const res = await fetch(config.getApiURL("manager/account/modules"), {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    });
    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status === "success") {
      dispatch(actionReceivedModules(json.data));
      dispatch(actionEndAPI());

      return;
    }
  };
  fetchModules();
}

export function loadUsers(dispatch) {
  dispatch(actionStartAPI());
  const fetchUsers = async () => {
    const res = await fetch(config.getApiURL("manager/account/users"), {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    });
    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status === "success") {
      dispatch(actionReceivedOrgUsers(json.data));
      return;
    }
  };
  fetchUsers();
}

export function loadCurrentCustomer(dispatch) {
  dispatch(actionStartAPI());
  const fetchCurrentCustomer = async () => {
    const res = await fetch(config.getApiURL("manager/account/customer"), {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    });
    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status === "success") {
      console.log(json);
      dispatch(actionReceivedCurrentCustomer(json.data));
      return;
    }
  };
  fetchCurrentCustomer();
}

export function loadCustomers(dispatch) {
  dispatch(actionStartAPI());
  const fetchCustomers = async () => {
    const res = await fetch(config.getApiURL("manager/customers"), {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    });
    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status === "success") {
      dispatch(actionReceivedCustomers(json.data));
      return;
    }
  };
  fetchCustomers();
}

export async function impersonateCustomer(dispatch, customerID: string) {
  dispatch(actionStartAPI());
  const res = await fetch(config.getApiURL("manager/customer/change/" + customerID), {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    }
  });
  const json = await res.json();
  dispatch(actionEndAPI());
  if (json.status === "success") {
    loadCurrentCustomer(dispatch);
    return;
  }

  throw "Unable to impersonate customer.";
}

// settings/userLogReport
export function loadLogTypes(dispatch) {
  dispatch(actionStartAPI());
  const fetchLogTypes = async () => {
    const res = await fetch(config.getApiURL("manager/log/logUserTypes"), {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    });
    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status === "success") {
      dispatch(actionReceivedLogTypes(json.data));
      return;
    }
  };
  fetchLogTypes();
}
