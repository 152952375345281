import React, { useState, useEffect } from 'react';
import TitlebarComponent from "../../titlebar.component";
import { useSelector } from "react-redux";
import ClientSelector from "../shell/clientselector.component";
import { ISendSms, sendSms, iRecipient } from "./sendsms/sendsms.api";
import "./sendsms/sendsms.scss";

import {
    Button,
    Grid,
    Card,
    TextField,
    List, 
    ListItem,
    ListItemSecondaryAction, 
    ListItemText,
    IconButton,
    MenuItem, Snackbar
  } from "@material-ui/core";
  import DeleteIcon from '@material-ui/icons/Delete';
  import MuiAlert from '@material-ui/lab/Alert';

const smsSkeleton = {
    recipient: '',
    customer_id: 0,
    client_id: 0,
    country_code: "+47",
    // TODO: hardkodet sender
    sender: 26200,
    sms_message: "", 
    user_id: 0
}

const emptyRecipient = {
    country_code: '+47',
    number: 0
}

  export default function SmsComponent() {
    const [message, setMessage] = useState<ISendSms>(smsSkeleton);
    const selectedClient = useSelector(state => state.shell.selectedClient);
    const currentCustomer = useSelector(state => state.shell.currentCustomer);
    const currentUser = useSelector(state => state.shell.user)
    const [recipients, setRecipients] = useState<iRecipient[]>([]);
    const [error, setError] = useState("");
    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [alertMsg, setAlertMsg] = useState({
        msg: "",
        severity: false
    });
    const [dense, setDense] = useState(true);
    const [secondary, setSecondary] = useState(false);
    useEffect(() => {
        setMessage({...message, 
            customer_id: currentCustomer.customer_id, 
            client_id: selectedClient ? selectedClient.value : 0, 
            user_id: currentUser.user_id})
    }, []);
    useEffect(() => {
        setMessage({...message, client_id: selectedClient ? selectedClient.value : 0});
    }, [selectedClient]);
    useEffect(() => {
        setMessage({...message, customer_id: currentCustomer.customer_id});
    }, [currentCustomer]);
    useEffect(() => {
        setMessage({...message, user_id: currentUser.user_id});
    }, [currentUser]);
    
    function generate(element) {
        return [0, 1, 2].map((value) =>
          React.cloneElement(element, {
            key: value,
          }),
        );
      }
    const removeRecipient = ( idx ) => {
        var array = [...recipients];
        if (idx !== -1) {
            array.splice(idx, 1);
            setRecipients( array )
        }
    }
    const handleChange = (e) => {
        const {name, type, value} = e.target;
        const val = type === 'number' ? parseFloat(value) : value;
        setMessage({ ...message, [name]: val });
    }
    
    const addToList = ( e ) => {
//        var reg = /[0-9]{8,10}+/;
        let strNumber = e.target.value;

        let newRecs:iRecipient[] = [];

        const matches = strNumber.matchAll(/([\d]+)[^\d]+/g);
        for (const match of matches) {
            strNumber = strNumber.replace(/^[\d]+[^\d]+/,'');
            //const newNumber = ;

            if (match[1].length < 8 || match[1].length >= 16) {
                setError("Recipient number must be at least 8 digits, and max 15 digits")
            } else {
                newRecs.push({ country_code: '+47', number: Number(match[1]) })            
            }
    
        }

        setMessage({ ...message, recipient: strNumber });
        setRecipients([  ...recipients, ...newRecs ]);
    }

    const createAndSendSms = () => {
        setError("");
        if (message.recipient === '' && !recipients.length) {
            setError("Recipient can't be empty")
            return;
        }

        if (message.sms_message === "" ) {
            setError("Message can't be empty")
            return;
        }

        sendSms(message, recipients)
        .then(res => {
            if (res.status !== "success") {
                setAlertMsg({ ...alertMsg, msg: res.message, severity: false});
            } else {
                setAlertMsg({ ...alertMsg, msg: "The SMS was sent", severity: true});
            }
            setAlertOpen(true);
        })
        .then(() => setMessage({...message, recipient: "", sms_message: ""}))
        .catch(err => setError(err))
    }

    return (<>
        <TitlebarComponent
        title="Send SMS"
        description="Send SMS"
        breadcrumbs={[
          { title: "Dashboard", link: "/" },
          { title: "Send SMS", link: "/sms" },
        ]}
      />

      <Grid>
          
          <Card className="smsCard">
            <Grid container spacing={2}>
                <Grid item sm={8}>
                    <Grid container spacing={4}>
                        <Grid item xs={6}>
                            <TextField
                            id="smssender"
                            value={message.sender !== 0 ? message.sender : 26200}
                            onChange={(e) => handleChange(e)}
                            name="sender"
                            label="Sender Number"
                            select
                            type="number"
                            className="smsItem"
                            //TODO: hardkoder inn sendernummer, burde ha en tabell med gyldige numre per customer
                            >
                                <MenuItem value={26200}>26200</MenuItem>
                                <MenuItem value={1908}>1908</MenuItem>
                                <MenuItem value={2220}>2220</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <ClientSelector fullWidth variant="material" />
                        </Grid>
                    </Grid>
                    <TextField
                        id="smsmessage"
                        value={message.sms_message !== "" ? message.sms_message : ""}
                        onChange={(e) => handleChange(e)}
                        name="sms_message"
                        label="Message"
                        helperText="Type in your message"
                        required
                        multiline
                        fullWidth
                        rows={7}
                        className="smsItem smsMessage" />

                        <Grid item className="smsActions">  
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={() => createAndSendSms()}
                                className="smsButton smsActionItem">
                                    Send Sms
                            </Button>
                            <span className="smsError smsActionItem">{error}</span>
                        </Grid>

                </Grid>
                <Grid item sm={4}>
                    <p>
                        <TextField
                                id="smscountry"
                                value={message.country_code ? message.country_code : ""}
                                onChange={(e) => handleChange(e)}
                                name="country_code"
                                label="Country Code"
                                select
                                className="smsItem"
                                //TODO: hardkoder inn landskoder
                                >
                                    <MenuItem value={"+47"}>Norway</MenuItem>
                                    <MenuItem value={"+46"}>Sweden</MenuItem>
                        </TextField>                            
                    </p>
                    <p>
                        <TextField
                            id="smsrecipient"
                            value={message.recipient? message.recipient : ""}
                            onChange={(e) => addToList(e)}
                            name="recipient"
                            type="text"
                            helperText="8-15 digits"
                            label="Recipient Number"                        
                            fullWidth
                            className="smsItem"/>
                    </p>
                    <List dense={dense} style={{maxHeight: '500px', overflow: 'auto'}}>
                        {recipients.map((rec, idx) => (
                        <ListItem key={idx}>
                            <ListItemText
                                primary={rec.number}
                                secondary={rec.country_code}
                            />
                            <ListItemSecondaryAction> 
                                <IconButton edge="end" aria-label="delete" onClick={(e) => { removeRecipient(idx) }}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
        </Card>
      </Grid>
      {/* alert bar */}
      <Snackbar 
                open={alertOpen} 
                autoHideDuration={3000} 
                onClose={() => setAlertOpen(false)}>
                <MuiAlert 
                    variant="filled"
                    severity={ alertMsg.severity ? "success" : "error"}>{alertMsg.msg}
                </MuiAlert>
        </Snackbar>  
    </>);
  }