export interface IOrderHeader {
  display_name: string;
  field_name: string;
  datatype: string;
  visible: boolean;
  searchable: boolean;
}

export interface IOrderReport {
  current_page: number;
  first_page_url: string;
  from: number;
  to: number;
  last_page: number;
  per_page: number;
  total: number;
  last_page_url: string;
  next_page_url: string;
  prev_page_url: string;
  path: string;
  // data contains customized results and are not definable here but in headers..
  headers: any[];
  data: any[];
}

export interface IOrderDetails {
  show: boolean;
  order: any;
  extra: any;
  operations: any;
  activities: any;
  editstatus: any;
}

export interface IOrder {
  headers: IOrderHeader[];
  report: IOrderReport | null;
  orderDetails: IOrderDetails;
}

const initialState: IOrder = {
  headers: [],
  report: null,
  orderDetails: {
    show: false,
    order: null,
    extra: null,
    operations: { list: [], target: null },
    activities: [],
    editstatus: null
  }
};

export const REPORTS_ORDERS_RECEIVED_HEADERS = "@sky/REPORTS_ORDERS_RECEIVED_HEADERS";
export const actionReceivedOrderHeaders = (headers: IOrderHeader[]) => ({
  type: REPORTS_ORDERS_RECEIVED_HEADERS,
  headers
});

export const REPORTS_ORDERS_RECEIVED_ORDERREPORT = "@sky/REPORTS_ORDERS_RECEIVED_ORDERREPORT";
export const actionReceivedOrderReport = (report: IOrderReport) => ({
  type: REPORTS_ORDERS_RECEIVED_ORDERREPORT,
  report
});

export const REPORTS_ORDERS_OPEN_DETAILS = "@sky/REPORTS_ORDERS_OPEN_DETAILS";
export const actionOpenDetails = orderId => ({
  type: REPORTS_ORDERS_OPEN_DETAILS,
  orderId
});

export const REPORTS_ORDERS_CLOSE_DETAILS = "@sky/REPORTS_ORDERS_CLOSE_DETAILS";
export const actionCloseDetails = () => ({
  type: REPORTS_ORDERS_CLOSE_DETAILS
});

export const REPORT_ORDERS_RECEIVED_DETAILS = "@sky/REPORT_ORDERS_RECEIVED_DETAILS";
export const actionReceivedOrderDetails = details => ({
  type: REPORT_ORDERS_RECEIVED_DETAILS,
  details
});

export const REPORT_ORDERS_RECEIVED_DETAILS_EXTRA = "@sky/REPORT_ORDERS_RECEIVED_DETAILS_EXTRA";
export const actionReceivedOrderDetailsExtra = extra => ({
  type: REPORT_ORDERS_RECEIVED_DETAILS_EXTRA,
  extra
});

export const REPORT_ORDERS_RECEIVED_OPERATIONS = "@sky/REPORT_ORDERS_RECEIVED_OPERATIONS";
export const actionReceivedOrderOperations = (operations, target) => ({
  type: REPORT_ORDERS_RECEIVED_OPERATIONS,
  operations,
  target
});


export const REPORT_ORDERS_RECEIVED_ACTIVITIES = "@sky/REPORT_ORDERS_RECEIVED_ACTIVITIES";
export const actionReceivedOrderActivities = (activities) => ({
  type: REPORT_ORDERS_RECEIVED_ACTIVITIES,
  activities
});


export const REPORT_ORDER_RECEIVED_EDITSTATUS = "@sky/REPORT_ORDER_RECEIVED_EDITSTATUS";
export const actionReceivedOrderEditStatus = editstatus => ({
  type: REPORT_ORDER_RECEIVED_EDITSTATUS,
  editstatus
});

export default function(state = initialState, action) {
  switch (action.type) {
    case REPORTS_ORDERS_RECEIVED_HEADERS:
      return {
        ...state,
        headers: action.headers
      };
    case REPORTS_ORDERS_RECEIVED_ORDERREPORT:
      return {
        ...state,
        report: action.report
      };
    case REPORTS_ORDERS_OPEN_DETAILS:
      return {
        ...state,
        orderDetails: { ...state.orderDetails, show: true }
      };
    case REPORTS_ORDERS_CLOSE_DETAILS:
      return {
        ...state,
        orderDetails: { ...state.orderDetails, show: false }
      };
    case REPORT_ORDERS_RECEIVED_DETAILS:
      return {
        ...state,
        orderDetails: { ...state.orderDetails, order: action.details }
      };
      case REPORT_ORDERS_RECEIVED_DETAILS_EXTRA:
        return {
          ...state,
          orderDetails: { ...state.orderDetails, extra: action.extra }
        };
      case REPORT_ORDERS_RECEIVED_OPERATIONS:
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          operations: { list: action.operations, target: action.target }
        }
      };
    case REPORT_ORDERS_RECEIVED_ACTIVITIES:
      console.log(action);
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          activities: action.activities
        }
      };
      case REPORT_ORDER_RECEIVED_EDITSTATUS:
      return {
        ...state,
        orderDetails: { ...state.orderDetails, editstatus: action.editstatus }
      };
  }

  return state;
}
