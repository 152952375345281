declare var env: any;

export async function saveVippsSettings(
  formConfirmationId: number,
  clientId: string,
  clientSecret: string,
  smsTemplate: string,
  formField: string | undefined,
  smsWelcomeTemplate: string
) {
  const req = {
    form_confirmation_id: formConfirmationId,
    client_id: clientId,
    client_secret: clientSecret,
    sms_template: smsTemplate,
    order_header_form_id: formField,
    sms_welcome_template: smsWelcomeTemplate
  };

  const res = await fetch(env._apiPath + "/formConfirmation/vipps", {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(req),
  });
  const json = await res.json();

  if (json.status === "success") {
    return;
  }

  throw json.message;
}
