import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { actionSelectClient } from "../../redux/reducers/shell";
import { loadShellClients } from "./shell.api";
import {
  MenuItem,
  TextField
} from "@material-ui/core";
import _uniqueId from "lodash/uniqueId";

interface IClientSelectorProps {
  variant?: string;
  fullWidth?: boolean;
  onChange?: any;
  value?: string;
  required?: boolean;
}


export default function ClientSelector(props: IClientSelectorProps) {
  const dispatch = useDispatch();
  const clients = useSelector(state => state.shell.clients);
  const selectedClient = useSelector(state => state.shell.selectedClient);
  const [id] = useState(_uniqueId("sky-"));

  useEffect(() => {
    loadShellClients(dispatch);
  }, [dispatch, loadShellClients]);

  function handleChange(e) {
    if (e.target.value == "") {
      dispatch(actionSelectClient(undefined));
    }
    else {
      dispatch(actionSelectClient(e.target));
    }
  }

  if (props.variant === "material") {
    return (
      <TextField 
          value={selectedClient ? selectedClient.value : ""}
          select 
          required={props.required}
          label="Client"
          id={id}
          fullWidth={props.fullWidth}
          // onChange={(e: any) => dispatch(actionSelectClient(e.target))}
          onChange={handleChange}
          >
          <MenuItem value="">None</MenuItem>
          {clients.map((client, key) => (
            <MenuItem key={key} value={client.value}>{client.label}</MenuItem>
          ))}
        </TextField>
    );
  }
  else {
    return (
      <Form.Group controlId="frmClient">
        <Form.Label>Client</Form.Label>
        <Select
          value={selectedClient}
          onChange={(opt: any) => dispatch(actionSelectClient(opt))}
          options={clients}
          isClearable={true}
        />
      </Form.Group>
    );
  }
}
