import React from "react";
import { useDispatch } from "react-redux";
import "./loadingscreen.scss";
import { actionStopAPI } from "./redux/reducers/shell";

interface ILoadingScreenProps {
  message?: string;
}

export default function LoadingScreen(props: ILoadingScreenProps) {
  const dispatch = useDispatch();
  const disableLoadingScreen = () => {
    dispatch(actionStopAPI());
  };

  return (
    <div className="loading-overlay" onClick={disableLoadingScreen}>
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
      {props.message ? props.message : null}
    </div>
  );
}
