import { IFormFieldData, IFormFieldNewData } from "./form.types";

declare var env: any;

export async function getFormFields(formId: number) {
  const res = await fetch(env._apiPath + "/orderHeaderForms?form_id=" + formId, {
    method: "GET",
    credentials: "include",
  });
  const json = await res.json();

  if (json.status === "success") {
    return json.data;
  }

  throw json.message;
}

export async function getFormFieldTypes(clientId: string) {
  const res = await fetch(env._apiPath + "/orderHeaders?client_id=" + clientId, {
    method: "GET",
    credentials: "include",
  });
  const json = await res.json();

  if (json.status === "success") {
    return json.data;
  }

  throw json.message;
}

export async function addFormField(formId: number, formField: IFormFieldNewData) {
  const req = {
    form_id: formId,
    mandatory_contact: formField.mandatory_contact,
    order_header_id: formField.order_header_id,
    col: formField.col,
    max_length: formField.max_length,
    mandatory_order: formField.mandatory_order,
    read_only: formField.read_only,
    disabled: formField.disabled,
    queue_active: formField.queue_active
  };

  const res = await fetch(env._apiPath + "/orderHeaderForm", {
    method: "PUT",
    credentials: "include",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(req)
  });
  const json = await res.json();

  if (json.status === "success") {
    return json.data;
  }

  throw json.message;
}

export async function updateFormField(formField: IFormFieldData) {
  const req = {
    order_header_form_id: formField.order_header_form_id,
    mandatory_contact: formField.mandatory_contact,
    order_header_id: formField.order_header_id,
    col: formField.col,
    max_length: formField.max_length,
    mandatory_order: formField.mandatory_order,
    read_only: formField.read_only,
    disabled: formField.disabled,
    queue_active: formField.queue_active
  };

  if (formField.min_length) {
    req["min_length"] = formField.min_length;
  }

  const res = await fetch(env._apiPath + "/orderHeaderForm", {
    method: "POST",
    credentials: "include",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(req)
  });
  const json = await res.json();

  if (json.status === "success") {
    return json.data;
  }

  throw json.message;
}

export async function moveFormField(orderHeaderFormId: string, direction: string) {
  const req = {
    order_header_form_id: orderHeaderFormId,
    direction
  };

  const res = await fetch(env._apiPath + "/moveOrderHeaderForm", {
    method: "POST",
    credentials: "include",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(req)
  });
  const json = await res.json();

  if (json.status === "success") {
    return json.data;
  }

  throw json.message;
}


export async function deleteFormField(orderHeaderFormId: number) {
  const req = {
    order_header_form_id: orderHeaderFormId
  };

  const res = await fetch(env._apiPath + "/orderHeaderForm", {
    method: "DELETE",
    credentials: "include",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(req)
  });
  const json = await res.json();

  if (json.status === "success") {
    return json.data;
  }

  throw json.message;
}

