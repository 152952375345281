import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import { GridReadyEvent } from "ag-grid-community";
import { FirstDataRenderedEvent, SelectionChangedEvent } from "ag-grid-community/dist/lib/events";
import MapComponent from "./map.component";
import Alert from "react-bootstrap/Alert";
import userSettings from "../../../userSettings";
import { fetchAreaResult } from "./maps.api";
import { useDebounce } from "../../../custom.hooks";

interface IAddAreaProps {
  show: boolean;
  onHide: any;
  onError: any;
  onAddArea: any;
  existingAreas: string[];
}

export default function AddAreaComponent(props: IAddAreaProps) {
  const [searchString, setSearchString] = useState("");
  const [searchBounds, setSearchBounds] = useState<any>(null);
  const [searchResult, setSearchResult] = useState([]);
  const [highlighedPolygonId, setHighlighedPolygonId] = useState<string[]>([]);
  const [selectedArea, setSelectedArea] = useState<any[]>([]);
  const [selectedAreaType, setSelectedAreaType] = useState<any>(userSettings.getOption(userSettings.MAPS_AREA_TYPE, 1));
  const [showAreaAdded, setShowAreaAdded] = useState<any>({ show: false, variant: "success", message: "" });
  const [apiAlertTimeout, setApiAlertTimeout] = useState<any>(null);

  const [areaSearchParams, setAreaSearchParams] = useState<any>(null);
  const debouncedAreaSearchParams = useDebounce(areaSearchParams, 400);

  const areaGridColumns = [
    { headerName: "Name", field: "area_name" },
    { headerName: "Code", field: "area_code" },
    { headerName: "Municipality", field: "municipality" },
    { headerName: "Addresses", field: "area_coordinates" },
    { headerName: "Blocked", field: "area_blacklistedCoordinates" }
  ];

  useEffect(() => {
    let areaTypeId = "";
    if (selectedAreaType !== null) {
      areaTypeId = selectedAreaType.value;
    }

    let bounds = null;
    if (searchBounds) {
      bounds = searchBounds;
    }

    // String searches gets priority in area search
    if (searchString) {
      bounds = null;
    }

    setAreaSearchParams({ areaTypeId, searchString, bounds });
  }, [selectedAreaType, searchString, searchBounds]);

  useEffect(() => {
    if (debouncedAreaSearchParams) {
      if (!debouncedAreaSearchParams.searchString && !debouncedAreaSearchParams.bounds) {
        setSearchResult([]);
        return;
      }

      fetchAreaResult(
        debouncedAreaSearchParams.areaTypeId,
        debouncedAreaSearchParams.searchString,
        debouncedAreaSearchParams.bounds
      )
        .then(data => {
          setSearchResult(data);
        })
        .catch(err => props.onError(err));
    }
  }, [debouncedAreaSearchParams]);

  useEffect(() => {
    userSettings.setOption(userSettings.MAPS_AREA_TYPE, selectedAreaType);
  }, [selectedAreaType]);

  useEffect(() => {
    clearTimeout(apiAlertTimeout);
    setTimeout(() => {
      setApiAlertTimeout(setShowAreaAdded({ ...showAreaAdded, show: false }));
    }, 5000);
  }, [showAreaAdded]);

  const onGridReady = (event: GridReadyEvent) => {
    event.api.sizeColumnsToFit();
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    event.api.sizeColumnsToFit();
  };

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const rows = event.api.getSelectedRows();
    const areaIds: string[] = rows.map(row => row.area_id);
    setHighlighedPolygonId(areaIds);
    setSelectedArea(rows);
  };

  const areaTypes = [
    { label: "Grunnkrets", value: 1 },
    { label: "Postnummer Sverige", value: 3 }
  ];

  const onAddSelected = () => {
    if (!selectedArea) {
      setShowAreaAdded({ show: true, variant: "warning", message: "Please select an area first." });
      return;
    }

    setShowAreaAdded({ show: true, variant: "success", message: "Area has been added." });
    props.onAddArea(selectedArea);
  };

  const onSelectBounds = (bounds: any) => {
    setSearchBounds(bounds);
  };

  return (
    <Modal size="lg" centered show={props.show} onHide={props.onHide}>
      <Modal.Header>
        <Modal.Title>Add Area</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form.Group controlId="frmAreaName">
              <Form.Label>Lookup Area</Form.Label>
              <Form.Control type="text" value={searchString} onChange={e => setSearchString(e.target.value)} />
              <Form.Text className="text-muted">Find areas by name, code, zip or municipality.</Form.Text>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="frmAreaType">
              <Form.Label>Area Type</Form.Label>
              <Select
                value={selectedAreaType}
                onChange={(opt: any) => setSelectedAreaType(opt)}
                options={areaTypes}
                isClearable={true}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="ag-theme-balham" style={{ height: "300px", width: "100%" }}>
              <AgGridReact
                columnDefs={areaGridColumns}
                rowData={searchResult}
                enableRangeSelection={false}
                rowSelection="multiple"
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                onSelectionChanged={onSelectionChanged}
                suppressAggFuncInHeader={true}
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  filter: true
                }}
              />
            </div>
          </Col>
          <Col>
            {props.show ? (
              <MapComponent
                highlightPolygonId={highlighedPolygonId}
                onSelectBounds={onSelectBounds}
                shadowAreaIDs={props.existingAreas}
              />
            ) : null}
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            {showAreaAdded.show ? <Alert variant={showAreaAdded.variant}>{showAreaAdded.message}</Alert> : null}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Done
        </Button>
        <Button variant="primary" onClick={onAddSelected}>
          Add Selected
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
