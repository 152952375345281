import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { actionSelectCampaign, actionSelectClient } from "../../redux/reducers/shell";
import { loadShellCampaigns } from "./shell.api";
import {
  MenuItem,
  TextField
} from "@material-ui/core";
import _uniqueId from "lodash/uniqueId";

interface ICampaignSelectorProps {
  clientId?: number;
  variant?: string;
  fullWidth?: boolean;
  onChange?: any;
  value?: string;
  required?: boolean;
}

export default function CampaignSelector(props: ICampaignSelectorProps) {
  const dispatch = useDispatch();
  const campaigns = useSelector(state => state.shell.campaigns);
  const selectedCampaign = useSelector(state => state.shell.selectedCampaign);
  const selectedClient = useSelector(state => state.shell.selectedClient);
  const selectedCampaignType = useSelector(state => state.shell.selectedCampaignType);
  const [id] = useState(_uniqueId("sky-"));

  function handleChange(e) {
    if (e.target.value == "") {
      dispatch(actionSelectCampaign(undefined));
    }
    else {
      dispatch(actionSelectCampaign(e.target));
    }
  }

  useEffect(() => {
    loadShellCampaigns(
      dispatch,
      selectedClient ? selectedClient.value : 0,
      selectedCampaignType ? selectedCampaignType.value : 0,
      0
    );
  }, [dispatch, selectedClient, selectedCampaignType, loadShellCampaigns]);

  useEffect(() => {
    if (props.clientId) {
      dispatch(actionSelectClient({value: props.clientId, label: ""}));
    }
  }, [props.clientId, dispatch]);

  if (props.variant === "material") {
    return (
      <TextField 
          value={selectedCampaign ? selectedCampaign.value : ""}
          select 
          label="Campaign"
          id={id}
          fullWidth={props.fullWidth}
          onChange={handleChange}
          required={props.required}
          >
          <MenuItem value="">None</MenuItem>
          {campaigns.map((campaign, key) => (
            <MenuItem key={key} value={campaign.campaign_id}>{campaign.campaign_name} ({campaign.shortname} - {campaign.campaign_id})</MenuItem>
          ))}
        </TextField>
    );
  }

  return (
    <Form.Group controlId="frmCampaign">
      <Form.Label>Campaign</Form.Label>
      <Select
        value={selectedCampaign}
        onChange={(opt: any) => dispatch(actionSelectCampaign(opt))}
        options={campaigns.map(c => ({ label: c.campaign_name, value: c.campaign_id }))}
        isClearable={true}
      />
    </Form.Group>
  );
}
