declare var env: any;

export interface searchFields {
    phoneNumber?: String,
    firstname: String,
    lastname: String,
    birthday: Date,
    buildingNumber: String,
    streetName: String,
    postCode?: String,
    city: string,
    meteringPoint?: String,
    companyVat?: String,
    socialSecurityNo?: String,
    socialSecurityNoView?: String,
    meteringFacility?: String
}

function encodeQueryString(params) {
    const keys = Object.keys(params);
    return keys.length
        ? "?" + keys.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])).join("&")
        : "";
}

const concatDateFormat = ( date: Date ) => {
    if (date == null) return '';
    var formattedDate = ('0' + date.getDate()).slice(-2);
    var formattedMonth = ('0' + (date.getMonth() + 1)).slice(-2);
    var formattedYear = date.getFullYear().toString().substr(-2);

//    return formattedYear + '-' + formattedMonth + '-' + formattedDate;
    return formattedDate + formattedMonth + formattedYear;
}

/*
export const validateForm = (searchFields: searchFields ) => {
    var missingFields = [];

    // Pri 0, elhub5 and elhub2 // org-id
    if (searchFields.companyVat !== undefined && searchFields.companyVat > 0) { 
        // elhub5
        if (searchFields.meteringFacility.length === 0) {
        missingFields.push('meteringFacility');
        }

        if (missingFields.length === 0) {
        return newValidation('pri0', null, 'elhub5', true, missingFields);
        }

        // elhub2
        missingFields = [];
        if (searchFields.facilityStreetName.length === 0) {
        missingFields.push('facilityStreetName');
        }

        if (searchFields.facilityHouseNo.length === 0) {
        // TODO: Addresses without building numbers?
        // missingFields.push('facilityHouseNo');
        }

        if (searchFields.facilityPostCode.length === 0) {
        missingFields.push('facilityPostCode');
        }

        if (missingFields.length > 0) {
        return newValidation('pri0', null, 'elhub2', false, missingFields);
        }

        return newValidation('pri0', null, 'elhub2', true, missingFields);
    }

    // Pri 1, DSF1, DSF2, Elhub1
    if (searchFields.contactBirthdate.length !== 0 &&
//        searchFields.facilityActivationDate.length === 0 &&
        searchFields.meteringFacility.length === 0) {

        // Validate elhub1
        if (searchFields.facilityStreetName.length === 0) {
        missingFields.push('facilityStreetName');
        }

        if (searchFields.facilityHouseNo.length === 0) {
        // TODO: Addresses without building numbers?
        // missingFields.push('facilityHouseNo');
        }

        if (searchFields.facilityPostCode.length === 0) {
        missingFields.push('facilityPostCode');
        }

        if (missingFields.length > 0) {
        return newValidation('pri1', 'dsf1,dsf2', 'elhub1', false, missingFields);
        }

        return newValidation('pri1', 'dsf1,dsf2', 'elhub1', true, missingFields);
    }

    // Pri 2, DSF1, DSF2, Elhub3
    if (searchFields.contactBirthdate.length !== 0 &&
        // searchFields.facilityActivationDate.length !== 0 &&
        searchFields.meteringFacility.length !== 0) {

        if (searchFields.facilityPostCode.length === 0) {
        missingFields.push('facilityPostCode');
        }

        if (searchFields.meteringFacility.length === 0) {
        missingFields.push('meteringFacility');
        }

        if (missingFields.length > 0) {
        return newValidation('pri2', 'dsf1,dsf2', 'elhub3', false, missingFields);
        }

        return newValidation('pri2', 'dsf1,dsf2', 'elhub3', true, missingFields);
    }

    // Pri 3, DSF1, DSF2, DSF3, Elhub1
    if (searchFields.contactBirthdate.length !== 0 &&
//        searchFields.facilityActivationDate.length !== 0 &&
        searchFields.meteringFacility.length === 0) {

        // Validate elhub1
        if (searchFields.facilityStreetName.length === 0) {
        missingFields.push('facilityStreetName');
        }

        if (searchFields.facilityHouseNo.length === 0) {
        // TODO: Addresses without building numbers?
        // missingFields.push('facilityHouseNo');
        }

        if (searchFields.facilityPostCode.length === 0) {
        missingFields.push('facilityPostCode');
        }

        if (missingFields.length > 0) {
        return newValidation('pri3', 'dsf1,dsf2', 'elhub1', false, missingFields);
        }

        return newValidation('pri3', 'dsf1,dsf2,dsf3', 'elhub1', true, missingFields);
    }

    return newValidation(null, null, null, false, missingFields);
}*/

const hasDSFHit = (data) => {
  if (typeof data !== 'undefined' &&
      typeof data['status'] !== 'undefined' && 
      data['status'] == 'success' &&
    (typeof data['data'] !== 'undefined' &&
    typeof data['data']['birthDate'] !== 'undefined' &&
    data['data']['birthDate'].length) ||
    (typeof data['data'] != 'undefined' && 
    typeof data['data']['socialSecurityNo'] !== 'undefined' && 
    data['data']['socialSecurityNo'].length)) {
    return true;
  }
  return false;
}

/**
 * DSF search controller.
 * @param searchFields 
 */
        /*
export const DSFController = (searchFields: searchFields ) => {
    var result = [];
    result['message'] = 'Operation did not complete.';


    console.log(searchFields);

    if (searchFields.companyVat == undefined || !searchFields.companyVat) {
        console.log("person search")
        if (
          searchFields.birthday &&
          searchFields.firstname.length &&
          searchFields.lastname.length
        ) {
            console.log("dsf1 search");
            let res = searchDSF('dsf1',searchFields)

            console.log
            result['data'] = res;
        } else {
            console.log("not dsf1");
        }
        console.log(result);
        if (
          !result['data'] &&
          searchFields.birthday &&
          searchFields.firstname.length &&
          searchFields.lastname.length &&
          searchFields.postCode
        ) {
            console.log("dsf2 search");
            result['data'] = searchDSF('dsf2',searchFields);
        } else {
            console.log("not dsf2");
        }
    
        if (!result['data']) {
            result['message'] = 'No valid search criterias found.';
            return result;
        }

        if (hasDSFHit(result['data'])) {
            result['data']['endUserIdentifier'] = result['data']['birthDate'] + result['data']['socialSecurityNo'];
        } else {
            result['message'] = 'Individual not found.';
        }    
     
    }

    return result;
  }*/

  export const NumberController = async(searchFields: searchFields) => { 
    var result = [];
    result['message'] = 'Operation did not complete.';

    console.log(searchFields);

    const query = {
      'type': 'number',
      'number': searchFields.phoneNumber
    };

    if (searchFields.phoneNumber != undefined && searchFields.phoneNumber.length) {
      const res = await fetch(env._apiPath + "/plugin/serviceSearch" + encodeQueryString(query), {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        });

        const json = await res.json();

        if(json.status === "success") {
            return json.data;
        }

        throw json.message;
    }
  }
  export const ZipController = async(searchFields: searchFields) => { 
    var result = [];
    result['message'] = 'Operation did not complete.';

    console.log(searchFields);

    const query = {
      'type': 'zip',
      'zip': searchFields.postCode
    };

    if (searchFields.postCode != undefined && searchFields.postCode.length) {
      const res = await fetch(env._apiPath + "/plugin/serviceSearch" + encodeQueryString(query), {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        });

        const json = await res.json();

        if(json.status === "success") {
            return json.data;
        }

        throw json.message;
    }
  }
  export const ConfigController = async() => { 
    var result = [];
    result['message'] = 'Operation did not complete.';

    const query = {
      'type': 'config'
    };

    const res = await fetch(env._apiPath + "/plugin/serviceSearch" + encodeQueryString(query), {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      });

      const json = await res.json();

      if(json.status === "success") {
          return json.data;
      }

      throw json.message;
  }

  export const DSFController = async(searchFields: searchFields) => { 
    var result = [];
    result['message'] = 'Operation did not complete.';

    console.log(searchFields);

    let type = '';
    if (searchFields.companyVat == undefined || !searchFields.companyVat) {
        console.log("person search")
        if (
          searchFields.birthday &&
          searchFields.firstname.length &&
          searchFields.lastname.length
        ) {
            console.log("dsf1 search");
            // let res = searchDSF('dsf1',searchFields)
            type = 'dsf1';
        } else {
            console.log("not dsf1");
        }
        if (
          type == '' &&
          searchFields.birthday &&
          searchFields.firstname.length &&
          searchFields.lastname.length &&
          searchFields.postCode
        ) {
          type = 'dsf2';
        } else {
          console.log("not dsf2");
        }

      const dsfToken = 'foobar'
      var result = [];
      result['resultType'] = type;
      const dsf = {
            dsf1: {
                'type': 'dsf',
                'token': dsfToken,
                'reference': 'manager',
                'birthDate': concatDateFormat(searchFields.birthday),
                'firstname': searchFields.firstname,
                'lastname': searchFields.lastname
            },
            dsf2: {
                'type': 'dsf',
                'token': dsfToken,
                'reference': 'manager',
                'birthDate': concatDateFormat(searchFields.birthday),
                'firstname': searchFields.firstname,
                'lastname': searchFields.lastname,
                'postCode': searchFields.postCode        
            }/*,
            dsf3: {
                'type': 'dsf',
                'token': dsfToken,
                'reference': 'manager',
                'birthDate': concatDateFormat(searchFields.birthday),
                'firstname': searchFields.po_firstname, // previous owner, not implemented
                'lastname': searchFields.po_lastname // previous owner, not implemented        
            }*/
    };

    console.log(dsf[type]);
    const res = await fetch(env._apiPath + "/plugin/serviceSearch" + encodeQueryString(dsf[type]), {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        });

        const json = await res.json();

        if(json.status === "success") {
            return json.data;
        }

        throw json.message;

/*
        if (result.data.socialSecurityNo == undefined) {
        return null;
        }

        return result;*/
      }
  }


  export const ElhubController = async(searchFields: searchFields ) => {      
    var result = [];
    let type = '';

    result['message'] = 'Operation did not complete.'
    
    var query;

    console.log(searchFields);
    if (
        !searchFields.companyVat &&
        searchFields.socialSecurityNo != undefined && searchFields.socialSecurityNo.length &&
        searchFields.streetName.length &&
        searchFields.buildingNumber.length &&
        searchFields.postCode != undefined && searchFields.postCode.length
    ) {
      query = {
        'type': 'elhub',
        'endUserIdentifier': searchFields.socialSecurityNo,
        'streetName': searchFields.streetName,
        'buildingNumber': searchFields.buildingNumber.toLocaleUpperCase(), // elhub requires uppercase building number?
        'postCode': searchFields.postCode,
        'wildcard': 1
    };
        console.log ("set elhub10");
        type = 'elhub10';
        //result['data'] = searchElhub('elhub1',query);
    }
    /*
            hvis postnr + husnr
            postnr + gatenavn
            gatenavn + husnr
    */
    if (
      !searchFields.companyVat &&
      searchFields.socialSecurityNo != undefined && searchFields.socialSecurityNo.length &&
      searchFields.buildingNumber.length &&
      searchFields.postCode != undefined && searchFields.postCode.length
  ) {
    query = {
      'type': 'elhub',
      'endUserIdentifier': searchFields.socialSecurityNo,
      'streetName': '*',
      'buildingNumber': searchFields.buildingNumber.toLocaleUpperCase(), // elhub requires uppercase building number?
      'postCode': searchFields.postCode,
      'wildcard': 1
  };
      console.log ("set elhub11");
      type = 'elhub11';
      //result['data'] = searchElhub('elhub1',query);
  }
  if (
    !searchFields.companyVat &&
    searchFields.socialSecurityNo != undefined && searchFields.socialSecurityNo.length &&
    searchFields.streetName.length &&
    searchFields.postCode != undefined && searchFields.postCode.length
) {
  query = {
    'type': 'elhub',
    'endUserIdentifier': searchFields.socialSecurityNo,
    'streetName': searchFields.streetName,
    'buildingNumber': '*',
    'postCode': searchFields.postCode,
    'wildcard': 1
};
    console.log ("set elhub12");
    type = 'elhub12';
    //result['data'] = searchElhub('elhub1',query);
}
if (
  !searchFields.companyVat &&
  searchFields.socialSecurityNo != undefined && searchFields.socialSecurityNo.length &&
  searchFields.streetName.length &&
  searchFields.buildingNumber.length
) {
query = {
  'type': 'elhub',
  'endUserIdentifier': searchFields.socialSecurityNo,
  'streetName': searchFields.streetName,
  'buildingNumber': searchFields.buildingNumber.toLocaleUpperCase(), // elhub requires uppercase building number?
  'postCode': '*',
  'wildcard': 1
};
  console.log ("set elhub13");
  type = 'elhub13';
  //result['data'] = searchElhub('elhub1',query);
}

    if (
      type == '' &&
      searchFields.companyVat &&
      searchFields.streetName.length &&
      searchFields.buildingNumber.length &&
      searchFields.postCode != undefined && searchFields.postCode.length 
    ) {
      query = {
        'type': 'elhub',
        'endUserIdentifier': searchFields.companyVat,
        'streetName': searchFields.streetName,
        'buildingNumber': searchFields.buildingNumber.toLocaleUpperCase(), // elhub requires uppercase building number?
        'postCode': searchFields.postCode,
        'wildcard': 1
      };
//      result['data'] = searchElhub('elhub2',query);
      type = 'elhub2';
      console.log ("set elhub2");
    }

    if (
      type == '' &&
      !searchFields.companyVat &&
      searchFields.postCode != undefined && searchFields.postCode.length &&
      searchFields.meteringFacility != undefined && searchFields.meteringFacility.length
    ) {
      query = {
        'type': 'elhub',
        'meteringFacility': searchFields.meteringFacility,
        'postCode': searchFields.postCode,
        'wildcard': 0
        };
        type = 'elhub3';
        //        result['data'] = searchElhub('elhub3',query);
        console.log ("set elhub3");
    }

    if (
      type == '' &&
      !searchFields.companyVat &&
      searchFields.socialSecurityNo != undefined && searchFields.socialSecurityNo.length &&
      searchFields.meteringFacility != undefined && searchFields.meteringFacility.length
    ) {
      query = {
        'type': 'elhub',
        'endUserIdentifier': searchFields.socialSecurityNo,
        'meteringFacility': searchFields.meteringFacility,
        'wildcard': 0
      };
      type = 'elhub4';
//      result['data'] = searchElhub('elhub4',query);
console.log ("set elhub4");
}

    if (
      type == '' &&
      searchFields.companyVat &&
      searchFields.meteringFacility != undefined && searchFields.meteringFacility.length
    ) {
      query = {
        'type': 'elhub',
        'endUserIdentifier': searchFields.companyVat,
        'meteringFacility': searchFields.meteringFacility,
        'wildcard': 0
      };
      type = 'elhub5';
//      result['data'] = searchElhub('elhub5',query);
  console.log ("set elhub5");
}
    if (
      type == '' &&
      searchFields.meteringPoint != undefined && searchFields.meteringPoint.length
    ) {
      query = {
        'type': 'elhub',
        'meteringPoint': searchFields.meteringPoint,
        'wildcard': 0
      };
      type = 'elhub7';
    //      result['data'] = searchElhub('elhub5',query);
    console.log ("set elhub7");
    }
/*
    if (result['data'] != undefined && result['data']['status'] !== 'success') {
      result['message'] = 'Invalid response from Elhub.';
      return result;
    }

    if (result['data'][0]['code'] !== undefined) {
      result['message'] = 'Response from Elhub: ' + result['data'][0]['name'];
      return result;
    }
    result['message'] = 'Elhub results.';


*/

  if (!query) return {'Error': "didn't match any" }; 

  const res = await fetch(env._apiPath + "/plugin/serviceSearch" + encodeQueryString(query), {
    method: "POST",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });

  const json = await res.json();
  
  if(json.status === "success") {
    json.data.type = type;
    return json.data;
  }

  throw json.message;

}
