import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-use";
import { loadLegacyPage, clearLegacyPage } from "./modules/shell/shell.api";

interface IGetPageProps {
  history: any;
  location: any;
  match: any;
  staticContext?: any;
  routes: any[];
}

export default function GetPageComponent(props: IGetPageProps) {
  const dispatch = useDispatch();
  const legacyHTML = useSelector(state => state.shell.legacy.html);
  const location = useLocation();

  useEffect(() => {
    if (location) {
      const page = location.pathname;

      if (page) {
        // TODO: Make sure the URL matches legacy or not
        const filterInternal = route => {
          const navparts = page.substr(1).split("/");
          const routeparts = route.path.substr(1).split("/");

          // Compare two first parts of the route. React-routes should not conflict
          // with legacy routes.
          if (navparts[0] === routeparts[0] && navparts[1] === routeparts[1]) {
            return true;
          }

          return false;
        };

        if (props.routes.filter(filterInternal).length > 0) {
          // console.log('Abort load, internal route')
          clearLegacyPage(dispatch);
          return;
        }
        console.log("Load legacy:", page);
        loadLegacyPage(dispatch, page);
      }
    }
  }, [location]);

  return <div id="legacyContainer">{/*legacyHTML
				? <div dangerouslySetInnerHTML={legacyHTML} />
			: 'Loading ...'*/}</div>;
}
