import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { actionToggleFilterView, actionSelectCampaign, actionSelectClient, actionSelectCampaignType, actionSelectEndDate, actionSelectStartDate } from "../../redux/reducers/shell";
import TitlebarComponent from "../../titlebar.component";
import ClientSelector from "../shell/clientselector.component";
import CampaignSelector from "../shell/campaignselector.component";
import PeriodSelector from "../shell/periodselector.component";
import CampaignTypeSelector from '../shell/campaigntypeselector.component';
import { rejectSoundfile, fetchRejectReasons, soundfileApproval, fetchSoundfiles, fetchOutcomes, Outcomes, Soundfile } from "./soundfiles/soundfiles.api";
import OrderDetails from "../reports/orders/detailsnew.component";
import { loadOrder, loadOrder2, IOrder} from "../reports/orders/orders.api";


import { format } from "date-fns/esm";
import { AgToolbarButton } from "../../agGridUtils";
import { AgGridReact } from "ag-grid-react";
import { Grid, CardContent, Card, CardActions,
     makeStyles, Theme, createStyles, TextField,
      MenuItem, Dialog, DialogTitle, DialogContent,
       Button, DialogActions,
       createTheme, ThemeProvider, CircularProgress } from "@material-ui/core";
// gridsystemet
import { GridReadyEvent, FirstDataRenderedEvent, SelectionChangedEvent, GridApi, ColumnApi } from "ag-grid-community";
import AudioPlayer from 'material-ui-audio-player';

const muiTheme = createTheme({});

// setter initielle datoer
const defaultStartDate = new Date();
var d = new Date(); d.setDate(d.getDate());
defaultStartDate.setDate(d.getDate()-1);

const marginStyles = {
   // margin: '24px 0 0 0',
}
function formatDateTime(date) {
    var d = new Date(date.replace(/\s/, 'T')),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour = '' + d.getHours(),
        min = '' + d.getMinutes();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    if (hour.length < 2) 
        hour = '0' + hour;
    if (min.length < 2) 
        min = '0' + min;

    return ([day, month, year].join('.') + ' ' + hour + ':' + min);
}

// theme for AdGridReact
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorMessage: {
            color: '#ff0000'
        },
        flexGrow: {
            flexGrow: 1,
        },
        player: {
            height: 100
        },
        centerBox: {
            justifyContent: "flex-end",
            alignItems: "flex-end"
        },          
        accepted: {
            color: '#fff',
            '&:hover': {
                color: '#000',
                backgroundColor: '#38d57a'
            },
            backgroundColor: '#38d57a'
        },
        rejected: {
            color: '#fff',
            '&:hover': {
                color: '#000',
                backgroundColor: '#E53057'
            },
            backgroundColor: '#E53057'
        },
        button: {
            backgroundColor: '#fff',
/*            backgroundColor: '#868e96'*/
        },
        actions: {
            "& .form-group": {
                minWidth: 200,
                marginRight: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
            "& button": {
              //  marginTop: 24,
            },
        },
        agGrid: {
            "& .aggrid-size": {
                minHeight: 500,
                height: "calc(100vh - 460px)",
                width: "100%",
            },
        },
    })
);
// for knappene over tabellen
const toolbarProps = {
    title: "",
    rightAlign: false,
    disabled: false,
    icon: "",
    onClick: () => { },
};

export default function SoundfilesComponent() {
    const [soundfiles, setSoundfiles] = useState([]);
    const [columnAPI, setColumnAPI] = useState<ColumnApi>();
    const [gridAPI, setGridAPI] = useState<GridApi>();
    const [loading, setLoading] = useState(false);
    // for tabellen
    const dispatch = useDispatch();
    const classes = useStyles();
    const hideFilters = useSelector((state) => state.shell.hideFilters);
    // const isOrderOpen = useSelector((state) => state.reports.orders.orderDetails.show);

    const [showDetailsOpen, setShowDetailsOpen] = useState(false)
    const [orderDetails, setorderDetails] = useState<IOrder | undefined>();
    const [orderDetails2, setorderDetails2] = useState<IOrder | undefined>();

    const selectedClient = useSelector((state) => state.shell.selectedClient);
    const selectedCampaignType = useSelector((state) => state.shell.selectedCampaignType);
    const selectedCampaign = useSelector((state) => state.shell.selectedCampaign);
    const selectedStartDate = useSelector((state) => state.shell.selectedStartDate);
    const selectedEndDate = useSelector((state) => state.shell.selectedEndDate);
    let selectedStartDateFormatted = format(new Date(selectedStartDate), "dd.MM.yyyy");
    let selectedEndDateFormatted = format(new Date(selectedEndDate), "dd.MM.yyyy");
    // const campaigns = useSelector((state) => state.shell.campaigns);
    const [showRecordingApproval, setShowRecordingApproval] = useState(false); // show recording dialog
    const [soundFileStatusText, setSoundFileStatusText] = useState<string>('');
    const [soundFileApprovalStatus, setSoundFileApprovalStatus] = useState<number>(0);
    const [outcome, setOutcome] = useState([{
        created_at: "",
        outcome_id: 0,
        outcome_name: "",
        outcome_status_description: "",
        outcome_status_external_ref: "",
        outcome_status_id: 0,
        outcome_status_name: "",

    }]);
    interface order_rejected_reasons {
        reason_id: number,
        created_at: Date,
        updated_at: Date,
        reason_name: string,
        rejected_type: number,
        hide_in_list: number,
        client?: number
    }
    const emptyRejectedReason = {
        reason_id: 0,
        created_at: new Date(),
        updated_at: new Date(),
        reason_name: '',
        rejected_type: 0,
        hide_in_list: 0
    }
    const [rejectedReasons,setRejectedReasons] = useState<order_rejected_reasons[]>([emptyRejectedReason])
    // const [selectedRejectedReason,setSelectedRejectedReason] = useState("");
    // const [rejectedReason, setRejectedReason] = useState<string>('');


    const [selectedOutcome, setSelectedOutcome] = useState("");
    const [selectedFile, setSelectedFile] = useState<Soundfile>({
        created_at: "",
        activity_id: "",
        activity_file_detail_id: 0,
        activity_file_id: 0,
        phone: "",
        firstname: "",
        lastname: "",
        login_name: "",
        client_name: "",
        campaign_stype_name: "",
        campaign_name: "",
        outcome_name: "",
        soundfile_url: "",
        client_id: "",
        contact_id: "",
        customer_id: "",
        order_id: "",
        rejected_at: "",
        confirmed_at: "",
        new_soundfile: "",
        order_action_comment: "",
        order_rejected_reason_id: ""
    });
    // const [showOrderModal, setShowOrderModal] = useState(false);
    // const [reportQuery, setReportQuery] = useState<any>(null);
    // const openInNewTab = (url) => {
    //     const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    //     if (newWindow) newWindow.opener = null
    // }
    
    const [playRecording, setPlayRecording] = useState<string>('');
    const [showRejectReason, setShowRejectReason] = useState(0);
    const showRecording = ( soundfile: any ) => {
        setSelectedFile(soundfile);

        console.log(soundfile);

        //openInNewTab(params.data.soundfile_url);
        if (soundfile.confirmed_at) {
            setSoundFileApprovalStatus(1);
            setShowRejectReason(0);
        } else 
        if (soundfile.rejected_at) {
            console.log("rejected is set");
            console.log(soundfile.rejected_at);
            setSoundFileApprovalStatus(2);
            setShowRejectReason(1);
        } else {
            console.log("rejected is not set");
            console.log(soundfile.rejected_at);
            setSoundFileApprovalStatus(0);
            setShowRejectReason(0);
        }

      //  fetchSoundfileApprovalStatus( soundfile ).then(() => {
            setShowRecordingApproval(true);
        //});
        // soundfile.soundfile_url = soundfile.soundfile_url.replace(/(audio\/)/g,'$1stream.php?sessid=');
/*
{
	"986": {
		"created_at": "2021-04-12 10:05:51.430",
		"activity_id": "46896596",
		"customer_id": "2",
		"client_id": "14",
		"contact_id": "21910055",
		"phone": "92013626",
		"firstname": "Gry Juli",
		"lastname": "Ottestad",
		"login_name": "20624",
		"client_name": "Canal Digital",
		"campaign_type_name": "Nysalg",
		"campaign_name": "Nysalg",
		"outcome_name": "Telefonsvarer",
		 "soundfile_url": "https://media.fiko.no/audio/MMGv8620210412100551168",
		"order_id": "0",
		"new_soundfile": null,
		"rejected_at": null,
		"confirmed_at": null,
		"sms_confirmed_at": null,
		"regretted_at": null
	}
}
*/
// interface DateTimeFormatOptions {
//     localeMatcher?: string;
//     weekday?: string;
//     era?: string;
//     year?: string;
//     month?: string;
//     day?: string;
//     hour?: string;
//     minute?: string;
//     second?: string;
//     timeZoneName?: string;
//     formatMatcher?: string;
//     hour12?: boolean;
//     timeZone?: string;
//     }
  
const options = {
    month: 'long', day: 'numeric',
    hour: 'numeric', minute: 'numeric', 
    hour12: false
} as const;

    let status = '';
    if (soundfile.rejected_at !== null) {
        status = 'Rejected at ' + new Intl.DateTimeFormat('nb-NO', options).format(new Date(soundfile.rejected_at));
    } else 
    if (soundfile.regretted_at !== null) {
        status = 'Regretted at ' + new Intl.DateTimeFormat('nb-NO', options).format(new Date(soundfile.regretted_at));
    } else 
    if (soundfile.confirmed_at !== null) {
        status = 'Confirmed at ' + new Intl.DateTimeFormat('nb-NO', options).format(new Date(soundfile.confirmed_at));
    } else 
    if (soundfile.sms_confirmed_at !== null) {
        status = 'SMS is confirmed at ' + new Intl.DateTimeFormat('nb-NO', options).format(new Date(soundfile.sms_confirmed_at));
    } else 
        status = 'Soundfile has no registered actions';

    let soundFileText = new Intl.DateTimeFormat('nb-NO', options).format(new Date(soundfile.created_at)) + ': Outcome: ' + soundfile.outcome_name + ', Status: ' + status;

    setSoundFileStatusText(soundFileText);
    setPlayRecording(soundfile.soundfile_url);
}

    const openOrder = (order_id) => {
        // dispatch(actionOpenDetails(order_id));
        // loadOrderDetails(dispatch, order_id);
        setLoading(true)
        loadOrder2(order_id)
            .then(res => setorderDetails2(res))
            .catch(err => {setorderDetails2(undefined); console.error(err)});
            // TODO ERRORHANDLER
        loadOrder(order_id)
            .then(res => setorderDetails(res))
            .catch(err => {setorderDetails(undefined); console.error(err)})
            .then(() => setLoading(false))
            .finally(() => setShowDetailsOpen(true));
            // TODO ERRORHANDLER
    };
    const GDPR7Days = [11,15,16,18,22];
    // Setter kolonner med navn etc
    const columnDefs = [
        { headerName: "Client", field: "client_name", sortable: true, filter: true,},
        { headerName: "Campaigntype", field: "campaign_type_name", sortable: true, filter: true},
        { headerName: "Campaign", field: "campaign_name", sortable: true, filter: true},
        { headerName: "Agent", field: "login_name", sortable: true, filter: true},
        { headerName: "Firstname", field: "firstname", sortable: true, filter: true},
        { headerName: "Lastname", field: "lastname", sortable: true, filter: true},
        { headerName: "Phone", field: "phone", sortable: true, filter: true},
        { headerName: "Outcome", field: "outcome_name", sortable: true, filter: true},
        { headerName: "Created at", field: "created_at", sortable: true, filter: true,
            cellRenderer: (r) => {
                if (!r.data.created_at) {
                    return '';
                } 
                return (formatDateTime(r.data.created_at))
            }
        },
        { headerName: "Confirmed at", field: "confirmed_at", sortable: true, filter: true,
            cellRenderer: (r) => {
                if (!r.data.confirmed_at) {
                    return '';
                } 
            return (formatDateTime(r.data.confirmed_at))
            }    
        },
        { headerName: "Rejected at", field: "rejected_at", sortable: true, filter: true,
            cellRenderer: (r) => {
                if (!r.data.rejected_at) {
                    return '';
                } 
            return (formatDateTime(r.data.rejected_at))
            }    
        },
        { headerName: "SMS Confirmed", field: "sms_confirmed_at", sortable: true, filter: true,
            cellRenderer: (r) => {
                if (!r.data.sms_confirmed_at) {
                    return '';
                } 
            return (formatDateTime(r.data.sms_confirmed_at))
            }    
        },
        /*{ headerName: "Accepted at", field: "accepted_at", sortable: true, filter: true,
            cellRenderer: (r) => {
                if (!r.data.accepted_at) {
                    return '';
                } 
            return (formatDateTime(r.data.accepted_at))
            }    
        },
        { headerName: "Regretted at", field: "regretted_at", sortable: true, filter: true,
            cellRenderer: (r) => {
                if (!r.data.regretted_at) {
                    return '';
                } 
            return (formatDateTime(r.data.regretted_at))
            }    
        },*/
        { headerName: "New Soundfile", field: "new_soundfile", sortable: true, filter: true,
            cellRenderer: (r) => {
                if (!r.data.new_soundfile) {
                    return '';
                } 
            return (formatDateTime(r.data.new_soundfile))
            }
        },
        { headerName: "Order ID", field: "order_id", sortable: true, filter: true,
            cellStyle: (r) => {
                return { textAlign: "center", cursor: "pointer" }
            },
            cellRenderer: (r) => {
                if (r.data.order_id !== "0" && r.data.outcome_name === "Ja") {
                    return `<span>${r.data.order_id} <i class="fas fa-tag" style="color: lightblue" title="View Order"></i></span>`;
                }
                else if (r.data.order_id !== "0" && r.data.outcome_name !== "Ja") {
                    return r.data.order_id;
                }
                return '';
            },
            onCellClicked: (r: any) => {
                if (!r.data) {
                    return;
                }
                if (r.data.order_id !== "0" && r.data.outcome_name === "Ja") {
                    openOrder(r.data.order_id);
                }
                return;
            }  
        },
        /*
        var timestamp = new Date().getTime() + (30 * 24 * 60 * 60 * 1000)
//                                      day hour  min  sec  msec
Compare that timestamp with the timestamp for the selected date.

if (timestamp > selectedTimestamp) {
    // The selected time is less than 30 days from now
}
else if (timestamp < selectedTimestamp) {
    // The selected time is more than 30 days from now
}
else {
    // -Exact- same timestamps.
}
        */
        { headerName: "Soundfile", field: "soundfile_url", sortable: true, filter: true,
            cellStyle: (r) => {
                return { textAlign: "center", cursor: "pointer" }
            },
            cellRenderer: (r) => {
                if (GDPR7Days.includes(parseInt(r.data.customer_id))) {
                    if ((r.data.sms_confirmed_at == null && r.data.confirmed_at == null) && new Date(r.data.created_at).getTime() < new Date().getTime() - 604800000)
                        return 'GDPR';
                    else 
                        return '<span><i class="fas fa-play" style="color: lightgreen" title="Play recording"></i></span>';
                }
                else 
                    return '<span><i class="fas fa-play" style="color: lightgreen" title="Play recording"></i></span>';
            },
            onCellClicked: (params: any) => {
                
                if (GDPR7Days.includes(parseInt(params.data.customer_id))) {
                    if (((new Date(params.data.created_at).getTime() < new Date().getTime() - 604800000 && (params.data.sms_confirmed_at == null && params.data.confirmed_at == null))) || !params.data) {
                        return;
                    }
                    else showRecording(params.data);
                }
                else 
                    showRecording(params.data);
                
                
            }    
        
        },
        // de under her er hidden
        { headerName: "Activity ID", field: "activity_id", sortable: true, filter: true, hide: true},
        { headerName: "Client ID", field: "client_id", sortable: true, filter: true, hide: true},
        { headerName: "Contact ID", field: "contact_id", sortable: true, filter: true, hide: true},
        { headerName: "Customer ID", field: "customer_id", sortable: true, filter: true, hide: true},
    ];

    // setter initielle datoer
    const defaultStartDate = new Date();
    var d = new Date(); d.setDate(d.getDate());
    defaultStartDate.setDate(d.getDate()-1);
    let defaultStartDateFormatted = format(new Date(defaultStartDate), "dd.MM.yyyy");
    const defaultEndDate = new Date();
    let defaultEndDateFormatted = format(new Date(defaultEndDate), "dd.MM.yyyy");

    let searchSoundfileQuery = {
        text: "",
        startDate: "",
        endDate: "",
        outcome: "",
    };
    // søkefelt som søker i tabellen
    const handleQuickFilter = event => {
        gridAPI!.setQuickFilter(event.target.value);
    };
    const queryText = () => {
        if (!selectedClient) { searchSoundfileQuery.text = "" }
        if (selectedClient && !selectedCampaignType) { searchSoundfileQuery.text = "?search_client=" + selectedClient.value }
        if (selectedClient && selectedCampaignType && !selectedCampaign) { searchSoundfileQuery.text = `?search_client=${selectedClient.value}&search_campaign_type=${selectedCampaignType.value}` }
        if (selectedClient && selectedCampaignType && selectedCampaign) { searchSoundfileQuery.text = `?search_client=${selectedClient.value}&search_campaign_type=${selectedCampaignType.value}&search_campaign=${selectedCampaign.value}` }
    };
    // Dette skjer når du klikker på en rad i tabellen, lagrer object med file id
    const onSelectionChanged = (event: SelectionChangedEvent) => {
        const rows = event.api.getSelectedRows();
        if (!rows[0]) {
            return;
        }
        setSelectedFile(rows[0]);
    };
    // laster inn data om tabellen
    const onGridReady = (event: GridReadyEvent) => {
        setGridAPI(event.api);
        setColumnAPI(event.columnApi);
    };
    // 3 knapper over tabellen, refresh, og endre størrelse på kolonner
    const onAdjustColumnsContent = (event: Event) => {
        var allColumnIds: any = [];
        if (columnAPI) {
            const allColumns = columnAPI!.getAllColumns();
            allColumns!.forEach((column) => {
                const colDef = column.getColDef();
                if (!colDef.suppressAutoSize) {
                    allColumnIds.push(column.getColId());
                }
            });
            columnAPI!.autoSizeColumns(allColumnIds);
        }
    };
    const onAdjustColumnsWindow = (event: Event) => {
        gridAPI!.sizeColumnsToFit();
    };
    const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
        event.api.sizeColumnsToFit();
    };
    const onExportToCsv = () => {
        if (gridAPI) {
        gridAPI.exportDataAsExcel();
        }
    };
    
    const getOutcomes = () => {
        fetchOutcomes()
        .then(res => {setOutcome(res)})
    }
    const getSoundfiles = () => {
        setLoading(true);
        // bygger søketekst
        queryText();
        // setter datoer, formatert
        searchSoundfileQuery.startDate = selectedStartDateFormatted ? selectedStartDateFormatted : defaultStartDateFormatted;
        searchSoundfileQuery.endDate = selectedEndDateFormatted ? selectedEndDateFormatted : defaultEndDateFormatted;
        // utfall &search_outcome_id=1
        selectedOutcome !== "" ? searchSoundfileQuery.outcome = selectedOutcome : searchSoundfileQuery.outcome = "";
        // last inn soundfiles
        fetchSoundfiles(searchSoundfileQuery)
        .then(res => {setSoundfiles(res)})
        .finally(() => setLoading(false));
    }

    // useEffect(() => {
    //     getSoundfiles();
    // },[selectedClient, selectedCampaignType, selectedCampaign,selectedStartDate, selectedEndDate, selectedOutcome, soundFileApprovalStatus]);
    const resetForm = () => {
        dispatch(actionSelectCampaign(undefined)); 
        dispatch(actionSelectClient(undefined));
        dispatch(actionSelectCampaignType(undefined)); 
        const today = new Date();
        let yday = new Date();
        yday.setDate(today.getDate() - 1);
        dispatch(actionSelectStartDate(yday));
        dispatch(actionSelectEndDate(today)); 
        setSelectedOutcome("");
    }
    const closeOrderDetailsDialog = () => {
        setShowDetailsOpen(false)
    }
    useEffect(() => {
        getOutcomes();
        fetchRejectReasons().then(res => { setRejectedReasons(res); })
    },[]);

    // useEffect(() => {
    //     if (!isOrderOpen) getSoundfiles();
    // },[isOrderOpen]);

    return (
        <div className="ag-theme-material">
            <TitlebarComponent
                title="Soundfiles"
                description="An overview of soundfiles"
                breadcrumbs={[
                    { title: "Dashboard", link: "/" },
                    { title: "Soundfiles", link: "/handling/soundfiles" }
                ]}
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div
                            style={{ color: "#ccc", width: 15 }}
                            data-toggle="tooltip"
                            data-original-title="Hide/Show filters"
                            onClick={() => dispatch(actionToggleFilterView())}
                        >
                            {hideFilters ? (
                                <i className="fas fa-fw fa-caret-square-right"></i>
                            ) : (
                                    <i className="fas fa-fw fa-caret-square-left"></i>
                                )}
                        </div>
                </Grid>
                <Grid item xs={12} md={2} style={{ display: hideFilters ? "none" : "block" }}>
                    <div className="soundfilesMenu" style={{maxWidth: "270px"}}>
                        <ClientSelector variant="material" fullWidth/>
                        <div className={`cmpSel ${!selectedClient ? 'disabled' : null}`} title="Select a client first">
                            <CampaignTypeSelector variant="material" fullWidth />
                        </div>
                        <div className={`cmpSel ${!selectedCampaignType ? 'disabled' : null}`} title="Select a client and campaign type first">
                            <CampaignSelector variant="material" fullWidth/>
                        </div>
                        
                        {/* Outcomes-velger */} 
                        <TextField 
                            style={{ width: "100%"}}
                            select
                            label="Outcomes"
                            onChange={(e) => setSelectedOutcome(e.target.value)}
                            value={ selectedOutcome ? selectedOutcome : ""}>
                                {outcome.map((outcomes: Outcomes, key) => (
                                    <MenuItem key={key} value={outcomes.outcome_id}>
                                        {outcomes.outcome_name}
                                    </MenuItem>
                                ))}
                                <MenuItem value="">
                                    -- None --
                                </MenuItem>
                        </TextField>
                        {/* Datovelger */}
                    <PeriodSelector defaultStartDate={defaultStartDate} variant="material" />
                    <div style={{ textAlign: "right", marginTop: 20 }}>
                        { loading ? <CircularProgress size={20} /> : null}
                        <Button
                            onClick={resetForm}
                            variant="outlined"
                            color="primary"
                            >
                                Reset
                            </Button>
                            &nbsp;
                        <Button 
                          onClick={getSoundfiles} 
                          variant="contained"
                          color="primary"
                          disabled={loading}>
                          Search
                        </Button>
                      </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={hideFilters ? 12 : 10}>
                    <Card>                
                        <CardActions disableSpacing className={classes.actions}>
                            {/* Knappene over tabellen */}
                            <div className={classes.flexGrow} />
                            <input 
                                type="text" 
                                style={marginStyles}
                                placeholder="Search" 
                                onChange={handleQuickFilter}
                            />
                            {AgToolbarButton({
                                ...toolbarProps,
                                title: "Refresh",
                                icon: "fa-sync-alt",
                                onClick: getSoundfiles,
                            })}

                            {AgToolbarButton({
                                ...toolbarProps,
                                title: "Export to CSV",
                                icon: "fa-file-export",
                                onClick: onExportToCsv,
                            })}
                            {AgToolbarButton({
                                ...toolbarProps,
                                title: "Reset Filter",
                                icon: "fa-eraser",
                                onClick: () => {
                                gridAPI?.setFilterModel(null);;
                                },
                            })}
                            {AgToolbarButton({
                                ...toolbarProps,
                                title: "Adjust columns to window",
                                icon: "fa-columns",
                                onClick: onAdjustColumnsWindow,
                            })}

                            {AgToolbarButton({
                                ...toolbarProps,
                                title: "Adjust columns to content",
                                icon: "fa-text-width",
                                onClick: onAdjustColumnsContent,
                            })}
                            
                        </CardActions>
                        {/* Selve tabellen */}
                        <CardContent>
                            <Grid container>
                            <Grid item xs={12} className={classes.agGrid}>
                                <div className="ag-theme-balham aggrid-size">
                                    <AgGridReact
                                        rowData={soundfiles}
                                        columnDefs={columnDefs}  
                                        onGridReady={onGridReady}
                                        onSelectionChanged={onSelectionChanged}
                                        enableRangeSelection={false}
                                        rowSelection="single"
                                        // justerer kolonnebredden etter load
                                        onFirstDataRendered={onFirstDataRendered}
                                        sideBar={{
                                            toolPanels: ["columns"],
                                        }}
                                        // pagination
                                        pagination={true}
                                        paginationPageSize={100}
                                        cacheBlockSize={100}
                                        cacheOverflowSize={2}
                                        maxBlocksInCache={2}
                                        enableCellTextSelection={true}
                                        ensureDomOrder={true}
                                        defaultColDef={{
                                            flex: 1,
                                            minWidth: 100,
                                            sortable: true,
                                            resizable: true,
                                            filter: true,
                                            filterParams: {apply: true, newRowsAction: 'keep'}
                                        }}
                                    />
                                </div>
                            </Grid>
                            </Grid>
                        </CardContent>  
                </Card>
                </Grid>
            </Grid>
            <OrderDetails
                showDetailsOpen={showDetailsOpen} setShowDetailsOpen={setShowDetailsOpen} order={orderDetails} order2={orderDetails2} onClose={closeOrderDetailsDialog} fetchOrder={() => {}}
                // onReloadReport={() => {
                //     console.log("onReloadReport");
                //     runReport(reportQuery);
                // }}
                />

            <Dialog fullWidth maxWidth="sm" open={showRecordingApproval} onClose={() => setShowRecordingApproval(false)}>
                <DialogTitle id="frm-add-company">Sound file</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12} className={classes.player}>
                            <ThemeProvider theme={muiTheme}>
                                <AudioPlayer
                                    elevation={1}
                                    width="100%"
                                    variation="default"
                                    spacing={3}
                                    download={false}
                                    autoplay={true}
                                    order="standart"
                                    loop={false}
                                    // src={playRecording}
                                    src={'https://media.mmgsky.no/audio/stream2.php?fileid=' + selectedFile.activity_file_id}
                                />
                            </ThemeProvider>
                        </Grid>
                        <Grid item md={12} title="Høyreklikk linken og velg lagre som">
                            <a href={'https://api-stage.fiko.no/manager/file/getSoundFile?activity_file_id=' + selectedFile.activity_file_id} download={selectedFile.activity_file_id}>Download soundfile</a>
                        </Grid>
                        <Grid item xs={12}>                            
                            {soundFileStatusText}
                        </Grid>                            
                    </Grid>                    
                    {showRejectReason?<Grid container>
                        <Grid item xs={12}>
                            Reject reason                            
                        </Grid>
                        <Grid item xs={12}>
                        <TextField 
                        style={{ width: "100%"}}
                        select
                        label="Reject reasons"
                        onChange={(e) => setSelectedFile({ ...selectedFile, order_rejected_reason_id: e.target.value })}
                        value={ selectedFile.order_rejected_reason_id ? selectedFile.order_rejected_reason_id : ""}>
                            {rejectedReasons.map((rejectedReason: order_rejected_reasons, key) => (
                                <MenuItem key={key} value={rejectedReason.reason_id}>
                                    {rejectedReason.reason_name}
                                </MenuItem>
                            ))}
                    </TextField>

                        <TextField 
                            style={{ width: "100%"}}
                            label="Comment"
                            onChange={(e) => setSelectedFile({ ...selectedFile, order_action_comment: e.target.value })}
                            value={selectedFile.order_action_comment ? selectedFile.order_action_comment : ""} />                                
                        </Grid>
                        <Grid item xs={3}>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                style={{ height: 40 }}
                                onClick={() => { soundfileApproval(selectedFile, 0).then((res) => {
                                    selectedFile.confirmed_at = res.confirmed_at;  
                                    selectedFile.rejected_at = res.rejected_at;  
                                    selectedFile.regretted_at = res.regretted_at;  
                                    rejectSoundfile( selectedFile ); 
                                    setSoundFileApprovalStatus(2);      
                                }); 
                             }}
                            >
                                Commit
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                style={{ height: 40 }}
                                onClick={() => { soundfileApproval(selectedFile, -1).then((res) => {
                                    selectedFile.confirmed_at = res.confirmed_at;  
                                    selectedFile.rejected_at = res.rejected_at;  
                                    selectedFile.regretted_at = res.regretted_at;  
                                    setSoundFileApprovalStatus(0);      
                                }); 
                             }}
                            >
                                Clear
                            </Button>
                        </Grid>
                    </Grid>:null}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="outlined" className={soundFileApprovalStatus === 1?classes.accepted:classes.button} onClick={() => { 
                        if (selectedFile.confirmed_at) {
                            soundfileApproval(selectedFile, -1).then((res) => {
                                selectedFile.confirmed_at = res.confirmed_at;  
                                selectedFile.rejected_at = res.rejected_at;  
                                selectedFile.regretted_at = res.regretted_at;  
                                setSoundFileApprovalStatus(0);
                            });
                        } else {
                            soundfileApproval(selectedFile, 1).then((res) => {
                                selectedFile.confirmed_at = res.confirmed_at;  
                                selectedFile.rejected_at = res.rejected_at;  
                                selectedFile.regretted_at = res.regretted_at;  
                                setSoundFileApprovalStatus(1);                              
                            });
                        }
                    }}>
                        <i className="fa fa-thumbs-up" aria-hidden={true}></i>&nbsp;Approve
                    </Button>
                    <Button color="primary" variant="outlined" className={soundFileApprovalStatus === 2?classes.rejected:classes.button} onClick={() => { setShowRejectReason(1); /*soundfileApproval(selectedFile, 0); setSoundFileApprovalStatus(soundFileApprovalStatus != 2?2:0);  getSoundfiles();*/ } }>
                        <i className="fa fa-thumbs-down" aria-hidden={true}></i>&nbsp;Reject
                    </Button>
                    <Button color="primary" variant="outlined" onClick={() => { setShowRecordingApproval(false); }}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>                
        </div>
    );
    
}