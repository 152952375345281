import React, { useState } from "react";
import { Map, Marker, Popup, TileLayer, Polygon } from "react-leaflet";
import { useSelector, useDispatch } from "react-redux";
import { actionSetZoomLevel } from "../../../../redux/reducers/listmanagement/maps";

declare var env: any;

const FullMapComponent: React.FC = () => {
  const zoomLevel = useSelector((state: any) => state.listmanagement.maps.zoomLevel);
  const polygons = useSelector((state: any) => state.listmanagement.maps.polygons);
  const agentPositions = useSelector((state: any) => state.listmanagement.maps.agentPositions);

  const dispatch = useDispatch();

  const [tmpAppartmentBuildings, setTmpAppartmentBuildings] = useState([]);

  const position = [58.12631, 7.94706];
  const mapBoxUrl =
    "https://api.mapbox.com/styles/v1/mapbox/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibW1nc2t5IiwiYSI6ImNqbXUzdGxmNTAxZGQzd28yNzk4OGs5NnQifQ.ilOJoTnkHzf2s2r0cWJ--w";
  const attribution =
    "Developed by &copy; <a href='https://www.fiko.no/' target='_blank'>MMG Sky</a> | " +
    "<a href='mailto:support@fiko.no?subject=I found a bug in maps.fiko.no'>" +
    "<i class='fa fa-fw fa-bug'></i> Report bug</a>";

  const onZoomEnd = (e: any) => {
    dispatch(actionSetZoomLevel(e.target.getZoom()));
  };

  const onMoveEnd = (e: any) => {
    const bounds = e.target.getBounds();
    const req = {
      bounds: {
        northWest: bounds.getNorthWest(),
        southEast: bounds.getSouthEast()
      }
    };

    const fetchAppartments = async () => {
      if (zoomLevel < 14) {
        setTmpAppartmentBuildings([]);
        return;
      }

      const res = await fetch(env._apiPath.replace("/manager", "") + "/maps/admin/appartmentbuilding", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(req),
        headers: { "Content-Type": "application/json" }
      });
      const json = await res.json();

      setTmpAppartmentBuildings(json.data);
    };
    fetchAppartments();
  };

  return (
    <Map center={position} zoom={zoomLevel} zoomControl={false} onZoomEnd={onZoomEnd} onMoveEnd={onMoveEnd}>
      <TileLayer id="streets-v11" url={mapBoxUrl} attribution={attribution} />

      {/* Areas:
          The areas are retrieved from API by user search or viewport bounds. We should be able to
          toggle selection of these areas, and retrieve detailed information (addresses, blocked)
          for the user to preview before adding them to a segment.
      */}
      {polygons.areas
        ? polygons.areas.map((area, idx) => {
            return <Polygon key={idx} color="gray" positions={area} />;
          })
        : null}

      {agentPositions.map((agent, idx) => {
        return (
          <Marker key={idx} position={{ lat: agent.lat, lng: agent.lng }}>
            <Popup>Agent {agent.agent_id}</Popup>
          </Marker>
        );
      })}

      {tmpAppartmentBuildings.map((bld: any, idx) => {
        return (
          <Marker key={idx} position={{ lat: bld.lat, lng: bld.lng }}>
            <Popup>
              {bld.street}
              <br />
              {bld.appartments} units
            </Popup>
          </Marker>
        );
      })}

      {/*<Marker position={position}>
            <Popup>A pretty CSS3 popup.<br />Easily customizable.</Popup>
          </Marker>*/}
    </Map>
  );
};

export default FullMapComponent;
