import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import TitlebarComponent from "../../titlebar.component";
import { 
    fetchSMSCampaign, 
    fetchLastInteractions,
    fetchSMSCampaigns, 
    fetchSMSCampaignStep, 
    fetchSMSCampaignSteps, 
    getSMSCampaignSettings,
    deleteCampaign,
    deleteCampaignStep,
    updateCampaign,
    updateCampaignStep,
    createCampaign,
    createCampaignStep,
    ICurrent,
    ISmsCampaign, 
    ISmsCampaignStep, 
    ISender, 
    IContactDirectory,
    IStepSettings,
    IInteraction,     
    ISMSInteraction
} from "./campaign/campaign.api";
import { format } from "date-fns";
import { actionStartAPI, actionEndAPI, actionToggleFilterView } from "../../redux/reducers/shell";
import ClientSelector from "../shell/clientselector.component";
import CampaignSelector from "../shell/campaignselector.component";
import { loadShellCampaigns, loadShellClients } from "../shell/shell.api";
import { CircularProgress, Typography, Popover, Divider } from "@material-ui/core";
import "./campaign/campaign.scss";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import {
    Button,
    Grid,
    Card,
    TextField,
    InputAdornment,
    MenuItem, Snackbar,
    CardContent,
    CardActions,
    makeStyles,
    Theme,
    Slider,
    createStyles,
    Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText,
    Table, TableHead, TableCell, TableRow, TableBody
  } from "@material-ui/core";
// gridsystemet
import {
    GridReadyEvent,
    FirstDataRenderedEvent,
    RowDoubleClickedEvent,
    GridApi,
    ColumnApi,
    SelectionChangedEvent,
  } from "ag-grid-community";
  import { AgToolbarButton } from "../../agGridUtils";
  import { AgGridReact } from "ag-grid-react";

function sanitizeDate(date) {
    var t = date.split(/[- :]/);

    // Apply each element to the Date function
    var d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
    return new Date(d);
}
function formatDateTime(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour = '' + d.getHours(),
        min = '' + d.getMinutes();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    if (hour.length < 2) 
        hour = '0' + hour;
    if (min.length < 2) 
        min = '0' + min;

    return ([day, month, year].join('.') + ' ' + hour + '.' + min);
}
/*,
    cellRenderer: (r) =>{
        if (r.data.date_to != null)
            return formatDateTime(r.data.date_to);
    }*/
// for knappene over tabellen
const toolbarProps = {
    title: "",
    rightAlign: false,
    disabled: false,
    icon: "",
    onClick: () => { },
};
const marginStyles = {
    margin: '24px 0 0 0',
}
// theme for AdGridReact
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorMessage: {
            color: '#ff0000'
        },
        flexGrow: {
            flexGrow: 1,
        },
        actions: {
            "& .form-group": {
                minWidth: 200,
                marginRight: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
            "& button": {
                marginTop: 24,
            },
        },
        agGrid: {
            "& .aggrid-size": {
                minHeight: 500,
                height: "calc(100vh - 460px)",
                width: "100%",
            },
        },
    })
);

const emptyStepSettings = {
    contact_directory_id: 0
};

const Interaction = {
    interaction_type_id: 0,
    interaction_type_name: '',
    created_at: new Date,
    updated_at: new Date
}
const SMSInteraction = {
    sms_campaign_interaction_id: 0,
    interaction_message: "",
    created_at: new Date,
    sender: "",
    recipient: "",
    firstname: "",
    lastname: ""
}

const Sender = {
    customer_sender_id: 0,
    customer_id: 0,
    client_id: 0,
    created_at: new Date,
    updated_at: new Date,
    sender: '',
    provider: '',
    sms_token_sender_id: 0,
    sender_name: '',
    country_id: 0
}
const emptySmsCampaign = {
    sms_campaign_id: 0,
    campaign_id: 0,
    sms_campaign_name: "",
    sms_campaign_description: "",
    interaction_type_id: 5,
    customer_sender_id: 0,
    send_percent: 0,
    created_at: new Date,
    updated_at: new Date,
    date_from: new Date,
    date_to: null,
    client_id: 0
}
const emptySmsCampaignStep = {
    sms_campaign_step_id: 0,
    sms_campaign_id: 0,
    step_name: "",
    parent_sms_campaign_step_id: 0,
    interaction_type_id: 0,
    send_percent: 0,
    settings: emptyStepSettings,
    delay: 0,
    interaction_message: "",
    created_at: new Date,
    updated_at: new Date
}

let today = new Date();

/*

Lister opp kampanjene
    - Lag ny kampanje
    - Endre -
    - Slett

Lag ny
    - 

Lister opp smsene


Komme inn via to metoder;
    - via svar på skjema
    - via leads


Ved svar på respons fra skjema. "OK "



Sekvensiell sms. 
Funksjonalitet ved utsendelse av sms. 
Første sms som kunde må bekrefte for å akseptere vårt tilbud. 
Så snart kunde har bekreftet denne så går det ut en ny sms som bygges opp som av tre delementer;
Takk for bestillingen
Tekst om samarbeidspartner
Respons, enten som ny respons på sms eller som link videre til annen landingsside 

Administrasjon av sekvensiell sms
Administrasjon av sekvensiell sms er også delt i to elementer
Administrasjon av body sms
Så det som er hakket mer fiffig. 
Vi ønsker å på kampanjenivå kunne angi hvilken sms som skal gå ut, 
og også innad på en kampanje differensiere på forskjellige sms'er - 
hvor man angir hvor stor andel i prosent av sms'ene som skal ha de forskjellige budskapene

*/

interface IData {
    customer_platform_id: number
}

export default function SMSCampaignComponent() {
    const [SMScampaigns, setSMSCampaigns] = useState<ISmsCampaign[]>([emptySmsCampaign]);
    const [SMSCampaign, setSMSCampaign] = useState<ISmsCampaign>(emptySmsCampaign);
    const [senders, setSenders] = useState<ISender[]>([Sender]);
    const [interactions, setInteractions] = useState<IInteraction[]>([Interaction]);
    const [outinteractions, setOutinteractions] = useState<ISMSInteraction[]>([SMSInteraction]);
    const [ininteractions, setIninteractions] = useState<ISMSInteraction[]>([SMSInteraction]);

    const [current, setCurrent] = useState<ICurrent>({ sms_campaign_id: 0, sms_campaign_step_id: 0 })
    const [directories, setDirectories] = useState<IContactDirectory[]>([{ contact_directory_id: 0, contact_directory_name: "" }]);
    const [currentSmsCampaignId, setCurrentSmsCampaignId] = useState<number>(0);
    const [SMSCampaignStep, setSMSCampaignStep] = useState<ISmsCampaignStep>(emptySmsCampaignStep);
    const [SMSCampaignSteps, setSMSCampaignSteps] = useState<ISmsCampaignStep[]>([emptySmsCampaignStep]); // all the steps for that campaign

    const [filteredSMSCampaignSteps, setFilteredSMSCampaignSteps] = useState<ISmsCampaignStep[]>([emptySmsCampaignStep]); // all the steps for that campaign

    const [dateFrom, setDateFrom] = useState<Date>(new Date());
    const [dateTo, setDateTo] = useState<Date>(new Date());


    const [query, setQuery] = useState({number: "", text: ""});
    const selectedClient = useSelector(state => state.shell.selectedClient);
    const selectedCampaignType = useSelector(state => state.shell.selectedCampaignType);

    const [data, setData] = useState<IData>({ customer_platform_id: 0 });
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [anchorElCreate, setAnchorElCreate] = useState(null);
    const openCreate = Boolean(anchorElCreate);

    const [selectedFromDate, setFromDate] = useState<Date | null>(today);
    const [selectedToDate, setToDate] = useState<Date | null>(today);
    const [formatedFromDate, setFormatedFromDate] = useState(formatDate(selectedFromDate));
    const [formatedToDate, setFormatedToDate] = useState(formatDate(selectedToDate));
    const [showSMSCampaignModal, setShowSMSCampaignModal] = useState(false);
    const [showInOutStats, setShowInOutStats] = useState(0);
    
    const [showSMSCampaignStepModal, setShowSMSCampaignStepModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [editType, setEditType] = useState(0);

    const clients = useSelector(state => state.shell.clients);
    const campaigns = useSelector(state => state.shell.campaigns);

    // for tabellen
    const dispatch = useDispatch();
    const hideFilters = useSelector((state) => state.shell.hideFilters);

    const [validationErrors, setValidationErrors] = useState(
        { interaction_type_error: "", message_error: "", send_percent_error: "", step_name_error: "", campaign_error: "", customer_sender_error: "" , sms_campaign_name_error: "" }
    );
    const [errorMessage, setErrorMessage] = useState("");

    const [successMessage, setSuccessMessage] = useState("");

    const [columnAPI, setColumnAPI] = useState<ColumnApi>();
    const [gridAPI, setGridAPI] = useState<GridApi>();
    const classes = useStyles();

    useEffect(() => {
        loadShellClients(dispatch);
      }, [dispatch, loadShellClients]);

    useEffect(() => {
        console.log("loading sms campaigns");
        getSMSCampaigns();
    }, [selectedClient]);

    useEffect(() => { 
        // fetch the steps for parent
        console.log("use Effect sets getCampaignSteps to " + current.sms_campaign_id);
        getCampaignSteps( current.sms_campaign_id );

    }, [current]);

    useEffect(() => {
        loadShellCampaigns(
          dispatch,
          selectedClient ? selectedClient.value : 0,
          selectedCampaignType ? selectedCampaignType.value : 0,
          0
        );
      }, [dispatch, selectedClient, selectedCampaignType, loadShellCampaigns]);
    
    
    useEffect(() => {
        getSMSCampaignSettings().then((res) => {
            setInteractions(res.data.interactions);
            setSenders(res.data.senders);
            setData(res.data.data);
            console.log("loading sms campaigns");
            getSMSCampaigns();
        });
    }, []);

    useEffect(() => {
        setFormatedFromDate(formatDate(selectedFromDate));
        setFormatedToDate(formatDate(selectedToDate));
    }, [selectedFromDate,selectedToDate]);

    function formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
        
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;
        
            return [day, month, year].join('.');
    }

    // Setter kolonner med navn etc
    const columnDefs = [
        { headerName: "SMS Robot Id", field: "sms_campaign_id", sortable: true, filter: true, hide: true},
        { headerName: "Address source campaign", field: "campaign_name", sortable: true, filter: true, hide: false},
        { headerName: "Description", field: "sms_campaign_description", sortable: true, filter: true, hide: false},
        { headerName: "Active", sortable: true, filter: true, hide: false, 

          cellRenderer: function(r) {
            if ((new Date(r.data.date_from) < new Date()) && (!r.data.date_to || new Date(r.data.date_to) > new Date())) 
                return '<i class="far fa-fw fa-check-square"></i>';
            else 
                return '<i class="far fa-fw fa-square"></i>';
          },
          /*cellClass: function(r) {
            if ((new Date(r.data.date_from) < new Date()) && (!r.data.date_to || new Date(r.data.date_to) > new Date())) 
                return "btn btn-primary";
            else 
                return "text-center fs-14";
          }*/
        },
/*        { headerName: "Start time", field: "date_from", sortable: true, filter: true, hide: false,
        cellRenderer: (r) =>{
            return formatDateTime(sanitizeDate(r.data.date_from));
        }},
        { headerName: "End time", field: "date_to", sortable: true, filter: true, hide: false },*/
        { headerName: "Out", field: "int_out", sortable: true, filter: true, hide: false,
            /*cellRenderer: (r) => {
                if (r.data.int_out) {
                    return '<div>' + r.data.int_out + ' ' + '<i class="fas fa-external-link-alt"></i></div>';
                }
            },*/
            onCellClicked: (params) => {
                //if (params.data.in_out)
                fetchLastInteractions(params.data.sms_campaign_id, params.data.sms_campaign_step_id).then((res) => {
                    setOutinteractions(res.data.outgoing);
                    setIninteractions(res.data.incoming);
                    console.log("fetchLastInteractions");
                    console.log(res);
                    setShowInOutStats(1);
                });
            }
        },
        { headerName: "In", field: "int_in", sortable: true, filter: true, hide: false,
            onCellClicked: (params) => {
                //if (params.data.in_out)
                fetchLastInteractions(params.data.sms_campaign_id, params.data.sms_campaign_step_id).then((res) => {
                    setOutinteractions(res.data.outgoing);
                    setIninteractions(res.data.incoming);
                    setShowInOutStats(2);
                });
            }
        }
    ];

    const getCampaignSteps = (thisSmsCampaignId) => {
        //const thisSmsCampaignId = current.sms_campaign_id?current.sms_campaign_id:0;
//        SMSCampaignStep.sms_campaign_id?SMSCampaignStep.sms_campaign_id:(
 //           SMSCampaign.sms_campaign_id?SMSCampaign.sms_campaign_id:0
            //);

        if (thisSmsCampaignId) { // && thisSmsCampaignId != currentSmsCampaignId) {
            console.log("Fetch from " + thisSmsCampaignId);
            fetchSMSCampaignSteps(thisSmsCampaignId)
            .then(res => {
                setDirectories(res.data.directories);
                console.log("set parents to");
                console.log(res.data.nodes);
                setSMSCampaignSteps(res.data.nodes);
            });

            setCurrentSmsCampaignId(thisSmsCampaignId);
        }
    }
    const onUpdateCampaignStep = () => {
        setErrorMessage("");

        dispatch(actionStartAPI());
        updateCampaignStep(SMSCampaignStep)
            .then(() => {
                setAnchorEl(null);
                //onFetchCampaigns();
                console.log("loading sms campaigns");
                getSMSCampaigns();
            })
            .catch((e: any) => setErrorMessage("Error removing sms robot step: " + e))
            .finally(() => dispatch(actionEndAPI()));
    };
    
    const onRemoveCampaignStep = () => {
        setErrorMessage("");

        dispatch(actionStartAPI());
        deleteCampaignStep(SMSCampaignStep)
            .then(() => {
                setAnchorEl(null);
//                onFetchCampaigns();
                console.log("loading sms campaigns");
                getSMSCampaigns();
            })
            .catch((e: any) => setErrorMessage("Error removing sms robot step: " + e))
            .finally(() => dispatch(actionEndAPI()));
    };
    const onRemoveCampaign = () => {
        setErrorMessage("");

        dispatch(actionStartAPI());
        deleteCampaign(SMSCampaign)
            .then(() => {
                setAnchorEl(null);
                //onFetchCampaigns();
                console.log("loading sms campaigns");
                getSMSCampaigns();
            })
            .catch((e: any) => setErrorMessage("Error removing sms robot: " + e))
            .finally(() => dispatch(actionEndAPI()));
    };
    const clearSelectedCampaign = () => {
        gridAPI!.deselectAll();
        setSMSCampaign(emptySmsCampaign);
        console.log("empty the setSMSCampaignStep");
        setSMSCampaignStep(emptySmsCampaignStep);
/*        setSelectedAgent(newAgent);
        setSelectedAgentTeams([]);
        setEditAgent(newAgent);
        setShowAgentAdd(false);
        setShowAgentEditor(false);
        setSelectedTeamId("");
        setAgentToken("");
        setTokenVisible(false);*/
    };
    // Get agents
    const onFetchCampaigns = () => {
        clearSelectedCampaign();
        console.log("loading sms campaigns");
        getSMSCampaigns();
    };
    const clearErrors = () => {
        // setError({});
        setValidationErrors({ interaction_type_error: "", message_error: "", send_percent_error: "", step_name_error: "", campaign_error: "", customer_sender_error: "" , sms_campaign_name_error: "" });
        setErrorMessage("");
        setSuccessMessage("");
    }
    const handleChange = (e) => {
        const {name, type, value} = e.target;
        const val = type === 'number' ? parseFloat(value) : value;
        setQuery({ ...query, [name]: val });
    }
    const handleFromDateChange = (date: Date | null) => {
        setFromDate(date);
    }
    const handleToDateChange = (date: Date | null) => {
        setToDate(date)
    }
    const getSMSCampaigns = () => {
        clearErrors();

        setLoading(true);
        fetchSMSCampaigns(selectedClient.client_id).then(res => {
            setSMSCampaigns(res.data); 
            setLoading(false);
        })
        .catch((e: any) => setErrorMessage("Error fetching SMS Robots: " + e));
        
    }
    const onSelectionChanged = (event: SelectionChangedEvent) => {
        const rows = event.api.getSelectedRows();
        if (!rows[0]) {
            return;
        }

        if (rows[0].sms_campaign_step_id !== null) {
            console.log("edit step " + rows[0].sms_campaign_step_id + ' in robot ' + rows[0].sms_campaign_id);
            setEditType(1);
            console.log(rows[0]);
            setCurrent( { sms_campaign_step_id: rows[0].sms_campaign_step_id, sms_campaign_id: rows[0].sms_campaign_id })
            setSMSCampaignStep( rows[0] );
            //console.log(SMSCampaignStep);
        } else {
            console.log("edit robot");
            setEditType(0);
            console.log(rows[0]);
            setCurrent( { sms_campaign_step_id: 0, sms_campaign_id: rows[0].sms_campaign_id })
            setSMSCampaign( rows[0] );
            //console.log(SMSCampaign);
        }
        //console.log(rows[0]);

    };
    const onCreateCampaign = () => {
        clearSelectedCampaign();

        clearErrors();

        setLoading(true);
        setShowSMSCampaignModal(true);
        setLoading(false);
    }
    const onSaveCampaign = () => {
        if (!campaignValidation()) { console.log("validateion fail"); return false; }
        if (SMSCampaign.sms_campaign_id !== 0) {
            updateCampaign(SMSCampaign).then(() => {
                setShowSMSCampaignModal(false);
                console.log("loading sms campaigns");
                getSMSCampaigns();
            }).catch((e: any) => setErrorMessage("Error updating SMS Robot: " + e));

        } else {
            createCampaign(SMSCampaign).then(() => {
                setShowSMSCampaignModal(false);
                console.log("loading sms campaigns");
                getSMSCampaigns();
            }).catch((e: any) => setErrorMessage("Error creating SMS robot: " + e));
        }
    }

    const stepValidation = () => {
        let hasErrors = false;
        let errorMessages = { 
            interaction_type_error: "", 
            message_error: "",
            send_percent_error: "", 
            step_name_error: "", 
            campaign_error: "", 
            customer_sender_error: "" , 
            sms_campaign_name_error: "" 
        };

        /*
Argument of type '{ interaction_type_error: string; send_percent_error: string; step_name_error: string; campaign_error: string; customer_sender_error: string; sms_campaign_name_error: string; }' is not assignable to parameter of type 'SetStateAction<{ interaction_type_error: string; message_error: string; send_percent_error: string; step_name_error: string; campaign_error: string; customer_sender_error: string; sms_campaign_name_error: string; }>'.
  Property 'message_error' is missing in type '{ interaction_type_error: string; send_percent_error: string; step_name_error: string; campaign_error: string; customer_sender_error: string; sms_campaign_name_error: string; }' but required in type '{ interaction_type_error: string; message_error: string; send_percent_error: string; step_name_error: string; campaign_error: string; customer_sender_error: string; sms_campaign_name_error: string; }'.ts(2345)
        */
        if (SMSCampaignStep.interaction_type_id === 0) {
            hasErrors = true;
            errorMessages.interaction_type_error = "Required";
        }
        if (SMSCampaignStep.interaction_message === "") {
            hasErrors = true;
            errorMessages.message_error = "Required";
        }
        if (SMSCampaignStep.send_percent === 0) {
            hasErrors = true;
            errorMessages.send_percent_error = "Required";
        }
        if (SMSCampaignStep.step_name === "") {
            hasErrors = true;
            errorMessages.step_name_error = "Required";
        }

        if (hasErrors) {
            setValidationErrors(errorMessages);
            return false;
        }        
        return true;
    }
    const campaignValidation = () => {
        let hasErrors = false;
        let errorMessages = { 
            interaction_type_error: "", 
            message_error: "",
            send_percent_error: "", 
            step_name_error: "", 
            campaign_error: "", 
            customer_sender_error: "" , 
            sms_campaign_name_error: "" 
        };

        if (SMSCampaign.campaign_id === 0) {
            hasErrors = true;
            errorMessages.campaign_error = "Required";
        }
/*        if (SMSCampaign.interaction_type_id === 0) {
            hasErrors = true;
            errorMessages.interaction_type_error = "Required";
        }*/
        
        if (SMSCampaign.customer_sender_id === 0) {
            hasErrors = true;
            errorMessages.customer_sender_error = "Required";
        }
        if (SMSCampaign.sms_campaign_name === "") {
            hasErrors = true;
            errorMessages.sms_campaign_name_error = "Required";
        }
        
        if (hasErrors) {
            setValidationErrors(errorMessages);
            return false;
        }        
        return true;
    }    

    const onSaveCampaignStep = () => {
        if (!stepValidation()) return false;

        //console.log(SMSCampaignStep);

        if (SMSCampaignStep.sms_campaign_step_id !== 0) {
            console.log("update sms robot step");
            updateCampaignStep(SMSCampaignStep).then(() => {
                setShowSMSCampaignStepModal(false);

                console.log("onSaveCampaignStep - updateCampaignStep sets getCampaignSteps to " + SMSCampaign.sms_campaign_id + ", current is " + current.sms_campaign_id );

                console.log("loading sms campaigns");
                getSMSCampaigns();
                getCampaignSteps( SMSCampaign.sms_campaign_id );
            })
            .catch((e: any) => setErrorMessage("Error updating SMS robot step: " + e));            
        } else {
            console.log("create campaign step");
            createCampaignStep(SMSCampaignStep).then(() => {                
                setShowSMSCampaignStepModal(false);

                console.log("onSaveCampaignStep - createCampaignStep sets getCampaignSteps to " + SMSCampaign.sms_campaign_id + ", current is " + current.sms_campaign_id );

                console.log("loading sms campaigns");
                getSMSCampaigns();
                getCampaignSteps( SMSCampaign.sms_campaign_id );
            })
            .catch((e: any) => setErrorMessage("Error creating SMS robot step: " + e));
        }
    }
    const onEditCampaign = () => {
        console.log("empty the setSMSCampaignStep");
        setSMSCampaignStep( emptySmsCampaignStep ); // reset so it doesn't get mixed up
        clearErrors();

        setLoading(true);
        
        setShowSMSCampaignModal(true)
        fetchSMSCampaign( SMSCampaign.sms_campaign_id )
        .then(res => { 
            setSMSCampaign( res.data );
        })
        .then(() => setLoading(false))
        .catch((e: any) => setErrorMessage("Error fetching SMS robot: " + e));
    }

    const onCreateCampaignStep = () => {        
        clearErrors();
        setLoading(true);

        const newSmsCampaignStep = { 
            ...emptySmsCampaignStep, 
            parent_sms_campaign_step_id: current.sms_campaign_step_id, 
            sms_campaign_id: current.sms_campaign_id 
        };
        //console.log(newSmsCampaignStep);
        setSMSCampaignStep( newSmsCampaignStep );
        setShowSMSCampaignStepModal(true);
        setLoading(false);

    }
    const onEditCampaignStep = () => {
        editCampaignStep(SMSCampaignStep.sms_campaign_id, SMSCampaignStep.sms_campaign_step_id);
    }

    const editCampaignStep = ( cid, csid ) => {
        clearErrors();

        //setSMSCampaign( emptySmsCampaign ); // reset so it doesn't get mixed up
        setLoading(true);

        setShowSMSCampaignStepModal(true);
        
        fetchSMSCampaignStep( cid, csid )
        .then(res => {      
            setSMSCampaignStep( res.data );
            getCampaignSteps( cid );
/*            
            console.log("set filtered campaign steps");
            console.log(SMSCampaignSteps);
            const filtered = SMSCampaignSteps.map((step, key) => {
                return step; 
            });      
            setFilteredSMSCampaignSteps(filtered);*/
        })
        .then(() => setLoading(false))
    }

    const onRowDoubleClicked = (event: RowDoubleClickedEvent) => {
//        setSelectedAgent(event.data);
        clearErrors();
        if (event.data.sms_campaign_step_id !== null) {
            editCampaignStep(event.data.sms_campaign_id, event.data.sms_campaign_step_id );
        } else {
            console.log("empty the setSMSCampaignStep");
            setSMSCampaignStep( emptySmsCampaignStep );
            setLoading(true);
            setShowSMSCampaignModal(true)
            fetchSMSCampaign( event.data.sms_campaign_id )
            .then(res => { 
                setSMSCampaign( res.data );
            })
            .then(() => setLoading(false))
        }
        /*
*/
   /*     
        let searchNumber = 0;
        if (selectedMessage.direction === "out") {
            searchNumber = parseFloat(selectedMessage.recipient)
        }
        else {
            searchNumber = parseFloat(selectedMessage.sender)
        }
        setLoading(true);
        setShowSMSCampaignModal(true)
        fetchSMSCampaign()
        .then(res => setSMSCampaignSteps(res.data))
        //.then(() => setShowSMSCampaignModal(true))
        .then(() => setLoading(false))*/
    }
    

    // laster inn data om tabellen
    const onGridReady = (event: GridReadyEvent) => {
        setGridAPI(event.api);
        setColumnAPI(event.columnApi);
    };
    // 3 knapper over tabellen, refresh, og endre størrelse på kolonner
    const onRefreshList = () => {
        fetchSMSCampaigns(selectedClient.client_id);
    };
    const onAdjustColumnsContent = (event: Event) => {
        var allColumnIds: any = [];
        columnAPI!.getAllColumns().forEach((column) => {
            const colDef = column.getColDef();
            if (!colDef.suppressAutoSize) {
                allColumnIds.push(column.getColId());
            }
        });
        columnAPI!.autoSizeColumns(allColumnIds);
    };
    const onAdjustColumnsWindow = (event: Event) => {
        gridAPI!.sizeColumnsToFit();
    };
    const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
        event.api.sizeColumnsToFit();
    };
    const onExportToCsv = () => {
        if (gridAPI) {
          gridAPI.exportDataAsExcel();
        }
    };
    const autoGroupColumnDef = {
        headerName: "Group"
    }
    // søkefelt som søker i tabellen
    const handleQuickFilter = event => {
        gridAPI!.setQuickFilter(event.target.value);
    };

    return (<>
        <div className="ag-theme-material">
            
            <TitlebarComponent
                title="SMS Robots"
                description="SMS Robots"
                breadcrumbs={[
                    { title: "Dashboard", link: "/" },
                    { title: "Userlog", link: "/sms/messages" }
                ]}
            />

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div
                            style={{ color: "#ccc", width: 15 }}
                            data-toggle="tooltip"
                            data-original-title="Hide/Show filters"
                            onClick={() => dispatch(actionToggleFilterView())}
                        >
                            {hideFilters ? (
                                <i className="fas fa-fw fa-caret-square-right"></i>
                            ) : (
                                    <i className="fas fa-fw fa-caret-square-left"></i>
                                )}
                        </div>
                </Grid>
                <Grid item xs={12} md={2} style={{ display: hideFilters ? "none" : "block" }}>                    
                    <ClientSelector />      
                </Grid>
                <Grid item xs={12} md={hideFilters ? 12 : 10}>
                    <Card>                
                        <CardActions disableSpacing className={classes.actions}>
                        <Grid container alignItems="center">
                        {SMSCampaign.sms_campaign_id !== 0 || SMSCampaignStep.sms_campaign_id !== 0?(
<>
                            {AgToolbarButton({
                                ...toolbarProps,
                                title: "Create new",
                                icon: "fa-plus",
                                onClick: e => {
                                    setAnchorElCreate(e.target);
                                },
                                disabled: SMSCampaign.sms_campaign_id === 0 && SMSCampaignStep.sms_campaign_step_id === 0
                            })}
</>
                            ):<>
                            {AgToolbarButton({
                                ...toolbarProps,
                                title: "Create sms robot",
                                icon: "fa-plus",
                                onClick: () => onCreateCampaign(),
                            })}
                            </>}
                            
                            {SMSCampaignStep.sms_campaign_step_id !== 0 && editType?
                            <>
                            {AgToolbarButton({
                                ...toolbarProps,
                                title: "Edit selected robot step",
                                icon: "fa-edit",
                                onClick: () => onEditCampaignStep(),
                                disabled: SMSCampaignStep.sms_campaign_step_id === 0
                            })}
                            </>
                            :
                            <>
                            {AgToolbarButton({
                                ...toolbarProps,
                                title: "Edit selected sms robot",
                                icon: "fa-edit",
                                onClick: () => onEditCampaign(),
                                disabled: SMSCampaign.sms_campaign_id === 0
                            })}
                            </>}

                            {SMSCampaignStep.sms_campaign_step_id !== 0 && editType?
                            <>
                            {AgToolbarButton({
                                ...toolbarProps,
                                title: "Remove selected robot step",
                                icon: "fa-trash",
                                onClick: e => {
                                    setAnchorEl(e.target);
                                },
                                disabled: SMSCampaignStep.sms_campaign_step_id === 0
                            })}
                            </>
                            :
                            <>
                            {AgToolbarButton({
                                ...toolbarProps,
                                title: "Remove selected sms robot",
                                icon: "fa-trash",
                                onClick: e => {
                                    setAnchorEl(e.target);
                                },
                                disabled: SMSCampaign.sms_campaign_id === 0
                            })}
                            </>}

                            </Grid>

                            <Popover 
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            anchorEl={anchorElCreate}
                            open={openCreate}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <Button onClick={() => { setAnchorElCreate(null); onCreateCampaign(); }} color="primary">Create SMS Robot</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={() => { setAnchorElCreate(null); onCreateCampaignStep(); }} color="primary">Create step</Button>
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={() => setAnchorElCreate(null)} color="secondary">Cancel</Button>
                                    </Grid>
                                </Grid>
                            </Popover>
                            <Popover 
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            >
                                <Grid container spacing={2}>
                                    
                                    {SMSCampaignStep.sms_campaign_step_id == 0?(
                                    <Grid item>
                                        <Button onClick={() => onRemoveCampaign()} color="primary">Delete</Button>
                                    </Grid>
                                    ):(
                                        <Grid item>
                                        <Button onClick={() => onRemoveCampaignStep()} color="primary">Delete step</Button>
                                    </Grid>
                                    )}
                                    <Grid item>
                                        <Button onClick={() => setAnchorEl(null)} color="secondary">Cancel</Button>
                                    </Grid>
                                </Grid>
                            </Popover>


                            {/* Knappene over tabellen */}
                            <div className={classes.flexGrow} />
                            <input 
                                type="text" 
                                style={marginStyles}
                                placeholder="Search" 
                                onChange={handleQuickFilter}
                            />
                            {AgToolbarButton({
                                ...toolbarProps,
                                title: "Refresh",
                                icon: "fa-sync-alt",
                                onClick: onRefreshList,
                            })}

                            {AgToolbarButton({
                                ...toolbarProps,
                                title: "Export to CSV",
                                icon: "fa-file-export",
                                onClick: onExportToCsv,
                            })}

                            {AgToolbarButton({
                                ...toolbarProps,
                                title: "Adjust columns to window",
                                icon: "fa-columns",
                                onClick: onAdjustColumnsWindow,
                            })}

                            {AgToolbarButton({
                                ...toolbarProps,
                                title: "Adjust columns to content",
                                icon: "fa-text-width",
                                onClick: onAdjustColumnsContent,
                            })}
                        </CardActions>
                        {/* Selve tabellen 
                        
                        

                                        animateRows={true}
                                        enableRangeSelection={false}
                                        onSelectionChanged={onSelectionChanged}
                                        rowSelection="single"
                                        onGridReady={onGridReady}
                                        onFirstDataRendered={onFirstDataRendered}
                                        onRowDoubleClicked={onRowDoubleClicked}

                        */}
                        <CardContent>
                            <Grid container>
                            <Grid item xs={12} className={classes.agGrid}>
                                <div className="ag-theme-balham aggrid-size">
                                    <AgGridReact
                                        rowData={SMScampaigns}
                                        onGridReady={onGridReady}
                                        columnDefs={columnDefs}  
                                        // justerer kolonnebredden etter load
                                        onFirstDataRendered={onFirstDataRendered}
                                        sideBar={{
                                            toolPanels: ["columns"],
                                        }}
                                        // grouping
                                        treeData={true}
                                        getDataPath={function (data) {
                                            return data.level;
                                        }}

/*                                        groupIncludeTotalFooter={false}
                                        groupMultiAutoColumn={false}*/
                                        autoGroupColumnDef={autoGroupColumnDef}

                                        // pagination
                                        pagination={true}
                                        paginationPageSize={100}
                                        cacheBlockSize={100}
                                        cacheOverflowSize={2}
                                        maxBlocksInCache={2}
                                        rowSelection="single"
                                        onSelectionChanged={onSelectionChanged}
                                        onRowDoubleClicked={onRowDoubleClicked}
                                        suppressAggFuncInHeader={true}
                                        defaultColDef={{
                                            sortable: true,
                                            resizable: true,
                                            filter: true,
                                        }}
                                    />
                                </div>
                            </Grid>
                            </Grid>
                        </CardContent>  
                </Card>
                </Grid>
            </Grid>
        
        </div>
       
        <Dialog fullWidth maxWidth="sm" open={showSMSCampaignModal} onClose={() => setShowSMSCampaignModal(false)}>
                        
                        <DialogTitle>{SMSCampaign.sms_campaign_id == 0?'Create SMS Robot':'Edit SMS Robot'}</DialogTitle>
                        <DialogContent>
                            { loading ? <div><CircularProgress/><span>Loading...</span></div> : (
                            <Grid container spacing={2}>
                            <Grid item xs={12} lg={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            error={validationErrors.sms_campaign_name_error !== ""}
                                            helperText={validationErrors.sms_campaign_name_error}
                                            label="Name"
                                            value={SMSCampaign.sms_campaign_name}
                                            onChange={(e) => setSMSCampaign({ ...SMSCampaign, sms_campaign_name: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                            <TextField
                                                fullWidth
                                                label="Description"
                                                value={SMSCampaign.sms_campaign_description}
                                                onChange={(e) => setSMSCampaign({ ...SMSCampaign, sms_campaign_description: e.target.value })}
                                            />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                             <TextField
                                                fullWidth
                                                label="Address source campaign"
                                                value={SMSCampaign.campaign_id}
                                                onChange={(e) => setSMSCampaign({ ...SMSCampaign, campaign_id: Number(e.target.value) })}
                                                error={validationErrors.campaign_error !== ""}
                                                helperText={validationErrors.campaign_error}
                                                required
                                                select>
                                                {campaigns.filter(function(c) { if (c.customer_platform_id == data.customer_platform_id) return true; else return false }).map((campaign, key) => (

                                                    <MenuItem key={key} value={campaign.campaign_id}>{campaign.campaign_name}</MenuItem>
                                                ))}
                                            </TextField>                                                
                                    </Grid>
                                    {0?(<Grid item xs={12} md={12} lg={12}>
                                    <TextField
                                            fullWidth                                            
                                            label="Interaction type"
                                            value={SMSCampaign.interaction_type_id}
                                            onChange={(e) => setSMSCampaign({ ...SMSCampaign, interaction_type_id: Number(e.target.value) })}
                                            error={validationErrors.interaction_type_error !== ""}
                                            helperText={validationErrors.interaction_type_error}
                                            required
                                            select>
                                            {interactions.map((client, key) => (
                                                <MenuItem key={key} value={client.interaction_type_id}>{client.interaction_type_name}</MenuItem>
                                            ))}
                                        </TextField>                                            
                                    </Grid>):null}
                                    <Grid item xs={12} md={12} lg={12}>
                                    <TextField
                                            fullWidth                                            
                                            label="Sender"
                                            value={SMSCampaign.customer_sender_id}
                                            onChange={(e) => setSMSCampaign({ ...SMSCampaign, customer_sender_id: Number(e.target.value) })}
                                            error={validationErrors.customer_sender_error !== ""}
                                            helperText={validationErrors.customer_sender_error}
                                            required
                                            select>
                                            {senders.map((sender, key) => (
                                                <MenuItem key={key} value={sender.customer_sender_id}>{sender.provider} - {sender.sender_name}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>                                                
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDateTimePicker
                                            variant="inline"
                                            label="Date from"
                                            ampm={false}
                                            value={SMSCampaign!.date_from}
 //                                           error={validationErrors.birthday !== ""}
                                            onChange={(date: any) => { 
                                                //setDateFrom(date); 
                                                setSMSCampaign({ ...SMSCampaign, date_from: date });                                            
                                            }}
                                            onError={console.log}
                                            format="dd.MM.yyyy HH:mm"
                                            fullWidth
                                        />
                                    </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDateTimePicker
                                            id="date-picker-to"
                                            variant="inline"
                                            label="Date to"
                                            clearable
                                            ampm={false}
                                            value={SMSCampaign!.date_to}
     //                                       error={validationErrors.birthday !== ""}
                                            onChange={(date: any) => { 
                                                ///setDateTo(date); 
                                                setSMSCampaign({ ...SMSCampaign, date_to: date });
                                            
                                            }}
                                            onError={console.log}
                                            format="dd.MM.yyyy HH:mm"
                                            fullWidth
                                        />
                                    </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <TextField
                                            fullWidth                                            
                                            label="Client"
                                            value={SMSCampaign.client_id}
                                            onChange={(e) => setSMSCampaign({ ...SMSCampaign, client_id: Number(e.target.value) })}
                                            select>
                                            <MenuItem value=""><em>None</em></MenuItem>    
                                            {clients.map((client, key) => (
                                                <MenuItem key={key} value={client.value}>{client.label}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                            </Grid>
)}                    {errorMessage ? <DialogContentText className={classes.errorMessage}>{errorMessage}</DialogContentText> : null}

                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => setShowSMSCampaignModal(false)}>
                                    Close
                                </Button>
                                <Button color="primary" onClick={() => onSaveCampaign()}>
                                    Save
                                </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog fullWidth maxWidth="sm" open={showSMSCampaignStepModal}>                        
                        <DialogTitle>{SMSCampaignStep.sms_campaign_step_id == 0?'Create SMS Robot Step':'Edit SMS Robot Step'}</DialogTitle>

                    <DialogContent>
                    { loading ? <div><CircularProgress/><span>Loading...</span></div> : (
                            <Grid container spacing={2}>
                            <Grid item xs={12} lg={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <TextField
                                            fullWidth
                                            label="Step Name"
                                            error={validationErrors.step_name_error !== ""}
                                            helperText={validationErrors.step_name_error}
                                            value={SMSCampaignStep.step_name}
                                            onChange={(e) => setSMSCampaignStep({ ...SMSCampaignStep, step_name: e.target.value })}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <TextField
                                            fullWidth
                                            label="Parent step"
                                            value={Number(SMSCampaignStep.parent_sms_campaign_step_id)?Number(SMSCampaignStep.parent_sms_campaign_step_id):0}
                                            onChange={(e) => setSMSCampaignStep({ ...SMSCampaignStep, parent_sms_campaign_step_id: Number(e.target.value) })}
                                            select>
                                            <MenuItem value={0}><em>{SMSCampaign.sms_campaign_name}</em></MenuItem>
                                            {SMSCampaignSteps.map((step, key) => (
                                                <MenuItem key={key} value={step.sms_campaign_step_id}>{step.step_name}</MenuItem>
                                            ))}
                                        </TextField>  
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                    <TextField
                                            fullWidth                                            
                                            label="Type of interaction for the responses"
                                            value={Number(SMSCampaignStep.interaction_type_id)}
                                            error={validationErrors.interaction_type_error !== ""}
                                            helperText={validationErrors.interaction_type_error}
                                            onChange={(e) => setSMSCampaignStep({ ...SMSCampaignStep, interaction_type_id: Number(e.target.value) })}
                                            select>
                                            {interactions.map((client, key) => (
                                                <MenuItem key={key} value={client.interaction_type_id}>{client.interaction_type_name}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                                    {SMSCampaignStep.interaction_type_id == 4?
                                    <>
                                    <Grid item xs={12} md={12} lg={12}>
                                    <TextField
                                            fullWidth                                            
                                            label="Target Contact Directory for the approving leads"
                                            value={Number(SMSCampaignStep.settings.contact_directory_id)}
                                            onChange={(e) => setSMSCampaignStep({ ...SMSCampaignStep, settings: { ...SMSCampaignStep.settings, contact_directory_id: Number(e.target.value) }})}
                                            select>
                                            {directories.map((client, key) => (
                                                <MenuItem key={key} value={client.contact_directory_id}>{client.contact_directory_name}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    </>                                    
                                    : null}

                                    <Grid item xs={12} md={12} lg={12}>
                                        
                                   {validationErrors.send_percent_error ? <DialogContentText className={classes.errorMessage}>Step distribution percentage</DialogContentText> : 
                                   <DialogContentText>Step distribution percentage</DialogContentText>}

                                    <Slider
                                        valueLabelDisplay="auto"
                                        step={1}
                                        marks                                        
                                        value={Number(SMSCampaignStep.send_percent)}
                                        onChange={(e, newValue) => setSMSCampaignStep({ ...SMSCampaignStep, send_percent: Number(newValue) })}
                                        min={1}
                                        max={100}
                                    />
                                   {validationErrors.send_percent_error ? <DialogContentText className={classes.errorMessage}>{validationErrors.send_percent_error}</DialogContentText> : null}

                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                    <TextField                                                                                        
                                            label="Delay before sending"
                                            type="number"
                                            fullWidth
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">Minutes</InputAdornment>
                                            }}          
                                            value={SMSCampaignStep.delay}
                                            onChange={(e) => {
                                                setSMSCampaignStep({ ...SMSCampaignStep, delay: Number(e.target.value) >= 0 ? Number(e.target.value) : 0 });
                                            }} />

                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <TextField
                                            fullWidth                                            
                                            label="Message"
                                            required
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            error={validationErrors.message_error !== ""}
                                            helperText={validationErrors.message_error}
                                            value={SMSCampaignStep.interaction_message}
                                            onChange={(e) => setSMSCampaignStep({ ...SMSCampaignStep, interaction_message: e.target.value })} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            </Grid>
                    )}
                    {errorMessage ? <DialogContentText className={classes.errorMessage}>{errorMessage}</DialogContentText> : null}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setShowSMSCampaignStepModal(false)}>
                                Close
                            </Button>
                            <Button color="primary" onClick={() => onSaveCampaignStep() }>
                                Save
                            </Button>
                    </DialogActions>
                </Dialog>                    
                
                <Dialog fullWidth maxWidth="md" open={showInOutStats > 0}>
                        <DialogTitle>Interactions</DialogTitle>

                    <DialogContent>
                    { loading ? <div><CircularProgress/><span>Loading...</span></div> : (
                            <Grid container spacing={2}>
                                {showInOutStats == 1?<><h2>Incoming</h2>
                                {ininteractions?ininteractions.map((intp, key) => (
                                    <Grid key={key} container xs={12}> 
                                        <Grid item xs={1}>{new Intl.DateTimeFormat('nb-NO').format(new Date(intp.created_at))}</Grid>
                                        <Grid item xs={2}>{intp.firstname}, {intp.lastname}</Grid>
                                        <Grid item xs={1}>{intp.recipient}</Grid>
                                        <Grid item xs={8}>{intp.interaction_message}</Grid>
                                    </Grid>
                                    )):null}
                                </>:null}
                                {showInOutStats == 2?<><h2>Outgoing</h2>
                                    {outinteractions?outinteractions.map((intp, key) => (
                                    <Grid key={key} container xs={12}> 
                                        <Grid item xs={1}>{new Intl.DateTimeFormat('nb-NO').format(new Date(intp.created_at))}</Grid>
                                        <Grid item xs={2}>{intp.firstname}, {intp.lastname}</Grid>
                                        <Grid item xs={1}>{intp.recipient}</Grid>
                                        <Grid item xs={8}>{intp.interaction_message}</Grid>
                                    </Grid>
                                )):null}
                                </>:null}
                            </Grid>
                    )}
                    {errorMessage ? <DialogContentText className={classes.errorMessage}>{errorMessage}</DialogContentText> : null}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setShowInOutStats(0)}>
                                Close
                            </Button>
                    </DialogActions>
                </Dialog>  
        </>);
}