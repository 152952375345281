import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Select from "react-select";
import Table from "react-bootstrap/Table";
import Popover from "react-bootstrap/Popover";
import Overlay from "react-bootstrap/Overlay";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import { GridReadyEvent, GridApi, ColumnApi, Column } from "ag-grid-community";
import "./newFilterDialog.scss";

declare var env: any;

interface NewFilterProps {
  show: boolean;
  directoryId: string;
  directoryInfo: any;
  campaignTypeId: string;
  clientId: string;
  onHide?: any;
  onNewFilter: any;
  filter?: any;
}

const previewAgSidebar = {
  toolPanels: [
    {
      id: "columns",
      labelDefault: "Columns",
      labelKey: "columns",
      iconKey: "columns",
      toolPanel: "agColumnsToolPanel",
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressSideButtons: true,
        suppressColumnFilter: true,
        suppressColumnSelectAll: true,
        suppressColumnExpandAll: true
      }
    }
  ]
  // defaultToolPanel: "columns"
};

export default function NewFilterDialogComponent(props: NewFilterProps) {
  const contactDeliveryOptions = [
    { value: "0", label: "Remaining Contacts" },
    { value: "1", label: "Delivered Contacts" },
    { value: "2", label: "All Contacts" }
  ];

  const defaultOperator = { value: "", label: "Choose Operator" };
  const defaultHeader = { value: "", label: "Choose Header", datatype_id: 0 };

  // Define defaults that we can override when editing an existing filter
  const defaultFilterName = "";
  const defaultRuntime = { value: 0, label: "Manual" };
  const defaultAutomaticHour = "";
  const defaultAutomaticMinute = "";
  const defaultCampaign = { value: "", label: "- Select Campaign -" };
  const defaultSegment = { value: "", label: "- Select Segment -" };
  const defaultContactSource = contactDeliveryOptions[0];
  const defaultContactDestination = { value: 0, label: "Campaign Segment" };
  const defaultCriterias = [];

  const [updateForm, setUpdateForm] = useState(0);

  const [newFilterName, setNewFilterName] = useState(defaultFilterName);
  const [newAutomaticHour, setNewAutomaticHour] = useState(defaultAutomaticHour);
  const [newAutomaticMinute, setNewAutomaticMinute] = useState(defaultAutomaticMinute);

  const [errorMessage, setErrorMessage] = useState("");

  // TAB #1: Settings for campaign, segment and run time.
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(defaultCampaign);
  const [selectedSegment, setSelectedSegment] = useState(defaultSegment);
  const [segments, setSegments] = useState([]);
  const [newSegmentName, setNewSegmentName] = useState("");
  const [runTime, setRunTime] = useState({ value: 0, label: "Manual" });

  // TAB #2: Delivery settings
  const [contactSource, setContactSource] = useState(defaultContactSource);
  const [availableContacts, setAvailableContacts] = useState(0);
  const [dryRunCount, setDryRunCount] = useState(null);
  const [contactDestination, setContactDestination] = useState(defaultContactDestination);

  // TAB #3: Settings for search criterias
  const [newHeader, setNewHeader] = useState(defaultHeader);
  const [newHeaders, setNewHeaders] = useState([]);
  const [newOperator, setNewOperator] = useState(defaultOperator);
  const [availableOperators, setAvailableOperators] = useState([]);
  const [newValue, setNewValue] = useState("");
  const [criterias, setCriterias]: any = useState(defaultCriterias);

  // TAB #4: Preview of results
  const [filterPreview, setFilterPreview] = useState([]);
  const [previewColumns, setPreviewColumns] = useState([]);

  // State for current datatype in criteria editor (text, date, email etc)
  const [htmlDataType, setHtmlDataType] = useState("text");

  // State for editing criterias
  const [editCriteriaIndex, setEditCriteriaIndex] = useState(null);

  // State for confirmation popover
  const [confirmDeleteTarget, setConfirmDeleteTarget]: any = useState(null);

  // Disable Save-button when clicking once
  const [saveDisabled, setSaveDisabled] = useState(false);

  // APIs
  const fetchSegments = async (campaignId: string) => {
    const res = await fetch(env._apiPath + "/campaign/segments?campaign_id=" + campaignId, {
      method: "GET",
      credentials: "include"
    });
    const json = await res.json();
    setSegments(json.data);
  };

  const fetchCampaigns = async (campaignTypeId: string) => {
    const res = await fetch(env._apiPath + "/customer/campaigns?campaign_type_id=" + campaignTypeId, {
      method: "GET",
      credentials: "include"
    });
    const json = await res.json();
    setCampaigns(json.data);
  };

  const fetchHeaders = async (clientId: string) => {
    const res = await fetch(env._apiPath + "/contacts/headers/" + clientId, { method: "GET", credentials: "include" });
    const json = await res.json();
    setNewHeaders(json.data);
  };

  const fetchOperators = async (dataTypeId: string) => {
    const res = await fetch(env._apiPath + "/listManagement/datatypeOperators?datatype_id=" + dataTypeId, {
      method: "GET",
      credentials: "include"
    });
    const json = await res.json();
    setAvailableOperators(json.data);
  };

  const addCriteria = (header: any, operator: any, value: string) => {
    const newCriterias = [...criterias];

    const criteria = {
      confirmDelete: false,
      header: header,
      operator: operator,
      value: value,
      hits: null,
      hitsLoading: false
    };

    if (editCriteriaIndex !== null) {
      newCriterias.splice(editCriteriaIndex || 0, 1, criteria);
      setEditCriteriaIndex(null);
    } else {
      newCriterias.push(criteria);
    }

    setCriterias(newCriterias);
    setNewValue("");
    setNewOperator(defaultOperator);
    setNewHeader(defaultHeader);
  };

  const moveCriteria = (criteriaIdx: any, up: boolean) => {
    const sortCriterias = [...criterias];
    if (up && criteriaIdx === 0) {
      return;
    }
    const [crit] = sortCriterias.splice(criteriaIdx, 1);
    up ? criteriaIdx-- : criteriaIdx++;
    sortCriterias.splice(criteriaIdx, 0, crit);
    setCriterias(sortCriterias);
  };

  const removeCriteria = (criteriaIdx: any) => {
    const removeCriterias = [...criterias];
    removeCriterias.splice(criteriaIdx, 1);
    setCriterias(removeCriterias);
  };

  const editCriteria = (criteriaIdx: any) => {
    const editCriteria = Object.assign({}, criterias[criteriaIdx]);
    setNewValue(editCriteria.value);
    setNewHeader(editCriteria.header);
    setNewOperator(editCriteria.operator);
    setEditCriteriaIndex(criteriaIdx);
  };

  const onGridReady = (event: GridReadyEvent) => {
    // this.gridApi = event.api;
    // this.gridColumnApi = event.columnApi;

    event.api.sizeColumnsToFit();
    window.addEventListener("resize", () => setTimeout(() => event.api.sizeColumnsToFit()));
    event.api.sizeColumnsToFit();
  };

  // Initial load of component, loads the campaigns and headers for search criteria.
  useEffect(() => {
    fetchCampaigns(props.campaignTypeId);
    fetchHeaders(props.clientId);
  }, []);

  // Loads segments for the selected campaign
  useEffect(() => {
    if (selectedCampaign.value) {
      fetchSegments(selectedCampaign.value);
    }
  }, [selectedCampaign]);

  // Loads operator types for the selected header
  useEffect(() => {
    if (newHeader.value) {
      fetchOperators(newHeader.datatype_id.toString());
    }
  }, [newHeader]);

  // Updates value type for the selected header/data type
  useEffect(() => {
    let dataType = "text";

    switch (newHeader.datatype_id) {
      default:
        dataType = "text";
        break;
      case 1:
        dataType = "number";
        break;
      case 2:
        dataType = "text";
        break;
      case 3:
        dataType = "datetime";
        break;
      case 5:
      case 6:
      case 7:
        dataType = "number";
        break;
      case 8:
        dataType = "date";
        break;
      case 10:
        dataType = "email";
        break;
      case 13:
        dataType = "dropdown";
        break;
    }

    setHtmlDataType(dataType);
  }, [newHeader]);

  const receivedSegmentList = () => {
    if (props.filter.contact_directory_filter_id) {
      let selSegment = { value: "0", label: "Create segment" };
      let createSegment = true;
      segments.forEach((c: any) => {
        if (c.segment_name === props.filter.segment_name) {
          selSegment = { value: c.segment_id, label: c.segment_name };
          createSegment = false;
        }
      });
      setNewSegmentName(createSegment ? props.filter.segment_name : "");
      setSelectedSegment(selSegment);
    }
  };

  const receivedCampaignList = () => {
    if (props.filter.contact_directory_filter_id) {
      let selCampaign = defaultCampaign;
      for (const i in campaignOptions) {
        const o = campaignOptions[i];
        if (o.value.toString() === props.filter.campaign_id.toString()) {
          selCampaign = o;
        }
      }
      setSelectedCampaign(selCampaign);
    }
  };

  useEffect(() => {
    receivedSegmentList();
  }, [segments]);
  useEffect(() => {
    receivedCampaignList();
  }, [campaigns]);

  // Update forms when editing/new filters.
  useEffect(() => {
    setSaveDisabled(false);

    if (props.filter.contact_directory_filter_id) {
      fetchCampaigns(props.campaignTypeId);
      fetchHeaders(props.clientId);

      setNewFilterName(props.filter.contact_directory_filter_name);
      setRunTime(
        props.filter.automatic.toString() === "1" ? { value: 1, label: "Automatic" } : { value: 0, label: "Manual" }
      );
      setNewAutomaticHour(props.filter.automatic_hour);
      setNewAutomaticMinute(props.filter.automatic_minute);
      const ctSource: any = contactDeliveryOptions.find(e => e.value === props.filter.assigned);
      setContactSource(ctSource);
      setContactDestination({ value: 0, label: "Campaign Segment" });

      const sCriterias: any = [];
      for (const i in props.filter.search) {
        const o = props.filter.search[i];
        let header = {};
        let operator: any = {
          value: o.operator_id,
          label: o.operator_name,
          operator_id: o.operator_id,
          operator_name: ""
        };
        for (const hIdx in newHeaders) {
          const hObj: any = newHeaders[hIdx];
          if (hObj.contact_header_id === o.contact_header_id) {
            header = {
              value: hObj.contact_header_id,
              label: hObj.header_name,
              datatype_id: hObj.datatype_id
            };
          }
        }

        const criteria = {
          confirmDelete: false,
          header: header,
          operator: operator,
          value: o.value,
          hits: null,
          hitsLoading: false
        };

        sCriterias.push(criteria);
      }
      setCriterias(sCriterias);
    } else {
      setNewFilterName(defaultFilterName);
      setRunTime(defaultRuntime);
      setNewAutomaticHour(defaultAutomaticHour);
      setNewAutomaticMinute(defaultAutomaticMinute);
      setSelectedCampaign(defaultCampaign);
      setSelectedSegment(defaultSegment);
      setNewSegmentName("");
      setContactSource(defaultContactSource);
      setContactDestination(defaultContactDestination);
      setCriterias(defaultCriterias);
    }

    setErrorMessage("");
  }, [updateForm]);

  useEffect(() => {
    setUpdateForm(updateForm + 1);
  }, [props.filter]);

  useEffect(() => {
    let contactsNo = 0;
    switch (contactSource.value) {
      case "0":
        contactsNo = props.directoryInfo.remainingContacts;
        break;
      case "1":
        contactsNo = props.directoryInfo.deliveredContacts;
        break;
      case "2":
        contactsNo = props.directoryInfo.totalContacts;
        break;
    }
    setAvailableContacts(contactsNo);
    setDryRunCount(null);
  }, [contactSource]);

  // Initial set of segment options (with create-option)
  const segmentOptions: any = [
    { value: "", label: "- Select Segment -" },
    {
      label: "Actions",
      options: [{ value: "0", label: "Create segment" }]
    },
    {
      label: "Segment",
      options: []
    }
  ];

  let segmentExists = false;
  if (segments !== null) {
    segments.forEach((c: any) => {
      segmentOptions[2].options.push({ value: c.segment_id, label: c.segment_name });

      if (c.segment_name === newSegmentName && !newSegmentName.includes("[")) {
        segmentExists = true;
      }
    });
  }

  // Initial set of campaign options
  const campaignOptions: any = [{ value: "", label: "- Select Campaign -" }];

  if (campaigns !== null) {
    campaigns.forEach((c: any) => {
      campaignOptions.push({ value: c.campaign_id, label: c.campaign_name });
    });
  }

  // Rewritten arrays for header and operators in render
  const newHeaderOpts: any = [];
  if (newHeaders !== null) {
    newHeaders.forEach((c: any) => {
      newHeaderOpts.push({
        value: c.contact_header_id,
        label: c.header_name,
        datatype_id: c.datatype_id
      });
    });
  }

  const availableOperatorOpts: any = [];
  if (availableOperators !== null) {
    availableOperators.forEach((c: any) => {
      availableOperatorOpts.push({
        value: c.datatype_operator_id,
        label: c.display_name,
        operator_id: c.operator_id,
        operator_name: c.operator_name
      });
    });
  }

  const toggleConfirmDeleteVisible = (idx: any) => {
    const crits = [...criterias];
    crits[idx].confirmDelete = !crits[idx].confirmDelete;
    setCriterias(crits);
  };

  // Sends search criterias to backend and expects a result with incremental counts for each criteria
  const dryRunCriterias = (e: any) => {
    const runCriterias = [...criterias];
    const request: any = {
      client_id: props.clientId,
      contact_directory_id: props.directoryId,
      assigned: contactSource.value,
      per_page: 50,
      order_by: "lastname",
      order_direction: "desc",
      contact_list_id: 0,
      segment_id: 0,
      count_filters: true,
      show_result: true,
      search: []
    };

    for (const i in runCriterias) {
      const o = runCriterias[i];
      o.hitsLoading = true;
      request.search.push({
        filter_type: "contact_header", // TODO: implement more filter types
        contact_header_id: o.header.value,
        value: o.value,
        operator_id: o.operator.operator_id
      });
    }

    const fetchSearchResult = async () => {
      const res = await fetch(env._apiPath + "/listManagement/searchDirectory", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(request),
        headers: {
          "Content-Type": "application/json"
        }
      });
      const json = await res.json();
      if (json.status === "success") {
        const updateCriterias = [...criterias];
        let lastCount: any = 0;
        for (const i in json.data.search_filter) {
          const o = json.data.search_filter[i];
          updateCriterias[i].hitsLoading = false;
          updateCriterias[i].hits = o.contacts_count;
          lastCount = o.contacts_count;
        }
        setCriterias(updateCriterias);
        setDryRunCount(lastCount);

        const preColumns: any = [];
        const filPreview: any = [];
        if (json.data.data.length > 0) {
          for (const c in json.data.data[0]) {
            let headerName = c.substring(0, 1).toUpperCase() + c.substring(1);
            headerName = headerName.replace("_", " ");
            preColumns.push({
              headerName,
              field: c,
              resizable: true
            });
          }

          for (const i in json.data.data) {
            const o = json.data.data[i];
            filPreview.push(o);
          }
        }
        setFilterPreview(filPreview);
        setPreviewColumns(preColumns);
      }
    };
    fetchSearchResult();

    setCriterias(runCriterias);
  };

  const stopLoading = (idx: any) => {
    const runCriterias = [...criterias];
    runCriterias[idx].hitsLoading = false;
    setCriterias(runCriterias);
  };

  const onSaveFilter = () => {
    const saveCriterias = [...criterias];

    const request: any = {
      client_id: props.clientId,
      contact_directory_filter_name: newFilterName,
      contact_directory_id: props.directoryId,
      assigned: contactSource.value,
      automatic: runTime.value,
      automatic_hour: newAutomaticHour,
      automatic_minute: newAutomaticMinute,
      campaign_id: selectedCampaign.value,
      segment_name: selectedSegment.value === "0" ? newSegmentName : selectedSegment.label,
      search: []
    };

    for (const i in saveCriterias) {
      const o = saveCriterias[i];
      request.search.push({
        contact_header_id: o.header.value,
        value: o.value,
        operator_id: o.operator.operator_id
      });
    }

    if (props.filter.contact_directory_filter_id) {
      request.contact_directory_filter_id = props.filter.contact_directory_filter_id;
    }

    const apiURL =
      "/listManagement/contactDirectoryFilter" + (props.filter.contact_directory_filter_id ? "/update" : "");
    const putContactFilter = async () => {
      const res = await fetch(env._apiPath + apiURL, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(request)
      });
      const json = await res.json();

      setSaveDisabled(false);
      if (json.status === "success") {
        props.onNewFilter();
        props.onHide();
        return;
      }

      setErrorMessage("Unable to save changes: " + json.message.join(" "));
    };
    putContactFilter();
  };

  return (
    <Modal size="lg" centered show={props.show} onHide={props.onHide}>
      <Modal.Header>
        <Modal.Title>{props.filter.contact_directory_filter_id ? "Edit Filter" : "New Filter"}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Tabs defaultActiveKey="details" id="newFilterTabs" className="tabs-page">
          <Tab eventKey="details" title="Details">
            <Form>
              <Form.Group controlId="frmFilterName">
                <Form.Label>Filter Name</Form.Label>
                <Form.Control
                  value={newFilterName}
                  onChange={e => setNewFilterName(e.target.value)}
                  type="text"
                  placeholder="Enter filter name"
                />
              </Form.Group>
            </Form>

            <Row>
              <Col>
                <Form.Group controlId="frmAutomatic">
                  <Form.Label>Run Time</Form.Label>
                  <Select
                    value={runTime}
                    onChange={(opt: any) => setRunTime(opt)}
                    options={[
                      { value: 0, label: "Manual" },
                      { value: 1, label: "Automatic" }
                    ]}
                  />
                  <Form.Text className="text-muted">
                    {runTime.value === 1 ? (
                      <>Run the filter and deliver contacts automatically.</>
                    ) : (
                      <>Run the filter and deliver contacts manually.</>
                    )}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="frmAutomaticHour">
                  <Form.Label>Hour</Form.Label>
                  <Form.Control
                    value={newAutomaticHour}
                    onChange={e => setNewAutomaticHour(e.target.value)}
                    type="text"
                    placeholder="*"
                    disabled={runTime.value === 0}
                  />
                  <Form.Text className="text-muted">Enter an hour between 1 and 23. Use * for every hour.</Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="frmAutomaticMinute">
                  <Form.Label>Minute</Form.Label>
                  <Form.Control
                    value={newAutomaticMinute}
                    onChange={e => setNewAutomaticMinute(e.target.value)}
                    type="text"
                    placeholder="1"
                    disabled={runTime.value === 0}
                  />
                  <Form.Text className="text-muted">Enter a minute between 1 and 59.</Form.Text>
                </Form.Group>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="delivery" title="Delivery">
            <Row>
              <Col>
                <Form.Group controlId="frmSource">
                  <Form.Label>Contact Source</Form.Label>
                  <Select
                    value={contactSource}
                    onChange={(opt: any) => setContactSource(opt)}
                    options={contactDeliveryOptions}
                  />
                  <Form.Text className="text-muted">
                    {contactSource.value === "0" ? <>Run filter on remaining contacts in the directory.</> : null}
                    {contactSource.value === "1" ? <>Run filter on delivered contacts in the directory.</> : null}
                    {contactSource.value === "2" ? <>Run filter on all contacts in the directory.</> : null}
                  </Form.Text>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="frmDestination">
                  <Form.Label>Contact Destination</Form.Label>
                  <Select
                    value={contactDestination}
                    onChange={(opt: any) => setContactDestination(opt)}
                    options={[
                      { value: 0, label: "Campaign Segment" },
                      { value: 1, label: "SFTP" },
                      { value: 2, label: "Manual .CSV or .TXT file" }
                    ]}
                  />
                </Form.Group>
              </Col>
            </Row>
            {contactDestination.value === 0 ? (
              <Row>
                <Col>
                  <Form.Group controlId="campaignSelect">
                    <Form.Label>Campaign</Form.Label>
                    <Select
                      value={selectedCampaign}
                      onChange={(opt: any) => setSelectedCampaign(opt)}
                      options={campaignOptions}
                      isSearchable={true}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="segmentSelect">
                    <Form.Label>Segment</Form.Label>
                    <Select
                      value={selectedSegment}
                      onChange={(opt: any) => setSelectedSegment(opt)}
                      options={segmentOptions}
                      isSearchable={true}
                    />
                  </Form.Group>

                  {selectedSegment.value === "0" ? (
                    <Form.Group controlId="frmSegmentName">
                      <Form.Label>Segment Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter a new segment name"
                        value={newSegmentName}
                        onChange={e => setNewSegmentName(e.target.value)}
                      />
                      <Form.Text className="text-muted">
                        {segmentExists ? "This segment name already exists. Pick another?" : null}
                      </Form.Text>
                    </Form.Group>
                  ) : null}
                </Col>
              </Row>
            ) : null}

            {contactDestination.value === 1 ? (
              <>
                <Row>
                  <Col>
                    <Row>
                      <Col md={9}>
                        <Form.Group controlId="frmSFTPHostname">
                          <Form.Label>Hostname</Form.Label>
                          <Form.Control type="text" placeholder="Enter a hostname for SFTP" />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="frmSFTPPort">
                          <Form.Label>Port</Form.Label>
                          <Form.Control type="text" placeholder="22" />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Form.Group controlId="frmSFTPUsername">
                      <Form.Label>Username</Form.Label>
                      <Form.Control type="text" />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="frmSFTPPath">
                      <Form.Label>Path</Form.Label>
                      <Form.Control type="text" placeholder="Where to upload files on remote." />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="frmSFTPPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control type="password" />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            ) : null}

            {contactDestination.value === 2 ? (
              <>
                <Row>
                  <Col>
                    <Form.Group controlId="frmSeparator">
                      <Form.Label>Separator</Form.Label>
                      <Select
                        options={[
                          { value: 0, label: "Semicolon" },
                          { value: 1, label: "Comma" },
                          { value: 2, label: "Tab" }
                        ]}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="frmEncapsulation">
                      <Form.Label>Encapsulation</Form.Label>
                      <Select
                        options={[
                          { value: 0, label: 'Double quotes ""' },
                          { value: 1, label: "Single quotes ''" },
                          { value: 2, label: "None" }
                        ]}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="frmEncoding">
                      <Form.Label>Encoding</Form.Label>
                      <Select
                        options={[
                          { value: 0, label: "UTF-8" },
                          { value: 1, label: "Windows-1252 (ANSI)" }
                        ]}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            ) : null}
          </Tab>
          <Tab eventKey="criteria" title="Criteria">
            <Row>
              <Col>
                <Form.Label>New Criteria</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Form.Group controlId="frmNewHeader">
                  <Select value={newHeader} onChange={(opt: any) => setNewHeader(opt)} options={newHeaderOpts} />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group controlId="frmNewOperator">
                  <Select
                    value={newOperator}
                    onChange={(opt: any) => setNewOperator(opt)}
                    options={availableOperatorOpts}
                  />
                </Form.Group>
              </Col>
              <Col md={5}>
                <Form.Group controlId="frmNewFilterValue">
                  <Form.Control
                    type={htmlDataType}
                    placeholder="Value"
                    value={newValue}
                    onChange={(e: any) => setNewValue(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={1}>
                {editCriteriaIndex === null ? (
                  <Button variant="success" onClick={e => addCriteria(newHeader, newOperator, newValue)}>
                    <i className={"fas fa-fw fa-plus-circle"}></i>
                  </Button>
                ) : (
                  <Button variant="warning" onClick={e => addCriteria(newHeader, newOperator, newValue)}>
                    <i className={"fas fa-fw fa-save"}></i>
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Table hover>
                <thead>
                  <tr>
                    <th></th>
                    <th>Header</th>
                    <th>Operator</th>
                    <th>Value</th>
                    <th>Hits</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {criterias.map((cri: any, idx: any) => {
                    return (
                      <tr key={idx}>
                        <td className="order-action">
                          <Row>
                            <Col>
                              <i onClick={e => moveCriteria(idx, true)} className={"fa fa-arrow-up"}></i>
                            </Col>
                            <Col>
                              <i onClick={e => moveCriteria(idx, false)} className={"fa fa-arrow-down"}></i>
                            </Col>
                          </Row>
                        </td>
                        <td>{cri.header.label}</td>
                        <td>{cri.operator.label}</td>
                        <td>{cri.value}</td>
                        <td>
                          {cri.hitsLoading ? (
                            <i className="fas fa-fw fa-spinner fa-spin"></i>
                          ) : cri.hits !== null ? (
                            cri.hits
                          ) : (
                            "-"
                          )}
                        </td>
                        <td style={{ textAlign: "right", width: "50px" }}>
                          <Row>
                            <Col>
                              <i
                                onClick={e => editCriteria(idx)}
                                className={"fa fa-edit"}
                                style={{ color: "#aaa" }}
                              ></i>
                            </Col>
                            <Col>
                              <i
                                onClick={e => {
                                  setConfirmDeleteTarget(e.target);
                                  toggleConfirmDeleteVisible(idx);
                                }}
                                className={"fa fa-trash"}
                                style={{ color: "#ff0000" }}
                              ></i>
                              <Overlay show={cri.confirmDelete} target={confirmDeleteTarget}>
                                <Popover id={"popover-confirm-" + idx}>
                                  <Popover.Title as="h3">Confirm?</Popover.Title>
                                  <Popover.Content>
                                    <Row>
                                      <Col>
                                        <Button size="sm" variant="danger" onClick={e => removeCriteria(idx)}>
                                          Delete
                                        </Button>
                                      </Col>
                                      <Col>
                                        <Button
                                          size="sm"
                                          variant="secondary"
                                          onClick={e => toggleConfirmDeleteVisible(idx)}
                                        >
                                          Cancel
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Popover.Content>
                                </Popover>
                              </Overlay>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Row>
          </Tab>
          <Tab eventKey="preview" title="Preview">
            <div
              className="ag-theme-balham"
              style={{
                height: "360px",
                width: "100%"
              }}
            >
              <AgGridReact
                columnDefs={previewColumns}
                rowData={filterPreview}
                onGridReady={onGridReady}
                groupIncludeTotalFooter={false}
                sideBar={previewAgSidebar}
                suppressContextMenu={true}
              />
            </div>
          </Tab>
        </Tabs>

        {errorMessage ? (
          <Alert variant="warning">
            <i className="fa fa-exclamation-circle"></i> {errorMessage}
          </Alert>
        ) : null}
      </Modal.Body>

      <Modal.Footer>
        <div className="contact-stats">
          <Row>
            {dryRunCount !== null ? (
              <Col>
                <small>Filter Result</small>
                <br />
                <span>
                  {dryRunCount} / {availableContacts}
                </span>
              </Col>
            ) : (
              <Col>
                <small>Contacts available to filter</small>
                <br />
                <span>{availableContacts}</span>
              </Col>
            )}
          </Row>
        </div>

        <Button variant="secondary" onClick={dryRunCriterias}>
          Dry Run
        </Button>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={e => {
            onSaveFilter();
            setSaveDisabled(true);
          }}
          disabled={saveDisabled}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
