import { actionStartAPI, actionEndAPI } from "../../../redux/reducers/shell";
import {
  actionReceivedOrderHeaders,
  actionReceivedOrderReport,
  actionReceivedOrderDetails,
  actionReceivedOrderDetailsExtra,
  actionReceivedOrderOperations,
  actionReceivedOrderEditStatus,
  actionReceivedOrderActivities
} from "../../../redux/reducers/reports/orders";
import { showShellError } from "../../shell/shell.api";

declare var env: any;
//env._apiPath = 'http://api-local.fiko.no/manager';
/**
 * Encode an object as url query string parameters
 * - includes the leading "?" prefix
 * - example input — {key: "value", alpha: "beta"}
 * - example output — output "?key=value&alpha=beta"
 * - returns empty string when given an empty object
 */
function encodeQueryString(params) {
  const keys = Object.keys(params);
  return keys.length
    ? "?" + keys.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])).join("&")
    : "";
}
export interface IOrder {
  
  order_id: number,
  confirmation_method: string,
  order_done_at: string,
  created_at: string,
  rejected_at?: string,
  regretted_at?: string,
  confirmed_at: string,
  completed_at?: null,
  campaign_id: string,
  shortname: string,
  form_status: number,
  order_data: [
      {
          display_name: string,
          value?: string | number,
          order_header_id: number
      }
  ],
  order_details: [
      {
          product_name: string,
          product_description: string,
          order_detail_id: number,
          price: number,
          amount: number,
          total: number,
          order_detail_data: [],
          api_sent_at?: string,
          api_sent_status?: string,
      }
  ],
  order_activities: [
      {
          order_activity_id: number,
          created_at: string,
          executed_at: string,
          order_activity_type_id: number,
          order_activity_type_name: string,
          success: boolean,
          details: []
      }
  ],
  order_sms: [
      {
          order_confirmation_id: number,
          sender: string,
          recipient: string,
          message_in?: string,
          message_out: string,
          sms_incoming_id?: string,
          outdated?: unknown,
          created_at: string,
          updated_at: string,
      }
  ],
  order_othersms: [{
    created_at: string,
    message: string,
    recipient: string,
    sender: string,
    sms_dialogue_id: number,
    sms_incoming_id: string,
  }],
  order_signatures: [{
    created_at: string,
    signature: string,
  }],
  order_actions: [{
    created_at: string,
    order_action_comment: string,
    order_action_id: number,
    order_action_name: string,
    order_rejected_reason: {
      id: number, 
      name: string, 
      type: string,}
    user: {
      id: number, 
      fullname: string,}
  }],
  view_soundfiles: boolean,
  soundfiles: [{
    activity_file_id: string,
    activity_id: string,
    confirmed_at?: string,
    created_at: string,
    filesize: string,
    order_action_comment: string,
    order_rejected_reason_id: string,
    outcome_name: string,
    regretted_at?: string,
    rejected_at?: string,
  }]

}
export interface Soundfile {
  created_at: string,
  activity_id: string,
  activity_file_detail_id: number,
  phone: string,
  firstname: string,
  lastname?: string,
  login_name: string,
  client_name: string,
  campaign_stype_name: string,
  campaign_name: string,
  outcome_name: string,
  soundfile_url: string,
  client_id: string,
  contact_id: string,
  customer_id: string,
  order_id: string,
  rejected_at?: unknown,
  new_soundfile?: unknown,
  regretted_at?: unknown,
  accepted_at?: unknown
  order_action_comment?: string,
  order_rejected_reason_id?: string
}
export const fetchRejectReasons = async () => {
  const res = await fetch(env._apiPath + "/campaign/orderRejectedReasons/read", {
      method: "GET",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
  });
  const json = await res.json();

  if(json.status === "success") {
      return json.data;
  }

  throw json.message;
}
export interface OrderConfirmation {
  order_confirmation_id: number,
  order_id: number,
  sms_incoming_id?: number,
  status: boolean
}
export const SMSConfirm = async (props:OrderConfirmation) => {
  console.log(props);
  const req = {
      order_confirmation_id: props.order_confirmation_id,
      order_id: props.order_id,
      status: props.status?1:0,
      ...props.sms_incoming_id && {sms_incoming_id: props.sms_incoming_id}      
    };
  const res = await fetch(env._apiPath + "/order/confirmSms", {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req)
  });
  const json = await res.json();

  if(json.status === "success") {
      return json.data;
  }

  throw json.message;
}
export interface OrderSoundfile {
  activity_id: number,
  activity_file_id: number,
  outcome_name: string,
  created_at: any,
  filesize: number
}
export interface SoundFileReject {
  order_action_comment: string,
  order_rejected_reason_id: string,
}
export const soundfileApproval = async (props:OrderSoundfile, status: number, rejectedReason: SoundFileReject) => {
  const req = {
      activity_id: props.activity_id,
      activity_file_id: props.activity_file_id,
      order_action_comment: rejectedReason.order_action_comment,
      order_rejected_reason_id: rejectedReason.order_rejected_reason_id !== '0'?rejectedReason.order_rejected_reason_id:null,    
      status: status
    };
  const res = await fetch(env._apiPath + "/file/updateSoundFileStatus", {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(req)
  });
  const json = await res.json();

  if(json.status === "success") {
      return json.data;
  }

  throw json.message;
}
const fParams = (method, body): RequestInit => ({
  method: method,
  credentials: "include",
  headers: {
    "Content-Type": "application/json"
  },
  body
});

export function loadOrderHeaders(dispatch, clientId, orderReport) {
  dispatch(actionStartAPI());

  const fetchHeaders = async () => {
    let urlHeaders = '';

    if (orderReport && orderReport.headers !== undefined) {
      orderReport.headers.map((val, key) => {
        urlHeaders = urlHeaders + (urlHeaders.length?',':'') + val;
        return 
      });
    }
    

    const res = await fetch(env._apiPath + "/orderReportHeaders?client_id=" + clientId + '&oh=' + urlHeaders + '&version=2', {
      method: "GET",
      credentials: "include"
    });
    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status !== "success") {
      // dispatch(actionSetLoginErrorMessage(json.message))
      return;
    }

    const headers = json.data;

    dispatch(actionReceivedOrderHeaders(headers));
  };

  fetchHeaders();
}

export function loadOrderReport(dispatch, reportParams) {
  dispatch(actionStartAPI());
  const fetchReport = async () => {
    const apiURL = env._apiPath + "/ordersNewer";
//    const apiURL = env._apiPath + "/ordersNew";
    const params = encodeQueryString(reportParams);

    /*
    console.log(reportParams)
    if (reportParams.csvdownload) {
      dispatch(actionEndAPI());
      const wnd = window.open(apiURL + params, '_blank')
      if (wnd) {
        wnd.document.onload = () => wnd.close()
      }
      return
    }
    */

    const res = await fetch(apiURL + params, {
      method: "GET",
      credentials: "include"
    });

    if (reportParams.csvdownload) {
      console.log("downloading csv");
      const blob = await res.blob();
      dispatch(actionEndAPI());
      const objUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = objUrl;
      a.download = "download.csv";
      document.body.appendChild(a);
      a.click();
      a.remove();
      return;
    }

    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status !== "success") {
      // dispatch(actionSetLoginErrorMessage(json.message))
      return;
    }

    const report = json.data;

    dispatch(actionReceivedOrderReport(report));
  };

  fetchReport();
}

export function loadOrderActivities(dispatch, orderId) {
  dispatch(actionStartAPI());
  const fetchActivities = async () => {
    const apiURL = env._apiPath + "/order/activities/" + orderId;

    const res = await fetch(apiURL, { method: "GET", credentials: "include" });
    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status !== "success") {
      return;
    }

    const activities = json.data;
    
    dispatch(actionReceivedOrderActivities(activities));
  };

  fetchActivities();
}

export function loadOrderDetails(dispatch, orderId, stepId = 0) {
  if (stepId != 2) dispatch(actionStartAPI());
  const fetchDetails = async () => {
    const apiURL = env._apiPath + "/order";
    
    const params = (stepId)?encodeQueryString({ order_id: orderId, step: stepId }):encodeQueryString({ order_id: orderId });

    const res = await fetch(apiURL + params, {
      method: "GET",
      credentials: "include"
    });
    const json = await res.json();
    if (stepId != 2) dispatch(actionEndAPI());
    if (json.status !== "success") {
      // dispatch(actionSetLoginErrorMessage(json.message))
      return;
    }

    const details = json.data;

    switch (stepId) {
      case 1:
        dispatch(actionReceivedOrderDetails(details));
        dispatch(actionReceivedOrderDetailsExtra(null));
        break; 
      case 2:
        dispatch(actionReceivedOrderDetailsExtra(details));
        break;     
      default:
        dispatch(actionReceivedOrderDetails(details));
        dispatch(actionReceivedOrderDetailsExtra(details));
    }
  };

  fetchDetails();
}

export function loadOrderOperations(dispatch, orderId, target) {
  dispatch(actionStartAPI());
  const fetchOperations = async () => {
    const apiURL = env._apiPath + "/order/operations/" + orderId;
    //const params = encodeQueryString({order_id: orderId})

    const res = await fetch(apiURL, { method: "GET", credentials: "include" });
    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status !== "success") {
      // dispatch(actionSetLoginErrorMessage(json.message))
      return;
    }

    const operations = json.data.operations;

    dispatch(actionReceivedOrderOperations(operations, target));
  };

  fetchOperations();
}

export function loadOrderStatus(dispatch, orderId, operation: string) {
  dispatch(actionStartAPI());
  const fetchEdit = async () => {
    const apiURL = env._apiPath + "/order/status";
    const req: any = {
      order_id: orderId,
      operation
    };

    const res = await fetch(apiURL, fParams("POST", JSON.stringify(req)));
    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status !== "success") {
      switch (operation) {
        case "edit":
          showShellError(dispatch, "danger", "Error Opening OMS", "Unable to load order status: " + json.message);
          return;
        default:
          showShellError(dispatch, "danger", "Error", "Unable to update order status: " + json.message);
      }
    }

    const data = json.data;
    switch (operation) {
      case "edit":
        dispatch(actionReceivedOrderEditStatus(data));
        break;
    }
  };

  fetchEdit();
}

export const rejectOrderWithReason = async (
  dispatch,
  orderId,
  operation: string,
  reasonID: number | string | null,
  comment: string
) => {
  dispatch(actionStartAPI());

  const apiURL = env._apiPath + "/order/status";
  const req: any = {
    order_id: orderId,
    operation,
    reason_id: reasonID,
    comment: comment
  };

  const res = await fetch(apiURL, fParams("POST", JSON.stringify(req)));
  const json = await res.json();
  dispatch(actionEndAPI());

  if (json.status !== "success") {
    throw "Unable to update order status: " + json.message;
  }

  return json;
};