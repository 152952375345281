import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import ClientSelector from "../shell/clientselector.component";
import CampaignSelector from "../shell/campaignselector.component";
import CampaignTypeSelector from '../shell/campaigntypeselector.component';
import TitlebarComponent from "../../titlebar.component";
import { fetchSegments, fetchSegmentActivities, Segment, SegmentActivities } from './activityreport/activityreport.api';
import { actionSelectCampaign, actionSelectClient, actionSelectCampaignType } from "../../redux/reducers/shell";

// material ui
import { Grid, 
    makeStyles,Theme,createStyles,
    CardContent, Card, CardActions,
    Button, TextField,
    Dialog, DialogTitle, DialogContent,DialogActions,
    Snackbar, MenuItem, CircularProgress
} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';

// ag gridsystemet
import { AgToolbarButton } from "../../agGridUtils";
import { AgGridReact } from "ag-grid-react";
import {
    GridReadyEvent,
    FirstDataRenderedEvent,
    SelectionChangedEvent,
    GridApi,
    ColumnApi,
} from "ag-grid-community";
import { actionToggleFilterView } from "../../redux/reducers/shell";

// theme for AdGridReact
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorMessage: {
            color: '#ff0000'
        },
        flexGrow: {
            flexGrow: 1,
        },
        actions: {
            "& .form-group": {
                minWidth: 200,
                marginRight: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
            "& button": {
                //marginTop: 24,
            },
        },
        agGrid: {
            "& .aggrid-size": {
                minHeight: 500,
                height: "calc(100vh - 460px)",
                width: "100%",
            },
        },
    })
);
// for knappene over tabellen
const toolbarProps = {
    title: "",
    rightAlign: false,
    disabled: false,
    icon: "",
    onClick: () => { },
};
const marginStyles = {
    // margin: '24px 0 0 0',
 }
 function formatDateTime(date) {
    var d = new Date(date.replace(/\s/, 'T')),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour = '' + d.getHours(),
        min = '' + d.getMinutes();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    if (hour.length < 2) 
        hour = '0' + hour;
    if (min.length < 2) 
        min = '0' + min;

    return ([day, month, year].join('.') + ' ' + hour + ':' + min);
}
 const columnDefs = [
    { headerName: "Activity", field: "activity_type_name", sortable: true, filter: true,},
    { headerName: "Agent", field: "fullname", sortable: true, filter: true, rowGroup: true},
    { headerName: "Date", field: "created_at", sortable: true, filter: true, cellRenderer: (r) => {
        if (r.data ) {
            return formatDateTime(r.data.created_at);
            }
        return '';
    },},
    { headerName: "Outcome", field: "outcome_name", sortable: true, filter: true},
    { headerName: "Reason", field: "outcome_reason_name", sortable: true, filter: true},
    { headerName: "Contact Id", field: "contact_id", sortable: true, filter: true},
    { headerName: "Firstname", field: "firstname", sortable: true, filter: true},
    { headerName: "Lastname", field: "lastname", sortable: true, filter: true},
    { headerName: "Mobile", field: "mobile", sortable: true, filter: true},
    { headerName: "Phone", field: "phone", sortable: true, filter: true},
    { headerName: "Address", field: "street", sortable: true, filter: true},
    { headerName: "House #", field: "house_no", sortable: true, filter: true},
    { headerName: "City", field: "city", sortable: true, filter: true},
    { headerName: "Zip", field: "zip", sortable: true, filter: true},
    // de under er hidden
    { headerName: "Activity Id", field: "activity_id", sortable: true, filter: true, hide: true},
    { headerName: "Activity Type", field: "activity_type_id", sortable: true, filter: true, hide: true},
    { headerName: "Campaign Id", field: "campaign_id", sortable: true, filter: true, hide: true},
    { headerName: "Segment Id", field: "segment_id", sortable: true, filter: true, hide: true},
    { headerName: "Agent Id", field: "agent_id", sortable: true, filter: true, hide: true},
    { headerName: "Team Id", field: "team_id", sortable: true, filter: true, hide: true},
    { headerName: "Customer Id", field: "customer_id", sortable: true, filter: true, hide: true},
    { headerName: "Outcome Id", field: "outcome_id", sortable: true, filter: true, hide: true},
    { headerName: "Reason Id", field: "outcome_reason_id", sortable: true, filter: true, hide: true},
];

export default function ActivityReportComponent() {
    const [results, setResults] = useState<SegmentActivities[]>([]);
    const [segments, setSegments] = useState<Segment[]>([]);
    const [selectedSegment, setSelectedSegments] = useState<string>();
    const [showDialog, setShowDialog] = useState(false);

    const selectedCampaign = useSelector(state => state.shell.selectedCampaign);

    // for tabellen
    const dispatch = useDispatch();
    const classes = useStyles();
    const [gridAPI, setGridAPI] = useState<GridApi>();
    const [columnAPI, setColumnAPI] = useState<ColumnApi>();
    const hideFilters = useSelector((state) => state.shell.hideFilters);
    // standard variabler
    const [loading, setLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [alertMsg, setAlertMsg] = useState({msg: "",severity: false});
    const [errorMsg, setErrorMsg] = useState("");

    // funksjoner for agGrid
    // Dette skjer når du klikker på en rad i tabellen, lagrer object med file id
    const onSelectionChanged = (event: SelectionChangedEvent) => {
        const rows = event.api.getSelectedRows();
        if (!rows[0]) {
            return;
        }
        // TODO: setSelectedContact(rows[0]);
    };
    // søkefelt som søker i tabellen
    const handleQuickFilter = event => {
        gridAPI!.setQuickFilter(event.target.value);
    };
    // laster inn data om tabellen
    const onGridReady = (event: GridReadyEvent) => {
        setGridAPI(event.api);
        setColumnAPI(event.columnApi);
    };
    // 3 knapper over tabellen, refresh, og endre størrelse på kolonner
    const onAdjustColumnsContent = (event: Event) => {
        var allColumnIds: any = [];
        if (columnAPI) {
            const allColumns = columnAPI!.getAllColumns();
            allColumns!.forEach((column) => {
                const colDef = column.getColDef();
                if (!colDef.suppressAutoSize) {
                    allColumnIds.push(column.getColId());
                }
            });
            columnAPI!.autoSizeColumns(allColumnIds);
        }
    };
    const onAdjustColumnsWindow = (event: Event) => {
        gridAPI!.sizeColumnsToFit();
    };
    const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
        event.api.sizeColumnsToFit();
    };
    const onExportToCsv = () => {
        if (gridAPI) {
        gridAPI.exportDataAsExcel();
        }
    };
    const refreshTable = () => {
        //fetchAllCampaigns(); TODO: 
    }
    // end funksjoner for agGrid
    
    function resetForm() {
        dispatch(actionSelectClient(undefined));
        dispatch(actionSelectCampaign(undefined));
        dispatch(actionSelectCampaignType(undefined));
        setSelectedSegments("");
    }
    function runSearch() {
        if (selectedSegment) {
            setLoading(true);
            fetchSegmentActivities(selectedSegment)
                .then(res => setResults(res.data))
                .finally(() => setLoading(false));
        }
    }

    useEffect(() => {
        if (selectedCampaign) {
            setLoading(true)
            fetchSegments(selectedCampaign.value)
            .then(res => setSegments(res.data))
            .finally(() => setLoading(false))
        }
        else {
            setSegments([]);
        }
    },[selectedCampaign])

    return (<>
    <TitlebarComponent
                title="Activity Report"
                description="View activities"
                breadcrumbs={[
                    { title: "Dashboard", link: "/" },
                    { title: "Activity Report", link: "/reports/activityreport" }
                ]}
            />
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <div
                        style={{ color: "#ccc", width: 15 }}
                        data-toggle="tooltip"
                        data-original-title="Hide/Show filters"
                        onClick={() => dispatch(actionToggleFilterView())}
                    >
                        {hideFilters ? (
                            <i className="fas fa-fw fa-caret-square-right"></i>
                        ) : (
                                <i className="fas fa-fw fa-caret-square-left"></i>
                            )}
                    </div>
            </Grid>
            <Grid item xs={12} md={2} style={{ display: hideFilters ? "none" : "block" }}>
                <div style={{maxWidth: "270px"}}>
                    <ClientSelector variant="material" fullWidth />
                    <CampaignTypeSelector variant="material" fullWidth />
                    <CampaignSelector variant="material" fullWidth required/>
                    {/* TODO: Søkefunksjon er ikke vital funksjonalitet i versjon 1.0
                    <TextField 
                        label="Name search"
                        type="text"
                         />
                    <TextField 
                        label="Address search"
                        type="text"
                         />
                    <TextField 
                        label="Phone search"
                        type="text"
                         /> */}
                    <TextField 
                        label="Segment"
                        select
                        fullWidth
                        required
                        disabled={!selectedCampaign}
                        value={selectedSegment ? selectedSegment : ""}
                        onChange={e => setSelectedSegments(e.target.value)}
                         ><MenuItem value="">None</MenuItem>
                        {segments.map((s, key) => <MenuItem key={key} value={s.segment_id}>{s.segment_name}</MenuItem>)} 
                        </TextField>

                    <div style={{ textAlign: "right", marginTop: 20 }}>
                        { loading ? <CircularProgress size={20}/> : null}
                        <Button onClick={resetForm} variant="outlined" color="primary">
                        Reset
                        </Button>
                        &nbsp;
                        <Button 
                        disabled={loading || !selectedCampaign}
                        onClick={runSearch} 
                        variant="contained"
                        color="primary">
                        Search
                        </Button>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12} md={hideFilters ? 12 : 10}>
            <Card>
            <CardActions disableSpacing className={classes.actions}>
                {/* Knappene over tabellen */}
                {AgToolbarButton({
                    ...toolbarProps,
                    title: "Refresh",
                    icon: "fa-refresh",
                   // onClick: () => // TODO: onAddContact(),
                })}
                <div className={classes.flexGrow} />
                <input 
                    type="text" 
                    style={marginStyles}
                    placeholder="Search" 
                    onChange={handleQuickFilter}
                />
                {AgToolbarButton({
                    ...toolbarProps,
                    title: "Export to CSV",
                    icon: "fa-file-export",
                    onClick: onExportToCsv,
                })}

                {AgToolbarButton({
                    ...toolbarProps,
                    title: "Adjust columns to window",
                    icon: "fa-columns",
                    onClick: onAdjustColumnsWindow,
                })}

                {AgToolbarButton({
                    ...toolbarProps,
                    title: "Adjust columns to content",
                    icon: "fa-text-width",
                    onClick: onAdjustColumnsContent,
                })}
            </CardActions>
            {/* Selve tabellen */}
            <CardContent >
                <Grid container>
                <Grid item xs={12} className={classes.agGrid}>
                    <div className="ag-theme-balham aggrid-size">
                        <AgGridReact
                            rowData={results}
                            columnDefs={columnDefs}  
                            onGridReady={onGridReady}
                            onSelectionChanged={onSelectionChanged}
                            enableRangeSelection={false}
                            rowSelection="single"
                            // justerer kolonnebredden etter load
                            onFirstDataRendered={onFirstDataRendered}
                            sideBar={{
                                toolPanels: ["columns"],
                            }}
                            // pagination
                            pagination={true}
                            paginationPageSize={100}
                            cacheBlockSize={100}
                            cacheOverflowSize={2}
                            maxBlocksInCache={2}
                            enableCellTextSelection={true}
                            ensureDomOrder={true}
                            defaultColDef={{
                                flex: 1,
                                minWidth: 100,
                                sortable: true,
                                resizable: true,
                                filter: true,
                                enableRowGroup: true,// allow grouping
                                enableValue: true, // allow value aggregate
                            }}
                        />
                    </div>
                </Grid>
                </Grid>
            
            </CardContent>  
            </Card>
            </Grid>
        </Grid>
        <Dialog fullWidth maxWidth="md" open={showDialog} onClose={() => setShowDialog(false)}>
            <DialogTitle>Add contact</DialogTitle>
            <DialogContent></DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setShowDialog(false)}>
                        Cancel
                    </Button>
            </DialogActions>
        </Dialog>
        {/* alert bar */}
        <Snackbar 
            open={alertOpen} 
            autoHideDuration={3000} 
            onClose={() => setAlertOpen(false)}>
            <MuiAlert 
                variant="filled"
                severity={ alertMsg.severity ? "success" : "error"}>{alertMsg.msg}
            </MuiAlert>
        </Snackbar>
    </>)
}