import React, { useState, useEffect } from "react";
import DirectoryListComponent from "./directories/directorylist.component";
import DirectoryOverviewComponent from "./directories/directoryoverview.component";
import TitlebarComponent from "../../titlebar.component";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import UserSettings from "../../userSettings";

declare var env: any;
declare var $: any;

interface DirectoriesProps {
  history?: any;
}

export default function DirectoriesComponent(props: DirectoriesProps) {
  const defaultSelection = { value: "", label: "" };
  const selectionSettings = UserSettings.getOption(UserSettings.DIRECTORY_LIST_SELECTION, {
    selectedCompany: defaultSelection,
    selectedClient: defaultSelection,
    selectedCampaignType: defaultSelection
  });

  const [selectedCompany, setSelectedCompany] = useState(selectionSettings.selectedCompany);
  const [selectedClient, setSelectedClient] = useState(selectionSettings.selectedClient);
  const [selectedCampaignType, setSelectedCampaignType] = useState(selectionSettings.selectedCampaignType);
  const [selectedCountry, setSelectedCountry] = useState(defaultSelection);

  const [companies, setCompanies] = useState([]);
  const [clients, setClients] = useState([]);
  const [campaignTypes, setCampaignTypes] = useState([]);
  const [countries, setCountries] = useState([]);

  const [showNewDirectory, setShowNewDirectory] = useState(false);
  const [newDirectoryName, setNewDirectoryName] = useState("");

  const resetState = () => {
    setSelectedCompany(defaultSelection);
    setSelectedClient(defaultSelection);
    setSelectedCampaignType(defaultSelection);
  };

  const hasDirectoryFilter = selectedCampaignType.value || selectedClient.value;

  useEffect(() => {
    const fetchCompanies = async () => {
      const res = await fetch(env._apiPath + "/companies", { method: "GET", credentials: "include" });
      const json = await res.json();
      const jcompanies: any = [];
      for (const i in json.data) {
        const o = json.data[i];
        jcompanies.push({
          value: o.company_id,
          label: o.company_name
        });
      }
      setCompanies(jcompanies);
    };
    fetchCompanies();

    const fetchClients = async () => {
      const res = await fetch(env._apiPath + "/customer/clients", { method: "GET", credentials: "include" });
      const json = await res.json();
      const jclients: any = [];
      for (const i in json.data) {
        const o = json.data[i];
        jclients.push({
          value: o.client_id,
          label: o.client_name
        });
      }
      setClients(jclients);
    };
    fetchClients();

    const fetchCountries = async () => {
      const res = await fetch(env._apiPath + "/country/all", { method: "GET", credentials: "include" });
      const json = await res.json();
      const jcountries: any = [];
      for (const i in json.data) {
        const o = json.data[i];
        jcountries.push({
          value: o.country_id,
          label: o.country_name
        });
      }
      setCountries(jcountries);
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    UserSettings.setOption(UserSettings.DIRECTORY_LIST_SELECTION, {
      selectedCompany,
      selectedClient,
      selectedCampaignType
    });
  }, [selectedCompany, selectedClient, selectedCampaignType]);

  useEffect(() => {
    let queryString = "";

    if (selectedClient.value) {
      queryString = "/" + selectedClient.value;
    }

    if (selectedCompany.value) {
      queryString += "?company_id=" + selectedCompany.value;
    }

    if (!selectedClient.value && !selectedCompany.value) {
      setCampaignTypes([]);
      return;
    }

    const fetchData = async () => {
      try {
        const res = await fetch(env._apiPath + "/customer/campaigntypes" + queryString, {
          method: "GET",
          credentials: "include"
        });
        const json = await res.json();
        const jcampaigntypes: any = [];
        for (const i in json.data) {
          const o = json.data[i];
          jcampaigntypes.push({
            value: o.campaign_type_id,
            label: o.campaign_type_name
          });
        }
        setCampaignTypes(jcampaigntypes);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [selectedCompany, selectedClient]);

  useEffect(() => {
    if (showNewDirectory) {
      setSelectedCountry(defaultSelection);
    }
  }, [showNewDirectory]);

  const onCreateNewDirectory = async () => {
    const request = {
      contact_directory_name: newDirectoryName,
      campaign_type_id: selectedCampaignType.value,
      country_id: selectedCountry.value
    };

    try {
      const res = await fetch(env._apiPath + "/listManagement/contactDirectory", {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(request),
        headers: {
          "Content-Type": "application/json"
        }
      });
      const json = await res.json();
      if (json.status === "success") {
        props.history.push("/listmanagement/directories/" + json.data.contact_directory_id);
        setShowNewDirectory(false);
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <TitlebarComponent
        title="Directories"
        description="Manage your directories"
        breadcrumbs={[
          { title: "Dashboard", link: "/" },
          { title: "Directories", link: "/listmanagement/directories" }
        ]}
      />

      <div className="widget-list">
        <div className="row">
          <div className="widget-holder col-md-12">
            <div className="widget-bg">
              <div className="widget-body">
                <div className="row" id="directoriesSearch">
                  <div className="col-lg-2">
                    <Form.Group controlId="frmCompany">
                      <Form.Label>Company</Form.Label>
                      <Select
                        value={selectedCompany}
                        onChange={(opt: any) => setSelectedCompany(opt)}
                        options={companies}
                      />
                    </Form.Group>

                    <Form.Group controlId="frmClient">
                      <Form.Label>Client</Form.Label>
                      <Select
                        value={selectedClient}
                        onChange={(opt: any) => setSelectedClient(opt)}
                        options={clients}
                      />
                    </Form.Group>

                    <Form.Group controlId="frmCampaignType">
                      <Form.Label>Campaign Type</Form.Label>
                      <Select
                        value={selectedCampaignType}
                        onChange={(opt: any) => setSelectedCampaignType(opt)}
                        options={campaignTypes}
                      />
                    </Form.Group>

                    <div style={{ textAlign: "right", marginTop: 20 }}>
                      <button className="btn btn-sm" onClick={resetState}>
                        Reset
                      </button>
                    </div>
                  </div>

                  <div className="col-lg-10">
                    <div className="ag-buttons">
                      <button
                        onClick={e => setShowNewDirectory(true)}
                        data-toggle="tooltip"
                        title=""
                        className="btn btn-sm btn-link mr-2 fs-18 dt-custom-background text-secondary"
                        data-original-title="New Directory"
                      >
                        <span>
                          <i className="fas fa-fw fa-folder-plus"></i>
                        </span>
                      </button>
                    </div>

                    {hasDirectoryFilter ? (
                      <DirectoryListComponent
                        history={props.history}
                        ClientID={selectedClient.value}
                        CampaignTypeID={selectedCampaignType.value}
                      />
                    ) : (
                      <DirectoryOverviewComponent />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal size="lg" centered show={showNewDirectory} onHide={() => setShowNewDirectory(false)}>
        <Modal.Header translate={undefined}>
          <Modal.Title>New Directory</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              <Form.Group controlId="frmDirectoryName">
                <Form.Label>Directory Name</Form.Label>
                <Form.Control
                  value={newDirectoryName}
                  onChange={e => setNewDirectoryName(e.target.value)}
                  type="text"
                  placeholder="Enter directory name"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="frmCountry">
                <Form.Label>Country</Form.Label>
                <Select value={selectedCountry} onChange={(opt: any) => setSelectedCountry(opt)} options={countries} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="frmCompany">
                <Form.Label>Company</Form.Label>
                <Select value={selectedCompany} onChange={(opt: any) => setSelectedCompany(opt)} options={companies} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="frmClient">
                <Form.Label>Client</Form.Label>
                <Select value={selectedClient} onChange={(opt: any) => setSelectedClient(opt)} options={clients} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="frmCampaignType">
                <Form.Label>Campaign Type</Form.Label>
                <Select
                  value={selectedCampaignType}
                  onChange={(opt: any) => setSelectedCampaignType(opt)}
                  options={campaignTypes}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowNewDirectory(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => onCreateNewDirectory()}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
