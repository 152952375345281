import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import ConfirmationAPI from "./confirmation.api";
import ManagerWidgetComponent from "../../../managerwidget.component";
import Form from "react-bootstrap/Form";
import "./confirmation.scss";
import Button from "react-bootstrap/Button";
import { actionChangedConfirmation } from "../../../redux/reducers/campaignmanagement/forms";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import Table from "react-bootstrap/Table";
import Accordion from "react-bootstrap/Accordion";
import LoadingScreen from "../../../loadingscreen.component";
import "./confirmation.scss";
import VippsConfirmationMethod from "./vippsconfirmationmethod.component";

import AccordionContext from 'react-bootstrap/AccordionContext';
import {useAccordionToggle} from 'react-bootstrap/AccordionToggle';
import classNames from 'classnames';

const VIPPS_CONFIRMATION_METHOD_ID = 6;

interface IConfirmationProps {
  formId: any;
}

interface ISelectOptions {
  label: string;
  value: string;
}

interface iDirectory {
  campaign_type_id: number;
  campaign_type_name: string;
  client_id: number;
  client_name: string;
  contact_directory_id: number;
  contact_directory_name: string;
  contacts_count: number;
  country_id: number;
  country_name: string;
  created_at: string;
  last_used_at: string;
}
declare var env: any;


export default function TabConfirmationComponent(props: IConfirmationProps) {
  const formId = props.formId;

  const currentEventKey = useContext(AccordionContext); // <-- Will update every time the eventKey changes.

  const dispatch = useDispatch();
  const apiRunning = useSelector((state) => state.shell.apiRunning);
  const availableSMSMergeFields = useSelector((state) => state.forms.smsMergeFields);
  const availableConfirmationMethods = useSelector((state) => state.forms.confirmations.availableConfirmationMethods);
  const [currentConfirmationMethod, setCurrentConfirmationMethod] = useState([]);
  const availableMethods = availableConfirmationMethods.map((m) => ({
    label: m.display_name,
    value: m.confirmation_method_id,
  }));
  const availableSenderIds = useSelector((state) => state.forms.customerSenders);
  const availableSenders = availableSenderIds.map((m) => ({ label: m.sender_name, value: m.customer_sender_id }));
  const formConfirmations = useSelector((state) => state.forms.confirmations.formConfirmations);

  const [directories, setDirectories] = useState<iDirectory[]>([{
    campaign_type_id: 0,
    campaign_type_name: "",
    client_id: 0,
    client_name: "",
    contact_directory_id: 0,
    contact_directory_name: "",
    contacts_count: 0,
    country_id: 0,
    country_name: "",
    created_at: "",
    last_used_at: ""
  }]);

  const [selectedDirectory, setSelectedDirectory] = useState({
    label: "", value: 0
  });

  
  const [selectedMethod, setSelectedMethod] = useState<ISelectOptions>({ label: "", value: "" });
  const [selectedSender, setSelectedSender] = useState<ISelectOptions>({ label: "", value: "" });

  const [accordionEventKey, setAccordionEventKey] = useState("");
  const [showMergeFieldSelector, setShowMergeFieldSelector] = useState(false);
  const [targetMergeField, setTargetMergeField] = useState<any>(undefined);
  const [targetFcId, setTargetFcId] = useState<any>(null);

  const [confirmDeleteTextVisible, setConfirmDeleteTextVisible] = useState(false);
  const [confirmDeleteTextTarget, setConfirmDeleteTextTarget] = useState<any>(null);
  const [confirmDeleteTextId, setConfirmDeleteTextId] = useState<any>(null);

  const [currentMethodData, setCurrentMethodData] = useState<iMethodData>({settings: [], fields: []});
  let dynamicFields:JSX.Element[] = [<div>uninit</div>];

  const [confirmDeleteConfirmationTarget, setConfirmDeleteConfirmationTarget] = useState<any>();
  useEffect(() => {
    ConfirmationAPI.loadFormConfirmations(dispatch, formId);
    ConfirmationAPI.loadFormCustomerSenders(dispatch, formId);
    ConfirmationAPI.loadSMSMergeFields(dispatch);
  }, []);

  useEffect(() => {
    fetchDirectories().then((res) =>  {
      console.log("setDirectories");
      console.log(res);
      setDirectories(res);
    });
  },[]);

  useEffect(() => {
    console.log("fields");
    console.log(currentMethodData.fields);
    console.log("fields");
  },[currentMethodData.fields]);

/*
  useEffect(() => {
    addFields(currentMethodData.settings);
  },[currentMethodData]);
*/
  const AccordionToggleActions = ({eventKey, callback}) => {
    const toggleOnClick = useAccordionToggle(eventKey, () => {
      callback(eventKey);
    });
    const isCurrentEventKey = currentEventKey === eventKey;
  
    return (
      <span      
        className={isCurrentEventKey?'custom-accordion-toggle fa fa-minus':'custom-accordion-toggle fa fa-plus'}
        onClick={toggleOnClick}
      />
    );
  }

/*  
 useEffect(() => {
    addFields(currentMethodData.settings);
  },[currentMethodData]);

const addFields = (settings: any) => {
    console.log("addfields");
    console.log(settings);
    if (settings !== null && settings['fields'] !== undefined && settings['fields'].length) {
      console.log("settings");
      console.log(settings);
      console.log("settings end");  
      
      settings['fields'].map((m) => {
        console.log("pushing");
        dynamicFields.push(<><div>foo foo</div></>);
        console.log(m);
    });
    } else {      
      dynamicFields.push(<div>ittno</div>);
    }
  }
  */

  const fetchDirectories = async () => {
    const res = await fetch(env._apiPath + "/listManagement/contactDirectories/quick", {
      method: "GET",
      credentials: "include"
    });
    const json = await res.json();
  
    if (json.status === "success") {
      return json.data;
    }
  
    throw json.message;
  }
  
  
  interface iMethodData {
    settings: any,
    fields: any
  }
  const AccordionCallback = (e: string) => {
    const idx = Number(e.substring(1));

    availableConfirmationMethods
      .filter((m) => (formConfirmations[idx].confirmation_method_id == m.confirmation_method_id))
      .map((m) => {
        setCurrentMethodData({ settings: m.settings, fields: formConfirmations[idx].settings });

        /*console.log("formConfirmations[idx].settings");
        console.log(formConfirmations[idx].settings);*/

        if (formConfirmations[idx].settings !== null && formConfirmations[idx].settings.directory !== null) {
/*          console.log("Inside set selected");
          console.log(formConfirmations[idx].settings.directory);
          console.log(directories);*/
          directories.filter((g) => (formConfirmations[idx].settings.directory == g.contact_directory_id))
            .map((d) => {
              setSelectedDirectory({ 
                label: d.campaign_type_name + ' - ' + d.contact_directory_name,
                value: d.contact_directory_id});
              console.log("sets directory to")
              console.log({ 
                label: d.campaign_type_name + ' - ' + d.contact_directory_name,
                value: d.contact_directory_id});
          });
        }
      });
  }

  const onAddConfirmation = () => {
    const confirmationMethodId = selectedMethod.value;
    const customerSenderId = selectedSender.value;
    ConfirmationAPI.createFormConfirmation(dispatch, formId, confirmationMethodId, customerSenderId);
    setSelectedMethod({ label: "", value: "" });
    setSelectedSender({ label: "", value: "" });
  };

  const onRemoveConfirmation = (cnMethod) => {
    ConfirmationAPI.deleteFormConfirmation(dispatch, formId, cnMethod.form_confirmation_id);
  };

  const onUpdateConfirmation = (cnMethod) => {
    ConfirmationAPI.updateFormConfirmation(dispatch, cnMethod.form_confirmation_id, cnMethod.customer_sender_id, currentMethodData.fields);
  };

  const setSelectedSenderForMethod = (cnMethod, opt) => {
    const cm = Object.assign({}, cnMethod);
    cm.customer_sender_id = "";
    cm.customer_sender_name = "";

    if (opt !== null) {
      cm.customer_sender_id = opt.value;
      cm.customer_sender_name = opt.label;
    }

    dispatch(actionChangedConfirmation(cm));
  };

  const setSelectedSmsTextForMethod = (cnMethod, opt) => {
    const cm = Object.assign({}, cnMethod);
    cm.confirmation_text_type_id = "";
    cm.confirmation_text_type_name = "";

    if (opt !== null) {
      cm.confirmation_text_type_id = opt.value;
      cm.confirmation_text_type_name = opt.label;
    }

    dispatch(actionChangedConfirmation(cm));
  };

  const setSelectedSmsTextValueForMethod = (cnMethod, text) => {
    const cm = Object.assign({}, cnMethod);
    cm.confirmation_text = "";

    if (text !== null) {
      cm.confirmation_text = text;
    }

    dispatch(actionChangedConfirmation(cm));
  };

  const onAddTextType = (cnMethod) => {
    ConfirmationAPI.createFormConfirmationText(
      dispatch,
      formId,
      cnMethod.form_confirmation_id,
      cnMethod.confirmation_text_type_id,
      cnMethod.confirmation_text
    );
    const cm = Object.assign({}, cnMethod);
    cm.confirmation_text = "";
    dispatch(actionChangedConfirmation(cm));
  };

  const toggleConfirmDeleteConfirmationVisible = (cnMethod) => {
    const cm = Object.assign({}, cnMethod);
    cm.confirmDelete = cnMethod.confirmDelete ? false : true;
    dispatch(actionChangedConfirmation(cm));
  };

  const onRemoveConfirmationText = () => {
    ConfirmationAPI.deleteConfirmationText(dispatch, formId, confirmDeleteTextId);
    setConfirmDeleteTextVisible(false);
  };

  const onEditConfirmationText = (cnMethod, fct) => {
    const cm = Object.assign({}, cnMethod);
    cm.confirmation_text = fct.confirmation_text;
    cm.confirmation_text_type_id = fct.confirmation_text_type_id;
    cm.confirmation_text_type_name = fct.confirmation_text_type_name;
    cm.confirmation_text_edit_id = fct.form_confirmation_text_id;
    dispatch(actionChangedConfirmation(cm));
  };

  let formatField=0;
  const getFormattedFields = (key, inData, cnMethod) => {
    let outData = [<></>];
    let type = '';
    switch (inData[key].type) {
      case 'directory':
        type = 'number';
        formatField++;
        outData.push(
        <li><Form.Group controlId="form{formatField}">
            <Form.Label>{inData[key].title}</Form.Label>
            <Button
            style={{ float: "right" }}
            variant="secondary"
            size="sm"
            onClick={() => onUpdateConfirmation(cnMethod)}
          >
            <span className="fa fa-save" />
          </Button>
            <Select
                value={selectedDirectory}
                onChange={(opt: any) => { 
                  setSelectedDirectory(opt);
                  if (currentMethodData.fields == null) currentMethodData.fields = {};
                  currentMethodData.fields[key] = opt.value;
                }}
              options={directories.map(d => (
                { label: d.campaign_type_name + ' - ' + d.contact_directory_name,
                  value: d.contact_directory_id}))}
              isClearable={false}
            />
          </Form.Group>
          </li>
          
          );
        break;
      case 'int':
        type = 'number';
        formatField++;
        outData.push(
        <li><Form.Group controlId="form{formatField}">
            <Form.Label>{inData[key].title}</Form.Label>
            <Button
            style={{ float: "right" }}
            variant="secondary"
            size="sm"
            onClick={() => onUpdateConfirmation(cnMethod)}
          >
            <span className="fa fa-save" />
          </Button>
            <Form.Control 
              type="number" 
              placeholder="" 
              onChange={(event) => currentMethodData.fields[key] = event.target.value }
              value={currentMethodData.fields[key]} 
            />
          </Form.Group></li>);
        break;
      case 'string':
        type = 'text';
        outData.push(
        <li><Form.Group controlId="form{formatField}">
            <Form.Label>{inData[key].title}</Form.Label>
            <Button
            style={{ float: "right" }}
            variant="secondary"
            size="sm"
            onClick={() => onUpdateConfirmation(cnMethod)}
          >
            <span className="fa fa-save" />
          </Button>
            <Form.Control 
              type="text" 
              placeholder="" 
              onChange={(event) => currentMethodData.fields[key] = event.target.value} 
              value={currentMethodData.fields !== null?currentMethodData.fields[key]:''} 
            />
          </Form.Group></li>);
        break;      
    }
    formatField++;
    return outData;
    /*
                              
                          <div id={key}>
                            
                            <h3>{currentMethodData.settings.fields[key].title}</h3>
                            {switch (.type) {(
                              <input type="number" />

    */
  }
  const onCancelEditTextType = (cnMethod) => {
    const cm = Object.assign({}, cnMethod);
    cm.confirmation_text = "";
    cm.confirmation_text_type_id = "";
    cm.confirmation_text_type_name = "";
    cm.confirmation_text_edit_id = null;
    dispatch(actionChangedConfirmation(cm));
  };

  const onEditTextType = (cnMethod) => {
    const confText = cnMethod.confirmation_text;
    const textEditId = cnMethod.confirmation_text_edit_id;
    ConfirmationAPI.editConfirmationText(dispatch, formId, textEditId, confText);
    onCancelEditTextType(cnMethod);
  };

  const onSelectAccordionKey = (eventKey) => {
    setAccordionEventKey(eventKey);
  };

  const addSMSMergeField = (formConfirmationId, field) => {
    const cnMethod = formConfirmations.find((m) => m.form_confirmation_id === formConfirmationId);
    if (!cnMethod) {
      return;
    }

    const cm = Object.assign({}, cnMethod);
    if (cm.confirmation_text === undefined) {
      cm.confirmation_text = "";
    }

    cm.confirmation_text += field.field_name;
    dispatch(actionChangedConfirmation(cm));
    setShowMergeFieldSelector(false);
  };

  return (
    <ManagerWidgetComponent>
      <Row>
        <Col lg={2} className="available-methods">
          <Form.Group controlId="frmMethod">
            <Form.Label>Add New Method</Form.Label>
            <Select
              value={selectedMethod}
              onChange={(opt: any) => setSelectedMethod(opt)}
              options={availableMethods}
              isClearable={true}
            />
          </Form.Group>

          <Form.Group controlId="frmSmsSender">
            <Form.Label>SMS Sender</Form.Label>
            <Select
              value={selectedSender}
              onChange={(opt: any) => setSelectedSender(opt)}
              options={availableSenders}
              isClearable={true}
            />
          </Form.Group>

          <div style={{ textAlign: "right", marginTop: 20 }}>
            <Button
              variant="secondary"
              size="sm"
              onClick={() => onAddConfirmation()}
              disabled={
                selectedSender === null ||
                selectedMethod === null ||
                selectedSender.value === "" ||
                selectedMethod.value === ""
              }
            >
              Add
            </Button>
          </div>
        </Col>

        <Col className="used-methods">
          {apiRunning ? <LoadingScreen /> : null}

          <Accordion onSelect={onSelectAccordionKey}>
            {formConfirmations.map((cnMethod: any, idx: any) => {
              const fcid = cnMethod.form_confirmation_id;

              const availableTextTypes = cnMethod.available_confirmation_text_types.map((cTextType, ttidx) => {
                return {
                  label: cTextType.confirmation_text_type_name + (cTextType.mandatory === 1 ? " *" : ""),
                  value: cTextType.confirmation_text_type_id,
                };
              });
/* directory: type: int, title: directory */
              return (
                <div className="method-item" key={idx}>
                  <Row>
                    <Col>
                      
                      <AccordionToggleActions callback={(e) => { AccordionCallback(e); }} eventKey={"e" + idx} />

                       <span className="title">{cnMethod.display_name}</span>

                      {accordionEventKey !== "e" + idx ? (
                        <Form.Text>
                          {cnMethod.form_confirmation_texts.length} message step
                          {cnMethod.form_confirmation_texts.length === 1 ? "" : "s"}.
                        </Form.Text>
                      ) : null}
                      {cnMethod.complete === false ? (
                        <Form.Text style={{ color: "red" }}>
                          This confirmation method is missing mandatory text messages.
                        </Form.Text>
                      ) : null}
                    </Col>
                  </Row>
                  <Accordion.Collapse eventKey={"e" + idx}>
                    <>
                      <Row>
                        <Col lg={8}>
                          {/* Special handling for confirmation methods requiring advanced settings*/}
                          {cnMethod.confirmation_method_id === VIPPS_CONFIRMATION_METHOD_ID ? (
                            <VippsConfirmationMethod
                              formId={props.formId}
                              method={cnMethod}
                              key={idx}
                            />
                          ) : (
                            <ol>
                              {cnMethod.form_confirmation_texts.map((fct) => {
                                return (
                                  <li>
                                    <div>
                                      {fct.confirmation_text_type_name}
                                      <Button
                                        style={{ float: "right" }}
                                        variant="danger"
                                        size="sm"
                                        disabled={fct.mandatory === 1}
                                        onClick={(e) => {
                                          setConfirmDeleteTextVisible(true);
                                          setConfirmDeleteTextTarget(e.target);
                                          setConfirmDeleteTextId(fct.form_confirmation_text_id);
                                        }}
                                      >
                                        <span className="fa fa-trash" />
                                      </Button>

                                      <Overlay show={confirmDeleteTextVisible} target={confirmDeleteTextTarget}>
                                        <Popover id={"popover-confirm-" + idx}>
                                          <Popover.Title as="h3">Confirm?</Popover.Title>
                                          <Popover.Content>
                                            <Row>
                                              <Col>
                                                <Button
                                                  size="sm"
                                                  variant="danger"
                                                  onClick={() => onRemoveConfirmationText()}
                                                >
                                                  Delete
                                                </Button>
                                              </Col>
                                              <Col>
                                                <Button
                                                  size="sm"
                                                  variant="secondary"
                                                  onClick={() => setConfirmDeleteTextVisible(false)}
                                                >
                                                  Cancel
                                                </Button>
                                              </Col>
                                            </Row>
                                          </Popover.Content>
                                        </Popover>
                                      </Overlay>

                                      <Button
                                        style={{ float: "right" }}
                                        variant="secondary"
                                        size="sm"
                                        onClick={() => onEditConfirmationText(cnMethod, fct)}
                                      >
                                        <span className="fa fa-edit" />
                                      </Button>
                                    </div>
                                    <div>
                                      <i>{fct.confirmation_text}</i>
                                    </div>
                                  </li>
                                );
                              })}
                              {currentMethodData.settings !== null && currentMethodData.settings.fields !== undefined?(
                                Object.keys(currentMethodData.settings.fields).map(function(key) {                           
                                  return getFormattedFields(key, currentMethodData.settings.fields, cnMethod)
                                  })):null}
                            </ol>
                          )}
                        </Col>
                        <Col style={{ borderLeft: "1px solid #eee" }}>
                          <Row>
                            <Col>
                              <Form.Group controlId="frmSmsSender">
                                <Form.Label>SMS Sender</Form.Label>
                                <Select
                                  value={{ label: cnMethod.customer_sender_name, value: cnMethod.customer_sender_id }}
                                  onChange={(opt: any) => setSelectedSenderForMethod(cnMethod, opt)}
                                  options={availableSenders}
                                  isClearable={false}
                                />
                              </Form.Group>
                              <div style={{ textAlign: "right" }}>
                                <Button variant="secondary" size="sm" onClick={() => onUpdateConfirmation(cnMethod)}>
                                  Update
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          {availableTextTypes.length > 0 || cnMethod.confirmation_text_edit_id ? (
                            <Row>
                              <Col>
                                <Form.Group controlId="frmSmsTextType">
                                  <Form.Label>SMS Text Type</Form.Label>
                                  {cnMethod.confirmation_text_edit_id ? (
                                    <div>
                                      <i>{cnMethod.confirmation_text_type_name}</i>
                                    </div>
                                  ) : (
                                    <Select
                                      value={{
                                        label: cnMethod.confirmation_text_type_name,
                                        value: cnMethod.confirmation_text_type_id,
                                      }}
                                      onChange={(opt: any) => setSelectedSmsTextForMethod(cnMethod, opt)}
                                      options={availableTextTypes}
                                      isClearable={false}
                                    />
                                  )}
                                </Form.Group>
                                <Form.Group controlId="frmSmsText">
                                  <Row>
                                    <Col>
                                      <Form.Label>SMS Text</Form.Label>
                                    </Col>
                                    <Col className="merge-field">
                                      <Overlay
                                        show={showMergeFieldSelector}
                                        onHide={() => setShowMergeFieldSelector(false)}
                                        rootClose={true}
                                        target={targetMergeField}
                                        placement="left"
                                      >
                                        <Popover id="popover-basic">
                                          <Popover.Title as="h3">Insert Merge Field</Popover.Title>
                                          <Popover.Content style={{ maxHeight: 300, overflow: "scroll" }}>
                                            <Table striped bordered hover size="sm">
                                              <tbody className="merge-fields">
                                                {availableSMSMergeFields.map((field, fidx) => {
                                                  return (
                                                    <tr onClick={() => addSMSMergeField(targetFcId, field)}>
                                                      <td>{field.field_name}</td>
                                                      <td>{field.description}</td>
                                                    </tr>
                                                  );
                                                })}
                                              </tbody>
                                            </Table>
                                          </Popover.Content>
                                        </Popover>
                                      </Overlay>
                                      <span
                                        onClick={(e) => {
                                          setShowMergeFieldSelector(true);
                                          setTargetMergeField(e.target);
                                          setTargetFcId(fcid);
                                        }}
                                      >
                                        Insert Merge Field
                                      </span>
                                    </Col>
                                  </Row>
                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Enter message..."
                                    value={cnMethod.confirmation_text}
                                    onChange={(e: any) => setSelectedSmsTextValueForMethod(cnMethod, e.target.value)}
                                  />
                                </Form.Group>
                                <div className="editor-btns">
                                  {cnMethod.confirmation_text_edit_id ? (
                                    <>
                                      <Button
                                        variant="secondary"
                                        size="sm"
                                        onClick={() => onCancelEditTextType(cnMethod)}
                                      >
                                        Cancel
                                      </Button>

                                      <Button
                                        variant="secondary"
                                        size="sm"
                                        onClick={() => onEditTextType(cnMethod)}
                                        disabled={
                                          cnMethod.confirmation_text === undefined ||
                                          cnMethod.confirmation_text.length === 0
                                        }
                                      >
                                        Update
                                      </Button>
                                    </>
                                  ) : (
                                    <Button
                                      variant="secondary"
                                      size="sm"
                                      onClick={() => onAddTextType(cnMethod)}
                                      disabled={
                                        cnMethod.confirmation_text_type_id === undefined ||
                                        cnMethod.confirmation_text_type_id === "" ||
                                        cnMethod.confirmation_text === undefined ||
                                        cnMethod.confirmation_text.length === 0
                                      }
                                    >
                                      Add Text
                                    </Button>
                                  )}
                                </div>
                              </Col>
                            </Row>
                          ) : null}
                        </Col>
                      </Row>

                      <div className="button-toolbar">
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={(e) => {
                            setConfirmDeleteConfirmationTarget(e.target);
                            toggleConfirmDeleteConfirmationVisible(cnMethod);
                          }}
                        >
                          Remove Confirmation
                        </Button>
                        <Overlay show={cnMethod.confirmDelete} target={confirmDeleteConfirmationTarget}>
                          <Popover id={"popover-confirm-" + idx}>
                            <Popover.Title as="h3">Confirm?</Popover.Title>
                            <Popover.Content>
                              <Row>
                                <Col>
                                  <Button size="sm" variant="danger" onClick={() => onRemoveConfirmation(cnMethod)}>
                                    Delete
                                  </Button>
                                </Col>
                                <Col>
                                  <Button
                                    size="sm"
                                    variant="secondary"
                                    onClick={() => toggleConfirmDeleteConfirmationVisible(cnMethod)}
                                  >
                                    Cancel
                                  </Button>
                                </Col>
                              </Row>
                            </Popover.Content>
                          </Popover>
                        </Overlay>
                      </div>
                    </>
                  </Accordion.Collapse>
                </div>
              );
            })}
          </Accordion>
        </Col>
      </Row>
    </ManagerWidgetComponent>
  );
}