import React from "react";
import { Link } from "react-router-dom";
import { Typography, Breadcrumbs, makeStyles, createStyles, Theme, Divider, Grid } from "@material-ui/core";

export interface TitlebarProps {
  title: any;
  description: any;
  breadcrumbs: any;
  variant?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titlebar: {
      marginTop: 25,
      marginBottom: 25
    },
    flexGrow: {
      flexGrow: 1
    },
    breadcrumb: {
      display: 'flex'
    },
    divider: {
      marginTop: 5
    },
    legacyTitle: {
      marginBottom: 30
    }
  })
);

export default function TitlebarComponent(props: TitlebarProps) {
  const classes = useStyles();

  if (props.variant === "material") {
    return (
      <div className={classes.titlebar}>
        <Grid container>
          <Grid item md={6}>
            <Typography variant="h3" style={{ fontSize: "1.5rem" }}>
              {props.title}
            </Typography>
          </Grid>
          <Grid item md={6} className={classes.breadcrumb}>
            <div className={classes.flexGrow} />
            <Breadcrumbs aria-label="breadcrumb">
              {props.breadcrumbs.map((b: any, k: any) => {
                const isActive = props.breadcrumbs.length === k + 1;
                return isActive ? (
                  <Typography color="textPrimary">{b.title}</Typography>
                ) : (
                  <Link to={b.link}>{b.title}</Link>
                );
              })}
            </Breadcrumbs>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
      </div>
    );
  }

  return (
    <div className={`row page-title clearfix ${classes.legacyTitle}`}>
      <div className="page-title-left">
        <h6 className="page-title-heading mr-0 mr-r-5">{props.title}</h6>
        <p className="page-title-description mr-0 d-none d-md-inline-block">{props.description}</p>
      </div>
      <div className="page-title-right d-inline-flex">
        <ol className="breadcrumb">
          {props.breadcrumbs.map((b: any, k: any) => {
            const isActive = props.breadcrumbs.length === k + 1 ? " active" : "";

            return (
              <li className={"breadcrumb-item" + isActive} key={k}>
                {isActive ? b.title : <Link to={b.link}>{b.title}</Link>}
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
}
