import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "react-vis/dist/style.css";
import { XYPlot, VerticalBarSeries, HorizontalGridLines, XAxis, YAxis } from "react-vis";

interface FilterPerformanceProps {
  filter: any;
  show: any;
  onHide?: any;
}

export default function FilterPerformanceComponent(props: FilterPerformanceProps) {
  const [filterData, setFilterData] = useState([]);
  const [filterXTitles, setFilterXTitles] = useState([]);

  useEffect(() => {
    if (props.filter) {
      const data: any = [];
      const xtitles: any = [];

      let e = 0;
      for (const x in props.filter.exported_contacts) {
        const y = props.filter.exported_contacts[x];
        data.push({ x: e++, y });
        xtitles.push(x);
      }
      setFilterData(data);
      setFilterXTitles(xtitles);
    }
  }, [props.filter]);

  const xTickFormat = (value: any, index: any, scale: any, tickTotal: any) => {
    return (
      <tspan>
        <tspan x="0" dy="1em">
          {filterXTitles[index]}
        </tspan>
      </tspan>
    );
  };

  let yMax = 0;
  filterData.forEach((val: any) => {
    yMax = Math.max(val.y, yMax);
  });
  yMax += 10;

  return (
    <Modal size="lg" centered show={props.show} onHide={props.onHide}>
      <Modal.Header>
        <Modal.Title>Performance of {props.filter ? props.filter.contact_directory_filter_name : ""}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <XYPlot height={250} width={740} margin={{ bottom: 100 }} yDomain={[0, yMax]} xType="ordinal">
          <HorizontalGridLines />
          <VerticalBarSeries data={filterData} />
          <XAxis title="Day" tickLabelAngle={-40} tickFormat={xTickFormat} />
          <YAxis title="Contacts Delivered" />
        </XYPlot>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={() => props.onHide()}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
