import React, { useState, useEffect } from "react";
import TitlebarComponent from "../../titlebar.component";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
//import Button from "react-bootstrap/Button";
import UserSettings from "../../userSettings";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-enterprise/chartsModule";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { GridReadyEvent, Column, GridApi, ColumnApi } from "ag-grid-community";
import {
  FirstDataRenderedEvent,
  RowDoubleClickedEvent,
  CellClickedEvent,
  SortChangedEvent,
  PaginationChangedEvent,
} from "ag-grid-community/dist/lib/events";

import {
  Button,
  Grid,
  Box,
  CardContent,
  Card,
  CardActions,
  makeStyles,
  Theme,
  createStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";

import { registerLocale, setDefaultLocale } from "react-datepicker";
import { format } from "date-fns/esm";
import nb from "date-fns/locale/nb";
import "react-datepicker/dist/react-datepicker.css";
import { AgToolbarButton } from "../../agGridUtils";
import Alert from "react-bootstrap/Alert";
import { useSelector, useDispatch, batch } from "react-redux";
import { loadOrderHeaders, loadOrderReport, loadOrderDetails, loadOrderActivities, IOrder } from "./orders/orders2.api";
import { IOrderHeader, IOrderReport, actionOpenDetails } from "../../redux/reducers/reports/orders";
import ReportSearchFilter from "./orders/reportSearchFilter.component";
import AgTransferStatusCell from "./orders/agTransferStatusCell.component";
import {
  actionToggleFilterView,
  actionSelectClient,
  actionSelectDepartment,
  actionSelectCampaignType,
  actionSelectCampaign,
  actionSelectCompany,
  actionSelectStartDate,
  actionSelectEndDate,
} from "../../redux/reducers/shell";
import OrderDetails from "./orders/details.component";
import ClientSelector from "../shell/clientselector.component";
import CompanySelector from "../shell/companyselector.component";
import DepartmentSelector from "../shell/departmentselector.component";
import PeriodSelector from "../shell/periodselector.component";
import CampaignTypeSelector from "../shell/campaigntypeselector.component";
import CampaignSelector from "../shell/campaignselector.component";
import { useWindowSize, useDebounce } from "../../custom.hooks";
import LoadingScreen from "../../loadingscreen.component";
import "./orders/orders.scss";
import { CSVLink } from "react-csv";
import { CircularProgress } from "@material-ui/core";

declare var env: any;

interface IAlertMessage {
  show: boolean;
  variant: "success" | "primary" | "secondary" | "danger" | "warning" | "info" | "dark" | "light" | undefined;
  message: string;
}

interface IReportProps {
  dispatch: any;
  currentPage: number;
  pageSize: number;
  selectedClient: string;
  selectedCompany: string;
  selectedStartDate: Date;
  selectedEndDate: Date;
  selectedCampaignType: string;
  selectedCampaign: string;
  selectedDepartment: string;
  sortColumns: any;
  reportFilter: any;
  exportToCsv: any;
}

registerLocale("nb", nb);
setDefaultLocale("nb");

const orderHeadersToColumnsDefs = (header: IOrderHeader) => {
  const colDef: any = {
    headerName: header.display_name,
    field: header.field_name,
    hide: !header.visible,
    filterFramework: ReportSearchFilter,
  };

  switch (header.datatype) {
    case "int":
      colDef.type = "numericColumn";
      break;
    case "decimal":
      colDef.type = "numericColumn";
      colDef.valueGetter = (params) => {
        if (!params.data) {
          return;
        }

        let value = params.data[params.colDef.field];
        if (!value || value.length === 0) {
          return;
        }

        if (value.startsWith(".")) {
          value = "0" + value;
        }

        try {
          value = parseFloat(value).toFixed(2);
        } catch (e) {
          value = "??";
        }

        return value;
      };
      break;
    case "datetime":
      colDef.valueGetter = (params) => {
        if (!params.data) {
          return;
        }

        let value = params.data[params.colDef.field];
        if (!value || value.length === 0) {
          return;
        }

        value = format(new Date(value), "yyyy-MM-dd HH:mm");
        return value;
      };
      break;
    case "transfer_status":      
      colDef.cellRenderer = "agTransferStatusCell";
      break;
    case "details":
      colDef.cellRenderer = "agCellButton";
      break;
  }

  return colDef;
};

class ViewportDatasource {
  params: any;
  setVpParams: any;
  setVpRange: any;

  constructor(setVpParams, setVpRange) {
    this.setVpParams = setVpParams;
    this.setVpRange = setVpRange;
  }

  init(params) {
    this.setVpParams(params);
    this.params = params;
  }

  setViewportRange(firstRow, lastRow) {
    this.setVpRange({ firstRow, lastRow });
  }

  destroy() {}
}

/**
 * TODO:
 * 	Params:
 * ALL_FIELDS er en spesiell verdi vi bruker til søk på alle.
 *
 * 		csvdownload brukes hvis vi eksporterer CSV.
 *
 *   Implementere datatyper
 *
 */
const runReport = (props: IReportProps) => {
  if (!props) {
    return;
  }

  let query = {
    client_id: props.selectedClient,
    company_id: props.selectedCompany,
    from_date: format(props.selectedStartDate, "yyyy-MM-dd"),
    to_date: format(props.selectedEndDate, "yyyy-MM-dd"),
    per_page: props.pageSize,
    page: props.currentPage,
    campaign_type_id: props.selectedCampaignType,
    campaign_id: props.selectedCampaign,
    department_id: props.selectedDepartment,
    csvdownload: props.exportToCsv ? 1 : 0,
  };

  if (props.sortColumns !== undefined) {
    let i = 0;
    props.sortColumns.forEach((c: Column) => {
      if (c.getSort() === null) {
        return;
      }
      query["order_by[" + i + "][field_name]"] = c.getColDef().field;
      query["order_by[" + i + "][direction]"] = c.getSort();
      i++;
    });
  }

  if (props.reportFilter) {
    let y = 0;
    props.reportFilter.forEach((f: any) => {
      query["filter_by[" + y + "][field_name]"] = f.field;
      query["filter_by[" + y + "][value]"] = f.filter;
      y++;
    });
  }

  console.log("RunReport");
  loadOrderReport(props.dispatch, query);
};
// for eksport av alle ordre
// const orderSkeleton = {
//   current_page: 1,
//   data: [{
//     agent_fullname: "",
//     agent_login_name: "",
//     birthdate: "",
//     campaign_name: "",
//     city: "",
//     client_name: "",
//     company_name: "",
//     company_vat: "",
//     completed_at: null,
//     confirmed_at: "",
//     email: "",
//     external_status: null,
//     firstname: "",
//     house_no: "",
//     last_api_transfer: null,
//     last_api_transfer_status: null,
//     lastname: "",
//     mobile: "",
//     order_details_count: "",
//     order_done_at: "",
//     order_id: 0,
//     phone: "",
//     regretted_at: null,
//     regretted_reason: null,
//     regretted_user: null,
//     rejected_at: null,
//     rejected_reason: null,
//     rejected_user: null,
//     shortname: "",
//     street: "",
//     total_price: "",
//     transferred: "",
//     zip: "",
//   }],
//   first_page_url: env._apiPath + '/ordersNew?page=1',
//   from: 1,
//   last_page: 2,
//   last_page_url: env._apiPath + '/ordersNew?page=2',
//   next_page_url: env._apiPath + '/ordersNew?page=2',
//   path: env._apiPath + '/ordersNew',

//   per_page: 1000,
//   prev_page_url: "",
//   to: 1000,
//   total: 1156,
// }
/*
const headers = [
  { label: "Order ID", key: "order_id" },
  { label: "Order Date", key: "order_done_at" },
  { label: "Campaign", key: "campaign_name" },
  { label: "shortname", key: "shortname" },
  { label: "Agent Name", key: "agent_fullname" },
  { label: "Login Name", key: "agent_login_name" },
  { label: "firstname", key: "firstname" },
  { label: "lastname", key: "lastname" },
  { label: "phone", key: "phone" },
  { label: "mobile", key: "mobile" },
  { label: "email", key: "email" },
  { label: "confirmed_at", key: "confirmed_at" },
  { label: "rejected_At", key: "rejected_at" },
  { label: "regretted_at", key: "regretted_at" },
  { label: "transferred", key: "transferred" },
  { label: "total price", key: "total_price" },
  { label: "order details count", key: "order_details_count" },
  { label: "F.dato", key: "birthdate" },
  { label: "By", key: "city" },
  { label: "street", key: "street" },
  { label: "house_no", key: "house_no" },
  { label: "zip", key: "zip" },
  { label: "Client", key: "client_name" },
  { label: "Company", key: "company_name" },
  { label: "Company vat", key: "company_vat" },
  { label: "completed at", key: "completed_at" },
  { label: "ext status", key: "external_status" },
  { label: "last_api_transfer", key: "last_api_transfer" },
  { label: "regretted_Reason", key: "regretted_reason" },
  { label: "regretted_user", key: "regretted_user" },
  { label: "rejected_reason", key: "rejected_reason" },
  { label: "rejected_user", key: "rejected_user" },
]; */
const headersSkandia = [
  { label: "Order ID", key: "order_id" },
  { label: "Order Date", key: "order_done_at" },
  { label: "Campaign", key: "campaign_name" },
  { label: "shortname", key: "shortname" },
  { label: "Login Name", key: "agent_login_name" },
  { label: "firstname", key: "firstname" },
  { label: "lastname", key: "lastname" },
  { label: "phone", key: "phone" },
  { label: "mobile", key: "mobile" },
  { label: "email", key: "email" },
  { label: "NIN", key: "custom_nin" },
  { label: "F.dato", key: "birthdate" },
  { label: "Overtakelsesdato", key: "custom_overtakelsesdato" },
  { label: "Utsatt oppstart", key: "custom_utsatt_oppstart" },
  { label: "Målernummer", key: "custom_maalernummer" },
  { label: "Målepunktid", key: "custom_mpid" },
  { label: "DSF Status", key: "custom_dsf_hit" },
  { label: "Elhub Status", key: "custom_elhub_hit" },
  { label: "Måler", key: "custom_settlementMethodType_396" },
  { label: "Epostfaktura", key: "custom_epostfaktura" },
  { label: "Samlefaktura", key: "custom_samlefaktura" },
  { label: "Billing city", key: "custom_billing_city" },
  { label: "Billing street", key: "custom_billing_street" },
  { label: "Billing house no", key: "custom_billing_house_no" },
  { label: "Billing house letter", key: "custom_billing_house_letter" },
  { label: "Billing zip", key: "custom_billing_zip" },
  { label: "House letter", key: "custom_house_letter" },
  { label: "By", key: "city" },
  { label: "street", key: "street" },
  { label: "house_no", key: "house_no" },
  { label: "zip", key: "zip" },
  { label: "Comment", key: "custom_kommentar" },
  { label: "Agent Name", key: "agent_fullname" },
  { label: "total price", key: "total_price" },
  { label: "order details count", key: "order_details_count" },
  { label: "Client", key: "client_name" },
  { label: "Company", key: "company_name" },
  { label: "Company vat", key: "company_vat" },
  { label: "ext status", key: "external_status" },
  { label: "last_api_transfer", key: "last_api_transfer" },
  { label: "last_api_transfer_status", key: "last_api_transfer_status" },
  { label: "completed at", key: "completed_at" },
  { label: "transferred", key: "transferred" },
  { label: "confirmed_at", key: "confirmed_at" },
  { label: "regretted_at", key: "regretted_at" },
  { label: "regretted_Reason", key: "regretted_reason" },
  { label: "regretted_user", key: "regretted_user" },
  { label: "rejected_At", key: "rejected_at" },
  { label: "rejected_reason", key: "rejected_reason" },
  { label: "rejected_user", key: "rejected_user" },
];
declare var env: any;
// end eksport for alle ordre

export default function OrdersComponent2() {
  const dispatch = useDispatch();
  const apiRunning = useSelector((state) => state.shell.apiRunning);
  const selectedClient = useSelector((state) => state.shell.selectedClient);
  const selectedCompany = useSelector((state) => state.shell.selectedCompany);
  const selectedDepartment = useSelector((state) => state.shell.selectedDepartment);
  const selectedCampaignType = useSelector((state) => state.shell.selectedCampaignType);
  const selectedCampaign = useSelector((state) => state.shell.selectedCampaign);
  const selectedStartDate = useSelector((state) => state.shell.selectedStartDate);
  const selectedEndDate = useSelector((state) => state.shell.selectedEndDate);
  const hideFilters = useSelector((state) => state.shell.hideFilters);
  const orderHeaders = useSelector((state) => state.reports.orders.headers);
  const orderActivities = useSelector((state) => state.reports.orders.orderDetails.activities);
  const orderReport: IOrderReport = useSelector((state) => state.reports.orders.report);
  const [showTransferStatus, setShowTransferStatus] = useState(false);

  const [show, setShow] = useState(false);

  const [gridAPI, setGridAPI] = useState<GridApi>();
  const [columnAPI, setColumnAPI] = useState<ColumnApi>();
  const [vpParams, setVpParams] = useState<any>();
  const [sortColumns, setSortColumns] = useState<Column[]>();
  const [reportFilter, setReportFilter] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [viewportRange, setViewportRange] = useState({ firstRow: 0, lastRow: 0 });

  const selectionSettings = UserSettings.getOption(UserSettings.REPORT_ORDER_SELECTIONS, {
    columnSize: "sizeColumnsToFit",
  });
  const [columnSizeSetting, setColumnSizeSetting] = useState(selectionSettings.columnSize);

  const defaultStartDate = new Date();
  defaultStartDate.setDate(1);
  const defaultEndDate = new Date();

  const [apiMessage, setApiMessage] = useState<IAlertMessage>({ show: false, variant: "success", message: "" });
  const [apiAlertTimeout, setApiAlertTimeout] = useState<any>(0);

  const defaultColdumnDefs = orderHeaders.map(orderHeadersToColumnsDefs);
  const [columnDefs, setColumnDefs] = useState(defaultColdumnDefs);

  const [reportQuery, setReportQuery] = useState<any>(null);
  const debouncedReportQuery = useDebounce(reportQuery, 400);

  const windowSize = useWindowSize();

  // greier for å eksportere alle ordre
  const [reportsForCSV, setReportsForCSV] = useState<IOrder[]>([]);
  const [showExportAllReportsModal, setShowExportAllReportsModal] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  let csvlinkProps = {
    data: reportsForCSV,
    headers: headersSkandia,
    filename: `All_orders.csv`
  }
  const loadOrderReport3 = async () => {
    console.log("loadORderReport3");
    const req = { 
      client_id: selectedClient ? selectedClient.value : 0,
      company_id: selectedCompany ? selectedCompany.value : 0,
      from_date: format(selectedStartDate, "yyyy-MM-dd"),
      to_date: format(selectedEndDate, "yyyy-MM-dd"),
      per_page: orderReport ? orderReport.total : 5000,
      page: 1,
      campaign_type_id: selectedCampaignType ? selectedCampaignType.value : 0,
      campaign_id: selectedCampaign ? selectedCampaign.value : 0 ,
      department_id: selectedDepartment ? selectedDepartment.value : 0,
      csvdownload: 0, }
    const res = await fetch(env._apiPath + "/ordersNewer?" + `client_id=${req.client_id}&company_id=${req.company_id}&from_date=${req.from_date}&to_date=${req.to_date}&per_page=${req.per_page}&page=${req.page}&campaign_type_id=${req.campaign_type_id}&campaign_id=${req.campaign_id}&department_id=${req.department_id}&csvdownload=${req.csvdownload}` ,{
//    const res = await fetch(env._apiPath + "/ordersNew?" + `client_id=${req.client_id}&company_id=${req.company_id}&from_date=${req.from_date}&to_date=${req.to_date}&per_page=${req.per_page}&page=${req.page}&campaign_type_id=${req.campaign_type_id}&campaign_id=${req.campaign_id}&department_id=${req.department_id}&csvdownload=${req.csvdownload}` ,{
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    const json = await res.json();
  
    if(json.status === "success") {
        return json;
    }
  
    throw json.message;
  }
  const fetchAllOrdersForExport = () => {
      console.log("fetchAllOrdersForExport");
    setExportLoading(true);
    setShowExportAllReportsModal(true);
    loadOrderReport3()
    .then(res => setReportsForCSV(res.data.data))
    .finally(() => setExportLoading(false))
  }
  // end eksporter alle ordre
  
  useEffect(() => {
    console.log('loadORderHeaders');

    const clientId = selectedClient ? selectedClient.value : 0;
    loadOrderHeaders(dispatch, clientId,orderReport);
  }, [selectedClient, dispatch]);
/*
  useEffect(() => {
    if (orderReport != null) {
      const clientId = selectedClient ? selectedClient.value : 0;
      loadOrderHeaders(dispatch, clientId,orderReport);
    }
  }, [selectedClient, orderReport, dispatch]);
*/

  useEffect(() => {
    console.log('new order headers');
    if (orderHeaders.length === 0) {
      return;
    }
    const oheaders = Object.assign([], orderHeaders);
    oheaders.push({
      display_name: "Details",
      field_name: "",
      datatype: "details",
      visible: true,
      searchable: false,
    });
    const newOrderHeaders = oheaders.map(orderHeadersToColumnsDefs);
    setColumnDefs(newOrderHeaders);
  }, [orderHeaders]);

  useEffect(() => {
    console.log('setShow');
    setShow(true);
  },[orderActivities])

  useEffect(() => {
    console.log("this effect ran report-query");
    setReportQuery({
      dispatch: dispatch,
      currentPage: currentPage,
      pageSize: pageSize,
      selectedClient: selectedClient ? selectedClient.value : 0,
      selectedCompany: selectedCompany ? selectedCompany.value : 0,
      selectedStartDate: selectedStartDate,
      selectedEndDate: selectedEndDate,
      selectedCampaignType: selectedCampaignType ? selectedCampaignType.value : 0,
      selectedCampaign: selectedCampaign ? selectedCampaign.value : 0,
      selectedDepartment: selectedDepartment ? selectedDepartment.value : 0,
      sortColumns: sortColumns,
      reportFilter: reportFilter,
      exportToCsv: false,
    });
  }, [
    currentPage,
    dispatch,
    selectedClient,
    selectedCompany,
    selectedStartDate,
    selectedEndDate,
    selectedCampaignType,
    selectedCampaign,
    selectedDepartment,
    sortColumns,
    reportFilter,
    pageSize
  ]);

  useEffect(() => { console.log("(debouncedReportQuery"); runReport(debouncedReportQuery); }, [debouncedReportQuery]);

  // TODO: A bug somewhere triggers a few re-renders of the ag-grid component when
  //       loading the next or previous grid page. This looks weird. Are we doing too
  //       much in this effect?
  useEffect(() => {
    console.log('vpParams');
    if (vpParams && vpParams !== undefined && orderReport && orderReport.data) {
      console.log("changing viewport data");
      vpParams?.setRowCount(orderReport.total);

      if (viewportRange) {
        if (vpParams && orderReport) {
          const rowData: any = [];
          let idx = viewportRange.firstRow;
          orderReport.data.forEach((row) => {
/* 
            for (const [key, value] of Object.entries(row)) {
                let newField = '';

                if (key.substring(0,2) == 'c_') {
                    if (newField = key.substring(2,key.lastIndexOf('_'))) { 
                        if (value != '' && value != null)
                            row['custom_' + newField] = value; // TODO: når det er bare en slik funksjon, så gjør c_ til standard.
                    }

                }
            }*/

            rowData[idx] = row;
            idx++;
          });
          vpParams?.setRowData(rowData);
        }
      }
    }
  }, [orderReport /*, vpParams, viewportRange*/]);

  useEffect(() => {
    console.log('setApiAlertTimeout');
    clearTimeout(apiAlertTimeout);
    setApiAlertTimeout(
      setTimeout(() => {
        setApiMessage({ ...apiMessage, show: false });
      }, 15000)
    );
  }, [apiMessage]);

  useEffect(() => {
    const updateGridColumnSizes = () => {
      if (!columnSizeSetting) {
        return;
      }

      switch (columnSizeSetting) {
        case "autoSizeColumns":
          var allColumnIds: any = [];
            if (columnAPI) {
              const allColumns = columnAPI!.getAllColumns();
              allColumns!.forEach((column) => {
                  const colDef = column.getColDef();
                  if (!colDef.suppressAutoSize) {
                      allColumnIds.push(column.getColId());
                  }
              });
              columnAPI!.autoSizeColumns(allColumnIds);
            }
          break;
        case "sizeColumnsToFit":
          //if (gridAPI) {
            gridAPI?.redrawRows();
            gridAPI?.sizeColumnsToFit();
          //}
          break;
      }
    };

    updateGridColumnSizes();
  }, [columnSizeSetting, hideFilters, orderReport, gridAPI, columnAPI, windowSize]);

  useEffect(() => {
    return () => {
//      if (gridAPI) {
        gridAPI?.destroy();
//      }
    };
  }, [gridAPI]);

  useEffect(() => {
    console.log("runreport pagesize");

    setReportQuery({
      ...reportQuery, 
      pageSize: pageSize
    });    
  }, [pageSize]);

  const resetState = () => {
    batch(() => {
      dispatch(actionSelectClient(null));
      dispatch(actionSelectCompany(null));
      dispatch(actionSelectDepartment(null));
      dispatch(actionSelectCampaignType(null));
      dispatch(actionSelectCampaign(null));
      dispatch(actionSelectStartDate(defaultStartDate));
      dispatch(actionSelectEndDate(defaultEndDate));
    });
  };

  const onGridReady = (event: GridReadyEvent) => {
    setGridAPI(event.api);
    setColumnAPI(event.columnApi);
    const viewportDatasource = new ViewportDatasource(setVpParams, setViewportRange);
    event.api.setViewportDatasource(viewportDatasource);
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    // event.api.sizeColumnsToFit()
    //updateGridColumnSizes()
  };

  const onCellClicked = (event: CellClickedEvent) => {
    if (event.column.getColDef().field == "transferred" && (event.data.last_api_transfer != null || event.data.last_api_transfer_status != null)) {
//    if (event.column.getColDef().field == "transferred" && event.data.last_api_transfer != null) {
      setShow(false);
      loadOrderActivities(dispatch, event.data.order_id);
      setShowTransferStatus(true);
    }
  }

  const onRowDoubleClicked = (event: RowDoubleClickedEvent) => {
    dispatch(actionOpenDetails(event.data.order_id));
    loadOrderDetails(dispatch, event.data.order_id,1);
  };

  const onSortChanged = (event: SortChangedEvent) => {
    const sortColumns = event.columnApi.getAllColumns().filter((c) => c.getSort() !== undefined);
    setSortColumns(sortColumns);
  };

  const onFilterChanged = (event: any) => {
    const rFilter: any = [];

    event.columnApi.getAllColumns().forEach((c: Column) => {
      const f = event.api.getFilterInstance(c.getColId());
      if (f) {
        const filter = f.getModel();
        const field = c.getColDef().field;
        if (filter.textFilter && filter.textFilter.length > 0) {
          rFilter.push({ field, filter: filter.textFilter });
        }

        if (filter.filterEmpty === true) {
          rFilter.push({ field, filter: "" });
        }
      }
    });

    setReportFilter(rFilter);
  };

  const onPaginationChanged = (event: PaginationChangedEvent) => {
    const page = event.api.paginationGetCurrentPage() + 1;
    if (event.newPage) {
      setCurrentPage(page);
    }
  };

  const onExportToExcel = () => {
    if (gridAPI) {
      gridAPI.exportDataAsExcel();
    }
  }
  const onExportToCsv = () => {
    if (gridAPI) {
      gridAPI.exportDataAsCsv();
    }
    /*
    setReportQuery({
      dispatch: dispatch,
      currentPage: currentPage,
      selectedClient: selectedClient ? selectedClient.value : 0,
      selectedCompany: selectedCompany ? selectedCompany.value : 0,
      startDate: startDate,
      endDate: endDate,
      selectedCampaignType: selectedCampaignType ? selectedCampaignType.value : 0,
      selectedCampaign: selectedCampaign ? selectedCampaign.value : 0,
      selectedDepartment: selectedDepartment ? selectedDepartment.value : 0,
      sortColumns: sortColumns,
      reportFilter: reportFilter,
      exportToCsv: true
    });
    */
  };
  const onPageSizeChanged = (event) => {
    setPageSize(Number(event.target.value));
  

// Force new cache block size by resetting internal cache
//    gridAPI!.gridOptionsWrapper.setProperty('cacheBlockSize', pageSize);
  //  gridAPI!.infinitePageRowModel.resetCache();

    gridAPI!.paginationSetPageSize(Number(event.target.value));

    //gridAPI!.cacheBlockSize(Number(event.target.value));
    //paginationPageSize={100}
    //onPaginationChanged={onPaginationChanged}
    //cacheBlockSize={100}

    //var value = document.getElementById('page-size').value?document.getElementById('page-size').value:20;
    //gridOptions.api.paginationSetPageSize(Number(value));
  }
  const frameworkComponents = {
    agCellButton: (props) => {
      return (
        <div
          className="ag-cell-button"
          onClick={() => {
            dispatch(actionOpenDetails(props.data.order_id));
            loadOrderDetails(dispatch, props.data.order_id);
          }}
        >
          <i className="fas fa-fw fa-tag"></i> Open
        </div>
      );
    },
    agTransferStatusCell: AgTransferStatusCell,
  };

  const toolbarProps = {
    title: "",
    rightAlign: true,
    disabled: false,
    icon: "",
    onClick: () => {},
  };

  return (
    <div>
      <TitlebarComponent
        title="Orders"
        description="View registered orders"
        breadcrumbs={[
          { title: "Dashboard", link: "/" },
          { title: "Orders", link: "/orders2" },
        ]}
      />

      <div className="widget-list">
        <Row>
          <Col className="widget-holder">
            <div className="widget-bg">
              <div className="widget-body">
                <Row>
                  <Col>{apiMessage.show ? <Alert variant={apiMessage.variant}>{apiMessage.message}</Alert> : null}</Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      style={{ color: "#ccc", width: 15 }}
                      data-toggle="tooltip"
                      data-original-title="Hide/Show filters"
                      onClick={() => dispatch(actionToggleFilterView())}
                    >
                      {hideFilters ? (
                        <i className="fas fa-fw fa-caret-square-right"></i>
                      ) : (
                        <i className="fas fa-fw fa-caret-square-left"></i>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={2} style={{ display: hideFilters ? "none" : "block" }}>
                    <ClientSelector />
                    <CompanySelector />

                    <PeriodSelector defaultEndDate={defaultEndDate} />

                    <DepartmentSelector />
                    <CampaignTypeSelector />
                    <CampaignSelector />

                    <div style={{ textAlign: "right", marginTop: 20 }}>
                      <Button onClick={resetState} variant="outlined">
                        Reset
                      </Button>
                      &nbsp;
                      <Button onClick={() => runReport(reportQuery)} variant="outlined">
                        Run
                      </Button>
                    </div>
                  </Col>
                  <Col>
                    {apiRunning ? <LoadingScreen message="Loading report. Please wait." /> : null}

                    <div className="ag-buttons">
                      
                      {AgToolbarButton({
                        ...toolbarProps,
                        title: "Export all orders to CSV",
                        icon: "fa-file-csv",
                        onClick: () => {
                          fetchAllOrdersForExport();
                        },
                      })}

                      {AgToolbarButton({
                        ...toolbarProps,
                        title: "Export this page to CSV",
                        icon: "fa-file-export",
                        onClick: onExportToCsv,
                      })}

                      {AgToolbarButton({
                        ...toolbarProps,
                        title: "Export this page to Excel",
                        icon: "fa-file-download",
                        onClick: onExportToExcel,
                      })}

                      {AgToolbarButton({
                        ...toolbarProps,
                        title: "Adjust columns to content",
                        icon: "fa-text-width",
                        onClick: () => setColumnSizeSetting("autoSizeColumns"),
                      })}

                      {AgToolbarButton({
                        ...toolbarProps,
                        title: "Adjust columns to window",
                        icon: "fa-columns",
                        onClick: () => setColumnSizeSetting("sizeColumnsToFit"),
                      })}

                      {AgToolbarButton({
                        ...toolbarProps,
                        title: "Refresh",
                        icon: "fa-sync-alt",
                        onClick: () => {
                          runReport(reportQuery);
                        },
                      })}

                      
                    </div>

                    <div className="ag-theme-balham" style={{ height: "calc(100vh - 400px)", width: "100%" }}>
                      <AgGridReact
                        columnDefs={columnDefs}
                        groupIncludeTotalFooter={false}
                        groupIncludeFooter={false}
                        groupMultiAutoColumn={true}
                        animateRows={true}
                        enableRangeSelection={false}
                        rowSelection="multiple"
                        onGridReady={onGridReady}
                        onFirstDataRendered={onFirstDataRendered}
                        onRowDoubleClicked={onRowDoubleClicked}
                        onCellClicked={onCellClicked}
                        sideBar={{
                          toolPanels: ["columns", "filters"],
                        }}
                        suppressAggFuncInHeader={true}
                        defaultColDef={{
                          sortable: true,
                          resizable: true,
                          filter: true,
                        }}
                        rowModelType="viewport"
                        viewportRowModelPageSize={100}
                        viewportRowModelBufferSize={0}
                        onSortChanged={onSortChanged}
                        onFilterChanged={onFilterChanged}
                        pagination={true}
                        paginationPageSize={100}
                        onPaginationChanged={onPaginationChanged}
                        cacheBlockSize={100}
                        cacheOverflowSize={2}
                        maxBlocksInCache={2}
                        frameworkComponents={frameworkComponents}
                        suppressCsvExport={false}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <OrderDetails
          onReloadReport={() => {
            console.log("onReloadReport");
            runReport(reportQuery);
          }}
        />
      </div>
      <Dialog fullWidth maxWidth="sm" open={showTransferStatus} onClose={() => setShowTransferStatus(false)}>
        <DialogTitle id="frm-add-company">Transfer status</DialogTitle>
        <DialogContent>
            <Grid container>
                <Grid item xs={12}>
                  <pre>
                    {!show?<>Loading..</>:<></>}
                    {show && orderActivities.map((data, i) => ( <><Box component="div" m={1}>{data.order_activity_type_name} @<>{data.executed_at}</></Box><>{data.response !== null && JSON.stringify(data.response, null, 3)}</><>{data.post !== null && JSON.stringify(data.post, null, 3)}</></> ))}
                  </pre>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setShowTransferStatus(false)} color="primary">
                Done
            </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth maxWidth="sm" open={showExportAllReportsModal} onClose={() => setShowExportAllReportsModal(false)}>
        <DialogTitle id="exportAllReports">Export all orders to CSV</DialogTitle>
        <DialogContent>
          { exportLoading ? <div><CircularProgress/><span> Big file loading...</span></div> :
          <>
            <div>Total rows in file: {reportsForCSV.length}</div>
            <Button variant="outlined" color="primary">
            <CSVLink 
              {...csvlinkProps}>Click to Export to CSV</CSVLink>
            </Button>
          </>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowExportAllReportsModal(false)}
            color="secondary"
            variant="contained">Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}