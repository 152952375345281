import { CSSProperties } from "@material-ui/core/styles/withStyles";
import React, { useState } from "react";

interface IExpandableText {
  text: string;
  disabled?: boolean
  maxLength: number;
}

export default function ExpandableText(props: IExpandableText) {
  const [expanded, setExpanded] = useState(false);

  const shortened = props.text.length > props.maxLength ? true : false;
  let textClass = shortened ? "expandable-text" : "";
  textClass = textClass + (expanded ? " full-text" : " compact-text");

  const oStyle: CSSProperties = {
    color: '#aaa'
  }  
  function color() {
    if (props.disabled) {
      return { color: oStyle.color}
    }
    else {
      return {}
    }

  }
  return (
    <div className={textClass} style={color()} onClick={() => setExpanded(!expanded)} title={!expanded ? "Click to expand" : ""}>
      {expanded ? props.text : props.text.substr(0, props.maxLength)}
      {!expanded && shortened ? "… " : null}
    </div>
  );
}
