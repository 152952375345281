import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { actionSelectDepartment } from "../../redux/reducers/shell";
import { loadShellDepartments } from "./shell.api";
import {
  TextField,
  MenuItem,
} from "@material-ui/core";
import _uniqueId from "lodash/uniqueId";

interface IDepartmentSelectorProps {
  variant?: string,
  fullWidth?: boolean
}

export default function DepartmentSelector(props: IDepartmentSelectorProps) {
  const dispatch = useDispatch();
  const departments = useSelector(state => state.shell.departments);
  const selectedDepartment = useSelector(state => state.shell.selectedDepartment);
  const selectedCompany = useSelector(state => state.shell.selectedCompany);
  const [id] = useState(_uniqueId("dsel-"));

  function handleChange(e) {
    if (e.target.value == "") {
      dispatch(actionSelectDepartment(undefined));
    }
    else {
      dispatch(actionSelectDepartment(e.target));
    }
  }

  useEffect(() => {
    loadShellDepartments(dispatch, selectedCompany ? selectedCompany.value : 0);
  }, [dispatch, loadShellDepartments, selectedCompany]);

  if(props.variant === "material") {
    return (
      <TextField 
          value={selectedDepartment ? selectedDepartment.value : ""}
          select 
          label="Department"
          id={id}
          fullWidth={props.fullWidth}
          onChange={handleChange}
          >
          <MenuItem value="">None</MenuItem>
          {departments.map((department, key) => (
            <MenuItem key={key} value={department.department_id}>{department.department_name} - {department.department_id}</MenuItem>
          ))}
        </TextField>
    );
  }
  else {
    return (
      <Form.Group controlId="frmDepartment">
        <Form.Label>Department</Form.Label>
        <Select
          value={selectedDepartment}
          onChange={(opt: any) => dispatch(actionSelectDepartment(opt))}
          options={departments.map(d => ({ label: d.company_name + " - " + d.department_name, value: d.department_id }))}
          isClearable={true}
        />
      </Form.Group>
    );
  }
}
