import { format } from "date-fns";

declare var env: any;

export async function getCampaigns(formId: number) {
  const res = await fetch(env._apiPath + "/client/form/campaigns?show_available=0&form_id=" + formId, {
    method: "GET",
    credentials: "include"
  });
  const json = await res.json();

  if (json.status === "success") {
    return json.data;
  }

  throw json.message;
}

export async function connectCampaign(formId: number, campaignId: number) {
  const req = {
    form_id: formId,
    campaign_id: campaignId
  };

  const res = await fetch(env._apiPath + "/form/campaign", {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(req)
  });
  const json = await res.json();

  if (json.status === "success") {
    return;
  }

  throw json.message;
}

export async function disconnectCampaign(formCampaignId: number) {
  const res = await fetch(env._apiPath + "/formCampaign/" + formCampaignId, {
    method: "DELETE",
    credentials: "include"
  });
  const json = await res.json();

  if (json.status === "success") {
    return;
  }

  throw json.message;
}

export async function activateFormCampaign(formCampaignId: number) {
  const req = {
    form_campaign_id: formCampaignId
  };

  const res = await fetch(env._apiPath + "/client/setActiveFormCampaign", {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(req)
  });
  const json = await res.json();

  if (json.status === "success") {
    return;
  }

  throw json.message;
}

export async function addCampaignCPO(formCampaignId: number, cpo: string, cpoType: string, dateFrom: Date) {
  const req = {
    form_campaign_id: formCampaignId,
    cpo: cpo,
    cpo_type: cpoType,
    date_from: format(dateFrom, "dd.MM.yyyy")
  };

  const res = await fetch(env._apiPath + "/form/campaignCPO", {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(req)
  });
  const json = await res.json();

  if (json.status === "success") {
    return;
  }

  throw json.message;
}
