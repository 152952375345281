import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actionToggleFilterView } from "../../redux/reducers/shell";
import TitlebarComponent from "../../titlebar.component";
import { getCallbacks, ICallbacks } from "./callback/callback.api";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Agent, fetchAgents } from "../organization/agents/agents.api";
import { Campaign, fetchCampaigns } from "../campaignmanagement/campaigns/campaigns.api";
import {
  Grid,
  Button,
  makeStyles,
  Theme,
  createStyles,
  CardContent,
  Card,
  CardActions,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import "./callback/callback.scss";

// gridsystemet
import { AgToolbarButton } from "../../agGridUtils";
import { AgGridReact } from "ag-grid-react";
import { GridReadyEvent, FirstDataRenderedEvent, SelectionChangedEvent, GridApi, ColumnApi } from "ag-grid-community";
// theme for AdGridReact
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorMessage: {
      color: "#ff0000",
    },
    flexGrow: {
      flexGrow: 1,
    },
    actions: {
      "& .form-group": {
        minWidth: 200,
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      "& button": {
        //marginTop: 24,
      },
    },
    agGrid: {
      "& .aggrid-size": {
        minHeight: 500,
        height: "calc(100vh - 460px)",
        width: "100%",
      },
    },
  })
);
// for knappene over tabellen
const toolbarProps = {
  title: "",
  rightAlign: false,
  disabled: false,
  icon: "",
  onClick: () => {},
};
const marginStyles = {
  // margin: '24px 0 0 0',
};
const columnDefs = [
  { headerName: "Campaign Name", field: "campaign_name", sortable: true, filter: true },
  { headerName: "Agent", field: "login_name", sortable: true, filter: true },
  { headerName: "Agent Name", field: "fullname", sortable: true, filter: true },
  {
    headerName: "Callback time",
    field: "callback_time",
    sortable: true,
    filter: true,
    cellRenderer: (r) => {
      if (r.data) {
        return formatDate2(r.data.callback_time);
      } else {
        return "";
      }
    },
  },
  { headerName: "Firstname", field: "firstname", sortable: true, filter: true },
  { headerName: "Lastname", field: "lastname", sortable: true, filter: true },
  { headerName: "Mobile", field: "phone_mobile", sortable: true, filter: true },
  { headerName: "Phone", field: "Phone", sortable: true, filter: true },
  { headerName: "Comment", field: "comments", sortable: true, filter: true },

  // de under er hidden
  { headerName: "Customer Id", field: "customer_id", sortable: true, filter: true, hide: true },
];
let today = new Date();
let fortnight = new Date();
fortnight.setDate(fortnight.getDate() + 7);

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("");
}
function formatDate2(date) {
  var d = new Date(date.replace(/\s/, "T")),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = "" + d.getHours(),
    min = "" + d.getMinutes();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (hour.length < 2) hour = "0" + hour;
  if (min.length < 2) min = "0" + min;

  return [day, month, year].join(".") + " " + hour + ":" + min;
}

export default function CallbackComponent() {
  const [results, setResults] = useState([]);

  const [agents, setAgents] = useState<Agent[]>([]);
  const [selectedAgent, setSelectedAgent] = useState("");

  const [campaigns, setCampaigns] = useState<Campaign[]>([
    { campaign_id: 0, campaign_name: "", campaign_type_id: 0, client_id: 0, shortname: "" },
  ]);
  const [selectedCampaign, setSelectedCampaign] = useState("");

  const [selectedFromDate, setSelectedFromDate] = useState<Date | null>(today);
  const [selectedToDate, setSelectedToDate] = useState<Date | null>(fortnight);

  const [loading, setLoading] = useState(false);

  // for tabellen
  const classes = useStyles();
  const [columnAPI, setColumnAPI] = useState<ColumnApi>();
  const [gridAPI, setGridAPI] = useState<GridApi>();
  const dispatch = useDispatch();
  const hideFilters = useSelector((state) => state.shell.hideFilters);

  // funksjoner for agGrid
  // Dette skjer når du klikker på en rad i tabellen, lagrer object med file id
  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const rows = event.api.getSelectedRows();
    if (!rows[0]) {
      return;
    }
    // TODO: setSelectedContact(rows[0]);
  };
  // søkefelt som søker i tabellen
  const handleQuickFilter = (event) => {
    gridAPI!.setQuickFilter(event.target.value);
  };
  // laster inn data om tabellen
  const onGridReady = (event: GridReadyEvent) => {
    setGridAPI(event.api);
    setColumnAPI(event.columnApi);
  };
  // 3 knapper over tabellen, refresh, og endre størrelse på kolonner
  const onAdjustColumnsContent = (event: Event) => {
    var allColumnIds: any = [];
    if (columnAPI) {
      const allColumns = columnAPI!.getAllColumns();
      allColumns!.forEach((column) => {
        const colDef = column.getColDef();
        if (!colDef.suppressAutoSize) {
          allColumnIds.push(column.getColId());
        }
      });
      columnAPI!.autoSizeColumns(allColumnIds);
    }
  };
  const onAdjustColumnsWindow = (event: Event) => {
    gridAPI!.sizeColumnsToFit();
  };
  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    event.api.sizeColumnsToFit();
  };
  const onExportToCsv = () => {
    if (gridAPI) {
      gridAPI.exportDataAsExcel();
    }
  };
  const refreshTable = () => {
    searchCallbacks();
  };
  // end funksjoner for agGrid

  const searchCallbacks = () => {
    setLoading(true);
    getCallbacks(
      selectedAgent !== "" ? parseFloat(selectedAgent) : 0,
      selectedCampaign !== "" ? parseFloat(selectedCampaign) : 0,
      parseFloat(formatDate(selectedFromDate)),
      parseFloat(formatDate(selectedToDate))
    )
      .then((res) => setResults(res.data))
      .finally(() => setLoading(false));
  };
  const resetForm = () => {
    setSelectedAgent("");
    setSelectedCampaign("");
    setSelectedFromDate(today);
    setSelectedToDate(fortnight);
  };
  const handleFromDateChange = (e) => {
    setSelectedFromDate(e);
  };
  const handleToDateChange = (e) => {
    setSelectedToDate(e);
  };

  useEffect(() => {
    fetchAgents(undefined, undefined, undefined).then((agents: Agent[]) => setAgents(agents));
    fetchCampaigns(0, 0).then((campaigns: Campaign[]) => setCampaigns(campaigns));
  }, []);
  return (
    <>
      <TitlebarComponent
        title="Callbacks"
        description="An overview of callbacks"
        breadcrumbs={[
          { title: "Dashboard", link: "/" },
          { title: "Callbacks", link: "/reports/callbacks" },
        ]}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div
            style={{ color: "#ccc", width: 15 }}
            data-toggle="tooltip"
            data-original-title="Hide/Show filters"
            onClick={() => dispatch(actionToggleFilterView())}
          >
            {hideFilters ? (
              <i className="fas fa-fw fa-caret-square-right"></i>
            ) : (
              <i className="fas fa-fw fa-caret-square-left"></i>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={2} style={{ display: hideFilters ? "none" : "block" }}>
          <div style={{ maxWidth: "270px" }}>
            {/* <div className="cbFormCont"> */}
            <TextField
              className="cbForm"
              label="Agents"
              select
              fullWidth
              value={selectedAgent}
              onChange={(e) => setSelectedAgent(e.target.value)}
            >
              <MenuItem value="">None</MenuItem>
              {agents ? (
                agents.map((agent, key) => (
                  <MenuItem key={key} value={agent.agent_id}>
                    {agent.fullname} - {agent.login_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>No agents loaded</MenuItem>
              )}
            </TextField>
            <TextField
              className="cbForm"
              label="Campaigns"
              select
              fullWidth
              value={selectedCampaign}
              onChange={(e) => setSelectedCampaign(e.target.value)}
            >
              <MenuItem value="">None</MenuItem>
              {campaigns ? (
                campaigns.map((campaign, key) => (
                  <MenuItem key={key} value={campaign.campaign_id}>
                    {campaign.campaign_name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>No campaigns loaded</MenuItem>
              )}
            </TextField>
            <div className="cbDate">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="fromDate">
                  <KeyboardDatePicker
                    className="cbDate"
                    disableToolbar
                    variant="inline"
                    format="dd.MM.yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date From"
                    value={selectedFromDate}
                    onChange={handleFromDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change from date",
                    }}
                  />
                </div>
                <div className="toDate">
                  <KeyboardDatePicker
                    className="cbDate"
                    disableToolbar
                    variant="inline"
                    format="dd.MM.yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date To"
                    value={selectedToDate}
                    onChange={handleToDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change to date",
                    }}
                  />
                </div>
              </MuiPickersUtilsProvider>
            </div>
            {/* <div className="cbFormButton"> */}
            <div style={{ textAlign: "right", marginTop: 20 }}>
              {loading ? <CircularProgress size={20} /> : null}
              <Button color="primary" variant="outlined" onClick={() => resetForm()}>
                Reset
              </Button>
              &nbsp;
              <Button color="primary" variant="contained" disabled={loading} onClick={() => searchCallbacks()}>
                Search
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={hideFilters ? 12 : 10}>
          <Card>
            <CardActions disableSpacing className={classes.actions}>
              {/* Knappene over tabellen */}
              <div className={classes.flexGrow} />
              <input type="text" style={marginStyles} placeholder="Search" onChange={handleQuickFilter} />
              {AgToolbarButton({
                ...toolbarProps,
                title: "Refresh",
                icon: "fa-sync-alt",
                onClick: refreshTable,
              })}

              {AgToolbarButton({
                ...toolbarProps,
                title: "Export to CSV",
                icon: "fa-file-export",
                onClick: onExportToCsv,
              })}

              {AgToolbarButton({
                ...toolbarProps,
                title: "Adjust columns to window",
                icon: "fa-columns",
                onClick: onAdjustColumnsWindow,
              })}

              {AgToolbarButton({
                ...toolbarProps,
                title: "Adjust columns to content",
                icon: "fa-text-width",
                onClick: onAdjustColumnsContent,
              })}
            </CardActions>
            {/* Selve tabellen */}
            <CardContent>
              <Grid container>
                <Grid item xs={12} className={classes.agGrid}>
                  <div className="ag-theme-balham aggrid-size">
                    <AgGridReact
                      rowData={results}
                      columnDefs={columnDefs}
                      onGridReady={onGridReady}
                      onSelectionChanged={onSelectionChanged}
                      enableRangeSelection={false}
                      rowSelection="single"
                      // justerer kolonnebredden etter load
                      onFirstDataRendered={onFirstDataRendered}
                      sideBar={{
                        toolPanels: ["columns"],
                      }}
                      // pagination
                      pagination={true}
                      paginationPageSize={100}
                      cacheBlockSize={100}
                      cacheOverflowSize={2}
                      maxBlocksInCache={2}
                      defaultColDef={{
                        flex: 1,
                        minWidth: 100,
                        sortable: true,
                        resizable: true,
                        filter: true,
                      }}
                      enableCellTextSelection={true}
                      ensureDomOrder={true}
                    />
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
