import React from "react";
import { useSelector } from "react-redux";

interface IAdminGuard {
  children: any;
}

export default function AdminGuard(props: IAdminGuard) {
  const userInfo = useSelector( (state: any) => state.shell.user);

  if (userInfo.is_admin === true) {
    return props.children;
  }

  return <div />;
}
