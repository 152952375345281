import React, { useState, useEffect, forwardRef, useRef, useImperativeHandle } from "react";
import ReactDOM from "react-dom";
import Card from "@material-ui/core/Card";
import {
  CardContent,
  CardActions,
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@material-ui/core";
import {
  IFormFieldsProps,
  ICampaignData,
  IFormFieldData,
  IFormFieldType,
  IFormData,
  IFormFieldNewData,
} from "./form.types";
import {
  GridApi,
  ColumnApi,
  GridReadyEvent,
  FirstDataRenderedEvent,
  Column,
  RowDoubleClickedEvent,
  SelectionChangedEvent,
} from "ag-grid-community";
import { AgToolbarButton } from "../../../agGridUtils";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { useSelector, useDispatch } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import {
  getCampaigns,
  activateFormCampaign,
  connectCampaign,
  disconnectCampaign,
  addCampaignCPO,
} from "./campaigns.api";
import {
  actionReceivedCampaigns,
  actionReceivedFormData,
  actionReceivedFormFields,
} from "../../../redux/reducers/campaignmanagement/forms";
import CampaignSelector from "../../shell/campaignselector.component";
import { getFormData } from "./edit.api";
import {
  getFormFields,
  getFormFieldTypes,
  addFormField,
  moveFormField,
  updateFormField,
  deleteFormField,
} from "./formfields.api";
import { Autocomplete } from "@material-ui/lab";
import { actionShellSetError, actionShellUnsetError } from "../../../redux/reducers/shell";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexGrow: {
      flexGrow: 1,
    },
    actions: {
      "& button.aggrid-btn": {
        //marginTop: 24
      },
    },
    agGrid: {
      display: "flex",
      flexDirection: "column",
      "& .aggrid-size": {
        minHeight: 500,
        //height: 500, //"calc(100vh - 460px)",
        flex: 1,
        width: "100%",
      },
    },
    dialogPaper: { overflow: "visible" },
  })
);

const toolbarProps = {
  title: "",
  rightAlign: false,
  disabled: false,
  icon: "",
  onClick: () => {},
};

const AgNumericCellEditor = forwardRef((params: any, ref) => {
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return inputRef!.current.value;
      },
    };
  });
  return <input type="text" ref={inputRef} defaultValue={params.value} />;
});

export default function TabFormFieldsComponent(props: IFormFieldsProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const rowData = useSelector((state) => state.forms.edit.formFields);
  const selectedCampaign = useSelector((state) => state.shell.selectedCampaign);
  const formData = useSelector((state) => state.forms.edit.formData) as IFormData;

  // const [rowData, setRowData] = useState([]);
  const [gridAPI, setGridAPI] = useState<GridApi>();
  const [columnAPI, setColumnAPI] = useState<ColumnApi>();
  const [selectedRows, setSelectedRows] = useState<IFormFieldData[]>([]);

  const [showRemoveFields, setShowRemoveFields] = useState(false);
  const [showAddField, setShowAddField] = useState(false);

  const [newFieldType, setNewFieldType] = useState<IFormFieldType | null>(null);
  const [newFieldWidth, setNewFieldWidth] = useState("1");
  const [newMaxCharacters, setNewMaxCharacters] = useState("50");
  const [newRequired, setNewRequired] = useState(false);
  const [newReadOnly, setNewReadOnly] = useState(false);
  const [newDisabled, setNewDisabled] = useState(false);
  const [newQueue, setNewQueue] = useState(false);

  const [formFieldTypes, setFormFieldTypes] = useState<IFormFieldType[]>([]);

  const columnDefs = [
    {
      headerName: "Sort order",
      width: 130,
      suppressSizeToFit: true,
      suppressAutoSize: true,
      suppressMenu: true,
      suppressFilter: true,
      suppressSorting: true,
      suppressResize: true,
      cellRenderer: "sortRenderer",
    },
    { headerName: "Field name", field: "header_name" },
    { headerName: "Sort order", field: "sort_by" },
    {
      headerName: "Field width (1-12)",
      field: "col",
      editable: true,
      cellEditor: "numericCellEditor",
      cellClass: "ag-editable-cell",
    },
    {
      headerName: "Min characters",
      field: "min_length",
      editable: true,
      cellEditor: "numericCellEditor",
      cellClass: "ag-editable-cell",
    },
    {
      headerName: "Max characters",
      field: "max_length",
      editable: true,
      cellEditor: "numericCellEditor",
      cellClass: "ag-editable-cell",
    },
    {
      headerName: "Required field",
      field: "mandatory_order",
      cellRenderer: "checkRenderer",
      cellClass: function (params) {
        if (params.data) return "btn ag-cell-btn ag-cell-tooltip";
        else return "";
      },
    },
    {
      headerName: "Read only field",
      field: "read_only",
      cellRenderer: "checkRenderer",
      cellClass: function (params) {
        if (params.data) return "btn ag-cell-btn ag-cell-tooltip";
        else return "";
      },
    },
    {
      headerName: "Disabled field",
      field: "disabled",
      cellRenderer: "checkRenderer",
      cellClass: function (params) {
        if (params.data) return "btn ag-cell-btn ag-cell-tooltip";
        else return "";
      },
    },
    {
      headerName: "Queue",
      field: "queue_active",
      cellRenderer: "checkRenderer",
      cellClass: function (params) {
        if (params.data) return "btn ag-cell-btn ag-cell-tooltip";
        else return "";
      },
    },
  ];
  const showError = (title) => (err) => {
    dispatch(actionShellSetError("danger", title, err));
    setTimeout(() => dispatch(actionShellUnsetError()), 10000);
  };

  const reloadFormFields = () => {
    getFormFields(props.formId)
      .then((formFields: IFormFieldData[]) => {
        dispatch(actionReceivedFormFields(formFields));

        if (formData.client_id) {
          getFormFieldTypes(formData.client_id)
            .then((formFieldTypes: IFormFieldType[]) => setFormFieldTypes(formFieldTypes))
            .catch(showError("Field Types"));
        }
      })
      .catch(showError("Form Fields"));
  };

  useEffect(() => {
    reloadFormFields();
    if (formData.form_id !== props.formId) {
      getFormData(props.formId).then((newFormData) => {
        dispatch(actionReceivedFormData(newFormData));
      });
    }
  }, [props.formId, formData.form_id, dispatch]);

  const onGridReady = (event: GridReadyEvent) => {
    window.addEventListener("resize", () => setTimeout(() => event.api.sizeColumnsToFit()));
    event.api.sizeColumnsToFit();
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    event.api.sizeColumnsToFit();
  };

  const onAdjustColumnsContent = (event: Event) => {
    var allColumnIds: string[] = [];
    columnAPI!.getAllColumns().forEach((column: Column) => {
      if (!column.getColDef().suppressAutoSize) {
        allColumnIds.push(column.getColId());
      }
    });
    columnAPI!.autoSizeColumns(allColumnIds);
  };

  const onAdjustColumnsWindow = (event: Event) => {
    gridAPI!.sizeColumnsToFit();
  };

  const onRefreshList = () => reloadFormFields();

  const onRowDoubleClicked = (event: RowDoubleClickedEvent) => {};

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const rows = event.api.getSelectedRows();
    if (!rows[0]) {
      setSelectedRows([]);
      return;
    }

    setSelectedRows(rows);
  };

  const onRemoveFields = () => {
    const prmFieldRemoval = selectedRows.map((formField: IFormFieldData) =>
      deleteFormField(formField.order_header_form_id)
    );
    Promise.all(prmFieldRemoval)
      .then((results) => {
        setSelectedRows([]);
        setShowRemoveFields(false);
        reloadFormFields();
      })
      .catch(showError("Error Removing Fields"));
  };

  const onAddField = () => {
    const formField: IFormFieldNewData = {
      order_header_id: newFieldType!.order_header_id,
      mandatory_order: "1",
      mandatory_contact: "0",
      col: newFieldWidth,
      max_length: newMaxCharacters,
      min_length: null,
      pattern: "",
      queue_active: newQueue ? "1" : "0",
      read_only: newReadOnly ? "1" : "0",
      disabled: newDisabled ? "1" : "0",
      inline: "",
    };

    addFormField(props.formId, formField)
      .then(() => {
        reloadFormFields();
        setShowAddField(false);
      })
      .catch(showError("Add Field"));
  };

  const onMoveFormField = (orderHeaderFormId: string, direction: string) => {
    moveFormField(orderHeaderFormId, direction)
      .then(() => {
        reloadFormFields();
      })
      .catch(showError("Move Field"));
  };

  const AgCheckRenderer = (params) => {
    const [checked, setChecked] = useState(parseInt(params.value) !== 0);

    const onCheckChanged = (event) => {
      const field = params.colDef.field;
      const formField = params.data as IFormFieldData;
      formField[field] = checked ? "0" : "1";
      setChecked(checked ? false : true);

      updateFormField(formField)
        .then(() => {
          reloadFormFields();
        })
        .catch(showError("Update Field"));
    };

    const key = params.rowIndex + "-" + params.node.id;

    return (
      <div key={key} className="checkbox checkbox-primary" style={{ padding: 0, margin: -4 }}>
        <label className="checkbox-md">
          <input type="checkbox" checked={checked} onChange={onCheckChanged} />
          <span className="label-text">
            <i className="fas fa-fw fa-pencil-alt"></i>
          </span>
        </label>
      </div>
    );
  };

  const AgSortRenderer = (params) => {
    const key = params.rowIndex + "-" + params.node.id;

    const btnUp = (
      <button
        key={key + "btn1"}
        style={{ width: "50%" }}
        className="btn btn-primary ag-cell-btn ag-cell-tooltip"
        data-toggle="tooltip"
        title="Move form field up"
        onClick={() => onMoveFormField(params.data.order_header_form_id, "up")}
      >
        <i className="fas fa-fw fa-arrow-up"></i>
      </button>
    );
    const btnDown = (
      <button
        key={key + "btn2"}
        style={{ width: "50%" }}
        className="btn btn-info ag-cell-btn ag-cell-tooltip"
        data-toggle="tooltip"
        title="Move form field down"
        onClick={() => onMoveFormField(params.data.order_header_form_id, "down")}
      >
        <i className="fas fa-fw fa-arrow-down"></i>
      </button>
    );

    const isBottom = params.node.rowIndex == params.api.rowModel.rowsToDisplay.length - 1;
    const isTop = params.node.rowIndex == 0;

    return (
      <span key={key} className="row" style={{ justifyContent: isTop ? "flex-end" : "space-between", height: "30px" }}>
        {isBottom ? btnUp : null}
        {isTop ? btnDown : null}
        {!isTop && !isBottom ? [btnUp, btnDown] : null}
      </span>
    );
  };

  const onCellEditingStopped = (params) => {
    const field = params.colDef.field;
    const formField = params.data as IFormFieldData;
    formField[field] = params.value;

    updateFormField(formField)
      .then(() => {
        reloadFormFields();
      })
      .catch(showError("Update Field"));
  };

  return (
    <>
      <Card>
        <CardActions disableSpacing className={classes.actions}>
          {AgToolbarButton({
            ...toolbarProps,
            title: "Create Form Field",
            icon: "fa-plus",
            onClick: () => setShowAddField(true),
          })}

          {AgToolbarButton({
            ...toolbarProps,
            title: "Remove Selected Fields",
            icon: "fa-trash",
            onClick: () => setShowRemoveFields(true),
            disabled: selectedRows === null,
          })}

          <div className={classes.flexGrow} />
          {AgToolbarButton({
            ...toolbarProps,
            title: "Refresh",
            icon: "fa-sync-alt",
            onClick: onRefreshList,
          })}

          {AgToolbarButton({
            ...toolbarProps,
            title: "Adjust columns to window",
            icon: "fa-columns",
            onClick: onAdjustColumnsWindow,
          })}

          {AgToolbarButton({
            ...toolbarProps,
            title: "Adjust columns to content",
            icon: "fa-text-width",
            onClick: onAdjustColumnsContent,
          })}
        </CardActions>
        <CardContent>
          <Grid container>
            <Grid item md={12} className={classes.agGrid}>
              <div className="ag-theme-balham aggrid-size">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={rowData}
                  groupIncludeTotalFooter={false}
                  groupIncludeFooter={false}
                  groupMultiAutoColumn={true}
                  animateRows={true}
                  enableRangeSelection={false}
                  onSelectionChanged={onSelectionChanged}
                  rowSelection="multiple"
                  onGridReady={onGridReady}
                  onFirstDataRendered={onFirstDataRendered}
                  onRowDoubleClicked={onRowDoubleClicked}
                  onCellEditingStopped={onCellEditingStopped}
                  sideBar={{
                    toolPanels: [],
                  }}
                  suppressAggFuncInHeader={true}
                  defaultColDef={{
                    sortable: false,
                    resizable: true,
                    filter: false,
                  }}
                  frameworkComponents={{
                    numericCellEditor: AgNumericCellEditor,
                    sortRenderer: AgSortRenderer,
                    checkRenderer: AgCheckRenderer,
                  }}
                />
              </div>
            </Grid>
          </Grid>

          <Dialog
            open={showRemoveFields}
            onClose={() => setShowRemoveFields(false)}
            aria-labelledby="alert-campdisconnect-title"
            aria-describedby="alert-campdisconnect-description"
          >
            <DialogTitle id="alert-campdisconnect-title">
              Remove Field{selectedRows!.length > 1 ? "s" : null}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-campdisconnect-description">
                Are you sure you want to remove the field{selectedRows!.length > 1 ? "s" : null}?
              </DialogContentText>
              <div>
                {selectedRows ? (
                  <ul>
                    {selectedRows.map((f: IFormFieldData, k) => (
                      <li key={k}>{f.header_name}</li>
                    ))}
                  </ul>
                ) : null}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowRemoveFields(false)} color="secondary" autoFocus>
                No
              </Button>
              <Button onClick={onRemoveFields} color="primary">
                Yes
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={showAddField}
            onClose={() => setShowAddField(false)}
            aria-labelledby="alert-addformfield-title"
            aria-describedby="alert-addformfield-description"
          >
            <DialogTitle id="alert-addformfield-title">Create Form Field</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Autocomplete
                    id="drop-fieldtype"
                    options={formFieldTypes}
                    getOptionLabel={(field: IFormFieldType) => field.header_name}
                    value={newFieldType}
                    onChange={(event, newValue: IFormFieldType | null) => setNewFieldType(newValue)}
                    renderInput={(params) => <TextField {...params} label="Field Type" variant="outlined" fullWidth />}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="drop-fieldwidth">Field Width</InputLabel>
                    <Select
                      id="drop-fieldwidth"
                      fullWidth
                      value={newFieldWidth}
                      onChange={(e) => setNewFieldWidth(e.target.value as string)}
                    >
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((n, k) => (
                        <MenuItem value={n} key={k}>
                          {n}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6}>
                  <FormControl fullWidth>
                    <TextField
                      label="Max Characters"
                      type="number"
                      fullWidth
                      value={newMaxCharacters}
                      onChange={(e) => setNewMaxCharacters(e.target.value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newRequired}
                        onChange={(e) => setNewRequired(e.target.checked)}
                        name="newRequired"
                        color="primary"
                      />
                    }
                    label="Required"
                  />
                </Grid>
                <Grid item md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newReadOnly}
                        onChange={(e) => setNewReadOnly(e.target.checked)}
                        name="newReadOnly"
                        color="primary"
                      />
                    }
                    label="Read Only"
                  />
                </Grid>
                <Grid item md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newDisabled}
                        onChange={(e) => setNewDisabled(e.target.checked)}
                        name="newDisabled"
                        color="primary"
                      />
                    }
                    label="Disabled"
                  />
                </Grid>
                <Grid item md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newQueue}
                        onChange={(e) => setNewQueue(e.target.checked)}
                        name="newQueue"
                        color="primary"
                      />
                    }
                    label="Queue"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowAddField(false)} color="secondary" autoFocus>
                Cancel
              </Button>
              <Button onClick={onAddField} color="primary">
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </CardContent>
      </Card>
      <br />
      <Card>
        <CardContent>
          <Grid container>
            <Grid item md={12}>
              <Typography variant="h3">Preview</Typography>
              <Grid container>
                {rowData.map((field: IFormFieldData, key) => {
                  let boxShadow = "";

                  if (field.mandatory_order) {
                    boxShadow = "0px 0px 1px 1px rgba(204,29,29,0.2)";
                  }

                  return (
                    <Grid item md={field.col as any} key={key}>
                      <div className="input-group" style={{ padding: 5 }}>
                        <span className="input-group-addon">{field.header_name}</span>
                        <input type="text" className="form-control" style={{ boxShadow }} />
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
