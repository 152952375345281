import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Grid,
  CardContent,
  Card,
  CardActions,
  makeStyles,
  Theme,
  createStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  Toolbar,
  Popover,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import TitlebarComponent from "../../titlebar.component";
import ClientSelector from "../shell/clientselector.component";
import { AgToolbarButton } from "../../agGridUtils";
import ColorPicker from "material-ui-color-picker";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import {
  GridReadyEvent,
  FirstDataRenderedEvent,
  RowDoubleClickedEvent,
  SelectionChangedEvent,
  GridApi,
  ColumnApi,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-enterprise/chartsModule";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {
  fetchOutcomeReasons,
  fetchOutcomeStatuses,
  fetchOutcomes,
  Outcome,
  OutcomeReason,
  OutcomeStatus,
  addOutcome,
  updateOutcome,
  addOutcomeReason,
  updateOutcomeReason,
  removeOutcomeReason,
  removeOutcome,
} from "./outcomes/outcomes.api";
import { actionShellSetError, actionShellUnsetError } from "../../redux/reducers/shell";

interface IOutcomesProps {
  match?: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexGrow: {
      flexGrow: 1,
    },
    actions: {
      "& .form-group": {
        minWidth: 200,
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
      "& button": {
        marginTop: 24,
      },
    },
    agGrid: {
      "& .aggrid-size": {
        minHeight: 500,
        height: "calc(100vh - 460px)",
        width: "100%",
      },
    },
  })
);

const toolbarProps = {
  title: "",
  rightAlign: false,
  disabled: false,
  icon: "",
  onClick: () => {},
};

const newOutcome: Outcome = {
  outcome_id: 0,
  created_at: "",
  customer_id: 0,
  client_id: "0",
  client_name: "",
  outcome_name: "",
  outcome_color: "",
  outcome_icon: "0",
  outcome_status_id: "0",
  outcome_status_name: "",
  outcome_status_description: "",
  outcome_status_external_ref: "",
  outcome_reason_count: 0,
};

const icons = [
  { id: "1", icon: "fas fa-thumbs-up", description: "Thumbs Up" },
  { id: "2", icon: "fas fa-thumbs-down", description: "Thumbs Down" },
  { id: "3", icon: "fas fa-question-circle", description: "Question Circle" },
  { id: "4", icon: "fas fa-plus", description: "Plus" },
  { id: "5", icon: "fa fa-ban", description: "Ban" },
  { id: "6", icon: "fa fa-history", description: "History" },
  { id: "7", icon: "fa fa-low-vision", description: "Low Vision" },
];

export default function OutcomesComponent(props: IOutcomesProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [confirmDeleteTarget, setConfirmDeleteTarget] = useState();
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);

  const [outcomeConfirmAnchorEl, setOutcomeConfirmAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [reasonConfirmAnchorEl, setReasonConfirmAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [reasonIdRemoval, setReasonIdRemoval] = useState(0);

  const [showOutcomeEditor, setShowOutcomeEditor] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editOutcome, setEditOutcome] = useState<Outcome>(newOutcome);
  const [outcomeReasons, setOutcomeReasons] = useState<OutcomeReason[]>([]);
  const [outcomeStatuses, setOutcomeStatuses] = useState<OutcomeStatus[]>([]);
  const selectedClient = useSelector((state) => state.shell.selectedClient);

  const [selectedOutcome, setSelectedOutcome] = useState<Outcome | undefined | null>();
  const [rowData, setRowData] = useState<Outcome[]>([]);
  const [gridAPI, setGridAPI] = useState<GridApi>();
  const [columnAPI, setColumnAPI] = useState<ColumnApi>();

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    fetchOutcomes(selectedClient ? selectedClient.value : null)
      .then((outcomes: Outcome[]) => setRowData(outcomes))
      .catch(showError("Unable fetch outcomes"));
    fetchOutcomeStatuses()
      .then((outcomeStatuses: OutcomeStatus[]) => setOutcomeStatuses(outcomeStatuses))
      .catch(showError("Unable to fetch outcome statuses"));
  }, [selectedClient]);

  useEffect(() => {
    if (editOutcome.outcome_id) {
      fetchOutcomeReasons(editOutcome.outcome_id).then((reasons) => {
        setOutcomeReasons(reasons);
      });
    }
  }, [editOutcome]);

  const showError = (title) => (err) => {
    dispatch(actionShellSetError("danger", title, err));
    setTimeout(() => dispatch(actionShellUnsetError()), 10000);
    setIsSaving(false);
  };

  const onAddOutcome = () => {
    setEditMode(false);
    setEditOutcome(newOutcome);
    setShowOutcomeEditor(true);
    setOutcomeReasons([]);
  };

  const onEditOutcome = () => {
    setEditMode(true);
    setEditOutcome(selectedOutcome!);
    setShowOutcomeEditor(true);
  };

  const onRefreshList = () => {
    fetchOutcomes(selectedClient ? selectedClient.value : null)
      .then((outcomes: Outcome[]) => setRowData(outcomes))
      .catch(showError("Unable fetch outcomes"));
    fetchOutcomeStatuses()
      .then((outcomeStatuses: OutcomeStatus[]) => setOutcomeStatuses(outcomeStatuses))
      .catch(showError("Unable to fetch outcome statuses"));
  };

  const onRemoveOutcome = () => {
    setOutcomeConfirmAnchorEl(null);
    removeOutcome(selectedOutcome!).then(() => {
      fetchOutcomes(selectedClient ? selectedClient.value : null)
        .then((outcomes: Outcome[]) => setRowData(outcomes))
        .catch(showError("Unable fetch outcomes"));
    });
  };

  const onSaveOutcome = () => {
    if (editOutcome.outcome_id !== 0 && editOutcome.customer_id !== 0) {
      const nOutcome = Object.assign({}, editOutcome);
      setIsSaving(true);
      updateOutcome(nOutcome)
        .then((updatedOutcome: Outcome) => {
          setEditOutcome(updatedOutcome);
          setIsSaving(false);
        })
        .catch(showError("Unable to update outcome"));
      return;
    }

    if (editOutcome.outcome_id === 0) {
      setIsSaving(true);
      const nOutcome = Object.assign({}, editOutcome);
      if (selectedClient) {
        nOutcome.client_id = selectedClient.value;
      }
      addOutcome(nOutcome)
        .then((addedOutcome: Outcome) => {
          setEditOutcome(addedOutcome);
          setEditMode(true);
          setIsSaving(false);
        })
        .catch(showError("Unable to add outcome"));
    }
  };

  const onAdjustColumnsContent = (event: Event) => {
    var allColumnIds: any = [];
    columnAPI!.getAllColumns().forEach((column) => {
      const colDef = column.getColDef();
      if (!colDef.suppressAutoSize) {
        allColumnIds.push(column.getColId());
      }
    });
    columnAPI!.autoSizeColumns(allColumnIds);
  };

  const onAdjustColumnsWindow = (event: Event) => {
    gridAPI!.sizeColumnsToFit();
  };

  const onGridReady = (event: GridReadyEvent) => {
    setGridAPI(event.api);
    setColumnAPI(event.columnApi);
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    event.api.sizeColumnsToFit();
  };

  const onRowDoubleClicked = (event: RowDoubleClickedEvent) => {
    setSelectedOutcome(event.data);
    setEditOutcome(event.data);
    setEditMode(true);
    setShowOutcomeEditor(true);
  };

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const rows = event.api.getSelectedRows();
    if (!rows[0]) {
      return;
    }
    setSelectedOutcome(rows[0]);
  };

  const columnDefs = [
    {
      headerName: "Client",
      field: "client_name",
      rowGroup: false,
      enableRowGroup: false,
      hide: true,
    },
    {
      headerName: "Color",
      rowGroup: false,
      enableRowGroup: false,
      width: 20,
      cellStyle: (r) => {
        return { backgroundColor: r.data.outcome_color, color: "#fff", textAlign: "center" };
      },
      cellRenderer: (r) => {
        let icon = "";
        const foundIcon = icons.find((ic) => ic.id === r.data.outcome_icon);
        if (foundIcon) {
          icon = foundIcon.icon;
        }
        return "<i class='" + icon + "'/>";
      },
    },
    { headerName: "Outcome Name", field: "outcome_name", rowGroup: false, enableRowGroup: false },
    { headerName: "Status Name", field: "outcome_status_name", rowGroup: false, enableRowGroup: false },
    { headerName: "Status Description", field: "outcome_status_description", rowGroup: false, enableRowGroup: false },
    {
      headerName: "Status External Reference",
      field: "outcome_status_external_ref",
      rowGroup: false,
      enableRowGroup: false,
    },
    { headerName: "Outcome Reasons", field: "outcome_reason_count", rowGroup: false, enableRowGroup: false },
    {
      headerName: "Created",
      field: "created_at",
      rowGroup: false,
      enableRowGroup: false,
      hide: true,
      valueGetter: (r) => {
        if (r.data.customer_id === null) {
          return "-";
        }

        return r.data.created_at;
      },
    },
    {
      headerName: "Modified",
      field: "updated_at",
      rowGroup: false,
      enableRowGroup: false,
      hide: true,
      valueGetter: (r) => {
        if (r.data.customer_id === null) {
          return "-";
        }

        return r.data.updated_at;
      },
    },
    {
      headerName: "Built-in",
      rowGroup: false,
      enableRowGroup: false,
      valueGetter: (r) => {
        if (r.data.customer_id === null) {
          return "Yes";
        }

        return "No";
      },
    },
  ];

  const renderIcon = (iconNo) => {
    let icon = "";
    const foundIcon = icons.find((ic) => ic.id === iconNo);
    if (foundIcon) {
      icon = foundIcon.icon;
    }
    return <i className={icon} />;
  };

  return (
    <div>
      <TitlebarComponent
        title="Outcomes"
        description=""
        breadcrumbs={[
          { title: "Dashboard", link: "/" },
          { title: "Outcomes", link: "/campaignmanagement/outcomeReason" },
        ]}
      />

      <Card>
        <CardActions disableSpacing className={classes.actions}>
          <ClientSelector />
          {AgToolbarButton({
            ...toolbarProps,
            title: "Add Outcome",
            icon: "fa-plus",
            onClick: onAddOutcome,
          })}

          {AgToolbarButton({
            ...toolbarProps,
            title: "Edit",
            icon: "fa-edit",
            onClick: onEditOutcome,
            disabled: selectedOutcome == undefined,
          })}

          {AgToolbarButton({
            ...toolbarProps,
            title: "Remove",
            icon: "fa-trash",
            onClick: (e: React.MouseEvent<HTMLButtonElement>) => setOutcomeConfirmAnchorEl(e.currentTarget),
            disabled: selectedOutcome == undefined || selectedOutcome!.customer_id == null,
          })}
          <Popover
            anchorEl={outcomeConfirmAnchorEl}
            open={Boolean(outcomeConfirmAnchorEl)}
            onClose={() => {
              setOutcomeConfirmAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Button size="small" color="secondary" aria-label="confirm deletion" onClick={onRemoveOutcome}>
              Confirm
            </Button>
          </Popover>

          <div className={classes.flexGrow} />

          {AgToolbarButton({
            ...toolbarProps,
            title: "Refresh",
            icon: "fa-sync-alt",
            onClick: onRefreshList,
          })}

          {AgToolbarButton({
            ...toolbarProps,
            title: "Adjust columns to window",
            icon: "fa-columns",
            onClick: onAdjustColumnsWindow,
          })}

          {AgToolbarButton({
            ...toolbarProps,
            title: "Adjust columns to content",
            icon: "fa-text-width",
            onClick: onAdjustColumnsContent,
          })}
        </CardActions>
        <CardContent>
          <Grid container>
            <Grid item md={12} className={classes.agGrid}>
              <div className="ag-theme-balham aggrid-size">
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={rowData}
                  groupIncludeTotalFooter={false}
                  groupIncludeFooter={false}
                  groupMultiAutoColumn={true}
                  animateRows={true}
                  enableRangeSelection={false}
                  onSelectionChanged={onSelectionChanged}
                  rowSelection="multiple"
                  onGridReady={onGridReady}
                  onFirstDataRendered={onFirstDataRendered}
                  onRowDoubleClicked={onRowDoubleClicked}
                  sideBar={{
                    toolPanels: ["columns"],
                  }}
                  suppressAggFuncInHeader={true}
                  defaultColDef={{
                    sortable: true,
                    resizable: true,
                    filter: true,
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Dialog
        fullWidth
        maxWidth="lg"
        open={showOutcomeEditor}
        onClose={() => {
          setShowOutcomeEditor(false);
          onRefreshList();
        }}
      >
        <DialogTitle id="frm-add-outcome" disableTypography style={{ display: "flex" }}>
          <Typography variant="h4">{editMode ? "Edit Outcome" : "Add Outcome"}</Typography>

          <div style={{ flexGrow: 1 }} />

          <Button style={{ backgroundColor: editOutcome.outcome_color, color: "#fff" }}>
            {renderIcon(editOutcome.outcome_icon)}
          </Button>
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "#efefef" }}>
          {editOutcome.customer_id === null ? (
            <DialogContentText>
              This is an internal outcome and not editable. Only outcome reasons are editable.
            </DialogContentText>
          ) : null}
          <Paper style={{ padding: 5 }}>
            <Grid container spacing={3}>
              <Grid item md={8}>
                <TextField
                  label="Outcome Name"
                  value={editOutcome.outcome_name}
                  fullWidth
                  onChange={(e) => setEditOutcome({ ...editOutcome, outcome_name: e.target.value })}
                  disabled={editOutcome.customer_id === null}
                />
              </Grid>
              <Grid item md={4}>
                <ClientSelector
                  variant="material"
                  fullWidth
                  value={editOutcome.client_id ? editOutcome.client_id : "0"}
                  onChange={(e) => setEditOutcome({ ...editOutcome, client_id: e.target.value })}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <InputLabel shrink id="icon-label">
                    Icon
                  </InputLabel>
                  <Select
                    labelId="icon-label"
                    value={editOutcome.outcome_icon}
                    fullWidth
                    onChange={(e) => setEditOutcome({ ...editOutcome, outcome_icon: e.target.value as string })}
                    disabled={editOutcome.customer_id === null}
                  >
                    <MenuItem value="0">No Icon</MenuItem>
                    {icons.map((ic, k) => (
                      <MenuItem key={k} value={ic.id}>
                        <i className={ic.icon} style={{ marginRight: 10 }} /> {ic.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={6}>
                <ColorPicker
                  label="Color"
                  name="color"
                  InputProps={{ value: editOutcome.outcome_color }}
                  value={editOutcome.outcome_color}
                  onChange={(outcome_color) => setEditOutcome({ ...editOutcome, outcome_color })}
                  disabled={editOutcome.customer_id === null}
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginBottom: 25 }} spacing={3}>
              <Grid item md={12}>
                <FormControl fullWidth>
                  <InputLabel shrink id="status-label">
                    Status
                  </InputLabel>
                  <Select
                    labelId="status-label"
                    value={editOutcome.outcome_status_id}
                    disabled={editOutcome.customer_id === null}
                    onChange={(e: ChangeEvent<{ value: unknown }>) => {
                      setEditOutcome({ ...editOutcome, outcome_status_id: e.target.value as string });
                    }}
                  >
                    {outcomeStatuses.map((stat, k) => (
                      <MenuItem key={k} value={stat.outcome_status_id}>
                        {stat.outcome_status_name} - {stat.outcome_status_description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>

          <TableContainer component={Paper} style={{ marginTop: 25, opacity: editOutcome.outcome_id === 0 ? 0.5 : 1 }}>
            <Toolbar>
              <Typography variant="subtitle1">Reasons</Typography>

              <div style={{ flexGrow: 1 }} />

              <IconButton
                size="small"
                aria-label="add reason"
                disabled={editOutcome.outcome_id === 0}
                onClick={() => {
                  // Skip adding editable record if we're already editing something.
                  if (outcomeReasons.find((e) => e.editing) !== undefined) {
                    return;
                  }

                  const nReasons = [
                    ...outcomeReasons,
                    {
                      outcome_reason_id: 0,
                      outcome_reason_name: "",
                      outline_color: "",
                      outcome_id: editOutcome.outcome_id,
                      created_at: "",
                      updated_at: "",
                      customer_id: 0,
                      ref_id: "",
                      editing: true,
                    },
                  ];

                  setOutcomeReasons(nReasons);
                }}
              >
                <AddIcon />
              </IconButton>
            </Toolbar>

            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Reason</TableCell>
                  <TableCell align="right">Color</TableCell>
                  <TableCell align="right">Created At</TableCell>
                  <TableCell align="right">#</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {outcomeReasons.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={2}>No reasons added.</TableCell>
                  </TableRow>
                ) : null}
                {outcomeReasons.map((row, idx) => (
                  <TableRow key={row.outcome_reason_id}>
                    <TableCell align="left">
                      {row.editing ? (
                        <>
                          <TextField
                            value={row.outcome_reason_name}
                            onChange={(e) => {
                              const nReasons: OutcomeReason[] = [];
                              outcomeReasons.forEach((v) => {
                                const n = Object.assign({}, v);
                                if (n.outcome_reason_id === row.outcome_reason_id) {
                                  n.outcome_reason_name = e.target.value;
                                }
                                nReasons.push(n);
                              });
                              setOutcomeReasons(nReasons);
                            }}
                          />
                        </>
                      ) : (
                        row.outcome_reason_name
                      )}
                    </TableCell>
                    <TableCell align="right">
                    {row.editing ? (
                        <>
                    <ColorPicker
                      label="ReasonColor"
                      name="outline_color"
                      InputProps={{ value: row.outline_color?row.outline_color:'' }}
                      value={row.outline_color?row.outline_color:''}
                      onChange={(outline_color) => {
                        const nReasons: OutcomeReason[] = [];
                        outcomeReasons.forEach((v) => {
                          const n = Object.assign({}, v);
                          if (n.outcome_reason_id === row.outcome_reason_id) {
                            n.outline_color = outline_color;
                          }
                          nReasons.push(n);
                        });
                        setOutcomeReasons(nReasons);
                      }}                      
                    />
                          <IconButton
                            size="small"
                            onClick={() => {
                              const nReasons: OutcomeReason[] = [];
                              outcomeReasons.forEach((v) => {
                                const n = Object.assign({}, v);
                                if (n.outcome_reason_id === row.outcome_reason_id) {
                                  n.editing = false;
                                }
                                nReasons.push(n);
                              });
                              setOutcomeReasons(nReasons);

                              nReasons.forEach((r: OutcomeReason) => {
                                if (r.outcome_reason_id === 0) {
                                  addOutcomeReason(editOutcome.outcome_id, r.outcome_reason_name, r.outline_color).then((e) => {
                                    fetchOutcomeReasons(editOutcome.outcome_id).then((reasons) => {
                                      setOutcomeReasons(reasons);
                                    });
                                  }).catch(showError("Unable to add outcome reason"));
                                }

                                if (r.outcome_reason_id !== 0 && r.outcome_reason_id === row.outcome_reason_id) {
                                  updateOutcomeReason(r.outcome_reason_id, r.outcome_reason_name, r.outline_color).then((e) => {
                                    fetchOutcomeReasons(editOutcome.outcome_id).then((reasons) => {
                                      setOutcomeReasons(reasons);
                                    });
                                  }).catch(showError("Unable to update outcome reason"));
                                }
                              });
                            }}
                          >
                            <SaveIcon />
                          </IconButton>
                        </>
                      ) : (
                          row.outline_color
                      )}
                    </TableCell>
                    <TableCell align="right">{row.created_at}</TableCell>
                    <TableCell align="right" style={{ width: 100 }}>
                      <IconButton
                        color="secondary"
                        size="small"
                        aria-label="delete"
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                          setReasonConfirmAnchorEl(event.currentTarget);
                          setReasonIdRemoval(row.outcome_reason_id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                      <Popover
                        anchorEl={reasonConfirmAnchorEl}
                        open={Boolean(reasonConfirmAnchorEl)}
                        onClose={() => {
                          setReasonConfirmAnchorEl(null);
                          setReasonIdRemoval(0);
                        }}
                        anchorOrigin={{
                          vertical: "center",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "center",
                          horizontal: "right",
                        }}
                      >
                        <Button
                          size="small"
                          color="secondary"
                          aria-label="confirm deletion"
                          onClick={() => {
                            setReasonConfirmAnchorEl(null);
                            setIsSaving(true);
                            removeOutcomeReason(reasonIdRemoval).then(() => {
                              fetchOutcomeReasons(editOutcome.outcome_id).then((reasons) => {
                                setOutcomeReasons(reasons);
                                setIsSaving(false);
                                setReasonIdRemoval(0);
                              });
                            }).catch(showError("Unable to remove outcome reason"));
                          }}
                        >
                          Confirm
                        </Button>
                      </Popover>

                      <IconButton
                        size="small"
                        aria-label="edit"
                        onClick={() => {
                          const nReasons: OutcomeReason[] = [];
                          outcomeReasons.forEach((v) => {
                            const n = Object.assign({}, v);
                            if (n.outcome_reason_id === row.outcome_reason_id) {
                              n.editing = true;
                            }
                            nReasons.push(n);
                          });
                          setOutcomeReasons(nReasons);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowOutcomeEditor(false);
              onRefreshList();
            }}
            color="primary"
          >
            Close
          </Button>
          {editOutcome.customer_id !== null ? (
            <Button color="primary" onClick={onSaveOutcome}>
              {isSaving ? <i className="fas fa-spin fa-spinner" style={{ marginRight: 5 }} /> : null}
              {editMode ? "Save" : "Add"}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}