/*

-- sms campaigns that is set up to use the 
select sms_campaign_id, sms_campaign_name, sc2.date_from,sc2.date_to
from segments s
inner join sms_campaigns sc2 on sc2.campaign_id = s.campaign_id 
inner join segment_contacts sc on sc.segment_id = s.segment_id
inner join contact_details cd on cd.contact_id = sc.contact_id
where 
	sc2.customer_id = 12 and
	sc2.date_from <= getdate() and 
	(sc2.date_to >= getdate() or sc2.date_to is null) and 
	cd.mobile = '90533836'

-- actions on campaign (latest action)
select * from sms_campaign_interactions sci where sms_campaign_id = 10 order by created_at desc



-- 

*/
import { parse } from "date-fns";

declare var env: any;
//env._apiPath = 'http://api-local.fiko.no/manager';

export interface ICurrent {
    sms_campaign_id: number, 
    sms_campaign_step_id: number 
};

export interface ISmsCampaign {
    sms_campaign_id: number,
    campaign_id: number,
    sms_campaign_name: string,
    sms_campaign_description: string,
    interaction_type_id: number,
    customer_sender_id: number,
    send_percent: number,
    created_at: Date,
    updated_at: Date,
    removed_at?: Date
    date_from: Date,
    date_to?: Date | null,
    client_id?: number
}

export interface IStepSettings {
    contact_directory_id: number
};

export interface ISmsCampaignStep {
    sms_campaign_step_id: number,
    sms_campaign_id: number,
    step_name: string,
    parent_sms_campaign_step_id: number,
    interaction_type_id: number,
    send_percent: number,
    settings: IStepSettings,
    interaction_message: string,
    delay: number,
    created_at: Date,
    updated_at: Date,
    removed_at?: Date
}

export interface ISender {
    customer_sender_id: number,
    customer_id?: number,
    client_id?: number,
    created_at: Date,
    updated_at: Date,
    sender: string,
    provider: string,
    sms_token_sender_id?: number,
    sender_name: string,
    country_id: number
}
export interface IInteraction {
    interaction_type_id: number,
    interaction_type_name: string,
    created_at: Date,
    updated_at: Date
}
export interface ISMSInteraction {
    sms_campaign_interaction_id: number,
    interaction_message: string,
    created_at: Date,
    sender: string,
    recipient: string,
    firstname: string,
    lastname: string
}
export interface IContactDirectory {
    contact_directory_id: number,
    contact_directory_name: string
};


export const deleteCampaignStep = async ( sms_campaign_step: ISmsCampaignStep ) => {
    const res = await fetch( env._apiPath + '/sms/campaign/' + sms_campaign_step.sms_campaign_id + '/step/' + sms_campaign_step.sms_campaign_step_id, { // 
        method: "DELETE",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    
    const json = await res.json();

    if(json.status === "success") {
        return json;
        
    }

    throw json.message;
}
export const updateCampaignStep = async ( sms_campaign_step: ISmsCampaignStep ) => {
    const res = await fetch( env._apiPath + '/sms/campaign/' + sms_campaign_step.sms_campaign_id + '/step', { // 
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(sms_campaign_step),
    });
    
    const json = await res.json();

    if(json.status === "success") {
        return json;
        
    }

    throw json.message;
}

export const createCampaignStep = async ( sms_campaign_step: ISmsCampaignStep ) => {
    const res = await fetch( env._apiPath + '/sms/campaign/' + sms_campaign_step.sms_campaign_id + '/step', { // 
        method: "PUT",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(sms_campaign_step),
    });
    
    const json = await res.json();

    if(json.status === "success") {
        return json;
        
    }

    throw json.message;
}

export const updateCampaign = async ( sms_campaign: ISmsCampaign ) => {
    const res = await fetch( env._apiPath + '/sms/campaign', { // 
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(sms_campaign),
    });
    
    const json = await res.json();

    if(json.status === "success") {
        return json;
        
    }

    throw json.message;
}

export const createCampaign = async ( sms_campaign: ISmsCampaign ) => {
    const res = await fetch( env._apiPath + '/sms/campaign', { // 
        method: "PUT",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(sms_campaign),
    });
    
    const json = await res.json();

    if(json.status === "success") {
        return json;
        
    }

    throw json.message;
}

export const deleteCampaign = async ( sms_campaign: ISmsCampaign ) => {
    const res = await fetch( env._apiPath + '/sms/campaign/' + sms_campaign.sms_campaign_id, { // 
        method: "DELETE",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    
    const json = await res.json();

    if(json.status === "success") {
        return json;
        
    }

    throw json.message;
}


export const getSMSCampaignSettings = async () => {
    const res = await fetch( env._apiPath + '/sms/campaigns/settings', { // 
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    
    const json = await res.json();

    if(json.status === "success") {
        return json;
        
    }

    throw json.message;
  
}

export const fetchSMSCampaigns = async (client_id?: number) => {
    const res = await fetch( env._apiPath + '/sms/campaigns' + (client_id?'client_id=' + client_id:''), { // 
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    
    const json = await res.json();

    if(json.status === "success") {
        return json;
    }

    throw json.message;

}

export const fetchLastInteractions = async (sms_campaign_id: number, sms_campaign_step_id?: number) => {
    var url:string;

    if (sms_campaign_step_id == null) 
        url = env._apiPath + '/sms/interactions/' + sms_campaign_id;
    else
        url = env._apiPath + '/sms/interactions/' + sms_campaign_id + '/step/' + sms_campaign_step_id;
        
    const res = await fetch( url, { // 
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    
    const json = await res.json();

    if(json.status === "success") {
        return json;
        
    }

    throw json.message;

}

export const fetchSMSCampaign = async ( sms_campaign_id: number ) => {
    const res = await fetch( env._apiPath + '/sms/campaign/' + sms_campaign_id, { // 
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    
    const json = await res.json();

    if(json.status === "success") {
        if (json.data.date_from !== null) {
            json.data.date_from = parse(json.data.date_from, "dd.MM.yyyy HH:mm", new Date());
        }
        if (json.data.date_to !== null) {
            json.data.date_to = parse(json.data.date_to, "dd.MM.yyyy HH:mm", new Date());
        }
      
        return json;        
    }

    throw json.message;
}

export const fetchSMSCampaignStep = async ( sms_campaign_id: number, sms_campaign_step_id: number ) => {
    const res = await fetch( env._apiPath + '/sms/campaign/' + sms_campaign_id + '/step/' + sms_campaign_step_id, { // 
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    
    const json = await res.json();

    if(json.status === "success") {
        return json;
        
    }

    throw json.message;
}
export const fetchSMSCampaignSteps = async ( sms_campaign_id: number ) => {
    const res = await fetch( env._apiPath + '/sms/campaign/' + sms_campaign_id + '/steps', { // 
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    
    const json = await res.json();

    if(json.status === "success") {
        return json;
        
    }

    throw json.message;
}
