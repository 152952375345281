import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from "react";
import {
  Checkbox,
  TextField,
  Button,
  FormControl,
  FormLabel,
  FormGroup,
  createStyles, 
  makeStyles,
  FormControlLabel
} from "@material-ui/core";

import "./reportSearchFilter.scss";
import { ContactSupportOutlined } from "@material-ui/icons";

interface IFRefProps {
  filterChangedCallback?: any;
  colDef?: any;
}

const useStyles = makeStyles(() =>
    createStyles({
      reportFilter: {
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
        fontSize: '12px',
        lineHeight: 'normal'
      }
    })
);

export default forwardRef((props: IFRefProps, ref) => {
  const classes = useStyles();
  const [filterModelTemp, setFilterModelTemp] = useState({ textFilter: "", filterEmpty: false });
  const [filterModel, setFilterModel]         = useState({ textFilter: "", filterEmpty: false });
  const inputRef = useRef<any>(null);
  
  useEffect(() => {
    props.filterChangedCallback();
  }, [filterModel]);

  useImperativeHandle(ref, () => ({
    isFilterActive: () => {
      if (filterModel.textFilter.length > 0) {
        return true;
      }

      if (filterModel.filterEmpty === true) {
        return true;
      }

      return false;
    },
    getModel: () => {
      return filterModel;
    },
    doesFilterPass: params => {
      //return params.data.price.toString() === inputRef.current.value;
    }
  }));

  const onTextChange = e => { 
    setFilterModelTemp({ filterEmpty: false, textFilter: e.target.value });
  };

  const onTextChangeCopy = e => {
    setFilterModel(filterModelTemp);
  }

  const onChangeEmptyFilter = e => {
    setFilterModel({
      ...filterModel,
      filterEmpty: e.target.checked ? true : false
    });
  };

  return (
    <div>
      <FormControl component="fieldset">
        <FormLabel component="legend" className={classes.reportFilter}>Filter</FormLabel>
        <FormGroup>
          <TextField           
            value={filterModelTemp.textFilter}
            label="Filter Column"
            onChange={onTextChange}
            ref={inputRef}            
          />
        <Button onClick={onTextChangeCopy}>Search</Button>
        <FormControlLabel
          control={<Checkbox checked={filterModel.filterEmpty} onChange={onChangeEmptyFilter} name="findEmpty" />}
          className={classes.reportFilter}
          label="Find empty"
        />
        </FormGroup>
      </FormControl>
    </div>
  );
});