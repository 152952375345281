declare var env: any;

export interface ICallbacks {

}
export const getCallbacks = async (agent: number = 0, core_campaign_id: number = 0, from_date: number, to_date: number) => {
    const res = await fetch(env._apiPath + `/reports/callbacks?agent=${agent}&core_campaign_id=${core_campaign_id}&from_date=${from_date}&to_date=${to_date}`, {
      method: "GET",
      credentials: "include"
    });
    const json = await res.json();

    if(json.status === "success") {
        return json;
    }

    throw json.message;
};