declare var env: any;

export interface Campaign {
    campaign_id: number,
    campaign_name: string,
    campaign_type_id: any,
    campaign_type_name?: string,
    client_id: any,
    ref_id?: any,
    created_at?: string,
    customer_platform_id?: number,
    customer_platform_name?: string,
    ext_state?: any,
    ext_state_change?: any,
    ext_status?: any,
    shortname: string,
    sysname?: string,
    state?: any
}

export interface CampaignType {
    campaign_type_id: number,
    campaign_type_name: string,
    client: any,
    client_id: any,
    company_id: any,
    ref_id?: any,
    created_at?: string,
    customer_id?: number,
    customer_platform_id?: any,
    ext_id?: any,
    ext_status?: any,
    prefix?: string,
    updated_at?: string
}

export interface CampaignClient {
    client_id: any,
    client_name?: string,
    client_color?: string,
    campaign_types_count?: string,
    prefix?: string,
    created_at?: string
}

function encodeQueryString(params) {
    const keys = Object.keys(params);
    return keys.length
        ? "?" + keys.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])).join("&")
        : "";
}

export const fetchCampaigns = async (clientId: number, companyId?: number, campaignTypeId?: number) => {
    const reqParams = {
        ...clientId && {client_id: clientId},
        ...companyId && {company_id: companyId},
        ...campaignTypeId && {campaign_type_id: campaignTypeId}
    }
    const params = encodeQueryString(reqParams);

    const res = await fetch(env._apiPath + "/customer/campaigns" + params, {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    const json = await res.json();

    if (json.status === "success") {
        return json.data;
    }

    throw json.message;
}

export const fetchClientsWithCampaigns = async(companyId: number) => {
    const reqParams = {
        ...companyId && {company_id: companyId},
    }
    const params = encodeQueryString(reqParams);

    const res = await fetch(env._apiPath + "/customer/clientsWithCampaigns" + params, {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    const json = await res.json();

    if (json.status === "success") {
        return json.data;
    }

    throw json.message;
}

export const fetchClientCampaignTypes = async(clientId: number) => {
    const res = await fetch(env._apiPath + "/customer/campaigntypes/" + clientId, {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    const json = await res.json();

    if(json.status === "success") {
        return json.data;
    }

    throw json.message;
}

export const createCampaign = async(campaign: Campaign) => {
    const req = {
        client_id: campaign.client_id,
        campaign_type_id: campaign.campaign_type_id,
        campaign_name: campaign.campaign_name,
        shortname: campaign.sysname
    }
    
    const res = await fetch(env._apiPath + "/customer/campaign", {
        method: "PUT",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(req)
    });
    const json = await res.json();

    if(json.status === "success") {
        return json.data;
    }

    throw json.message;
}

export const updateCampaign = async(campaign: Campaign) => {
    const req = {
        client_id: campaign.client_id,
        campaign_id: campaign.campaign_id,
        campaign_type_id: campaign.campaign_type_id,
        campaign_name: campaign.campaign_name,
        shortname: campaign.shortname,
        ref_id: campaign.ref_id
    }
    
    const res = await fetch(env._apiPath + "/customer/campaign/edit", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(req)
    });
    const json = await res.json();

    if(json.status === "success") {
        return json.data;
    }

    throw json.message;
}

export const deleteCampaign = async(campaignId: number) => {
    const res = await fetch(env._apiPath + "/customer/campaign/" + campaignId, {
        method: "DELETE",
        credentials: "include",
        headers: { "Content-Type": "application/json" }
    });
    const json = await res.json();

    if (json.status === "success") {
        return json.data;
    }

    throw json.message;
}