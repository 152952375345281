import React from "react";
import ReactDOM from "react-dom";
// import Flag from "react-world-flags";

export function AgLinkRenderer(params: any) {
  const directoryContainer = document.createElement("div");

  if (params.data.contact_directory_name) {
    ReactDOM.render(
      <a href={"/listmanagement/directories/" + params.data.contact_directory_id}>
        {params.data.contact_directory_name}
      </a>,
      directoryContainer
    );
  }

  return directoryContainer;
}

export function AgNoLinkRenderer(params: any) {
  const directoryContainer = document.createElement("div");

  if (params.data.contact_directory_name) {
    ReactDOM.render(<span className="no-link">{params.data.contact_directory_name}</span>, directoryContainer);
  }

  return directoryContainer;
}

export function AgCountryRenderer(params: any) {
  const countryContainer = document.createElement("div");

  if (params.data.country && params.data.country.id) {
    const countryName = params.data.country.name;
    const code = params.data.country.code;

    ReactDOM.render(
      <div className="directory-country">
        {/* todo make flag icons and not depend on huge flag-package <Flag code={code} height={16} />*/}
        {countryName}
      </div>,
      countryContainer
    );
  } else {
    ReactDOM.render(<div className="directory-country">-</div>, countryContainer);
  }

  return countryContainer;
}

export function AgStatusRenderer(options: any) {
  return (params: any) => {
    const container = document.createElement("div");
    if (params.data[options.field]) {
      ReactDOM.render(
        <div className={options.className}>
          {options.switches[params.data[options.field]]
            ? options.switches[params.data[options.field]]
            : options.default}
        </div>,
        container
      );
    }

    return container;
  };
}

export interface IAgToolbarButtonProps {
  title: string;
  rightAlign: boolean;
  disabled: boolean;
  icon: string;
  onClick: any;
  iconColor?: string;
}

export function AgToolbarButton(props: IAgToolbarButtonProps) {
  let clsName = "";

  if (props.rightAlign) {
    clsName = "btn btn-sm btn-link mr-2 fs-18 dt-custom-background ag-button text-secondary";
  }

  if (!props.rightAlign) {
    clsName = "btn btn-sm btn-link mr-2 fs-18 dt-custom-background text-secondary";
  }

  return (
    <button
      data-toggle="tooltip"
      onClick={props.onClick}
      title={props.title}
      className={clsName}
      disabled={props.disabled}
    >
      <span>
        <i className={"fas fa-fw " + props.icon}></i>
      </span>
    </button>
  );
}
