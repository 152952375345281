import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button
} from "@material-ui/core";

interface INewSegmentDialogProps {
  open: boolean;
  onClose: any;
  onSave: any;
}

export default function NewSegmentDialog(props: INewSegmentDialogProps) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">New Map Segment</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will create a new segment and allows you to start assigning areas
          into it. Please enter a name to begin.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Segment Name"
          type="text"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={props.onSave} color="primary">
          Begin
        </Button>
      </DialogActions>
    </Dialog>
  );
}
