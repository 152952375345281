import {
  actionReceivedConfirmations,
  actionReceivedConfirmationDetail,
  actionReceivedCustomerSenders,
  actionFormConfirmationFailed,
  actionFormConfirmationTextFailed,
  actionFormConfirmationTextUpdateFailed,
  actionFormConfirmationDeleteFailed,
  actionFormConfirmationUpdateFailed,
  actionFormConfirmationTextDeleteFailed,
  actionReceivedSMSMergeFields
} from "../../../redux/reducers/campaignmanagement/forms";
import { actionStartAPI, actionEndAPI } from "../../../redux/reducers/shell";

declare var env: any;

const fParams = (method, body): RequestInit => ({
  method: method,
  credentials: "include",
  headers: {
    "Content-Type": "application/json"
  },
  body
});

function loadFormConfirmations(dispatch, formId) {
  const fetchConfirmations = async () => {
    dispatch(actionStartAPI());
    const res = await fetch(env._apiPath + "/formConfirmations?form_id=" + formId, {
      method: "GET",
      credentials: "include"
    });
    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status !== "success") {
      // dispatch(actionSetLoginErrorMessage(json.message))
      return;
    }
    const confirmations = json.data;

    // Utility states
    confirmations.form_confirmations.forEach(c => {
      c.confirmDelete = false;
    });

    dispatch(actionReceivedConfirmations(confirmations));
  };

  fetchConfirmations();
}

function loadFormConfirmationDetails(dispatch, formConfirmationId) {
  const fetchConfirmations = async () => {
    dispatch(actionStartAPI());
    const res = await fetch(env._apiPath + "/formConfirmation?form_confirmation_id=" + formConfirmationId, {
      method: "GET",
      credentials: "include"
    });
    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status !== "success") {
      // dispatch(actionSetLoginErrorMessage(json.message))
      return;
    }

    const details = json.data;

    dispatch(actionReceivedConfirmationDetail(details));
  };

  fetchConfirmations();
}

function loadFormCustomerSenders(dispatch, formId) {
  const fetchCustomerSenders = async () => {
    dispatch(actionStartAPI());
    const res = await fetch(env._apiPath + "/customerSenders?form_id=" + formId, {
      method: "GET",
      credentials: "include"
    });
    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status !== "success") {
      // dispatch(actionSetLoginErrorMessage(json.message))
      return;
    }

    const customerSenders = json.data;

    dispatch(actionReceivedCustomerSenders(customerSenders));
  };

  fetchCustomerSenders();
}

function createFormConfirmation(dispatch, formId, confirmationMethodId, customerSenderId) {
  const fetchCreateFormConfirmation = async () => {
    dispatch(actionStartAPI());
    const req = {
      form_id: formId,
      confirmation_method_id: confirmationMethodId,
      customer_sender_id: customerSenderId
    };

    const res = await fetch(env._apiPath + "/formConfirmation", fParams("PUT", JSON.stringify(req)));
    const json = await res.json();
    if (json.status !== "success") {
      dispatch(actionFormConfirmationFailed(json.message, formId, confirmationMethodId, customerSenderId));
      return;
    }
    dispatch(actionEndAPI());
    loadFormConfirmations(dispatch, formId);
  };
  fetchCreateFormConfirmation();
}

function updateFormConfirmation(dispatch, formConfirmationId, customerSenderId, settings) {
  const fetchUpdateFormConfirm = async () => {
    dispatch(actionStartAPI());
    const req = {
      form_confirmation_id: formConfirmationId,
      customer_sender_id: customerSenderId,
      settings: settings
    };
    const res = await fetch(env._apiPath + "/formConfirmation", fParams("POST", JSON.stringify(req)));
    const json = await res.json();
    if (json.status !== "success") {
      dispatch(actionFormConfirmationUpdateFailed(json.message, formConfirmationId, customerSenderId));
      return;
    }
    dispatch(actionEndAPI());
  };
  fetchUpdateFormConfirm();
}

function deleteFormConfirmation(dispatch, formId, formConfirmationId) {
  const fetchDeleteConfirmation = async () => {
    dispatch(actionStartAPI());
    const req = {
      form_confirmation_id: formConfirmationId
    };
    const res = await fetch(env._apiPath + "/formConfirmation", fParams("DELETE", JSON.stringify(req)));
    const json = await res.json();
    if (json.status !== "success") {
      dispatch(actionFormConfirmationDeleteFailed(json.message, formConfirmationId));
      return;
    }
    dispatch(actionEndAPI());
    loadFormConfirmations(dispatch, formId);
  };
  fetchDeleteConfirmation();
}

function createFormConfirmationText(dispatch, formId, formConfirmationId, confirmationTextTypeId, confirmationText) {
  const fetchCreateConfirmationText = async () => {
    dispatch(actionStartAPI());
    const req = {
      form_confirmation_id: formConfirmationId,
      confirmation_text_type_id: confirmationTextTypeId,
      confirmation_text: confirmationText
    };

    const res = await fetch(env._apiPath + "/formConfirmationText", fParams("PUT", JSON.stringify(req)));
    const json = await res.json();
    if (json.status !== "success") {
      dispatch(
        actionFormConfirmationTextFailed(json.message, formConfirmationId, confirmationTextTypeId, confirmationText)
      );
      return;
    }
    dispatch(actionEndAPI());
    loadFormConfirmations(dispatch, formId);
  };
  fetchCreateConfirmationText();
}

function editConfirmationText(dispatch, formId, formConfirmationTextId, confirmationText) {
  const fetchEditConfText = async () => {
    dispatch(actionStartAPI());
    const req = {
      form_confirmation_text_id: formConfirmationTextId,
      confirmation_text: confirmationText
    };
    const res = await fetch(env._apiPath + "/formConfirmationText", fParams("POST", JSON.stringify(req)));
    const json = await res.json();
    if (json.status !== "success") {
      dispatch(actionFormConfirmationTextUpdateFailed(json.message, formConfirmationTextId, confirmationText));
      return;
    }
    dispatch(actionEndAPI());
    loadFormConfirmations(dispatch, formId);
  };
  fetchEditConfText();
}

function deleteConfirmationText(dispatch, formId, formConfirmationTextId) {
  const fetchDeleteConfText = async () => {
    dispatch(actionStartAPI());
    const req = {
      form_confirmation_text_id: formConfirmationTextId
    };
    const res = await fetch(env._apiPath + "/formConfirmationText", fParams("DELETE", JSON.stringify(req)));
    const json = await res.json();
    if (json.status !== "success") {
      dispatch(actionFormConfirmationTextDeleteFailed(json.message, formConfirmationTextId));
      return;
    }
    dispatch(actionEndAPI());
    loadFormConfirmations(dispatch, formId);
  };
  fetchDeleteConfText();
}

function loadSMSMergeFields(dispatch) {
  const fetchMergeFields = async () => {
    dispatch(actionStartAPI());
    const res = await fetch(env._apiPath + "/mergeFields?object_name=sms_text_form", {
      method: "GET",
      credentials: "include"
    });
    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status !== "success") {
      // dispatch(actionSetLoginErrorMessage(json.message))
      return;
    }
    const fields = json.data;

    dispatch(actionReceivedSMSMergeFields(fields));
  };

  fetchMergeFields();
}

export default {
  loadFormConfirmations,
  loadFormConfirmationDetails,
  loadFormCustomerSenders,
  createFormConfirmation,
  updateFormConfirmation,
  deleteFormConfirmation,
  createFormConfirmationText,
  editConfirmationText,
  deleteConfirmationText,
  loadSMSMergeFields
};
