declare var env: any;

export interface Agent {
    agent_id: number;
    customer_id?: number;
    fullname?: string;
    login_name?: string;
    email?: string;
    mobile?: string;
    location?: string;
    department_id?: any;
    department_name?: string;
    company_id?: any;
    company_name?: string;
    created_at?: string;
    has_salary?: number;
    leader?: number;
    customer_platform_id?: any;
}

export interface AgentType {
    agent_type_id: any,
    agent_type_name?: string,
    customer_id?: number,
    created_at?: string,
}

export interface AgentTypeEdit {
    agent_type_id?: any,
    date_from?: Date
}

export interface Team {
    team_id: number;
    company_id?: string;
    department_id?: string;
    team_name?: string;
    customer_platform_id?: string;
    joined_at: Date;
}

export interface CustomerPlatform {
    customer_platform_id: number;
    customer_platform_name: string;
}

function encodeQueryString(params) {
    const keys = Object.keys(params);
    return keys.length
        ? "?" + keys.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])).join("&")
        : "";
}

export const fetchAgents = async (companyId?: number, departmentId?: number, teamId?: number) => {
    const reqParams = {
        ...departmentId && {department_id: departmentId},
        ...companyId && {company_id: companyId},
        ...teamId && {team_id: teamId}
    }
    const params = encodeQueryString(reqParams);

    const res = await fetch(env._apiPath + "/customer/agents" + params, {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    const json = await res.json();

    if(json.status === "success") {
        return json.data;
    }

    throw json.message;
}

export const fetchAgent = async(agentId: number) => {
    const res = await fetch(env._apiPath + "/agent/" + agentId, {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    const json = await res.json();

    if(json.status === "success") {
        return json.data;
    }

    throw json.message;
}

export const fetchTeams = async (companyId: any, departmentId: any) => {
    var queryString = companyId ? "?company_id=" + companyId + "&departmentId=" + departmentId : "";

    const res = await fetch(env._apiPath + "/company/teams" + queryString, {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    const json = await res.json();

    if(json.status === "success") {
        return json.data;
    }

    throw json.message;
}

export const fetchAgentTeams = async(agentId: number) => {
    const res = await fetch(env._apiPath + "/agentTeams/" + agentId, {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    const json = await res.json();

    if(json.status === "success") {
        return json.data;
    }

    throw json.message;
}

export const fetchPlatforms = async() => {
    const res = await fetch(env._apiPath + "/customerPlatforms", {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    const json = await res.json();

    if(json.status === "success") {
        return json.data;
    }

    throw json.message;
}

export const fetchAgentTypes = async () => {
    const res = await fetch(env._apiPath + "/agentTypes", {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    const json = await res.json();

    if(json.status === "success") {
        return json.data;
    }

    throw json.message;
}

export const addAgentToTeam = async(agentId: number, teamId: any) => {
    const req = {
        agent_id: agentId,
        team_id: teamId
    }

    const res = await fetch(env._apiPath + "/team/agent", {
        method: "PUT",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(req),
    });
    const json = await res.json();

    if(json.status === "success") {
        return json;
    }

    throw json.message;
}

export const addAgentToUser = async(agentId: any, userId: number) => {
    const req = {
        agent_id: agentId,
        user_id: userId
    }

    const res = await fetch(env._apiPath + "/account/changeUserAgent", {
        method: "PUT",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(req),
    });
    const json = await res.json();

    if(json.status === "success") {
        return json.data;
    }

    throw json.message;
}

export const removeAgentFromUser = async(userId: number) => {
    const req = {
        user_id: userId
    }

    const res = await fetch(env._apiPath + "/account/changeUserAgent", {
        method: "PUT",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(req),
    });
    const json = await res.json();

    if (json.status === "success") {
        return json.data;
    }

    throw json.message;
}

export const removeAgentFromTeam = async(agentId: number, teamId: any) => {
    const req = {
        agent_id: agentId,
        team_id: teamId
    }

    const res = await fetch(env._apiPath + "/team/agent", {
        method: "DELETE",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(req),
    });
    const json = await res.json();

    if (json.status === "success") {
        return json;
    }

    throw json.message;
}

export const disconnectAgent = async(agentId: number) => {
    // const res = await fetch(env._apiPath + "/altitude/agentCleanUp/" + agentId, {
    //     method: "GET",
    //     credentials: "include",
    //     headers: { "Content-Type": "application/json" },
    // });
    // const json = await res.json();
    
    // if(json.status === "success") {
    //     return json.data;   
    // }

    // throw json.message;
}

export const resetAgentToken = async(agentId: number) => {
    const res = await fetch(env._apiPath + "/agent/token/" + agentId, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    const json = await res.json();

    if(json.status === "success") {
        return json.data.token;
    }

    throw json.message;
}

export const resetAgentPassword = async(agentId: number) => {
    const req = {
        agent_id: agentId
    }

    const res = await fetch(env._apiPath + "/agent/resetPassword", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(req),
    });
    const json = await res.json();
    console.log(json);

    if(json.status === "success") {
        return json.data;
    }

    throw json.message;
}

export const createAgent = async(agent: Agent) => {
    const req = {
        company_id: agent.company_id,
        department_id: agent.department_id,
        customer_platform_id: agent.customer_platform_id,
        login_name: agent.login_name,
        fullname: agent.fullname,
        email: agent.email,
        mobile: agent.mobile,
    }

    const res = await fetch(env._apiPath + "/agent", {
        method: "PUT",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(req),
    });
    const json = await res.json();

    if(json.status === "success") {
        return json.data;
    }

    throw json.message;
}

export const updateAgent = async(agent: Agent) => {
    const req = {
        agent_id: agent.agent_id,
        login_name: agent.login_name,
        fullname: agent.fullname,
        mobile: agent.mobile,
        email: agent.email,
        location: agent.location,
    }

    const res = await fetch(env._apiPath + "/agent/edit", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(req),
    });
    const json = await res.json();

    if(json.status === "success") {
        return json.data;
    }

    throw json.message;
}

export const deleteAgent = async(agentId: number) => {
    const res = await fetch(env._apiPath + "/agent/" + agentId, {
        method: "DELETE",
        credentials: "include",
        headers: { "Content-Type": "application/json" }
    });
    const json = await res.json();

    if (json.status === "success") {
        return json.data;
    }

    throw json.message;
}