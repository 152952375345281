import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import FullMapComponent from "./largemap/FullMap.component";
import {
  AppBar,
  Toolbar,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Paper,
  Card,
  CardContent,
  CardHeader,
  Snackbar,
  Slide,
  Badge,
  Drawer,
  Grid,
  withStyles,
  createMuiTheme,
  ThemeProvider
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import OMSLogo from "./largemap/omslogo.png";
import {
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  AddLocation as AddLocationIcon,
  EditLocation as EditLocationIcon,
  Search as SearchIcon,
  Close as CloseIcon,
  Save as SaveIcon
} from "@material-ui/icons";
import { actionZoomIn, actionZoomOut, actionReceivedAgentPositions } from "../../../redux/reducers/listmanagement/maps";
import NewSegmentDialog from "./largemap/newsegmentdialog";
import SaveSegmentDialog from "./largemap/savesegmentdialog";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { actionHideSnackbar, actionShowSnackbar } from "../../../redux/reducers/shell";
import ClientSelector from "../../shell/clientselector.component";
import CompanySelector from "../../shell/companyselector.component";
import Form from "react-bootstrap/Form";
import CampaignTypeSelector from "../../shell/campaigntypeselector.component";
import CampaignSelector from "../../shell/campaignselector.component";
import DatePicker from "react-datepicker";
import { useInterval } from "../../../custom.hooks";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useAutocompleteStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "#fff",
      "& > div > label": {
        color: "#fff"
      },
      "& > div > label.Mui-focused": {
        color: "#eee"
      },
      "& > div > div::before": {
        borderBottom: 0
      },
      "& > div:hover > div.MuiInput-underline::before": {
        borderBottom: "2px solid #eee"
      },
      "& > div > div::after": {
        borderBottom: "2px solid #fff"
      }
    },
    focused: {
      color: "#fff"
    },
    option: {
      "& > div": {
        fontSize: 12,
        display: "block"
      },
      "& > span": {
        fontSize: 10
      }
    },
    endAdornment: {
      color: "#fff"
    },
    clearIndicator: {
      color: "#fff"
    },
    popupIndicator: {
      color: "#fff"
    },
    popupIndicatorOpen: {
      color: "#fff"
    },
    input: {
      color: "#fff"
    }
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    module: {},
    fullwindow: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      zIndex: 600
    },
    widespace: {
      flex: 1
    },
    appBar: {
      top: 0,
      background: "linear-gradient(0deg, rgba(255,255,255,0) 5%, rgba(0,0,0,0.5) 100%)",
      boxShadow: "none"
    },
    button: {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
      fontWeight: "bold",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.1)"
      }
    },
    tooltext: {
      fontWeight: "bold"
    },
    drop: {
      color: "#fff",
      fontWeight: "bold",
      "&::before": {
        border: 0
      },
      "&::after": {
        border: 0
      },
      "&:hover:not(.Mui-disabled)::before": {
        border: 0
      },
      "&:hover::after": {
        border: 0
      },
      "& .MuiSelect-icon": {
        color: "#fff"
      },
      "& .Mui-focused": {
        color: "#fff"
      }
    },
    formControl: {
      margin: theme.spacing(1)
    },
    drawerPadding: {
      padding: theme.spacing(3)
    }
  })
);

interface ILargeMapProps {
  onClose: any;
  rowData: any;
}
declare var env: any;
export default function LargeMapComponent(props: ILargeMapProps) {
  const classes = useStyles();
  const acClasses = useAutocompleteStyles();

  const dispatch = useDispatch();
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [showNewSegment, setShowNewSegment] = useState(false);
  const [showSaveSegment, setShowSaveSegment] = useState(false);

  const [showFilterDrawer, setShowFilterDrawer] = useState(false);

  const snackbar = useSelector((state: any) => state.shell.snackbar);

  const defaultStartDate = new Date();
  defaultStartDate.setDate(1);
  const [startDate, setStartDate] = useState(defaultStartDate);

  const defaultEndDate = new Date();
  defaultEndDate.setMonth(defaultEndDate.getMonth() + 3);
  defaultEndDate.setDate(0);
  const [endDate, setEndDate] = useState(defaultEndDate);

  useEffect(() => {
    document.body.style.overflow = "hidden";


    const agentPositionFetcher = setInterval(() => {
      const fetchAgents = async () => {
        const res = await fetch(env._apiPath + "/listManagement/mapsSeenAgents", { method: "GET", credentials: "include" });
        const json = await res.json();
        if (json.status === "success") {
          dispatch(actionReceivedAgentPositions(json.data.dots));
        }
      };
      fetchAgents();
    }, 30000);

    return () => {
      document.body.style.overflow = "unset";

       clearInterval(agentPositionFetcher);
    };
  }, []);

  useEffect(() => {
    const fetchAreaPolygons = async () => {
      const areaIds = props.rowData.map(s => s.areas.map(a => a.area_id));
      // console.log("fetch polygons for", areaIds);
    };

    fetchAreaPolygons();
  }, [props.rowData]);

  return (
    <div className={classes.fullwindow}>
      <FullMapComponent />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar disableGutters={true}>
          <IconButton
            onClick={() => dispatch(actionShowSnackbar("Areas could not be loaded. Please zoom in.", "warning"))}
          >
            <img src={OMSLogo} style={{ width: 32 }} />
          </IconButton>

          <Typography style={{ fontWeight: "bold" }}>Segment Editor</Typography>

          <IconButton onClick={() => dispatch(actionZoomIn())}>
            <ZoomInIcon style={{ color: "#fff" }} />
          </IconButton>

          <IconButton onClick={() => dispatch(actionZoomOut())}>
            <ZoomOutIcon style={{ color: "#fff" }} />
          </IconButton>

          <IconButton onClick={() => setShowNewSegment(true)}>
            <AddLocationIcon style={{ color: "#fff" }} />
          </IconButton>

          <IconButton onClick={() => setShowFilterDrawer(!showFilterDrawer)}>
            <Badge color="secondary" variant="dot">
              <SearchIcon style={{ color: "#fff" }} />
            </Badge>
          </IconButton>

          <div className={classes.widespace}></div>

          {/*}
          <Typography style={{ fontWeight: "bold", cursor: "pointer" }}>
            Editing: Tinnheia-2020-01-31
          </Typography>
          <IconButton>
            <SaveIcon style={{ color: "#fff" }} />
            </IconButton>*/}

          <div style={{ width: 20 }} />

          <Autocomplete
            id="segment-select"
            style={{ width: 300 }}
            classes={acClasses}
            options={props.rowData}
            autoHighlight
            getOptionLabel={(option: any) => option.segment_name}
            renderOption={(option: any) => (
              <React.Fragment>
                <Grid container>
                  <Grid item xs={10}>
                    <div>{option.segment_name}</div>
                  </Grid>
                  <Grid item xs={10}>
                    <span>
                      {option.segment_blocked} of {option.segment_coordinates} blocked
                    </span>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
            renderInput={params => (
              <TextField
                {...params}
                label="Select Segment"
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password" // disable autocomplete and autofill
                }}
              />
            )}
          />

          <IconButton onClick={props.onClose}>
            <CloseIcon style={{ color: "#fff" }} />
          </IconButton>
        </Toolbar>
      </AppBar>

      <NewSegmentDialog
        open={showNewSegment}
        onClose={() => setShowNewSegment(false)}
        onSave={() => setShowNewSegment(false)}
      />

      <SaveSegmentDialog
        open={showSaveSegment}
        onClose={() => setShowSaveSegment(false)}
        onSave={() => setShowSaveSegment(false)}
      />

      <Snackbar open={snackbar.open} onClose={() => dispatch(actionHideSnackbar())} autoHideDuration={6000}>
        <Alert severity="warning" onClose={() => dispatch(actionHideSnackbar())}>
          Areas could not be loaded. Please zoom in.
        </Alert>
      </Snackbar>

      <Drawer open={showFilterDrawer} onClose={() => setShowFilterDrawer(false)}>
        <div className={classes.drawerPadding}>
          <ClientSelector />
          <CompanySelector />

          <Form.Group controlId="frmPeriod">
            <Form.Label>Period</Form.Label>
            <Form.Text className="text-muted">From</Form.Text>
            <DatePicker
              dateFormat="dd.MM.yyyy"
              className="form-control"
              selected={startDate}
              onChange={date => setStartDate(date)}
            />
          </Form.Group>
          <Form.Group controlId="frmPeriod">
            <Form.Text className="text-muted">To</Form.Text>
            <DatePicker
              dateFormat="dd.MM.yyyy"
              className="form-control"
              selected={endDate}
              onChange={date => setEndDate(date)}
            />
          </Form.Group>

          <CampaignTypeSelector />
          <CampaignSelector />
        </div>
      </Drawer>
    </div>
  );
}
