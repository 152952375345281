import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import RefreshIcon from "@material-ui/icons/Refresh";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";

declare var env: any;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  table: {}
}));

const fetchJobHistory = async setJobHistory => {
  const res = await fetch(env._apiPath.replace("/manager", "") + "/admin/jobqueue/listHistory", {
    method: "POST",
    credentials: "include"
  });
  const json = await res.json();
  setJobHistory(json.data);
};

const fetchJobQueue = async setJobQueue => {
  const res = await fetch(env._apiPath.replace("/manager", "") + "/admin/jobqueue/list", {
    method: "POST",
    credentials: "include"
  });
  const json = await res.json();
  setJobQueue(json.data);
};

export default function JobsComponent() {
  const classes = useStyles();

  const [jobQueue, setJobQueue] = useState([]);
  const [jobHistory, setJobHistory] = useState([]);

  const [jobDetails, setJobDetails] = useState<any>({});
  const [showJobDetails, setShowJobDetails] = useState(false);

  useEffect(() => {
    fetchJobQueue(setJobQueue);
    fetchJobHistory(setJobHistory);
  }, [setJobHistory, setJobQueue]);

  useEffect(() => {
    if (jobDetails.jobData) {
      setShowJobDetails(true);
    }
  }, [jobDetails]);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>Queue</Typography>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Queue</TableCell>
                  <TableCell>Job</TableCell>
                  <TableCell>Attempts</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Available At</TableCell>
                  <TableCell>Reserved At</TableCell>
                  <TableCell align="right">
                    <IconButton size="small" onClick={() => fetchJobQueue(setJobQueue)}>
                      <RefreshIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobQueue.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      No jobs in queue.
                    </TableCell>
                  </TableRow>
                ) : null}
                {jobQueue.map((job: any, idx) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell>{job.id}</TableCell>
                      <TableCell>{job.queue}</TableCell>
                      <TableCell>{job.payload.displayName}</TableCell>
                      <TableCell>{job.attempts}</TableCell>
                      <TableCell>{job.created_at}</TableCell>
                      <TableCell>{job.available_at}</TableCell>
                      <TableCell>{job.reserved_at}</TableCell>
                      <TableCell align="right">
                        <PopupState variant="popover" popupId="k-0">
                          {popupState => (
                            <>
                              <IconButton size="small" {...bindTrigger(popupState)}>
                                <MoreVertIcon />
                              </IconButton>
                              <Menu {...bindMenu(popupState)}>
                                <MenuItem
                                  onClick={() => {
                                    setJobDetails(job.payload.data.command);
                                  }}
                                >
                                  Inspect
                                </MenuItem>
                                <MenuItem>Reset</MenuItem>
                                <MenuItem>Remove</MenuItem>
                              </Menu>
                            </>
                          )}
                        </PopupState>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>History</Typography>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Queue</TableCell>
                  <TableCell>Job</TableCell>
                  <TableCell>Customer ID</TableCell>
                  <TableCell>Client ID</TableCell>
                  <TableCell>File Name</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell align="right">
                    <IconButton size="small" onClick={() => fetchJobHistory(setJobHistory)}>
                      <RefreshIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobHistory.map((job: any, idx) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell>{job.job_history_id}</TableCell>
                      <TableCell>{job.queue}</TableCell>
                      <TableCell>{job.payload.displayName}</TableCell>
                      <TableCell>{job.payload.data.command.jobData.customerId}</TableCell>
                      <TableCell>{job.payload.data.command.jobData.clientId}</TableCell>
                      <TableCell>{job.payload.data.command.jobData.originalFilename}</TableCell>
                      <TableCell>{job.created_at}</TableCell>
                      <TableCell align="right">
                        <PopupState variant="popover" popupId="k-1">
                          {popupState => (
                            <>
                              <IconButton size="small" {...bindTrigger(popupState)}>
                                <MoreVertIcon />
                              </IconButton>
                              <Menu {...bindMenu(popupState)}>
                                <MenuItem
                                  onClick={() => {
                                    setJobDetails(job.payload.data.command);
                                  }}
                                >
                                  Inspect
                                </MenuItem>
                                <MenuItem>Re-Queue</MenuItem>
                              </Menu>
                            </>
                          )}
                        </PopupState>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Dialog open={showJobDetails} onClose={() => setShowJobDetails(false)}>
        <DialogTitle id="details-dialog">Job Details</DialogTitle>
        <DialogContent>
          {jobDetails.jobData ? (
            <TableContainer>
              <Table className={classes.table} size="small">
                <TableBody>
                  {Object.keys(jobDetails.jobData).map((key, idx) => {
                    return (
                      <TableRow key={idx}>
                        <TableCell>{key}</TableCell>
                        <TableCell>
                          <div>{jobDetails.jobData[key] ? jobDetails.jobData[key] : null}</div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setShowJobDetails(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
