import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { XYPlot, LineSeries, HorizontalGridLines, XAxis, YAxis } from "react-vis";

declare var env: any;

interface IExportResponsesProps {
  onHide: any;
  showDialog: boolean;
  selectedSegment: any;
  rowData: any;
}

const fetchResponseStatistics = async (
  segmentId: number,
  setStatistics: any,
  setSegmentStatsOutcomes: any,
  setYMax: any,
  setTickLabels: any
) => {
  const res = await fetch(env._apiPath + "/listManagement/mapssegmentresponsestats", {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ segment_id: segmentId })
  });
  const json = await res.json();

  if (json.data) {
    const outcomes = {};
    // find unique outcomes
    // create hash [outcomeid] = {X: date, Y: count}
    const dateRange = json.data.range;
    setTickLabels(dateRange);

    const lookupDateIndex = date => {
      let dateIndex = null;
      dateRange.forEach((d, idx) => {
        if (d === date) {
          dateIndex = idx;
        }
      });

      return dateIndex;
    };

    let yMax = 0;
    json.data.points.forEach(o => {
      if (!outcomes[o.last_outcome_id]) {
        outcomes[o.last_outcome_id] = [];
      }
      outcomes[o.last_outcome_id].push({
        x: lookupDateIndex(o.response_date),
        y: parseInt(o.responses)
      });
      yMax = Math.max(o.responses, yMax);
    });
    yMax += 10;
    setYMax(yMax);

    const outcomeIds = Object.keys(outcomes);

    setStatistics(outcomes);
    setSegmentStatsOutcomes(outcomeIds);

    return;
  }

  setStatistics([]);
};

export default function ExportResponsesComponent(props: IExportResponsesProps) {
  const defaultEmptySegment = {
    outcome_1: 0,
    outcome_2: 0,
    outcome_9: 0,
    outcome_10: 0,
    segment_coordinates: 0,
    segment_blocked: 0
  };

  const [segmentDetails, setSegmentDetails] = useState(defaultEmptySegment);
  const [segmentStatistics, setSegmentStatistics] = useState([]);
  const [segmentStatsOutcomes, setSegmentStatsOutcomes] = useState([]);
  const [tickLabels, setTickLabels] = useState({});

  const [yMax, setYMax] = useState(0);

  const xTickFormat = (value: any, index: any, scale: any, tickTotal: any) => {
    return (
      <tspan>
        <tspan x="0" dy="1em">
          {tickLabels[index]}
        </tspan>
      </tspan>
    );
  };

  useEffect(() => {
    if (props.rowData) {
      const segment = props.rowData
        .filter(s => parseInt(s.segment_id) === parseInt(props.selectedSegment.segmentId))
        .pop();

      if (segment) {
        setSegmentDetails(segment);
        // fetchResponseStatistics(segment.segment_id, setSegmentStatistics, setSegmentStatsOutcomes, setYMax, setTickLabels);
        return;
      }

      setSegmentDetails(defaultEmptySegment);
    }
  }, [props.rowData, props.selectedSegment]);

  const onExportResponse = () => {
    window.open(
      env._apiPath + "/listManagement/mapsResponseFile?segment_id=" + props.selectedSegment.segmentId,
      "_blank"
    );
    props.onHide();
  };

  return (
    <Modal size="lg" centered show={props.showDialog} onHide={props.onHide}>
      <Modal.Header>
        <Modal.Title>Export Responses</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {/*
        <XYPlot height={250} width={740} margin={{ bottom: 100 }} yDomain={[0, yMax]} xType="ordinal">
          <HorizontalGridLines />

          {segmentStatsOutcomes.map((o, k) => {
            return <LineSeries data={segmentStatistics[o]} key={k} />;
          })}

          <XAxis title="Day" tickLabelAngle={-40} tickFormat={xTickFormat} />
          <YAxis title="Outcomes" />
        </XYPlot>
        */}

        <Row>
          <Col>
            This will export all responses on segment <i>{props.selectedSegment.segmentName}</i>.
          </Col>
        </Row>

        <br />

        <Row>
          <Col md={6}>
            <Row>
              <Col>Statistics for Segment</Col>
            </Row>
            <Row>
              <Col>
                <b>Addresses</b>
                <br />
                {segmentDetails.segment_coordinates}
              </Col>
              <Col>
                <b>Blocked</b>
                <br />
                {segmentDetails.segment_blocked}
              </Col>
            </Row>
          </Col>
          <Col md={6}>
            <Row>
              <Col>Statistics for Export</Col>
            </Row>
            <Row>
              <Col>
                <b>Yes</b>
                <br />
                {segmentDetails.outcome_1}
              </Col>
              <Col>
                <b>No</b>
                <br />
                {segmentDetails.outcome_2}
              </Col>
              <Col>
                <b>Come Back</b>
                <br />
                {segmentDetails.outcome_9}
              </Col>
              <Col>
                <b>Not Home</b>
                <br />
                {segmentDetails.outcome_10}
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.onHide()}>
          Close
        </Button>
        <Button variant="primary" onClick={() => onExportResponse()}>
          Export Responses
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
