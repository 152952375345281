import React, { useState, useEffect } from "react";
import { Tabs, Tab, Typography, Box } from "@material-ui/core";
import TitlebarComponent from "../../titlebar.component";
import TabConfirmationComponent from "./forms/tabconfirmation.component";
import TabEditComponent from "./forms/tabedit.component";
import TabCategoriesComponent from "./forms/tabcategories.component";
import TabCampaignsComponent from "./forms/tabcampaigns.component";
import TabDiscountComponent from "./forms/tabdiscount.component";
import TabFormFieldsComponent from "./forms/tabformfields.component";
import userSettings from "../../userSettings";

interface IFormsProps {
  match?: any;
}

interface ITabPanel {
  children: any;
  index: any;
  value: any;
}

function TabPanel(props: ITabPanel) {
  const { children, value, index, ...other } = props;

  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

export default function FormsComponent(props: IFormsProps) {
  const formId = props.match.params.formId;
  const navigateToTab = props.match.params.selectTab;

  const [selectedTab, setSelectedTab] = useState(userSettings.getOption(userSettings.CAMPAIGNS_FORMS_SELECTED_TAB, 0));

  useEffect(() => {
    userSettings.setOption(userSettings.CAMPAIGNS_FORMS_SELECTED_TAB, selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    switch (navigateToTab) {
      case "edit":
        setSelectedTab(0);
        break;
      case "campaigns":
        setSelectedTab(1);
        break;
      case "categories":
        setSelectedTab(2);
        break;
      case "discount":
        setSelectedTab(3);
        break;
      case "fields":
        setSelectedTab(4);
        break;
      case "confirmation":
        setSelectedTab(5);
        break;
    }
  }, [navigateToTab]);

  return (
    <div>
      <TitlebarComponent
        title="Forms"
        description=""
        breadcrumbs={[
          { title: "Dashboard", link: "/" },
          { title: "Forms", link: "/campaignmanagement/offer" },
        ]}
      />

      <div>
        <Tabs value={selectedTab} onChange={(event, v) => setSelectedTab(v)}>
          <Tab label="Edit Form" />
          <Tab label="Campaigns" />
          <Tab label="Categories" />
          <Tab label="Discount" />
          <Tab label="Form Fields" />
          <Tab label="Confirmation" />
        </Tabs>
      </div>
      <TabPanel value={selectedTab} index={0}>
        <TabEditComponent formId={formId} />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <TabCampaignsComponent formId={formId} />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <TabCategoriesComponent formId={formId} />
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
        <TabDiscountComponent formId={formId} />
      </TabPanel>
      <TabPanel value={selectedTab} index={4}>
        <TabFormFieldsComponent formId={formId} />
      </TabPanel>
      <TabPanel value={selectedTab} index={5}>
        <TabConfirmationComponent formId={formId} />
      </TabPanel>
    </div>
  );
}
