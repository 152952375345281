import React, { useState, useEffect } from "react";
import TitlebarComponent from "../../titlebar.component";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import UserSettings from "../../userSettings";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-enterprise/chartsModule";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { GridReadyEvent } from "ag-grid-community";
import { FirstDataRenderedEvent } from "ag-grid-community/dist/lib/events";
import { useSelector, useDispatch } from "react-redux";
import { loadShellClients, loadShellCompanies } from "../shell/shell.api";
import ClientSelector from '../shell/clientselector.component';
import CompanySelector from '../shell/companyselector.component';
import CampaignTypeSelector from '../shell/campaigntypeselector.component';
import { CircularProgress } from "@material-ui/core";

declare var env: any;

interface StatisticsProps {
  history?: any;
}

export default function StatisticsComponent(props: StatisticsProps) {
  const dispatch = useDispatch();
  // const clients = useSelector(state => state.shell.clients);
  // const companies = useSelector(state => state.shell.companies);

  const selectedCampaignType = useSelector(state => state.shell.selectedCampaignType);
  const selectedClient = useSelector(state => state.shell.selectedClient);
  const selectedCompany = useSelector(state => state.shell.selectedCompany);

  const defaultSelection = { value: "", label: "" };
  const selectionSettings = UserSettings.getOption(UserSettings.STATISTICS_SELECTIONS, {
    selectedCompany: defaultSelection,
    selectedClient: defaultSelection,
    selectedContactSource: defaultSelection,
    selectedContactDirectory: defaultSelection,
    // selectedCampaignType: defaultSelection
  });
  const [loading, setLoading] = useState(false);

  // const [selectedCompany, setSelectedCompany] = useState(selectionSettings.selectedCompany);
  // const [selectedClient, setSelectedClient] = useState(selectionSettings.selectedClient);
  // const [selectedCampaignType, setSelectedCampaignType] = useState(selectionSettings.selectedCampaignType);

  const [selectedContactSource, setSelectedContactSource] = useState(selectionSettings.selectedContactSource);
  const [contactSources, setContactSources] = useState([]);

  const [selectedContactDirectory, setSelectedContactDirectory] = useState(selectionSettings.selectedContactDirectory);
  const [contactDirectories, setContactDirectories] = useState([]);

  const aggEnable = {
    aggFunc: "sum",
    enableValue: true,
    allowedAggFuncs: ["sum", "min", "max"],
    filter: "agNumberColumnFilter",
    cellStyle: { textAlign: "right" },
    width: 80
  };

  const defaultColdumnDefs = [
    //{headerName: "contact source id", field: "contact_source_id"},
    { headerName: "Contact Source Name", field: "contact_source_name", rowGroup: true, hide: true },
    //{headerName: "client id", field: "client_id"},
    { headerName: "Client Name", field: "client_name", rowGroup: true, hide: true },
    //{headerName: "contact group id", field: "contact_group_id"},
    { headerName: "Contact Group Name", field: "contact_group_name", rowGroup: true, hide: true },
    //{headerName: "contact directory id", field: "contact_directory_id"},
    { headerName: "Contact Directory Name", field: "contact_directory_name", rowGroup: true, hide: true },
    { headerName: "Imported At", field: "imported_at", rowGroup: true, hide: true },
    { headerName: "Total", field: "total", ...aggEnable },
    { headerName: "Rejected", field: "rejected", ...aggEnable },
    { headerName: "Delivered", field: "delivered", ...aggEnable },
    { headerName: "Remaining", field: "remaining", ...aggEnable },
    {
      headerName: "Price",
      field: "price",
      ...aggEnable,
      valueFormatter: (params: any) => {
        if (!params.value && params.value !== 0) {
          return params.value;
        }

        const frmt = new Intl.NumberFormat("nb-NO", { style: "currency", currency: "NOK" });
        return frmt.format(params.value);
      }
    }
  ];

  const [columnDefs, setColumnDefs] = useState(defaultColdumnDefs);
  const [rowData, setRowData] = useState([]);

  const fetchContactSources = async () => {
    const res = await fetch(env._apiPath + "/reports/contactSources", {
      method: "GET",
      credentials: "include"
    });
    const json = await res.json();
    setContactSources(json);
  };

  const fetchDirectoryReport = async () => {
    setLoading(true);
    const params: string[] = [];

    if (selectedClient) {
      params.push("client_id=" + selectedClient.value)
    }
    if (selectedCompany) {
      params.push("company_id=" + selectedCompany.value)
    }
    if (selectedCampaignType) {
      params.push("campaigntype_id=" + selectedCampaignType.value)
    }
    if (selectedContactSource.value) {
      params.push("contact_source_id=" + selectedContactSource.value);
    }
    if (selectedContactDirectory.value) {
      params.push("contact_directory_id=" + selectedContactDirectory.value);
    }

    const res = await fetch(env._apiPath + "/reports/contactDirectory?" + params.join("&"), {
      method: "GET",
      credentials: "include"
    });
    const json = await res.json();
    if (json.status === "success") {
      const data: any = [];
      json.data.forEach((row: any) => {
        row.total = parseInt(row.total);
        row.rejected = parseInt(row.rejected);
        row.delivered = parseInt(row.delivered);
        row.remaining = parseInt(row.remaining);
        row.price = parseFloat(row.price);
        data.push(row);
      });
      setRowData(data);
      setLoading(false);
    }
  };

  // const resetState = () => {
  //   setSelectedCompany(defaultSelection);
  //   setSelectedClient(defaultSelection);
  //   setSelectedContactSource(defaultSelection);
  // };

  const runReport = () => {
    fetchDirectoryReport();
  };

  useEffect(() => {
    loadShellClients(dispatch);
    loadShellCompanies(dispatch);
    fetchContactSources();
  }, []);

  const onGridReady = (event: GridReadyEvent) => {
    window.addEventListener("resize", () => setTimeout(() => event.api.sizeColumnsToFit()));
    event.api.sizeColumnsToFit();
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    event.api.sizeColumnsToFit();
  };

  return (
    <div>
      <TitlebarComponent
        title="Statistics"
        description="List Analysis"
        breadcrumbs={[
          { title: "Dashboard", link: "/" },
          { title: "Statistics", link: "/listmanagement/statistics" }
        ]}
      />

      <div className="widget-list">
        <Row>
          <Col className="widget-holder">
            <div className="widget-bg">
              <div className="widget-body">
                <Row>
                  <Col lg={2}>
                    {/* <Form.Group controlId="frmClient">
                      <Form.Label>Client</Form.Label>
                      <Select
                        value={selectedClient}
                        onChange={(opt: any) => setSelectedClient(opt)}
                        options={clients}
                      />
                    </Form.Group>
                    <Form.Group controlId="frmCompany">
                      <Form.Label>Company</Form.Label>
                      <Select
                        value={selectedCompany}
                        onChange={(opt: any) => setSelectedCompany(opt)}
                        options={companies}
                      />
                    </Form.Group> */}
                    <ClientSelector />
                    <CompanySelector />
                    <CampaignTypeSelector />
                    {/* <Form.Group controlId="frmClient">
                      <Form.Label>Contact Source</Form.Label>
                      <Select
                        value={selectedContactSource}
                        onChange={(opt: any) => setSelectedContactSource(opt)}
                        options={contactSources}
                      />
                    </Form.Group>
                    <Form.Group controlId="frmClient">
                      <Form.Label>Contact Directory</Form.Label>
                      <Select
                        value={selectedContactDirectory}
                        onChange={(opt: any) => setSelectedContactDirectory(opt)}
                        options={contactDirectories}
                      />
                    </Form.Group> */}

                    <div style={{ textAlign: "right", marginTop: 20 }}>
                      { loading ? <CircularProgress /> : null}
                      {/* <Button onClick={resetState} size="sm" variant="secondary">
                        Reset
                      </Button> */}
                      &nbsp;
                      <Button onClick={runReport} size="sm" variant="secondary">
                        Run
                      </Button>
                    </div>
                  </Col>
                  <Col>
                    <div className="ag-theme-balham" style={{ height: "800px", width: "100%" }}>
                      <AgGridReact
                        columnDefs={columnDefs}
                        rowData={rowData}
                        groupIncludeTotalFooter={true}
                        groupIncludeFooter={true}
                        animateRows={true}
                        enableRangeSelection={true}
                        onGridReady={onGridReady}
                        onFirstDataRendered={onFirstDataRendered}
                        sideBar={{
                          toolPanels: ["columns", "filters"]
                        }}
                        suppressAggFuncInHeader={true}
                        defaultColDef={{
                          sortable: true,
                          resizable: true,
                          filter: true
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}