import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, makeStyles, createStyles, Theme, Table, TableRow, TableCell, TableBody } from "@material-ui/core";
import { getMergeFields } from "./edit.api";
import { IMergeField } from "./form.types";

export interface IMergeFieldSelectorProps {
  onSelect: any;
  type: string;
  formId: number;
  noFrame?: boolean | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      backgroundColor: "#f7f7f7",
      padding: "8px 14px",
      borderBottom: "1px solid #ebebeb",
    },
    mergeFieldList: {
      maxHeight: 300,
      overflow: "auto",
    },
    fieldRow: {
      "&:hover": {
        backgroundColor: "#f2f2f2",
        cursor: "pointer",
      },
    },
    footer: {
      padding: "8px 14px",
      backgroundColor: "#f7f7f7",
    },
  })
);

export default function MergeFieldSelector(props: IMergeFieldSelectorProps) {
  const classes = useStyles();

  const [mergeFields, setMergeFields] = useState<IMergeField[]>([]);

  useEffect(() => {
    getMergeFields(props.formId, props.type)
      .then((mFields: IMergeField[]) => {
        setMergeFields(mFields);
      })
      .catch(console.log);
  }, [props.type, props.formId]);

  if (props.noFrame) {
    return (
      <div>
        <Table size="small">
          <TableBody>
            {mergeFields.map((m) => (
              <TableRow className={classes.fieldRow} onClick={() => props.onSelect(m)}>
                <TableCell>{m.field_name}</TableCell>
                <TableCell>{m.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }

  return (
    <div>
      <div className={classes.header}>
        <Typography variant="subtitle2">Insert Merge Field</Typography>
      </div>
      <div className={classes.mergeFieldList}>
        <Table size="small">
          <TableBody>
            {mergeFields.map((m) => (
              <TableRow className={classes.fieldRow} onClick={() => props.onSelect(m)}>
                <TableCell>{m.field_name}</TableCell>
                <TableCell>{m.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
