declare var env: any;
export interface Logdata {
    
    log_user_id?: number;
    created_at?: string;
    user_id?: number; 
    fullname?: string;
    log_user_type_id?: number;
    log_user_type_name?: string;
    comment?: string; 
}
interface PropsInn {
    startDateFormatted: string,
    endDateFormatted: string,
    selLogUser?: number,
    selLogType?: number,
}

export const fetchUserLogReport = async (props: PropsInn) => {
    const { startDateFormatted, endDateFormatted, selLogUser, selLogType } = props;
    let selLogUserConst = 0;
    let selLogTypeConst = 0;

    //console.log(JSON.stringify(props));
    if (selLogUser) {
        selLogUserConst = selLogUser;
    }
    if (selLogType) {
        selLogTypeConst = selLogType;
    }
    const res = await fetch(env._apiPath + `/log/logUsers?user_id=${selLogUserConst}&log_user_type_id=${selLogTypeConst}&from_date=${startDateFormatted}&to_date=${endDateFormatted}`, {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    
    const json = await res.json();

    if(json.status === "success") {
        return json.data;
        
    }

    throw json.message;

}
