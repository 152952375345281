import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function ManagerWidgetComponent(props) {
  return (
    <div className="widget-list">
      <Row>
        <Col className="widget-holder">
          <div className="widget-bg">
            <div className="widget-body">{props.children}</div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
