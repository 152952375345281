import React, { useState, useEffect } from "react";
import TitlebarComponent from "../../../titlebar.component";
import "./directorydetails.scss";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useInterval } from "../../../custom.hooks";
import { useIdle } from "react-use";

import WorkersComponent from "./directorydetails/workers.component";
import HistoryComponent from "./directorydetails/history.component";
import FiltersComponent from "./directorydetails/filters.component";
import ImportContactsComponent from "./directorydetails/importcontacts.component";

declare var env: any;

interface DirectoryDetailsProps {
  match?: any;
}

export default function DirectoryDetailsComponent(props: DirectoryDetailsProps) {
  const [directoryId, setDirectoryId] = useState(props.match.params.id);
  const [directoryInfo, setDirectoryInfo] = useState({
    isLoading: true,
    contact_directory_id: 0,
    contact_directory_name: "",
    created_at: "",
    client_id: 0,
    client_name: "",
    campaign_type_id: 0,
    campaign_type_name: "",
    last_used_at: "",
    country_id: "",
    country_name: "",
    totalContacts: 0,
    deliveredContacts: 0,
    remainingContacts: 0
  });
  const [showImportContacts, setShowImportContacts] = useState(false);

  const isIdle = useIdle(15e3);

  const fetchDirectoryInfo = async () => {
    const res = await fetch(env._apiPath + "/listManagement/contactDirectory?contact_directory_id=" + directoryId, {
      method: "GET",
      credentials: "include"
    });
    const json = await res.json();
    setDirectoryInfo({ ...json.data, isLoading: false });
  };

  useEffect(() => setDirectoryId(props.match.params.id), [props.match.params.id]);
  useEffect(() => {
    fetchDirectoryInfo();
  }, []);
  useInterval(() => {
    if (!isIdle) {
      fetchDirectoryInfo();
    }
  }, 10e3);

  return (
    <div className="directory-component">
      <TitlebarComponent
        title="View Directory Info"
        description="Details about a directory"
        breadcrumbs={[
          { title: "List Management", link: "/" },
          { title: "Directories", link: "/listmanagement/directories" },
          { title: "Details", link: "/listmanagement/directories/" + directoryId }
        ]}
      />
      <div className="row directory-details">
        <div className="col-md-3 p-0" style={{ display: "flex", flexDirection: "column" }}>
          <div className="directory-info">
            {directoryInfo.isLoading ? (
              <div>
                <i className="fas fa-fw fa-spinner fa-spin"></i> Loading...
              </div>
            ) : (
              <>
                <div className="row">
                  <div className="col-md-12">
                    <h5>{directoryInfo.contact_directory_name}</h5>
                  </div>
                </div>
                <Row>
                  <Col lg={4} md={12}>
                    <b>Total</b>
                    <br />
                    {directoryInfo.totalContacts}
                  </Col>
                  <Col lg={4} md={12}>
                    <b>Delivered</b>
                    <br />
                    {directoryInfo.deliveredContacts}
                  </Col>
                  <Col lg={4} md={12}>
                    <b>Remaining</b>
                    <br />
                    {directoryInfo.remainingContacts}
                  </Col>
                </Row>
              </>
            )}
          </div>

          <div className="directory-actions">
            <div className="row">
              <div className="col-md-12">
                <Button variant="success" onClick={(e: any) => setShowImportContacts(true)}>
                  Import Contacts
                </Button>
              </div>
            </div>
            {directoryInfo.isLoading === false ? (
              <ImportContactsComponent
                campaignTypeId={directoryInfo.campaign_type_id.toString()}
                directoryId={directoryId}
                show={showImportContacts}
                onHide={() => setShowImportContacts(false)}
              />
            ) : null}
          </div>

          {directoryInfo.isLoading === false ? <HistoryComponent directoryId={directoryId} /> : null}
        </div>
        <div className="col-md-9 p-0 directory-flex">
          {directoryInfo.isLoading === false ? (
            <>
              <FiltersComponent
                directoryId={directoryId}
                directoryInfo={directoryInfo}
                campaignTypeId={directoryInfo.campaign_type_id.toString()}
                clientId={directoryInfo.client_id.toString()}
              />
              <WorkersComponent directoryId={directoryId} />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
