import { useState } from 'react';
import { ISoundFile, order_rejected_reasons } from './detailsnew.component'
import { soundfileApproval, IOrder } from './orders.api';
import { useSelector } from "react-redux";
import {
    Grid,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent, 
    DialogActions, MenuItem, 
  } from "@material-ui/core";
  import Button from "react-bootstrap/Button";
  import ButtonGroup from "react-bootstrap/ButtonGroup";

  import format from 'date-fns/format';

type IProps = {
    s: ISoundFile,
    i: number,
    hasConfirmedSms: boolean,
    rejectedReasons: order_rejected_reasons[],
    order: IOrder
}

export default function SoundfileComponent({s,i, hasConfirmedSms, rejectedReasons, order}: IProps) {
    const snd = s;
    const idx = i;
    const GDPR7Days = [11,16,18,22];
    const [showRejectedReason,setShowRejectedReason] = useState(-1);
    const [rejectedReason, setRejectedReason] = useState({ order_rejected_reason_id: "0", order_action_comment: '' });
    const customer_id = useSelector(state => state.shell.user.customer_id);


    const onSoundfileReject = (snd, idx) => {
    //   setUpdateSoundStatus(-1); 
      soundfileApproval(snd,0,rejectedReason).then(res => { 
        snd.confirmed_at = res.confirmed_at;  
        snd.rejected_at = res.rejected_at;  
        snd.regretted_at = res.regretted_at;  
  
        // setUpdateSoundStatus(idx);
      });
      snd.order_action_comment = rejectedReason.order_action_comment; 
      snd.order_rejected_reason_id = rejectedReason.order_rejected_reason_id; 
    }
    const onRemoveSoundfileReject = (snd, idx) => {
    //   setUpdateSoundStatus(-1); 
      soundfileApproval(snd,-1,rejectedReason).then(res => { 
        snd.confirmed_at = res.confirmed_at;  
        snd.rejected_at = res.rejected_at;  
        snd.regretted_at = res.regretted_at;  
  
        // setUpdateSoundStatus(idx);
      });
      snd.order_action_comment = ''; 
      snd.order_rejected_reason_id = '';  
    }


    return(order?.confirmed_at == null && new Date(order.created_at).getTime() < new Date().getTime() - 604800000 && !hasConfirmedSms && GDPR7Days.includes(customer_id))
    ?
    <>
      <Grid item md={12}>
        Lydfiler er fjernet grunnet GDPR
      </Grid>
    </>
    :(
      <>
      <Grid container spacing={1}>
        <Grid item md={12}>
          <b style={{marginLeft: "15px"}}>#{idx + 1}</b> {snd.created_at}{snd.rejected_at?', Rejected at: '+ format(new Date(snd.rejected_at), "dd.MM.yy HH:mm:ss"):snd.regretted_at?', Regretted at: '+ format(new Date(snd.regretted_at), "dd.MM.yy HH:mm:ss"):snd.confirmed_at?', Confirmed at: '+ format(new Date(snd.confirmed_at), "dd.MM.yy HH:mm:ss"):''}
        </Grid>
        
          <span style={{marginLeft: "15px"}}>{snd.rejected_at ? <Grid item md={12}>Rejected reason: "{snd.order_action_comment}" </Grid>: null}</span>
        
        <Grid item container>
          <Grid item sm={12} md={10}>
            { snd.activity_file_id ? 
              <audio
                controls
                // src={env._apiPath + "/file/getSoundFile?activity_file_id=" + soundfile}
                // src={'https://media.mmgsky.no/audio/stream.php?sessid=' + soundfile} // denne brukte sessionid, men en sessionid kan ha flere filer
                // laget en ny stream2.php fil som tar activity_file_id som input, da får du kun en lydfil, og den korrekte hver gang
                src={'https://media.mmgsky.no/audio/stream2.php?fileid=' + snd.activity_file_id}
                style={{width: "350px"}}
              >
                Your browser does not support the audio element.
              </audio>
          : "Error, soundfile is not loaded." }
          </Grid>
          <Grid item md={2}>
            <ButtonGroup aria-label="Approval" style={{paddingTop: "10px"}}>
              <Button variant={snd.confirmed_at?'success':'secondary'} onClick={() => { 
                // setUpdateSoundStatus(-1); 
                soundfileApproval(snd, snd.confirmed_at?-1:1, rejectedReason).then(res => { 
                  snd.confirmed_at = res.confirmed_at;  
                  snd.rejected_at = res.rejected_at;  
                  snd.regretted_at = res.regretted_at;  

                //   setUpdateSoundStatus(idx);
                  })
                } 
              }>
                  <i className="fa fa-thumbs-up" aria-hidden={true}></i>
              </Button>
              <Button variant={snd.rejected_at?'primary':'secondary'} onClick={() => { 
                  setRejectedReason({
                    order_action_comment: snd.order_action_comment ? snd.order_action_comment : "",
                    order_rejected_reason_id: snd.order_rejected_reason_id ? snd.order_rejected_reason_id : ""
                  }); 
                  setShowRejectedReason(idx);
                  }}>
                  <i className="fa fa-thumbs-down" aria-hidden={true}></i>
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={showRejectedReason === idx} fullWidth maxWidth="sm" onClose={() => setShowRejectedReason(-1)}>
        <DialogTitle>Reject soundfile</DialogTitle>
        <DialogContent>
          <TextField 
            select
            label="Reject Reasons"
            fullWidth
            value={rejectedReason.order_rejected_reason_id ? rejectedReason.order_rejected_reason_id : "" }
            onChange={(e) => { setRejectedReason({ ...rejectedReason, order_rejected_reason_id: e.target.value }); }}
          >
            {rejectedReasons.map((rejectedReason: order_rejected_reasons, key) => (
                <MenuItem key={key} value={rejectedReason.reason_id}>
                    {rejectedReason.reason_name}
                </MenuItem>
            ))}
          </TextField>
          <TextField
            label="Comment"
            required
            fullWidth
            onChange={(e) => { setRejectedReason({ ...rejectedReason, order_action_comment: e.target.value })}}
            value={rejectedReason.order_action_comment ? rejectedReason.order_action_comment : ""}
            />
        </DialogContent>
        <DialogActions>
          <Button                                   
            color="secondary" 
            style={{ height: 40 }}
            onClick={() => setShowRejectedReason(-1)}
            >
            Cancel
          </Button>
          <Button                                   
            color="primary" 
            style={{ height: 40 }}
            onClick={(e) => { onRemoveSoundfileReject(snd,idx); setShowRejectedReason(-1); }}
            >
            Remove
          </Button>
          <Button                                   
            color="primary" 
            style={{ height: 40 }}
            onClick={() => { onSoundfileReject(snd,idx); setShowRejectedReason(-1); }}
            >
            Reject
          </Button>
        </DialogActions>
        </Dialog>
      </>
    )
  }
