export interface IOutboundReport {
  current_page: number;
  first_page_url: string;
  from: number;
  to: number;
  last_page: number;
  per_page: number;
  total: number;
  last_page_url: string;
  next_page_url: string;
  prev_page_url: string;
  path: string;
  // data contains customized results and are not definable here but in headers..
  data: any[];
}

export interface ILogtime {
  agent_status: string;
  duration: number;
  percent: number;
}

export interface ICalls {
  call_status: string;
  outcome_count: number;
  percent: number;
}

export interface IOutbound {
  report: IOutboundReport | null;
  logtime: ILogtime[] | null;
  logtimeRaw: ILogtime[] | null;
  calls: ICalls[] | null;
}

const initialState: IOutbound = {
  report: null,
  logtime: null,
  logtimeRaw: null,
  calls: null
};

export const REPORTS_OUTBOUND_RECEIVED = "@sky/REPORTS_OUTBOUND_RECEIVED";
export const actionReceivedOutboundReport = (report: IOutboundReport) => ({
  type: REPORTS_OUTBOUND_RECEIVED,
  report
});

export const REPORTS_OUTBOUND_LOGTIME_RECEIVED = "@sky/REPORTS_OUTBOUND_LOGTIME_RECEIVED";
export const actionReceivedOutboundLogtimeReport = report => ({
  type: REPORTS_OUTBOUND_LOGTIME_RECEIVED,
  report
});

export const REPORTS_OUTBOUND_CALLS_RECEIVED = "@sky/REPORTS_OUTBOUND_CALLS_RECEIVED";
export const actionReceivedOutboundCallsReport = report => ({
  type: REPORTS_OUTBOUND_CALLS_RECEIVED,
  report
});

export default function(state = initialState, action) {
  switch (action.type) {
    case REPORTS_OUTBOUND_RECEIVED:
      return { ...state, report: action.report };
    case REPORTS_OUTBOUND_LOGTIME_RECEIVED:
      let logtime: ILogtime[] = [];
      let logtimeRaw: ILogtime[] = [];

      let total = 0.0;
      action.report.forEach((l: any) => {
        total += parseFloat(l.duration.replace(/^\./, "0."));
      });

      let other: ILogtime = {
        agent_status: "Other",
        duration: 0,
        percent: 0
      };

      action.report.forEach((v: any) => {
        const dur = parseFloat(v.duration.replace(/^\./, "0."));
        let l: ILogtime = {
          agent_status: v.agent_status,
          duration: dur,
          percent: Math.round(((dur * 100) / total) * 100) / 100
        };

        logtimeRaw.push(l);

        if (l.percent > 2) {
          logtime.push(l);
          return;
        }

        other.duration += l.duration;
        other.percent += l.percent;
      });
      other.percent = Math.round(other.percent * 100) / 100;
      logtime.push(other);
      return { ...state, logtime, logtimeRaw };
    case REPORTS_OUTBOUND_CALLS_RECEIVED:
      let totalC = 0;
      action.report.forEach((v: any) => {
        totalC += parseInt(v.outcome_count);
      });

      let calls: ICalls[] = [];
      action.report.forEach((v: any) => {
        let outcomeCount = parseInt(v.outcome_count);
        let c: ICalls = {
          call_status: v.call_status,
          outcome_count: outcomeCount,
          percent: Math.round(((outcomeCount * 100) / totalC) * 100) / 100
        };
        calls.push(c);
      });
      return { ...state, calls };
  }

  return state;
}
