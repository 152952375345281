declare var env: any;

export interface ISendSms {
    recipient: string,
    customer_id: number,
    sms_message: string,
    sender: number,
    client_id: number,
    country_code: string,
    user_id: number
}
export interface iRecipient {
    country_code: string,
    number: Number
}

export const sendSms = async (object: ISendSms, recipients: iRecipient[] ) => {
    const { recipient, customer_id, sms_message, sender, client_id, country_code, user_id } = object;
    const req = {
        recipient,
        recipients,
        sms_message,
        customer_id,
        sender,
        client_id,
        country_code,
        user_id
    }

    console.log(req);

    const res = await fetch(env._apiPath + `/sms/send`, {
        method: "PUT",
        credentials: "include", 
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(req),
    });
    const json = await res.json();
    if(json.status === "success") {
        return json;
    }
    throw json.message;
    
}