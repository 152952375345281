import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardContent,
  Grid,
  TextField, TextFieldProps,
  Divider,
  CardActions,
  Button,
  Typography,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Menu,
  MenuItem,
  Popover,
  Table,
} from "@material-ui/core";
import { Editor } from "@tinymce/tinymce-react";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import ColorPicker from "material-ui-color-picker";
import { getFormData, saveFormData } from "./edit.api";
import { format } from "date-fns";
import { IEditProps, IMergeField } from "./form.types";
import { actionReceivedFormData, actionUpdatedFormData } from "../../../redux/reducers/campaignmanagement/forms";
import CircularProgress from '@material-ui/core/CircularProgress';
import MergeFieldSelector from "./mergeFieldSelector.component";
import { actionStartAPI, actionStopAPI } from "../../../redux/reducers/shell";

const tinymceSettings = {
  height: 200,
  menubar: true,
  plugins: [
    "advlist autolink lists link image charmap print preview anchor",
    "searchreplace visualblocks code fullscreen",
    "insertdatetime media table paste code help wordcount",
  ],
  toolbar:
    "undo redo | formatselect | bold italic backcolor | \
alignleft aligncenter alignright alignjustify | \
bullist numlist outdent indent | removeformat | help",
};

export default function TabEditComponent(props: IEditProps) {
  const formData = useSelector((state) => state.forms.edit.formData);
  const apiRunning = useSelector((state) => state.shell.apiRunning);
  const dispatch = useDispatch();

  const [showDataChangedWarning, setShowDataChangedWarning] = useState(false);
  const [dataSaveError, setDataSaveError] = useState("");
  const [showDataSaveError, setShowDataSaveError] = useState(false);
  const [wMergeFieldMenuAnchor, setWMergeFieldMenuAnchor] = useState<null | HTMLElement>(null);
  const [vMergeFieldMenuAnchor, setVMergeFieldMenuAnchor] = useState<null | HTMLElement>(null);
  const [qMergeFieldMenuAnchor, setQMergeFieldMenuAnchor] = useState<null | HTMLElement>(null);
  const [activeEditor, setActiveEditor] = useState<any>();

  useEffect(() => {
    if (!formData.edited) {
      getFormData(props.formId).then((newFormData) => {
        dispatch(actionReceivedFormData(newFormData));
      });
    }
  }, [props.formId, actionReceivedFormData, dispatch, formData.edited]);

  const onSaveChanges = () => {
    dispatch(actionStartAPI());
    saveFormData(formData)
      .then(() => {
        dispatch(actionReceivedFormData(formData));
        dispatch(actionStopAPI());
      })
      .catch((error) => {
        setShowDataSaveError(true);
        setDataSaveError(error);
        dispatch(actionStopAPI());
      });
  };

  const onCancelChanges = () => {
    if (formData.edited) {
      setShowDataChangedWarning(true);
    }
  };

  const onCancelChangesReal = () => {
    dispatch(actionReceivedFormData(formData));
    setShowDataChangedWarning(false);
  };

  const tinymceSettingsQueueDetails = {
    ...tinymceSettings,
    toolbar: `mergeFields | undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help`,
    setup: (editor) => {
      editor.addButton("mergeFields", {
        text: "Merge Field",
        icon: false,
        tooltip: "Insert Merge Field",
        onclick: (e) => {
          setQMergeFieldMenuAnchor(e.target);
          setActiveEditor(editor);
        },
      });
    },
  };

  const tinymceSettingsWelcome = {
    ...tinymceSettings,
    toolbar: `mergeFields |undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help`,
    setup: (editor) => {
      editor.addButton("mergeFields", {
        text: "Merge Field",
        icon: false,
        tooltip: "Insert Merge Field",
        onclick: (e) => {
          setWMergeFieldMenuAnchor(e.target);
          setActiveEditor(editor);
        },
      });
    },
  };

  const tinymceSettingsVerification = {
    ...tinymceSettings,
    toolbar: `mergeFields | undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help`,
    setup: (editor) => {
      editor.addButton("mergeFields", {
        text: "Merge Field",
        icon: false,
        tooltip: "Insert Merge Field",
        onclick: (e) => {
          setVMergeFieldMenuAnchor(e.target);
          setActiveEditor(editor);
        },
      });
    },
  };

  const handleCloseWMergeFieldMenu = () => {
    setWMergeFieldMenuAnchor(null);
  };

  const handleCloseVMergeFieldMenu = () => {
    setVMergeFieldMenuAnchor(null);
  };

  const handleCloseQMergeFieldMenu = () => {
    setQMergeFieldMenuAnchor(null);
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <TextField fullWidth disabled label="Form Name" value={formData!.form_name} />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid item md={6}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <KeyboardDateTimePicker
                    variant="inline"
                    ampm={false}
                    id="date-picker-start"
                    label="Start Date"
                    value={formData!._start_date}
                    onChange={(date: any) =>
                      dispatch(
                        actionUpdatedFormData({
                          ...formData,
                          _start_date: date,
                          form_start_date: format(date, "dd.MM.yyyy HH:mm"),
                        })
                      )
                    }
                    onError={console.log}
                    format="dd.MM.yyyy HH:mm"
                    fullWidth
                  />
                </Grid>
                <Grid item md={6}>
                  <KeyboardDateTimePicker
                    variant="inline"
                    ampm={false}
                    id="date-picker-end"
                    label="End Date"
                    value={formData!._end_date}
                    onChange={(date: any) =>
                      dispatch(
                        actionUpdatedFormData({
                          ...formData,
                          _end_date: date,
                          form_end_date: format(date, "dd.MM.yyyy HH:mm"),
                        })
                      )
                    }
                    onError={console.log}
                    format="dd.MM.yyyy HH:mm"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: 13 }}>
                <Grid item md={6}>
                  <ColorPicker
                    label="Primary Color"
                    name="color"
                    value={formData!.color_primary}
                    onChange={(color) =>
                      dispatch(
                        actionUpdatedFormData({
                          ...formData,
                          color_primary: color,
                        })
                      )
                    }
                    fullWidth
                    InputProps={{ value: formData!.color_primary }}
                  />
                </Grid>
                <Grid item md={6}>
                  <ColorPicker
                    label="Secondary Color"
                    name="color"
                    value={formData!.color_secondary}
                    onChange={(color) =>
                      dispatch(
                        actionUpdatedFormData({
                          ...formData,
                          color_secondary: color,
                        })
                      )
                    }
                    fullWidth
                    InputProps={{ value: formData!.color_secondary  }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
          <Grid item md={6}>
            <TextField
              label="Description"
              multiline
              rows="4"
              value={formData!.form_description}
              fullWidth
              onChange={(event) =>
                dispatch(actionUpdatedFormData({ ...formData, form_description: event.target.value }))
              }
            />
          </Grid>
        </Grid>

        <Divider style={{ margin: "20px 0" }} />

        <Grid container spacing={2}>
          <Grid item md={6}>
            <Typography variant="overline" color="textSecondary">
              Welcome Text
            </Typography>
            <Editor
              value={formData!.intro}
              init={tinymceSettingsWelcome}
              onEditorChange={(value) => dispatch(actionUpdatedFormData({ ...formData, intro: value }))}
            />
            <Popover
              anchorEl={wMergeFieldMenuAnchor}
              open={Boolean(wMergeFieldMenuAnchor)}
              onClose={handleCloseWMergeFieldMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MergeFieldSelector
                type="welcome_text"
                onSelect={(m: IMergeField) => {
                  activeEditor.insertContent(m.field_name);
                  handleCloseWMergeFieldMenu();
                }}
                formId={props.formId}
              />
            </Popover>
          </Grid>
          <Grid item md={6}>
            <Typography variant="overline" color="textSecondary">
              Verification Text
            </Typography>
            <Editor
              value={formData!.summary}
              init={tinymceSettingsVerification}
              onEditorChange={(value) => dispatch(actionUpdatedFormData({ ...formData, summary: value }))}
            />
            <Popover
              anchorEl={vMergeFieldMenuAnchor}
              open={Boolean(vMergeFieldMenuAnchor)}
              onClose={handleCloseVMergeFieldMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MergeFieldSelector
                type="verification_text"
                onSelect={(m: IMergeField) => {
                  activeEditor.insertContent(m.field_name);
                  handleCloseVMergeFieldMenu();
                }}
                formId={props.formId}
              />
            </Popover>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item md={12}>
            <Typography variant="overline" color="textSecondary">
              Queue Details
            </Typography>
            <Editor
              value={formData!.queue_details}
              init={tinymceSettingsQueueDetails}
              onEditorChange={(value) => dispatch(actionUpdatedFormData({ ...formData, queue_details: value }))}
            />
            <Popover
              anchorEl={qMergeFieldMenuAnchor}
              open={Boolean(qMergeFieldMenuAnchor)}
              onClose={handleCloseQMergeFieldMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MergeFieldSelector
                type="queue_details"
                onSelect={(m: IMergeField) => {
                  activeEditor.insertContent(m.field_name);
                  handleCloseQMergeFieldMenu();
                }}
                formId={props.formId}
              />
            </Popover>
          </Grid>
        </Grid>

        <CardActions>
          <div style={{ flex: 1 }} />
          <Button color="primary" variant="outlined" onClick={onCancelChanges}>
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={onSaveChanges} disabled={!!apiRunning}>
            {apiRunning ? <CircularProgress size={20}/> : null} Save
          </Button>
          

          <Dialog
            open={showDataChangedWarning}
            onClose={() => setShowDataChangedWarning(false)}
            aria-labelledby="alert-datachanged-title"
            aria-describedby="alert-datachanged-description"
          >
            <DialogTitle id="alert-datachanged-title">Reset Changes</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-datachanged-description">
                The form has been modified. Are you sure you want to reset the changes?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowDataChangedWarning(false)} variant="outlined" color="primary" autoFocus>
                No
              </Button>
              <Button onClick={onCancelChangesReal} color="primary" variant="contained">
                Yes
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={showDataSaveError}
            onClose={() => setShowDataSaveError(false)}
            aria-labelledby="alert-saveerror-title"
            aria-describedby="alert-saveerror-description"
          >
            <DialogTitle id="alert-saveerror-title">Error</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-saveerror-description">
                An error occured while saving changes: {dataSaveError}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowDataSaveError(false)} variant="contained" color="primary" autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </CardActions>
      </CardContent>
    </Card>
  );
}
