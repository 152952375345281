import React, { useEffect } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { actionSelectStartDate, actionSelectEndDate } from "../../redux/reducers/shell";
import Form from "react-bootstrap/Form";
import DatePicker from "react-datepicker";
import { KeyboardDatePicker, MuiPickersUtilsProvider  } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

interface PeriodSelectorProps {
  defaultStartDate?: Date,
  defaultEndDate?: Date,
  variant?: string
}

export default function PeriodSelector<PeriodSelectorProps>(props) {
    const dispatch = useDispatch();
    const selectedStartDate = useSelector(state => state.shell.selectedStartDate);
    const selectedEndDate = useSelector(state => state.shell.selectedEndDate);

    useEffect(() => {
      if(props.defaultStartDate) {
        dispatch(actionSelectStartDate(props.defaultStartDate));
      } else {
        let startDate = new Date();
        startDate.setDate(1);
        dispatch(actionSelectStartDate(startDate));
      }

      if (props.defaultEndDate) {
        dispatch(actionSelectEndDate(props.defaultEndDate));
      } else {
        dispatch(actionSelectEndDate(new Date()));
      }
    }, [])

  if (props.variant === "material") {
    const handleStartDateChange = (date: Date | null) => {
      if (date) {
        dispatch(actionSelectStartDate(date))
      }
    }
    const handleToDateChange = (date: Date | null) => {
      if (date) {
        dispatch(actionSelectEndDate(date))
      }
    }
    
    return (<>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="fromDate">
          <KeyboardDatePicker
            className="actItem"
            disableToolbar
            variant="inline"
            format="dd.MM.yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Date From"
            value={selectedStartDate}
            onChange={handleStartDateChange}
            KeyboardButtonProps={{
                'aria-label': 'change from date',
            }}
            />
        </div>
        <div className="toDate">
          <KeyboardDatePicker
            className="actItem"
            disableToolbar
            variant="inline"
            format="dd.MM.yyyy"
            margin="normal"
            id="date-picker-inline2"
            label="Date To"
            value={selectedEndDate}
            onChange={handleToDateChange}
            KeyboardButtonProps={{
                'aria-label': 'change to date',
            }}
            />
        </div>
      </MuiPickersUtilsProvider>
    </>);
  }
  else {
    return (
      <div>
        <Form.Group controlId="frmPeriod" style={{marginBottom: "1rem"}}>
          <Form.Label>Period</Form.Label>
          <Form.Text className="text-muted">From</Form.Text>
          <DatePicker
            dateFormat="dd.MM.yyyy"
            className="form-control"
            selected={selectedStartDate}
            onChange={(date: Date) => dispatch(actionSelectStartDate(date))}
          />
        </Form.Group>
        <Form.Group controlId="frmPeriod">
          <Form.Text className="text-muted">To</Form.Text>
          <DatePicker
            dateFormat="dd.MM.yyyy"
            className="form-control"
            selected={selectedEndDate}
            onChange={(date: Date) => dispatch(actionSelectEndDate(date))}
          />
        </Form.Group>
      </div>
    );
  }
}
