import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { actionSelectCampaignType } from "../../redux/reducers/shell";
import { loadShellCampaignTypes } from "./shell.api";
import {
  MenuItem,
  TextField
} from "@material-ui/core";
import _uniqueId from "lodash/uniqueId";

interface ICampaignTypeSelectorProps {
  variant?: string,
  fullWidth?: boolean,
  required?: boolean,
}

export default function CampaignTypeSelector(props: ICampaignTypeSelectorProps) {
  const dispatch = useDispatch();
  const campaignTypes = useSelector(state => state.shell.campaignTypes);
  const selectedCampaignType = useSelector(state => state.shell.selectedCampaignType);
  const selectedClient = useSelector(state => state.shell.selectedClient);
  const selectedCompany = useSelector(state => state.shell.selectedCompany);
  const [id] = useState(_uniqueId("cxsel-"));

  function handleChange(e) {
    if (e.target.value == "") {
      dispatch(actionSelectCampaignType(undefined));
    }
    else {
      dispatch(actionSelectCampaignType(e.target));
    }
  }

  useEffect(() => {
    loadShellCampaignTypes(
      dispatch,
      selectedClient ? selectedClient.value : 0,
      selectedCompany ? selectedCompany.value : 0
    );
  }, [dispatch, selectedClient, selectedCompany, loadShellCampaignTypes]);

  if(props.variant === "material") {
    return (
      <TextField 
          value={selectedCampaignType ? selectedCampaignType.value : ""}
          select 
          label="Campaign Type"
          id={id}
          required={props.required}
          fullWidth={props.fullWidth}
          onChange={handleChange}
          >
          <MenuItem value="">None</MenuItem>
          {campaignTypes.map((campaignType, key) => (
            <MenuItem key={key} value={campaignType.campaign_type_id}>{campaignType.campaign_type_name} - {campaignType.campaign_type_id}</MenuItem>
          ))}
        </TextField>
    );
  }
  else {
    return (
      <Form.Group controlId="frmCampaignType">
        <Form.Label>Campaign Type</Form.Label>
        <Select
          value={selectedCampaignType}
          onChange={(opt: any) => dispatch(actionSelectCampaignType(opt))}
          options={campaignTypes.map(c => ({
            label: c.client.client_name + " - " + c.campaign_type_name,
            value: c.campaign_type_id
          }))}
          isClearable={true}
        />
      </Form.Group>
    );
  }
}
