import React, { useState, useEffect } from "react";
import TitlebarComponent from "../../titlebar.component";

import UserSettings from "../../userSettings";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-enterprise/chartsModule";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { GridReadyEvent, Column, GridApi, ColumnApi } from "ag-grid-community";
import {
  FirstDataRenderedEvent,
  RowDoubleClickedEvent,
  SortChangedEvent,
  FilterChangedEvent,
  PaginationChangedEvent
} from "ag-grid-community/dist/lib/events";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { format } from "date-fns/esm";
import nb from "date-fns/locale/nb";
import "react-datepicker/dist/react-datepicker.css";
import { AgToolbarButton } from "../../agGridUtils";
import Alert from "react-bootstrap/Alert";
import { useSelector, useDispatch, batch } from "react-redux";
import { IOutboundReport } from "../../redux/reducers/reports/outbound";
import { loadOutboundReport, resetOutboundReport } from "./outbound/outbound.api";
import RadialComponent from "./outbound/radial.component";
import {
  actionToggleFilterView,
  actionSelectClient,
  actionSelectCompany,
  actionSelectDepartment,
  actionSelectCampaignType,
  actionSelectCampaign,
  actionSelectStartDate,
  actionSelectEndDate
} from "../../redux/reducers/shell";
import { useWindowSize, useDebounce } from "../../custom.hooks";
import ClientSelector from "../shell/clientselector.component";
import CompanySelector from "../shell/companyselector.component";
import DepartmentSelector from "../shell/departmentselector.component";
import CampaignTypeSelector from "../shell/campaigntypeselector.component";
import CampaignSelector from "../shell/campaignselector.component";
import PeriodSelector from "../shell/periodselector.component";
import {
  Grid,
  CardContent,
  Card,
  CardActions,
  makeStyles,
  Theme,
  createStyles,
  Button, CircularProgress, TextField, MenuItem
} from "@material-ui/core";

interface IAlertMessage {
  show: boolean;
  variant: "success" | "primary" | "secondary" | "danger" | "warning" | "info" | "dark" | "light" | undefined;
  message: string;
}

interface IReportProps {
  dispatch: any;
  currentPage: number;
  selectedClient: string;
  selectedCompany: string;
  selectedStartDate: Date;
  selectedEndDate: Date;
  selectedReport: string;
  selectedCampaignType: string;
  selectedCampaign: string;
  selectedDepartment: string;
  sortColumns: any;
  reportFilter: any;
}

registerLocale("nb", nb);
setDefaultLocale("nb");

class ViewportDatasource {
  params: any;
  setVpParams: any;
  setVpRange: any;

  constructor(setVpParams, setVpRange) {
    this.setVpParams = setVpParams;
    this.setVpRange = setVpRange;
  }

  init(params) {
    this.setVpParams(params);
    this.params = params;
  }

  setViewportRange(firstRow, lastRow) {
    this.setVpRange({ firstRow, lastRow });
  }

  destroy() {}
}

const intervalFormat = value => {
  let initsec = value * 3600;
  let hours = Math.floor(initsec / 3600);
  let minutes = Math.floor((initsec % 3600) / 60);
  let seconds = Math.round(initsec % 60);

  let sHours = hours.toString();
  let sMinutes = minutes.toString();
  let sSeconds = seconds.toString();

  if (hours < 10) {
    sHours = "0" + sHours;
  }
  if (minutes < 10) {
    sMinutes = "0" + sMinutes;
  }
  if (seconds < 10) {
    sSeconds = "0" + sSeconds;
  }

  return sHours + ":" + sMinutes + ":" + sSeconds;
};

const formatIntervalAvg = avgField => params => {
  if (!params.data) {
    return '00:00:00';
  }

  let value = params.data[params.colDef.field];
  if (!value) {
    return '00:00:00';
  }

  if (value && value.length !== 0) {
    value = value / params.data[avgField];
  }

  if (params.data[avgField] === "0" || params.data[avgField] === 0) {
    value = 0;
  }

  value = intervalFormat(value);

  return value;
};

const formatInterval = params => {
  if (!params.data) {
    return '00:00:00';
  }

  let value = params.data[params.colDef.field];
  if (!value) {
    return '00:00:00';
  }

  // TODO: Handle negative values (idle time ..)
  if (value < 0) {
    value = 0;
  }

  value = intervalFormat(value);

  return value;
};
const pauseProsent = params => {
  if (!params.data) {
    return '';
  }
  let logtime = params.data.log_time;
  let pausetime = params.data.pause_time;
  let result = pausetime / logtime;

  return (result*100).toFixed(1);

}
const readyProsent = params => {
  if (!params.data) {
    return '';
  }
  let logtime = params.data.log_time;
  let readytime = params.data.ready_time;
  let result = readytime / logtime;

  return (result*100).toFixed(1);

}
const grpColsDate = [
  { headerName: "Outcome Date", field: "outcome_date" },
  { headerName: "Log Time", field: "log_time", valueGetter: formatInterval },
  { headerName: "Ready Time", field: "ready_time", valueGetter: formatInterval },
  { headerName: "Talk Time", field: "talk_time", valueGetter: formatInterval },
  { headerName: "Wrapup Time", field: "wrapup_time", valueGetter: formatInterval },
  { headerName: "Idle Time", field: "idle_time", valueGetter: formatInterval },
  { headerName: "Pause Time", field: "pause_time", valueGetter: formatInterval },
  { headerName: "Wrapup Time (avg)", field: "wrapup_time", valueGetter: formatIntervalAvg("outcome_count") },
  { headerName: "Talk Time (avg)", field: "talk_time", valueGetter: formatIntervalAvg("outcome_count") },
  { headerName: "Outcome Count", field: "outcome_count" },
  { headerName: "Pause %", valueGetter: pauseProsent },
  { headerName: "Ready %", valueGetter: readyProsent }
];

const grpColsCampaign = [
  { headerName: "Company", field: "company_name" },
  { headerName: "Client", field: "client_name" },
  { headerName: "Campaign Type", field: "campaign_type_name" },
  { headerName: "Campaign", field: "campaign_name" },
  { headerName: "Log Time", field: "log_time", valueGetter: formatInterval },
  { headerName: "Ready Time", field: "ready_time", valueGetter: formatInterval },
  { headerName: "Talk Time", field: "talk_time", valueGetter: formatInterval },
  { headerName: "Wrapup Time", field: "wrapup_time", valueGetter: formatInterval },
  { headerName: "Idle Time", field: "idle_time", valueGetter: formatInterval },
  { headerName: "Pause Time", field: "pause_time", valueGetter: formatInterval },
  { headerName: "Wrapup Time (avg)", field: "wrapup_time", valueGetter: formatIntervalAvg("outcome_count") },
  { headerName: "Talk Time (avg)", field: "talk_time", valueGetter: formatIntervalAvg("outcome_count") },
  { headerName: "Outcome Count", field: "outcome_count" },
  { headerName: "Pause %", valueGetter: pauseProsent },
  { headerName: "Ready %", valueGetter: readyProsent }
];

const grpColsAgent = [
  { headerName: "Department Name", field: "department_name" },
  { headerName: "Login Name", field: "login_name" },
  { headerName: "Log Time", field: "log_time", valueGetter: formatInterval },
  { headerName: "Ready Time", field: "ready_time", valueGetter: formatInterval },
  { headerName: "Talk Time", field: "talk_time", valueGetter: formatInterval },
  { headerName: "Wrapup Time", field: "wrapup_time", valueGetter: formatInterval },
  { headerName: "Idle Time", field: "idle_time", valueGetter: formatInterval },
  { headerName: "Pause Time", field: "pause_time", valueGetter: formatInterval },
  { headerName: "Wrapup Time (avg)", field: "wrapup_time", valueGetter: formatIntervalAvg("outcome_count") },
  { headerName: "Talk Time (avg)", field: "talk_time", valueGetter: formatIntervalAvg("outcome_count") },
  { headerName: "Outcome Count", field: "outcome_count" },
  { headerName: "Pause %", valueGetter: pauseProsent },
  { headerName: "Ready %", valueGetter: readyProsent }
];

const runReport = (props: IReportProps) => {
  if (!props) {
    return;
  }

  let query = {
    client_id: props.selectedClient,
    company_id: props.selectedCompany,
    from_date: format(props.selectedStartDate, "yyyy-MM-dd"),
    to_date: format(props.selectedEndDate, "yyyy-MM-dd"),
    group_by: props.selectedReport,
    per_page: 100,
    page: props.currentPage,
    campaign_type_id: props.selectedCampaignType,
    campaign_id: props.selectedCampaign,
    department_id: props.selectedDepartment
  };

  if (props.sortColumns !== undefined) {
    let i = 0;
    props.sortColumns.forEach((c: Column) => {
      if (c.getSort() === null) {
        return;
      }
      query["order_by[" + i + "][field_name]"] = c.getColId();
      query["order_by[" + i + "][direction]"] = c.getSort();
      i++;
    });
  }

  if (props.reportFilter) {
    let y = 0;
    props.reportFilter.forEach((f: any) => {
      query["filter_by[" + y + "][field_name]"] = f.field;
      query["filter_by[" + y + "][value]"] = f.filter;
      y++;
    });
  }

  loadOutboundReport(props.dispatch, query);
};
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorMessage: {
            color: "#ff0000"
        },
        flexGrow: {
            flexGrow: 1,
        },
        actions: {
            "& .form-group": {
                minWidth: 200,
                marginRight: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
            "& button": {
                marginTop: 24,
            },
        },
        agGrid: {
            "& .aggrid-size": {
                minHeight: 500,
                height: "calc(100vh - 460px)",
                width: "100%",
            },
        },
    })
);

export default function ReportOutboundComponent() {
  const defaultSelection = null;
  const classes = useStyles();

  const selectionSettings = UserSettings.getOption(UserSettings.REPORT_OUTBOUND_SELECTIONS, {
    selectedCompany: defaultSelection,
    selectedClient: defaultSelection,
    selectedReport: { label: "View per date", value: "outcome_date" },
    selectedDepartment: defaultSelection,
    selectedCampaignType: defaultSelection,
    selectedCampaign: defaultSelection
  });

  const dispatch = useDispatch();
  const hideFilters = useSelector(state => state.shell.hideFilters);

  const outboundReport: IOutboundReport = useSelector(state => state.reports.outbound.report);
  const apiRunning = useSelector(state => state.shell.apiRunning);
  const [loading, setLoading] = useState(false);
  const selectedClient = useSelector(state => state.shell.selectedClient);
  const selectedCompany = useSelector(state => state.shell.selectedCompany);
  const selectedDepartment = useSelector(state => state.shell.selectedDepartment);
  const selectedCampaignType = useSelector(state => state.shell.selectedCampaignType);
  const selectedCampaign = useSelector(state => state.shell.selectedCampaign);
  const selectedStartDate = useSelector(state => state.shell.selectedStartDate);
  const selectedEndDate = useSelector(state => state.shell.selectedEndDate);

  const [gridAPI, setGridAPI] = useState<GridApi>();
  const [columnAPI, setColumnAPI] = useState<ColumnApi>();
  const [vpParams, setVpParams] = useState<any>();
  const [sortColumns, setSortColumns] = useState<Column[]>();
  const [reportFilter, setReportFilter] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const [viewportRange, setViewportRange] = useState({ firstRow: 0, lastRow: 0 });

  const [selectedReport, setSelectedReport] = useState(selectionSettings.selectedReport);
  const [columnSizeSetting, setColumnSizeSetting] = useState(selectionSettings.columnSize);

  const [apiMessage, setApiMessage] = useState<IAlertMessage>({ show: false, variant: "success", message: "" });
  const [apiAlertTimeout, setApiAlertTimeout] = useState<any>(0);

  const [columnDefs, setColumnDefs] = useState(grpColsDate);

  const [logtimeReportParams, setLogtimeReportParams] = useState({});
  const [reportQuery, setReportQuery] = useState<any>(null);
  const debouncedReportQuery = useDebounce(reportQuery, 400);
  const windowSize = useWindowSize();

  const availableReports = [
    { label: "View per agent", value: "login_name" },
    { label: "View per campaign", value: "campaign_name" },
    { label: "View per date", value: "outcome_date" }
  ];

  useEffect(() => {
    clearTimeout(apiAlertTimeout);
    setApiAlertTimeout(
      setTimeout(() => {
        setApiMessage({ ...apiMessage, show: false });
      }, 15000)
    );
  }, [apiMessage]);

  // useEffect(() => {
  //   UserSettings.setOption(UserSettings.REPORT_OUTBOUND_SELECTIONS, {
  //     selectedCompany,
  //     selectedClient,
  //     selectedReport,
  //     selectedStartDate,
  //     selectedEndDate,
  //     selectedCampaignType,
  //     selectedCampaign,
  //     selectedDepartment
  //   });

  //   // TODO: Are we sure we want to run the report on every change?
  //   // runReport(currentPage)
  // }, [
  //   selectedCompany,
  //   selectedClient,
  //   selectedReport,
  //   selectedStartDate,
  //   selectedEndDate,
  //   selectedCampaignType,
  //   selectedCampaign,
  //   selectedDepartment
  // ]);

  useEffect(() => {
    resetOutboundReport(dispatch);
  }, [selectedReport, dispatch]);

  useEffect(() => {
    if (vpParams && outboundReport && outboundReport.data) {
      vpParams.setRowCount(outboundReport.total);

      if (viewportRange) {
        if (vpParams && outboundReport) {
          const rowData: any = [];
          let idx = viewportRange.firstRow;
          outboundReport.data.forEach(row => {
            rowData[idx] = row;
            idx++;
          });
          vpParams.setRowData(rowData);
        }
      }
    }
  }, [outboundReport]);

  useEffect(() => {
    setLogtimeReportParams({
      client_id: selectedClient ? selectedClient.value : 0,
      company_id: selectedCompany ? selectedCompany.value : 0,
      from_date: format(selectedStartDate, "yyyy-MM-dd"),
      to_date: format(selectedEndDate, "yyyy-MM-dd")
    });

    switch (selectedReport ? selectedReport.value : "") {
      case "login_name":
        setColumnDefs(grpColsAgent);
        break;
      case "campaign_name":
        setColumnDefs(grpColsCampaign);
        break;
      case "outcome_date":
        setColumnDefs(grpColsDate);
        break;
    }

    setReportQuery({
      dispatch: dispatch,
      currentPage: currentPage,
      selectedClient: selectedClient ? selectedClient.value : 0,
      selectedCompany: selectedCompany ? selectedCompany.value : 0,
      selectedStartDate: selectedStartDate,
      selectedEndDate: selectedEndDate,
      selectedReport: selectedReport ? selectedReport.value : "",
      selectedCampaignType: selectedCampaignType ? selectedCampaignType.value : 0,
      selectedCampaign: selectedCampaign ? selectedCampaign.value : 0,
      selectedDepartment: selectedDepartment ? selectedDepartment.value : 0,
      sortColumns: sortColumns,
      reportFilter: reportFilter
    });
  }, [
    dispatch,
    currentPage,
    selectedClient,
    selectedCompany,
    selectedStartDate,
    selectedEndDate,
    selectedReport,
    selectedCampaignType,
    selectedCampaign,
    selectedDepartment,
    sortColumns,
    reportFilter
  ]);

  // useEffect(() => runReport(debouncedReportQuery), [debouncedReportQuery]);
  
  useEffect(() => {
    const updateGridColumnSizes = () => {
      if (!columnSizeSetting) {
        return;
      }

      switch (columnSizeSetting) {
        case "autoSizeColumns":
          if (columnAPI) {
            var allColumnIds: any = [];
            columnAPI.getAllColumns().forEach(column => {
              const colDef = column.getColDef();
              if (colDef.suppressAutoSize) {
                allColumnIds.push(column.getColId());
              }
            });
            columnAPI.autoSizeColumns(allColumnIds);
          }
          break;
        case "sizeColumnsToFit":
          if (gridAPI) {
            gridAPI.redrawRows();
            gridAPI.sizeColumnsToFit();
          }
          break;
      }
    };

    updateGridColumnSizes();
  }, [columnSizeSetting, hideFilters, outboundReport, gridAPI, columnAPI, windowSize]);

  const resetState = () => {
    batch(() => {
      dispatch(actionSelectClient(null));
      dispatch(actionSelectCompany(null));
      dispatch(actionSelectDepartment(null));
      dispatch(actionSelectCampaignType(null));
      dispatch(actionSelectCampaign(null));
      dispatch(actionSelectStartDate(new Date()));
      dispatch(actionSelectEndDate(new Date()));
    });
  };

  const onGridReady = (event: GridReadyEvent) => {
    setGridAPI(event.api);
    setColumnAPI(event.columnApi);

    const viewportDatasource = new ViewportDatasource(setVpParams, setViewportRange);
    event.api.setViewportDatasource(viewportDatasource);
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {};
  const onRowDoubleClicked = (event: RowDoubleClickedEvent) => {};

  const onSortChanged = (event: SortChangedEvent) => {
    const sortColumns = event.columnApi.getAllColumns().filter(c => c.getSort() !== undefined);
    setSortColumns(sortColumns);
  };

  const onFilterChanged = (event: FilterChangedEvent) => {
    const rFilter: any = [];
    event.columnApi.getAllColumns().forEach((c: Column) => {
      const f = event.api.getFilterInstance(c.getColId());
      if (f) {
        const filter = f.getModel();
        const field = c.getColId();
        if (filter.length > 0) {
          rFilter.push({ field, filter });
        }
      }
    });
    setReportFilter(rFilter);
  };

  const onPaginationChanged = (event: PaginationChangedEvent) => {
    const page = event.api.paginationGetCurrentPage() + 1;
    if (event.newPage) {
      setCurrentPage(page);
    }
  };

  const toolbarProps = {
    title: "",
    rightAlign: true,
    disabled: false,
    icon: "",
    onClick: () => {}
  };

  return (
    <div>
      <TitlebarComponent
        title="Outbound Report"
        description="Report for outbound calls"
        breadcrumbs={[
          { title: "Dashboard", link: "/" },
          { title: "Outbound Report", link: "/reports/outbound" }
        ]}
      />

      
      <Grid container spacing={2}>
        <Grid item md={12}>
            {apiMessage.show ? <Alert variant={apiMessage.variant}>{apiMessage.message}</Alert> : null}
            <div
              style={{ color: "#ccc", width: 15 }}
              data-toggle="tooltip"
              data-original-title="Hide/Show filters"
              onClick={() => dispatch(actionToggleFilterView())}
            >
              {hideFilters ? (
                <i className="fas fa-fw fa-caret-square-right"></i>
              ) : (
                <i className="fas fa-fw fa-caret-square-left"></i>
              )}
            </div>
        </Grid>
        <Grid item md={2} style={{ display: hideFilters ? "none" : "block" }}>  
          <div style={{maxWidth: "270px"}}>
            <ClientSelector variant="material" fullWidth/>
            <CompanySelector variant="material" fullWidth/>
            <DepartmentSelector variant="material" fullWidth/>
            <CampaignTypeSelector variant="material" fullWidth/>
            <CampaignSelector variant="material" fullWidth/>
            <PeriodSelector variant="material" fullWidth/>

            <TextField 
                    value={selectedReport ? selectedReport.value : ""}
                    select 
                    label="Options"
                    fullWidth
                    onChange={e => setSelectedReport(e.target)}
                    >
                    {availableReports.map((a, key) => (
                      <MenuItem key={key} value={a.value}>{a.label}</MenuItem>
                    ))}
            </TextField>

            <div style={{ textAlign: "right", marginTop: 20 }}>
            { loading || apiRunning ? <CircularProgress size={20}/> : null}
              <Button onClick={resetState}  color="primary" variant="outlined">
                Reset
              </Button>
              &nbsp;
              <Button 
                disabled={loading || !!apiRunning } 
                onClick={() => runReport(reportQuery)} 
                color="primary" 
                variant="contained" >
                Search
              </Button>
            </div>
          </div>
        </Grid>
        <Grid item md={hideFilters ? 12 : 10}>
          <Card>
              {/* apiRunning ? <LoadingScreen message="Loading report. Please wait." /> : null */}
              <div style={{paddingTop: "10px"}}>
                {logtimeReportParams ? <RadialComponent reportParams={logtimeReportParams} /> : null}
              </div>
            <CardActions disableSpacing className={classes.actions}>
              <div style={{ textAlign: "center" }}>
                <i>Report data is delayed by 15 minutes.</i>
              </div>

              <div className={classes.flexGrow} />
                {AgToolbarButton({
                  ...toolbarProps,
                  title: "Export to Excel",
                  rightAlign: true,
                  icon: "fa-file-export",
                  onClick: () => {
                    if (gridAPI) {
                      gridAPI.exportDataAsExcel();
                    }
                  }
                })}

                {AgToolbarButton({
                  ...toolbarProps,
                  title: "Adjust columns to content",
                  icon: "fa-text-width",
                  onClick: () => setColumnSizeSetting("autoSizeColumns")
                })}

                {AgToolbarButton({
                  ...toolbarProps,
                  title: "Adjust columns to window",
                  icon: "fa-columns",
                  onClick: () => setColumnSizeSetting("sizeColumnsToFit")
                })}

                {AgToolbarButton({
                  ...toolbarProps,
                  title: "Refresh",
                  rightAlign: true,
                  icon: "fa-sync-alt",
                  onClick: () => {
                    runReport(reportQuery);
                  }
                })}
            
            </CardActions>
            <CardContent>
              <Grid container>
                  <Grid item md={12} className={classes.agGrid}>
                    <div className="ag-theme-balham" style={{ height: "800px", width: "100%" }}>
                      <AgGridReact
                        columnDefs={columnDefs}
                        groupIncludeTotalFooter={false}
                        groupIncludeFooter={false}
                        groupMultiAutoColumn={true}
                        animateRows={true}
                        enableRangeSelection={false}
                        rowSelection="multiple"
                        onGridReady={onGridReady}
                        onFirstDataRendered={onFirstDataRendered}
                        onRowDoubleClicked={onRowDoubleClicked}
                        sideBar={{
                          toolPanels: ["columns", "filters"]
                        }}
                        suppressAggFuncInHeader={true}
                        defaultColDef={{
                          sortable: true,
                          resizable: true,
                          filter: true
                        }}
                        rowModelType="viewport"
                        viewportRowModelPageSize={100}
                        viewportRowModelBufferSize={0}
                        onSortChanged={onSortChanged}
                        onFilterChanged={onFilterChanged}
                        pagination={true}
                        paginationPageSize={100}
                        onPaginationChanged={onPaginationChanged}
                        cacheBlockSize={100}
                        cacheOverflowSize={2}
                        maxBlocksInCache={2}
                        frameworkComponents={{}}
                        applyColumnDefOrder={true}
                      />
                    </div>
                  </Grid>
                </Grid>
            </CardContent>
        </Card>
    </Grid>
  </Grid>
  </div>
    
  );
}
