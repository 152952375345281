import { actionStartAPI, actionEndAPI } from "../../../redux/reducers/shell";
import {
  actionReceivedOutboundReport,
  actionReceivedOutboundLogtimeReport,
  actionReceivedOutboundCallsReport
} from "../../../redux/reducers/reports/outbound";

declare var env: any;

/**
 * Encode an object as url query string parameters
 * - includes the leading "?" prefix
 * - example input — {key: "value", alpha: "beta"}
 * - example output — output "?key=value&alpha=beta"
 * - returns empty string when given an empty object
 */
function encodeQueryString(params) {
  const keys = Object.keys(params);
  return keys.length
    ? "?" + keys.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])).join("&")
    : "";
}

export function loadOutboundReport(dispatch, reportParams) {
  dispatch(actionStartAPI());
  const fetchReport = async () => {
    const apiURL = env._apiPath + "/reports/dialerReport";
    const params = encodeQueryString(reportParams);

    const res = await fetch(apiURL + params, { method: "GET", credentials: "include" });
    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status !== "success") {
      // dispatch(actionSetLoginErrorMessage(json.message))
      return;
    }

    const report = json.data;

    dispatch(actionReceivedOutboundReport(report));
  };

  fetchReport();
}

export function resetOutboundReport(dispatch) {
  const report = {
    current_page: 0,
    first_page_url: "",
    from: 0,
    to: 0,
    last_page: 0,
    per_page: 0,
    total: 0,
    last_page_url: "",
    next_page_url: "",
    prev_page_url: "",
    path: "",
    data: []
  };

  dispatch(actionReceivedOutboundReport(report));
}

export function loadOutboundLogtime(dispatch, reportParams) {
  dispatch(actionStartAPI());
  const fetchReport = async () => {
    const apiURL = env._apiPath + "/reports/dialerReportLogtime";
    const params = encodeQueryString(reportParams);

    const res = await fetch(apiURL + params, { method: "GET", credentials: "include" });
    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status !== "success") {
      // dispatch(actionSetLoginErrorMessage(json.message))
      return;
    }

    const report = json.data;

    dispatch(actionReceivedOutboundLogtimeReport(report));
  };

  fetchReport();
}

export function loadOutboundCalls(dispatch, reportParams) {
  dispatch(actionStartAPI());
  const fetchReport = async () => {
    const apiURL = env._apiPath + "/reports/dialerReportCalls";
    const params = encodeQueryString(reportParams);

    const res = await fetch(apiURL + params, { method: "GET", credentials: "include" });
    const json = await res.json();
    dispatch(actionEndAPI());
    if (json.status !== "success") {
      // dispatch(actionSetLoginErrorMessage(json.message))
      return;
    }

    const report = json.data;

    dispatch(actionReceivedOutboundCallsReport(report));
  };

  fetchReport();
}
