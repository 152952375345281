import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  makeStyles,
  Theme,
  createStyles
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import Form from "react-bootstrap/Form";
import RejectReasonsAPI from "../../campaignmanagement/orderrejectedreasons/rejectreasons.api";
import { rejectOrderWithReason } from "./orders.api";

interface IRejectedReasons {
  open: boolean;
  operation: string;
  order: any;
  onClose: any;
  onReloadReport?: any;
  fetchOrder: Function;
}

interface ISelectOption {
  label: string;
  value: string | number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorMessage: {
      color: "#ff0000"
    },
    dialogPaper: { overflow: "visible" }
  })
);

export default function RejectedReasons(props: IRejectedReasons) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedReason, setSelectedReason] = useState<null | ISelectOption>(null);
  const [rejectComment, setRejectComment] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [reasons, setReasons] = useState();

  useEffect(() => {
    var rejectedType = 1;
    switch (props.operation) {
      case 'reject':
        rejectedType = 1;
        break;
      case 'regret': 
        rejectedType = 2;
        break;
      case 'undoreject':
        rejectedType = 3;
        break;
    }
    //const rejectedType = props.operation === "regret" ? 2 : 1;
    RejectReasonsAPI.fetchRejectedReasons(null, rejectedType)
      .then(r => {
        const reasonOptions: any = [];
        r.data.forEach(d => {
          let reasonLabel = d.reason_name;
          if (d.client) {
            reasonLabel = d.client.client_name + " - " + d.reason_name;
          }
          reasonOptions.push({ value: d.reason_id, label: reasonLabel });
        });
        setReasons(reasonOptions);
      })
      .catch(e => setErrorMessage("Unable to get rejected reasons: " + e));

    setSelectedReason(null);
    setRejectComment("");
    setErrorMessage("");
  }, [props.order, props.operation, setReasons, setSelectedReason, setRejectComment, setErrorMessage]);

  const onCancelReject = () => {
    props.onClose();
    setSelectedReason(null);
    setRejectComment("");
    setErrorMessage("");
  };

  const onReject = () => {
    let reasonID = selectedReason ? selectedReason!.value : null;

    rejectOrderWithReason(dispatch, props.order.order_id, props.operation, reasonID, rejectComment)
      .then(r => {
        props.onClose();
        // props.onReloadReport();
        props.fetchOrder(props.order.order_id);
      })
      .catch(e => {
        setErrorMessage(e);
      });
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="frm-reject-operation"
      PaperProps={{ className: classes.dialogPaper }}
    >
      <DialogTitle id="frm-reject-operation">
        Change Order Status for #{props.order ? props.order.order_id : "..."}
      </DialogTitle>
      <DialogContent className={classes.dialogPaper}>
        <DialogContentText>
          Select the reason for {props.operation} {props.operation === "undoreject" ? "undo of the reject" : props.operation}.
        </DialogContentText>

        <Form.Group controlId="frm-reason">
          <Form.Label>Reason</Form.Label>
          <Select
            value={selectedReason}
            onChange={(opt: any) => setSelectedReason(opt)}
            options={reasons}
            isClearable={true}
          />
        </Form.Group>

        <TextField
          autoFocus
          margin="dense"
          id="reject-comment"
          label="Comment"
          type="text"
          fullWidth
          value={rejectComment}
          onChange={(e: any) => setRejectComment(e.target.value)}
        />

        {errorMessage ? <DialogContentText className={classes.errorMessage}>{errorMessage}</DialogContentText> : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelReject} color="primary">
          Cancel
        </Button>
        <Button onClick={onReject} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
