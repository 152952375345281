import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./details.scss";
import LoadingScreen from "../../../loadingscreen.component";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {
  fetchRejectReasons,
  SMSConfirm,
  loadOrderStatus,
  resetOrderDetailAPISent,
  loadOrderStatusNoRedux,
  fetchOrderOperations,
  IOperations,
  loadOrder,
  loadOrder2,
  IOrder,
} from "./orders.api";
import ExpandableText from "./expandableText.component";
import NewWindow from "react-new-window";
import RejectedReasons from "./rejectedReasons.component";
import SoundfileComponent from "./soundfile.component";
import {
  Button as MUIButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography,
  Popover,
  Snackbar,
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

// declare var env: any;
// const env = {
//   _apiPath: "https://api-stage.fiko.no/manager",
//   _apiPathOms: "https://oms.fiko.no/"
// };

interface IRegretRejectReasons {
  open: boolean;
  operation: string;
}
export interface ISoundFile {
  activity_id: string;
  activity_file_id: string;
  confirmed_at?: string;
  rejected_at?: string;
  regretted_at?: string;
  outcome_name: string;
  created_at: string;
  filesize: string;
  order_rejected_reason_id?: string;
  order_action_comment?: string;
}
export interface order_rejected_reasons {
  reason_id: number;
  created_at: Date;
  updated_at: Date;
  reason_name: string;
  rejected_type: number;
  hide_in_list: number;
  client?: number;
}
type IOMSData = {
  url: string;
};
// options for Intl.DataTimeFormat
let options = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour12: false,
} as const;

export default function OrderDetails(props) {
  const dispatch = useDispatch();
  const [order, setOrder] = useState<IOrder>(props.order);
  const [orderExtra, setOrder2] = useState<IOrder>(props.order2);
  const [loading, setLoading] = useState(false);
  const [openOMSHandle, setOpenOMSHandle] = useState<any>(null); // sett annet enn null for å åpne OMS
  const [omsData, setOmsData] = useState<IOMSData>();
  useEffect(() => {
    if (props) {
      setOrder(props.order);
      setOrder2(props.order2);
    }
  }, [props]);
  const [operations, setOperations] = useState<IOperations[]>([]);
  // var til åpne oms via redux knappen
  // const orderEditStatus = useSelector((state) => state.reports.orders.orderDetails.editstatus);
  const apiRunning = useSelector((state) => state.shell.apiRunning);
  const [showEmpty, setShowEmpty] = useState(false);
  // eslint-disable-next-line
  const [showOrderStatusActions, setShowOrderStatusActions] = useState(false);
  const defaultRejectRegret = { open: false, operation: "" };
  const [rejectRegretReasons, setRejectRegretReasons] = useState<IRegretRejectReasons>(defaultRejectRegret);

  const [actDebugHandle, setActDebugHandle] = useState<any>(null);
  const [openAPITransferDialog, setOpenAPITransferDialog] = useState(false);
  const [openChangeStatusDialog, setOpenChangeStatusDialog] = useState(false);

  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMsg, setAlertMsg] = useState({ msg: "", severity: false });

  const rejectReasons = ["Rejected", "Regretted", "Undid rejection"];
  const emptyRejectedReason = {
    reason_id: 0,
    created_at: new Date(),
    updated_at: new Date(),
    reason_name: "",
    rejected_type: 0,
    hide_in_list: 0,
  };
  const [rejectedReasons, setRejectedReasons] = useState<order_rejected_reasons[]>([emptyRejectedReason]);

  function fetchOrder(order_id: number) {
    setLoading(true);
    loadOrder(order_id)
      .then((res) => setOrder(res))
      .catch((err) => console.error(err));
    loadOrder2(order_id)
      .then((res) => setOrder2(res))
      .then(() => setLoading(false))
      .catch((err) => console.error(err));
  }
  const orderHeaderIdMap = {
    "2": "firstname",
    "3": "lastname",
    "4": "mobile",
    "5": "phone",
    "6": "email",
    "7": "street",
    "8": "house_number",
    "9": "zip",
    "10": "city",
    "11": "birthdate",
    "12": "company_name",
    "13": "company_id",
    "16": "comment",
    "36": "national_id_number",
    "37": "door_code",
    "52": "customer_number",
  };

  const [hasConfirmedSms, setHasConfirmedSms] = useState(false);

  const [hasUnconfirmedSMS, setHasUnconfirmedSMS] = useState(0);
  useEffect(() => {
    if (order !== null) {
      var result: number = 0;
      // eslint-disable-next-line
      order?.order_sms.some(function (sms) {
        if (!sms.message_in && !sms.outdated) {
          result = sms.order_confirmation_id;
          return true;
        }
      });
      setHasUnconfirmedSMS(result);
      // eslint-disable-next-line
      order?.order_activities.some(function (act) {
        if (act.order_activity_type_id === 13 && act.success) setHasConfirmedSms(true);
      });
    }
  }, [order, dispatch]);

  // useEffect(() => {
  //   if (orderEditStatus) {
  //     setOpenOMSHandle(orderEditStatus);
  //   }
  // }, [orderEditStatus]);
  useEffect(() => {
    fetchRejectReasons().then((res) => {
      setRejectedReasons(res);
    });
  }, []);

  const changeOrderStatus = (order_id) => {
    if (order_id) {
      fetchOrderOperations(order_id)
        .then((res) => setOperations(res.operations))
        .catch((err) => console.error(err))
        .finally(() => setOpenChangeStatusDialog(true));
      // TODO: error handling
      // TODO: reload når error reason er lagt til
    }
  };

  function resetOrderDetailAPI(orderDetailId: number) {
    if (orderDetailId && order) {
      let data = {
        order_id: order.order_id,
        order_detail_id: orderDetailId,
        debug: 0, // sett til 1 for test
      };
      resetOrderDetailAPISent(data)
        .then((res) => {
          console.log(res);
          if (res[0].status === "OK") {
            fetchOrder(order.order_id);
          }
        })
        .catch((err) => console.error(err));
    }
  }

  function OrderDetailsAPIPopover({ product }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const apiStatus =
      product.api_sent_at && product.api_sent_status === "HTTP/1.1 200 OK" ? (
        <i className="fas fa-fw fa-check text-success"></i>
      ) : product.api_sent_at && product.api_sent_status !== "HTTP/1.1 200 OK" ? (
        <i className="fas fa-fw fa-times text-danger"></i>
      ) : null;

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
      <>
        {product && product.api_sent_at ? (
          <div>
            <MUIButton aria-describedby={id} color="primary" onClick={handleClick} title={product.api_sent_at}>
              {apiStatus}
            </MUIButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div style={{ height: "130px", width: "300px", padding: "10px", overflow: "hidden" }}>
                <Grid container spacing={1}>
                  <Grid item md={12}>
                    <MUIButton
                      style={{ margin: "0 auto", width: "100%" }}
                      variant="outlined"
                      onClick={() => {
                        resetOrderDetailAPI(product.order_detail_id);
                        handleClose();
                      }}
                      title={`ID: ${product.order_detail_id}`}
                    >
                      Reset API Sent
                    </MUIButton>
                  </Grid>
                  <Grid item md={12}>
                    {" "}
                    Status: {product.api_sent_status}
                  </Grid>
                  <Grid item md={12}>
                    Date: {product.api_sent_at}
                  </Grid>
                </Grid>
              </div>
            </Popover>
          </div>
        ) : null}
      </>
    );
  }

  const openInOMS = () => {
    // gamle måten via redux: denne hadde en bug som åpnet oms vindu i tide og utide, fordi verdien var satt globalt og ble lest på nytt i useeffect 
    // event.preventDefault();
    // event.stopPropagation();
    // loadOrderStatus(dispatch, order.order_id, "edit");
    // return false;

    // ny uten redux:
    // fikser sånn at oms vindu ikke spretter opp når det ikke skal. det var fordi global state hadde satt data fra edit mode, så den leste det i useeffect og åpnet oms på nytt
    loadOrderStatusNoRedux(order.order_id, "edit")
      .then((res) => {
        if (res.status === "success") {
          setOmsData(res.data);
          setOpenOMSHandle(true);
        }
      })
      .catch((err) => {
        setAlertMsg({ msg: JSON.stringify(err.message), severity: false });
        setAlertOpen(true);
      });
  };

  const onChangeOrderStatus = (operation) => {
    setShowOrderStatusActions(false);

    // Some operations require additional parameters
    if (operation === "reject" || operation === "regret" || operation === "undoreject") {
      // Show window for selection of reject reasons, and comment
      setRejectRegretReasons({
        operation,
        open: true,
      });
      return;
    }
    // sender endring via redux
    loadOrderStatus(dispatch, order.order_id, operation);
    // reloader ordre
    fetchOrder(order.order_id);
  };
  const CustomerDetailsComponent = ({ orderinfo }) => {
    return (
      <>
        <h5>Customer Details</h5>

        <Grid item md={12} sm={12}>
          {orderinfo.company_name ? (
            <span title="Company Name">
              {orderinfo.company_name.toUpperCase()} - {orderinfo.company_id}
              <br />
            </span>
          ) : null}
        </Grid>
        <Grid item sm={12} md={12}>
          <strong style={{ fontSize: "20px" }}>
            <span title="Firstname">{orderinfo.firstname}</span> <span title="Lastname">{orderinfo.lastname}</span>
          </strong>
        </Grid>
        <Grid item sm={12} md={12}>
          <span title="Street">{orderinfo.street}</span> <span title="House">{orderinfo.house_number}</span>
        </Grid>
        <Grid item sm={12} md={12}>
          <span title="Zip">{orderinfo.zip}</span> <span title="City">{orderinfo.city}</span>
        </Grid>
        <Grid item sm={12} md={12}>
          <span title="Email">
            {orderinfo.email ? <a href={"mailto:" + orderinfo.email}>{orderinfo.email}</a> : null}
          </span>
        </Grid>
        <br />
        {orderinfo.mobile ? (
          <Grid container item>
            <Grid item md={2}>
              Mobile{" "}
            </Grid>
            <Grid item md={10}>
              {orderinfo.mobile}
            </Grid>
          </Grid>
        ) : null}
        {orderinfo.phone ? (
          <Grid container item>
            <Grid item md={2}>
              Phone{" "}
            </Grid>
            <Grid item md={10}>
              {orderinfo.phone}
            </Grid>
          </Grid>
        ) : null}
        {orderinfo.birthdate ? (
          <Grid container item>
            <Grid item md={2}>
              Birthdate{" "}
            </Grid>
            <Grid item md={10}>
              {new Intl.DateTimeFormat("nb-NO", options).format(new Date(orderinfo.birthdate))}
            </Grid>
          </Grid>
        ) : null}
        {orderinfo.comment ? (
          <Grid container item>
            <Grid item md={3}>
              Comment{" "}
            </Grid>
            <Grid item md={9}>
              {orderinfo.comment}
            </Grid>
          </Grid>
        ) : null}
      </>
    );
  };
  const CampaignDataComponent = () => {
    return (
      <>
        <h5>Campaign Data</h5>
        <Grid container>
          <Grid item md={12} style={{ height: "200px", maxHeight: "200px", overflow: "auto" }}>
            <MUITable size="small" style={{ lineHeight: "60%" }}>
              <TableBody>
                  <TableRow style={{ lineHeight: "80%" }}>
                    <TableCell>Campaign Id</TableCell>
                    <TableCell className="text-right">{order.campaign_id}</TableCell>
                  </TableRow>
                  <TableRow style={{ lineHeight: "80%" }}>
                    <TableCell>Shortname</TableCell>
                    <TableCell className="text-right">{order.shortname}</TableCell>
                  </TableRow>
                {order?.order_data.map((data, idx) => {
                  // Skip known internal fields, because we present them above.
                  if (orderHeaderIdMap[data.order_header_id]) {
                    return null;
                  }

                  if ((data.value === null || data.value === "Unknown" || data.value === "") && showEmpty === false) {
                    return null;
                  }

                  return (
                    <TableRow key={idx} style={{ lineHeight: "80%" }}>
                      <TableCell>{data.display_name}</TableCell>
                      <TableCell className="text-right">{data.value}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </MUITable>
          </Grid>
          <Grid item md={12}>
            <Form.Group controlId="frmShowEmpty" style={{ marginLeft: "30px" }}>
              <Form.Check
                type="checkbox"
                label="Show empty values"
                checked={showEmpty}
                onChange={(e) => setShowEmpty(e.target.checked)}
              />
            </Form.Group>
          </Grid>
        </Grid>
      </>
    );
  };
  const OrderDetailsComponent = () => {
    return (
      <>
        <h5>Order Details</h5>
        <TableContainer>
          <MUITable style={{ lineHeight: "60%" }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell width="10%">#</TableCell>
                <TableCell width="45%">Product</TableCell>
                <TableCell width="15%">Price</TableCell>
                <TableCell width="10%">Quantity</TableCell>
                <TableCell width="15%">Total</TableCell>
                <TableCell width="5%">API</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order?.order_details.map((product, idx) => (
                <TableRow key={idx}>
                  <TableCell component="th" scope="row" width="10%">
                    {idx + 1}
                  </TableCell>
                  <TableCell width="45%">
                    {product.product_name}
                    {product.product_description ? " - " + product.product_description : null}
                  </TableCell>
                  <TableCell width="15%">
                    {new Intl.NumberFormat("nb-NO", { style: "currency", currency: "NOK" }).format(product.price)}
                  </TableCell>
                  <TableCell width="10%">{product.amount}</TableCell>
                  <TableCell width="15%">
                    {new Intl.NumberFormat("nb-NO", { style: "currency", currency: "NOK" }).format(product.total)}
                  </TableCell>
                  <TableCell width="5%">
                    {/* <span title={product.api_sent_at}>{product.api_sent_at && product.api_sent_status === "HTTP/1.1 200 OK" 
                    ? <i className="fas fa-fw fa-check text-success"></i> 
                    : product.api_sent_at && product.api_sent_status !== "HTTP/1.1 200 OK" 
                    ? <i className="fas fa-fw fa-times text-danger"></i> 
                    : null}</span> */}
                    {product?.api_sent_status ? <OrderDetailsAPIPopover product={product} /> : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MUITable>
        </TableContainer>
      </>
    );
  };
  const ActivitiesComponent = () => {
    let optionsOD = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    } as const;
    return (
      <>
        <h5>Activities</h5>
        {!orderExtra ? (
          <>
            <i>...loading</i>
          </>
        ) : null}
        {!orderExtra?.order_activities.length &&
        !orderExtra?.order_signatures.length &&
        !orderExtra?.order_actions.length ? (
          <i>No activities available.</i>
        ) : null}
        {orderExtra?.order_signatures ? (
          <>
            <TableContainer>
              <MUITable style={{ lineHeight: "60%" }} size="small" aria-label="a dense table">
                <TableBody>
                  {orderExtra.order_signatures.map((sign, idx) => (
                    <TableRow key={idx}>
                      <TableCell component="th" scope="row" width="30%">
                        {sign.created_at}
                      </TableCell>
                      <TableCell width="30%">Signature</TableCell>
                      <TableCell width="60%">
                        <img src={sign.signature} style={{ height: 50 }} alt="" />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </MUITable>
            </TableContainer>
          </>
        ) : null}
        {orderExtra?.order_activities ? (
          <>
            <TableContainer>
              <MUITable style={{ lineHeight: "60%" }} size="small" aria-label="a dense table">
                <TableBody>
                  {orderExtra.order_activities.map((act, idx) => (
                    <TableRow key={idx}>
                      <TableCell component="th" scope="row" width="30%">
                        {act.executed_at
                          ? new Intl.DateTimeFormat("nb-NO", optionsOD).format(new Date(act.executed_at))
                          : null}
                      </TableCell>
                      <TableCell width="40%">{act.order_activity_type_name}</TableCell>
                      <TableCell width="15%">
                        {act.success ? (
                          <>
                            <i className="fas fa-fw fa-check text-success"></i> OK
                          </>
                        ) : (
                          <>
                            <i className="fas fa-fw fa-times text-danger"></i> Error
                          </>
                        )}
                      </TableCell>
                      <TableCell width="15%">
                        {act.order_activity_type_id ===
                          4 /* API Transfer */ /* Here one should check if there is an actual activity and not just display dialog if the type is this or that */ ||
                        act.order_activity_type_id === 7 /* Updated external status */ ||
                        act.order_activity_type_id === 16 /* second api transfer */ ||
                        act.order_activity_type_id === 11 /* Credit check */ ? (
                          // <a href="" onClick={(e) => {openDebugActivity(e, act)}}>Open Details</a>
                          // <a href="" onClick={() => (setActDebugHandle(act), setOpenAPITransferDialog(true))}>Open Details</a>
                          // eslint-disable-next-line
                          <span
                            style={{ textDecoration: "underline", color: "gray", cursor: "pointer" }}
                            onClick={() => {setActDebugHandle(act); setOpenAPITransferDialog(true)}}
                          >
                            Open Details
                          </span>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </MUITable>
            </TableContainer>
          </>
        ) : null}
      </>
    );
  };
  const ChangeLogComponent = () => {
    return (
      <>
        <h5>Changelog</h5>
        {!orderExtra ? <Grid container>...loading</Grid> : null}
        {orderExtra?.order_actions ? (
          <>
            <TableContainer>
              <MUITable style={{ lineHeight: "60%" }} size="small" aria-label="a dense table">
                <TableBody>
                  {orderExtra.order_actions.map((act, idx) => (
                    <TableRow key={idx}>
                      <TableCell component="th" scope="row" width="10%">
                        {new Intl.DateTimeFormat("nb-NO", options).format(new Date(act.created_at))}
                      </TableCell>
                      <TableCell width="30%">
                        {rejectReasons[Number(act.order_rejected_reason.type) - 1]} by {act.user.fullname}
                      </TableCell>
                      <TableCell width="30%">{act.order_rejected_reason.name}</TableCell>
                      <TableCell width="30%">{act.order_action_comment}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </MUITable>
            </TableContainer>
          </>
        ) : null}
      </>
    );
  };

  const SMSConfirmationComponent = () => {
    return (
      <>
        <h5>SMS Confirmation</h5>
        {!orderExtra ? <span>...loading</span> : null}
        <Grid container>
          {orderExtra?.order_sms.map((sms, idx) => {
            return (
              <Grid container key={idx}>
                {sms.message_in ? (
                  <Grid item container spacing={2} style={{ marginBottom: "15px" }}>
                    <Grid
                      item
                      md={10}
                      style={{
                        borderRadius: "10px",
                        backgroundColor: "hsl(145deg 65% 53% / 34%)",
                        border: "1px solid lightgray",
                      }}
                    >
                      <div>
                        <span>{sms.updated_at}</span> - {sms.recipient}
                      </div>
                      <ExpandableText disabled={sms.outdated ? true : false} text={sms.message_in} maxLength={50} />
                    </Grid>
                    <Grid item md={2}></Grid>
                  </Grid>
                ) : null}
                <Grid item container key={"cont2-" + idx} spacing={2} style={{ marginBottom: "15px" }}>
                  <Grid item md={2}>
                    {!sms.message_in && !sms.outdated ? (
                      <Button
                        variant={"secondary"}
                        onClick={() =>
                          SMSConfirm({
                            order_confirmation_id: sms.order_confirmation_id,
                            order_id: order.order_id,
                            status: true,
                          })
                            .then((res) => {
                              order.order_sms[idx].message_in = res.message_in;
                            })
                            // .then(() => { renderOrder(); })
                            .then(() => fetchOrder(order?.order_id))
                        }
                      >
                        <i className="fa fa-thumbs-up" aria-hidden={true}></i>
                      </Button>
                    ) : null}
                  </Grid>
                  <Grid
                    item
                    md={10}
                    style={{ borderRadius: "10px", border: "1px solid lightgray", backgroundColor: "white" }}
                  >
                    <div className="text-right">
                      <span>{sms.created_at}</span> - {sms.sender}
                    </div>
                    <ExpandableText disabled={sms.outdated ? true : false} text={sms.message_out} maxLength={50} />
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
          {orderExtra && orderExtra.order_othersms && orderExtra.order_othersms.length ? (
            <>
              <h5>Nonconfirmatory messages</h5>
              <Grid container>
                {orderExtra &&
                  orderExtra.order_othersms &&
                  orderExtra.order_othersms.map((sms, idx) => {
                    return (
                      <Grid container item key={"cont-non-" + idx} spacing={2} style={{ marginBottom: "15px" }}>
                        <Grid
                          item
                          md={10}
                          style={{ borderRadius: "10px", border: "1px solid pink", backgroundColor: "white" }}
                        >
                          <div>
                            <span>{sms.created_at}</span> - {sms.recipient}
                          </div>
                          {/* <ExpandableText disabled={sms.outdated?true:false} text={sms.message} maxLength={50} /> */}
                          <ExpandableText text={sms.message} maxLength={50} />
                        </Grid>
                        <Grid item md={2}>
                          {hasUnconfirmedSMS ? (
                            <Button
                              variant={"secondary"}
                              onClick={
                                () =>
                                  SMSConfirm({
                                    order_confirmation_id: hasUnconfirmedSMS,
                                    sms_incoming_id: +sms.sms_incoming_id,
                                    order_id: order.order_id,
                                    status: true,
                                  }).then((res) => {
                                    // eslint-disable-next-line
                                    order.order_sms.some(function (sms, sidx) {
                                      if (sms.order_confirmation_id === hasUnconfirmedSMS) {
                                        order.order_sms[sidx].message_in = res.message_in;
                                        order.order_othersms.splice(sidx, 1);
                                      }
                                    });
                                  })
                                // .then(() => { renderOrder(); })
                              }
                            >
                              <i className="fa fa-thumbs-up" aria-hidden={true}></i>
                            </Button>
                          ) : null}
                        </Grid>
                      </Grid>
                    );
                  })}
              </Grid>
            </>
          ) : null}
        </Grid>
      </>
    );
  };
  const OrderInfoGenerate = () => {
    const orderinfo: any = {};
    if (order && typeof order.order_data != "undefined") {
      order.order_data.forEach((o) => {
        if (orderHeaderIdMap[o.order_header_id]) {
          const k = orderHeaderIdMap[o.order_header_id];
          orderinfo[k] = o.value;
        }
      });
      order.order_data.forEach((d) => {
        if (orderHeaderIdMap[d.order_header_id]) {
          return;
        }
      });
    }
    return orderinfo;
  };
  const reloadOrder = () => {
    // brukeren har klikket på reload knappen i heading på view order
    if (order && order.order_id) {
      props.fetchOrder(order.order_id);
      return;
    }
  };
  // main return
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={props.showDetailsOpen}
        onClose={() => {
          props.onClose();
          setOpenOMSHandle(null);
        }}
      >
        <DialogTitle>
          <Grid container spacing={1}>
            <Grid item md={2}>
              Order #
              {order ? order.order_id : <span style={{ color: "red", fontSize: "16px" }}> Could not find order</span>}
              &nbsp;
              <MUIButton onClick={() => reloadOrder()}>
                <i className="fas fa-sync" title="Reload order"></i>
              </MUIButton>
            </Grid>
            <Grid item md={2}>
              {loading ? <CircularProgress size={20} /> : null}
              {order && !order.form_status ? " - Form is deleted!" : ""}
            </Grid>
            <Grid item md={3}>
              {order?.rejected_at || order?.regretted_at ? (
                <Typography
                  style={{
                    backgroundColor: "hsl(0, 88%, 68%)",
                    color: "white",
                    padding: "5px",
                    borderRadius: "10px",
                    textAlign: "center",
                  }}
                >
                  Order Status:{order?.rejected_at ? <span> REJECTED</span> : null}{" "}
                  {order?.regretted_at && order?.rejected_at ? <span> & </span> : null}
                  {order?.regretted_at ? <span>REGRETTED</span> : null}
                </Typography>
              ) : null}
              {/* {order && order.rejected_at ? <Typography style={{backgroundColor: "#D4B52F", color: "black", padding: "5px", borderRadius: "10px"}}>Order Status: REJECTED</Typography> : null}
              // {order && order.regretted_at ? <Typography style={{backgroundColor: "#D4B52F", color: "black", padding: "5px", borderRadius: "10px"}}>Order Status: REGRETTED</Typography> : null} */}
            </Grid>
            <Grid item md={2}>
              {order && order.confirmed_at && !order.regretted_at && !order.rejected_at ? (
                <Typography
                  style={{
                    backgroundColor: "hsl(120, 41%, 61%)",
                    color: "black",
                    padding: "5px",
                    borderRadius: "10px",
                    textAlign: "center",
                  }}
                >
                  Order Status: CONFIRMED
                </Typography>
              ) : null}
              {order && !order.confirmed_at ? (
                <Typography
                  style={{
                    backgroundColor: "#FFDF52",
                    color: "black",
                    padding: "5px",
                    borderRadius: "10px",
                    textAlign: "center",
                  }}
                >
                  Order Status: UNCONFIRMED
                </Typography>
              ) : null}
            </Grid>
            <Grid item md={3} style={{ textAlign: "right" }}>
              {order && order.confirmation_method ? (
                <Typography
                  style={{
                    backgroundColor: "hsl(216, 6%, 56%)",
                    color: "white",
                    padding: "5px",
                    borderRadius: "10px",
                    textAlign: "center",
                  }}
                >
                  Confirmation Method: {order.confirmation_method}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {apiRunning ? <LoadingScreen message="Loading order…" /> : null}
          {order ? (
            <Grid container>
              <Grid item container>
                <Grid item md={6} sm={12}>
                  <Grid container spacing={1}>
                    <Grid item md={6} sm={12}>
                      <CustomerDetailsComponent orderinfo={OrderInfoGenerate()} />
                    </Grid>
                    <Grid item md={6} sm={12}>
                      <CampaignDataComponent />
                    </Grid>
                    <Grid item md={12}>
                      <OrderDetailsComponent />
                    </Grid>
                    <Grid item md={12}>
                      <ActivitiesComponent />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={1}>
                  <Divider orientation="vertical" variant="middle" style={{ marginLeft: "60px" }} />
                </Grid>
                <Grid item md={5} sm={12}>
                  <Grid container spacing={1}>
                    <Grid item md={12}>
                      <h5>Recorded Calls</h5>
                      {order?.view_soundfiles
                        ? order.soundfiles.map((snd: ISoundFile, idx) => (
                            <SoundfileComponent
                              s={snd}
                              i={idx}
                              key={idx}
                              order={order}
                              rejectedReasons={rejectedReasons}
                              hasConfirmedSms={hasConfirmedSms}
                            />
                          ))
                        : null}
                    </Grid>
                    <Grid item md={12}>
                      <SMSConfirmationComponent />
                    </Grid>
                    <Grid item md={12}></Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item md={12}>
                  <ChangeLogComponent />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <span>Could not find order</span>
          )}
        </DialogContent>
        <DialogActions>
          {/* <MUIButton variant="outlined" onClick={() => setOpenChangeStatusDialog(true)}>Change Status</MUIButton> */}
          {order ? (
            <MUIButton variant="outlined" color="primary" onClick={() => changeOrderStatus(order?.order_id)}>
              Change Status
            </MUIButton>
          ) : null}

          {/* <ChangeStatusPopover /> */}
          {order && order.form_status ? (
            <MUIButton variant="outlined" color="primary" onClick={(e) => openInOMS()}>
              Open in OMS
            </MUIButton>
          ) : (
            ""
          )}
          {/* <Button variant="secondary" onClick={() => dispatch(actionCloseDetails())}>Done</Button> */}
          <MUIButton
            variant="outlined"
            color="primary"
            onClick={() => {
              props.onClose();
              setOpenOMSHandle(null);
            }}
          >
            Done
          </MUIButton>
        </DialogActions>
      </Dialog>

      {/* Denne laster siste steget ved Change Status order, fra loadOrderDetails dispatch :( */}
      <RejectedReasons
        order={order}
        operation={rejectRegretReasons.operation}
        open={rejectRegretReasons.open}
        onClose={() => {
          setRejectRegretReasons(defaultRejectRegret);
          if (order) {
            fetchOrder(+order.order_id);
          }
          // loadOrderDetails(dispatch, order.order_id);
        }}
        onReloadReport={props.onReloadReport}
        fetchOrder={fetchOrder}
      />

      {openOMSHandle ? (
        <NewWindow
          name="_managerOMS"
          title="Manager - OMS"
          onUnload={() => {setOpenOMSHandle(null); setOmsData(undefined)}}
          onBlock={() => {setOpenOMSHandle(null); setOmsData(undefined)}}
          // url={env._apiPathOms + orderEditStatus.url}
          url={"https://oms.fiko.no/" + omsData?.url}
        />
      ) : null}

      {/* Dialog for å vise API Transfer status fra Activities i view order details */}
      <Dialog open={openAPITransferDialog} onClose={() => setOpenAPITransferDialog(false)}>
        <DialogTitle>API Transfer Status for Order #{order ? order.order_id : "..."}</DialogTitle>
        <DialogContent>
          {actDebugHandle?.details.map((a, idx) => (
            <Grid container key={idx}>
              {a.meta ? (
                <Grid item container key={idx}>
                  {Object.keys(a.meta).map((key, idx) => (
                    <Grid item md={3} key={idx}>
                      <label>{key}</label>
                      <br />
                      {a.meta[key]}
                    </Grid>
                  ))}
                </Grid>
              ) : null}
              <Grid item md={12}>
                Response
                <br />
                <pre>{a.response != null ? JSON.stringify(a.response, null, 3) : ""}</pre>
              </Grid>
              <Grid item md={12}>
                <br />
                Post
                <br />
                <pre>{a.post != null ? JSON.stringify(a.post, null, 3) : ""}</pre>
              </Grid>
            </Grid>
          ))}
        </DialogContent>
        <DialogActions>
          <MUIButton variant="outlined" onClick={() => setOpenAPITransferDialog(false)}>
            Close
          </MUIButton>
        </DialogActions>
      </Dialog>

      {/* Dialog for å endre status på en vist ordre*/}
      <Dialog maxWidth="md" open={openChangeStatusDialog} onClose={() => setOpenChangeStatusDialog(false)}>
        <DialogTitle>Change Status on order # {order ? order.order_id : "..."}</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item md={12}>
              {order?.completed_at ? (
                <div>This order is already transferred, are you sure you want to change it?</div>
              ) : null}
            </Grid>
            <Grid item container>
              {operations ? (
                operations?.map((o, idx) => {
                  return (
                    <Grid item md={10} key={idx} style={{ marginBottom: "10px" }}>
                      <MUIButton
                        key={idx}
                        variant="outlined"
                        color="primary"
                        onClick={() => onChangeOrderStatus(o.operation)}
                      >
                        {o.operation_name}
                      </MUIButton>
                    </Grid>
                  );
                })
              ) : (
                <i className="fa fa-spinner fa-spin"></i>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MUIButton variant="outlined" color="primary" onClick={() => setOpenChangeStatusDialog(false)}>
            Close
          </MUIButton>
        </DialogActions>
      </Dialog>
      {/* alert bar */}
      <Snackbar open={alertOpen} autoHideDuration={8000} onClose={() => setAlertOpen(false)}>
        <MuiAlert variant="filled" severity={alertMsg.severity ? "success" : "error"}>
          {alertMsg.msg}
        </MuiAlert>
      </Snackbar>
    </>
  );
}
