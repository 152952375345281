import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { AgNoLinkRenderer, AgCountryRenderer } from "../../../agGridUtils";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { GridReadyEvent, ColumnApi, GridApi } from "ag-grid-community";
import "./directorylist.scss";
import ReactDOM from "react-dom";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Grid, IconButton } from "@material-ui/core";
import "react-vis/dist/style.css";
import { XYPlot, VerticalBarSeries, HorizontalGridLines, XAxis, YAxis } from "react-vis";
import { useSelector, useDispatch } from "react-redux";
import LoadingScreen from "../../../loadingscreen.component";
import { actionStartAPI, actionEndAPI } from "../../../redux/reducers/shell";
import BarChartIcon from '@material-ui/icons/BarChart';

declare var env: any;
declare var getPage: any;
declare var $: any;

interface DirectoryListProps {
  ClientID: any;
  CampaignTypeID: any;
  history?: any;
}

function updateDirectoryList(clientID: any, campaignTypeID: any, setRowData: any, dispatch: any) {
  const apiURL = new URL(env._apiPath + "/listManagement/contactDirectories");
  const params = {
    client_id: clientID,
    campaign_type_id: campaignTypeID,
  };
  Object.keys(params).forEach((key) => apiURL.searchParams.append(key, params[key]));

  dispatch(actionStartAPI());

  fetch(apiURL.toString(), { method: "GET", credentials: "include" })
    .then((response) => response.json())
    .then((response: any) => {
      dispatch(actionEndAPI());
      const rowData: any = [];
      for (let row in response.data) {
        const o = response.data[row];

        // TODO: Show country code in api
        let countryCode = "";
        switch (o.country_id) {
          case "1":
            countryCode = "no";
            break;
          case "2":
            countryCode = "se";
            break;
        }

        rowData.push({
          contact_directory_id: o.contact_directory_id,
          contact_directory_name: o.contact_directory_name,
          contacts_count: o.contacts_count,
          contactsDelivered: o.contactsDelivered || "-",
          contactsAvailable: o.contactsAvailable || "-",
          last_used_at: o.last_used_at || "-",
          country: {
            name: o.country_name,
            id: o.country_id,
            code: countryCode,
          },
        });
      }
      setRowData(rowData);
    });
}

function fetchDirectoryPerformance(directoryId: number, directoryName: string, setPerformanceData: any) {
  fetch(env._apiPath + "/listManagement/contactDirectories/performance/" + directoryId, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => response.json())
    .then((response: any) => {
      setPerformanceData({data: response.data, directoryId, directoryName});
    });
}

function AgGraphRenderer(loadPerformance: any) {
  return function (params: any) {
    const cont = document.createElement("div");
    cont.style.textAlign = 'right';
    ReactDOM.render(
        <Grid container>
          <Grid item md={6} style={{textAlign: 'left'}}>
            {params.data.last_used_at}
          </Grid>
          <Grid item md={6} style={{textAlign: 'right'}}>
            <IconButton
              size='small'
              title='Show performance'
              onClick={() => {
                loadPerformance(params.data.contact_directory_id, params.data.contact_directory_name);
            }}>
              <BarChartIcon />
            </IconButton>
          </Grid>
        </Grid>,
      cont
    );

    return cont;
  };
}

export default function DirectoryListComponent(props: DirectoryListProps) {
  const apiRunning = useSelector(state => state.shell.apiRunning);
  const dispatch = useDispatch();
  const [performanceData, setPerformanceData] = useState<any>(null);
  const [gridAPI, setGridAPI] = useState<GridApi>();
  const [columnAPI, setColumnAPI] = useState<ColumnApi>();

  const loadPerformance = (directoryId: number, directoryName: string) => {
    fetchDirectoryPerformance(directoryId, directoryName, setPerformanceData);
  };

  const defaultColdumnDefs = [
    {
      headerName: "Directory",
      field: "contact_directory_name",
      sortable: true,
      cellRenderer: AgNoLinkRenderer,
      onCellClicked: (params: any) => {
        if (params.data.contact_directory_name) {
          const rt = "/listmanagement/directories/" + params.data.contact_directory_id;
          props.history.push(rt);
        }
      },
    },
    { headerName: "Contacts Total", field: "contacts_count", sortable: true, type: 'numericColumn' },
    /*{ headerName: "Contacts Delivered", field: "contactsDelivered" },
    { headerName: "Contacts Available", field: "contactsAvailable" },*/
    {
      headerName: "Last Updated",
      field: "last_used_at",
      sortable: true,
      sortingOrder: ["desc", "asc", ""],
      cellRenderer: AgGraphRenderer(loadPerformance)
    },
    {
      headerName: "Country",
      field: "country.name",
      //cellRenderer: AgCountryRenderer,
      sortable: true,
      type: 'numericColumn'
    },
  ];

  const [columnDefs, setColumnDefs] = useState(defaultColdumnDefs);
  const [rowData, setRowData] = useState([]);
  useEffect(() => {
    updateDirectoryList(props.ClientID, props.CampaignTypeID, setRowData, dispatch);
  }, [props.ClientID, props.CampaignTypeID]);

  useEffect(() => {
    gridAPI?.sizeColumnsToFit();
    gridAPI?.setSortModel([{colId: 'last_used_at', sort: 'desc'}]);
  }, [rowData]);

  const onGridReady = (event: GridReadyEvent) => {
    setGridAPI(event.api);
    setColumnAPI(event.columnApi);

    event.api.sizeColumnsToFit();
    window.addEventListener("resize", () => setTimeout(() => event.api.sizeColumnsToFit()));
  };

  useEffect(() => {
    if (performanceData) {
      const dataDay: any = [];
      const dataHour: any = [];
      const xtitlesDay: any = [];
      const xtitlesHour: any = [];

      let e = 0;
      for (const x in performanceData.data.perfPrDay) {
        const y: any = performanceData.data.perfPrDay[x];
        dataDay.push({ x: e++, y: y.contacts });

        xtitlesDay.push(y.day);
      }
      e = 0;
      for (const x in performanceData.data.perfPrHour) {
        const y: any = performanceData.data.perfPrHour[x];
        dataHour.push({ x: e++, y: y.contacts });

        xtitlesHour.push(y.hour + ":00");
      }
      setFilterDataDay(dataDay);
      setFilterDataHour(dataHour);
      setFilterXTitlesDay(xtitlesDay);
      setFilterXTitlesHour(xtitlesHour);
    }
  }, [performanceData]);
  const [filterDataDay, setFilterDataDay] = useState([]);
  const [filterDataHour, setFilterDataHour] = useState([]);
  const [filterXTitlesDay, setFilterXTitlesDay] = useState([]);
  const [filterXTitlesHour, setFilterXTitlesHour] = useState([]);
  const xTickFormatDay = (value: any, index: any, scale: any, tickTotal: any) => {
    return (
      <tspan>
        <tspan x="0" dy="1em">
          {filterXTitlesDay[index]}
        </tspan>
      </tspan>
    );
  };
  const xTickFormatHour = (value: any, index: any, scale: any, tickTotal: any) => {
    return (
      <tspan>
        <tspan x="0" dy="1em">
          {filterXTitlesHour[index]}
        </tspan>
      </tspan>
    );
  };
  let yMaxDay = 0;
  filterDataDay.forEach((val: any) => {
    yMaxDay = Math.max(val.y, yMaxDay);
  });
  yMaxDay += 10;
  let yMaxHour = 0;
  filterDataHour.forEach((val: any) => {
    yMaxHour = Math.max(val.y, yMaxHour);
  });
  yMaxHour += 10;

  return (
    <div className="ag-theme-balham" style={{ height: "500px", width: "100%" }}>

      {apiRunning ? <LoadingScreen message="Loading directories. Please wait." /> : null}

      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        rowSelection=""
        suppressContextMenu={true}
        groupIncludeTotalFooter={false}
        suppressAutoSize={false}
        onGridReady={onGridReady}
      />

      <Dialog onClose={() => setPerformanceData(null)} open={performanceData !== null} maxWidth={"lg"}>
        <DialogTitle>Directory Performance - {performanceData ? performanceData.directoryName : null}</DialogTitle>
        <DialogContent>
          {filterDataHour.length > 0 ? (
            <>
              <Typography variant="h5">Today</Typography>
              <XYPlot height={250} width={740} margin={{ bottom: 100 }} yDomain={[0, yMaxHour]} xType="ordinal">
                <HorizontalGridLines />
                <VerticalBarSeries data={filterDataHour} />
                <XAxis title="Hour" tickLabelAngle={-40} tickFormat={xTickFormatHour} />
                <YAxis title="Contacts Received" />
              </XYPlot>
            </>
          ) : null}

          {filterDataDay.length > 0 ? (
            <>
              <Typography variant="h5">Week</Typography>
              <XYPlot height={250} width={740} margin={{ bottom: 100 }} yDomain={[0, yMaxDay]} xType="ordinal">
                <HorizontalGridLines />
                <VerticalBarSeries data={filterDataDay} />
                <XAxis title="Day" tickLabelAngle={-40} tickFormat={xTickFormatDay} />
                <YAxis title="Contacts Received" />
              </XYPlot>
            </>
          ) : null}

          {filterDataHour.length === 0 && filterDataDay.length === 0 ? (
            <span>No recent data for this directory.</span>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPerformanceData(null)}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
