import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { actionCloseDetails } from "../../../redux/reducers/reports/orders";
import "./details.scss";
import LoadingScreen from "../../../loadingscreen.component";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import { fetchRejectReasons, SMSConfirm, soundfileApproval, loadOrderOperations, loadOrderStatus, loadOrderDetails, resetOrderDetailAPISent } from "./orders.api";
import ExpandableText from "./expandableText.component";
import NewWindow from "react-new-window";
import Badge from "react-bootstrap/Badge";
import RejectedReasons from "./rejectedReasons.component";
import AudioPlayer from 'material-ui-audio-player';
import {
  Button as MUIButton,
  Grid, Popover as MUIPopover,
} from "@material-ui/core";

declare var env: any;

interface IRegretRejectReasons {
  open: boolean;
  operation: string;
}

export default function OrderDetails(props) {
  const dispatch = useDispatch();
  const show = useSelector(state => state.reports.orders.orderDetails.show);
  const order = useSelector(state => state.reports.orders.orderDetails.order);
  const orderExtra = useSelector(state => state.reports.orders.orderDetails.extra);
  const operations = useSelector(state => state.reports.orders.orderDetails.operations);
  const orderEditStatus = useSelector(state => state.reports.orders.orderDetails.editstatus);
  const apiRunning = useSelector(state => state.shell.apiRunning);
  const customer_id = useSelector(state => state.shell.user.customer_id);
  const GDPR7Days = [11,16,18,22];
  // const [step, setStep] = useState<Number>(0);
  const [showEmpty, setShowEmpty] = useState(false);

  const [showOrderStatusActions, setShowOrderStatusActions] = useState(false);
  const [changeStatusTarget, setChangeStatusTarget] = useState<any>(null);
  const defaultRejectRegret = { open: false, operation: "" };
  const [rejectRegretReasons, setRejectRegretReasons] = useState<IRegretRejectReasons>(defaultRejectRegret);

  const [actDebugHandle, setActDebugHandle] = useState<any>(null);
  const [openOMSHandle, setOpenOMSHandle] = useState<any>(null);

  const [updateSoundStatus, setUpdateSoundStatus] = useState<number>(-1);
  const rejectReasons = ['Rejected', 'Regretted', 'Undid rejection'];

  const orderHeaderIdMap = {
    "2": "firstname",
    "3": "lastname",
    "4": "mobile",
    "5": "phone",
    "6": "email",
    "7": "street",
    "8": "house_number",
    "9": "zip",
    "10": "city",
    "11": "birthdate",
    "12": "company_name",
    "13": "company_id",
    "16": "comment",
    "36": "national_id_number",
    "37": "door_code",
    "52": "customer_number"
  };

  interface order_rejected_reasons {
    reason_id: number,
    created_at: Date,
    updated_at: Date,
    reason_name: string,
    rejected_type: number,
    hide_in_list: number,
    client?: number
}
const emptyRejectedReason = {
    reason_id: 0,
    created_at: new Date(),
    updated_at: new Date(),
    reason_name: '',
    rejected_type: 0,
    hide_in_list: 0
}
const [rejectedReasons,setRejectedReasons] = useState<order_rejected_reasons[]>([emptyRejectedReason])
const [showRejectedReason,setShowRejectedReason] = useState(-1);
const [rejectedReason, setRejectedReason] = useState({ order_rejected_reason_id: "0", order_action_comment: '' });
const [hasConfirmedSms, setHasConfirmedSms] = useState(false);

const [hasUnconfirmedSMS, setHasUnconfirmedSMS] = useState(0);
  useEffect(() => {
    if (order !== null) {
      var result:number = 0;
      // eslint-disable-next-line
      order.order_sms.some(function(sms) {
          if (!sms.message_in && !sms.outdated) {
              result = sms.order_confirmation_id;
              return true;
          }
      });
      setHasUnconfirmedSMS(result);

      // eslint-disable-next-line
      order.order_activities.some(function(act) {
        if (act.order_activity_type_id === 13 && act.success) 
            setHasConfirmedSms(true);
      });
    }

    if (order) {
      console.log("order part 2");
      loadOrderDetails(dispatch, order.order_id, 2);
    }
    // eslint-disable-next-line
  },[order]);


  useEffect(() => {
    fetchRejectReasons().then(res => { setRejectedReasons(res); })
  },[]);

  useEffect(() => {
    if (operations && operations.target) {
      setShowOrderStatusActions(true);
      setChangeStatusTarget(operations.target);
    }
  }, [operations]);

  useEffect(() => {
    if (!show) {
      // Trigger closing of external window if open..
      setActDebugHandle(null);
    }
  }, [show]);

  useEffect(() => {
    setOpenOMSHandle(orderEditStatus);
  }, [orderEditStatus]);

  const changeOrderStatus = event => {
    loadOrderOperations(dispatch, order.order_id, event.target);
    event.preventDefault();
    event.stopPropagation();
    return false;
  };

  const openContract = (event, orderActivityId) => {
    window.open("https://media.fiko.no/contract/" + orderActivityId, "_blank");
    event.preventDefault();
    event.stopPropagation();
    return false;
  };

  const openDebugActivity = (event, activity) => {
    event.preventDefault();
    event.stopPropagation();

    setActDebugHandle(activity);

    return false;
  };
  function resetOrderDetailAPI(orderDetailId: number) {
    if (orderDetailId && order) {
      let data = {
        order_id: order.order_id, 
        order_detail_id: orderDetailId,
        debug: 0 // sett til 1 for test
      }
      resetOrderDetailAPISent(data)
      .then(res => {console.log(res); if (res[0].status === "OK") { loadOrderDetails(dispatch, order.order_id) }})
      .catch(err => console.error(err))
    }
  }

  function OrderDetailsAPIPopover({product}) {
    // const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const apiStatus = product.api_sent_at && product.api_sent_status === "HTTP/1.1 200 OK" 
    ? <i className="fas fa-fw fa-check text-success"></i> 
    : product.api_sent_at && product.api_sent_status !== "HTTP/1.1 200 OK" 
    ? <i className="fas fa-fw fa-times text-danger"></i> 
    : null
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  
    return (<>
      { product && product.api_sent_at ? 
      (<div>
        <MUIButton aria-describedby={id} color="primary" onClick={handleClick} title={product.api_sent_at}>
          {apiStatus}
        </MUIButton>
        <MUIPopover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div style={{height: "130px", width: "300px", padding: "10px", overflow: "hidden"}}>
          <Grid container spacing={1}>
            <Grid item md={12}>
              <MUIButton style={{margin: "0 auto", width: "100%"}} variant="outlined" onClick={() => {resetOrderDetailAPI(product.order_detail_id); handleClose()}} title={product.order_detail_id}>Reset API Sent</MUIButton>
            </Grid>
            <Grid item md={12}> Status: {product.api_sent_status}</Grid>
            <Grid item md={12}>Date: {product.api_sent_at}</Grid>
          </Grid>
             
          </div>
        </MUIPopover>
      </div>) 
      : null}
    </>);
  }
  /*const hasNoSMSReply = (idx: number) => {
    return 1;
    let SMSreturn = 1;

    if (order.order_sms[ idx ].direction == 'in') return 0; // only outbound should have approval

    let chosen = order.order_sms[ idx ].created_at.match(/^(\d+)\.(\d+)\.(\d+) (\d+)\:(\d+)\:(\d+)/);
    let chosen_created_at = '';
    if (chosen !== undefined && chosen.length) {
        chosen_created_at = chosen[3] + '-' + chosen[2] + '-' + chosen[1]  + ' ' + chosen[4]  + ':' + chosen[5]  + ':' + chosen[6];    
    }
    let chosen_date = new Date(chosen_created_at);

    order.order_sms.forEach((sms,_idx) => {
      if (order.order_sms[ idx ].sender == order.order_sms[ _idx ].recipient) {

        let found = order.order_sms[ _idx ].created_at.match(/^(\d+)\.(\d+)\.(\d+) (\d+)\:(\d+)\:(\d+)/);      

        let found_created_at = '';
        if (found !== undefined && found.length) {
            found_created_at = found[3] + '-' + found[2] + '-' + found[1]  + ' ' + found[4]  + ':' + found[5]  + ':' + found[6];    
        }
        let found_date = new Date(found_created_at);

        if (chosen_date < found_date) { 
          SMSreturn = 0;
        } 
      }
      //console.log(_idx);
      console.log(sms);
    });
    //console.log("current:");
    //console.log(order.order_sms[ idx ]);
    return SMSreturn;
  }*/

  const openInOMS = event => {
    event.preventDefault();
    event.stopPropagation();

    loadOrderStatus(dispatch, order.order_id, "edit");

    return false;
  };

  const onChangeOrderStatus = operation => {
    setShowOrderStatusActions(false);

    // Some operations require additional parameters
    if (operation === "reject" || operation === "regret" || operation === 'undoreject') {
      // Show window for selection of reject reasons, and comment
      setRejectRegretReasons({
        operation,
        open: true
      });
      return;
    }

    loadOrderStatus(dispatch, order.order_id, operation);
  };

  const onSoundfileReject = (snd, idx) => {
    setUpdateSoundStatus(-1); 
    soundfileApproval(snd,0,rejectedReason).then(res => { 
      snd.confirmed_at = res.confirmed_at;  
      snd.rejected_at = res.rejected_at;  
      snd.regretted_at = res.regretted_at;  

      setUpdateSoundStatus(idx);
    });
    snd.order_action_comment = rejectedReason.order_action_comment; 
    snd.order_rejected_reason_id = rejectedReason.order_rejected_reason_id; 
  }

  const onRemoveSoundfileReject = (snd, idx) => {
    setUpdateSoundStatus(-1); 
    soundfileApproval(snd,-1,rejectedReason).then(res => { 
      snd.confirmed_at = res.confirmed_at;  
      snd.rejected_at = res.rejected_at;  
      snd.regretted_at = res.regretted_at;  

      setUpdateSoundStatus(idx);
    });
    snd.order_action_comment = ''; 
    snd.order_rejected_reason_id = '';  
  }

  const renderOrder = ( part = 0 ) => {
    const orderinfo: any = {};
    let hasCampaignData = false;
    if (order && typeof order.order_data != 'undefined') {
      order.order_data.forEach(o => {
        if (orderHeaderIdMap[o.order_header_id]) {
          const k = orderHeaderIdMap[o.order_header_id];
          orderinfo[k] = o.value;
        }
      });
      order.order_data.forEach(d => {
        if (orderHeaderIdMap[d.order_header_id]) {
          return;
        }
        hasCampaignData = true;
      });
    }    
    // const options = {
    //   year: 'numeric', month: 'numeric', day: 'numeric',
    //   hour: 'numeric', minute: 'numeric' , second: 'numeric',
    //   hour12: false
    // };

    return (  
      <>
      {!part || part === 1 ? (
        <>
        <Row>
          <Col sm={5}>
            <h5>Customer Details</h5>
            {orderinfo.company_name ? (
              <span title="Company Name">
                {orderinfo.company_name} - Org.no: {orderinfo.company_id}
                <br />
              </span>
            ) : null}
            <span title="Firstname">{orderinfo.firstname}</span> <span title="Lastname">{orderinfo.lastname}</span>
            <br />
            <span title="Street">{orderinfo.street}</span> <span title="House">{orderinfo.house_number}</span>
            <br />
            <span title="Zip">{orderinfo.zip}</span> <span title="City">{orderinfo.city}</span>
            <br />
            <span title="Email">{orderinfo.email ? <a href={"mailto:" + orderinfo.email}>{orderinfo.email}</a> : null}</span>
            <br />
            
            {orderinfo.mobile ? (
              <Row>
                <Col sm={2}>Mobile</Col>
                <Col className="text-left">{orderinfo.mobile}</Col>
              </Row>
            ) : null}
            {orderinfo.phone ? (
              <Row>
                <Col sm={2}>Phone</Col>
                <Col className="text-left">{orderinfo.phone}</Col>
              </Row>
            ) : null}
            {orderinfo.birthdate ? (
              <Row>
                <Col sm={2}>Birthdate</Col>
                <Col className="text-left">{new Intl.DateTimeFormat('nb-NO').format(new Date(orderinfo.birthdate))}</Col>
              </Row>
            ) : null}
            {orderinfo.comment ? (
              <Row>
                <Col sm={2}>Comment</Col>
                <Col className="text-left">{orderinfo.comment}</Col>
              </Row>
            ) : null}
          </Col>
          {hasCampaignData ? (
            <Col sm={3}> 
              <h5>Campaign Data</h5>
              <div className="additional-info">
                <Table striped hover bordered size="sm">
                  <tbody>
                    {order.order_data.map((data, idx) => {
                      // Skip known internal fields, because we present them above.
                      if (orderHeaderIdMap[data.order_header_id]) {
                        return null;
                      }

                      if ((data.value === null || data.value.length === 0) && showEmpty === false) {
                        return null;
                      }

                      return (
                        <tr key={idx}>
                          <td>{data.display_name}</td>
                          <td className="text-right">{data.value}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <Form.Group controlId="frmShowEmpty">
                <Form.Check
                  className="show-empty-check"
                  type="checkbox"
                  label="Show empty values"
                  checked={showEmpty}
                  onChange={e => setShowEmpty(e.target.checked)}
                />
              </Form.Group>
            </Col>
          ) : null}
          
          <Col className="col-actions">
            
            <ul>
              <li> 
                <Overlay
                  show={showOrderStatusActions}
                  onHide={() => setShowOrderStatusActions(false)}
                  rootClose={true}
                  target={changeStatusTarget}
                  placement="left"
                >
                  <Popover id="popover-basic">
                    <Popover.Title as="h3">Change Status</Popover.Title>
                    <Popover.Content style={{ maxHeight: 300, overflowY: "auto" }}>
                      { order.completed_at ? <div>This order is already transferred, are you sure you want to change it?</div>
                      : null }
                      {operations.list ? (
                        operations.list.map((o, idx) => {
                          return (
                            <Button
                              key={idx}
                              size="sm"
                              className="order-action"
                              variant="outline-danger"
                              onClick={() => onChangeOrderStatus(o.operation)}
                            >
                              {o.operation_name}
                            </Button>
                          );
                        })
                      ) : (
                        <i className="fa fa-spinner fa-spin"></i>
                      )}
                    </Popover.Content>
                  </Popover>
                </Overlay>
                
                
              </li>
              {!orderExtra?<><li>...loading</li></>:null}
              {orderExtra && orderExtra.order_activities && orderExtra.order_activities.map((a, idx) => {
                if (a.order_activity_type_id === 5) {
                  return (
                    <li key={idx}>
                      <a href="#" onClick={e => openContract(e, a.order_activity_id)}>
                        View Contract
                      </a>
                    </li>
                  );
                }
              })}
 
            </ul>
          </Col>
        </Row> 
        <div className="ordDet">
          <h5>Order Details</h5>
          <Row>
            <Col sm={8}>
          <Table striped bordered hover size="sm" w-auto="true">
            <thead>
              {/*
                "product_name": "Topp 10",
                "order_detail_id": 813915,
                "price": 0,
                "amount": 1,
                "total": 0,
                "order_detail_data": []
              */}
              <tr>
                <th>#</th>
                <th>Product</th>
                <th className="text-left">Price</th>
                <th className="text-left">Quantity</th>
                <th className="text-left">Total</th>
                <th className="text-left">API</th>
              </tr>
            </thead>
            <tbody>
              {order && order.order_details && order.order_details.map((product, idx) => {
                return (
                  <tr key={idx}> 
                    <td>{idx + 1}</td>
                    <td>
                      {product.product_name}
                      {product.description ? " - " + product.description : null}
                    </td>
                    <td className="text-left">
                      {new Intl.NumberFormat("nb-NO", { style: "currency", currency: "NOK" }).format(product.price)}
                    </td>
                    <td className="text-left">{product.amount}</td>
                    <td className="text-left">
                      {new Intl.NumberFormat("nb-NO", { style: "currency", currency: "NOK" }).format(product.total)}
                    </td>
                    <td className="text-left">
                      {/* <span title={product.api_sent_at}>{product.api_sent_status ? <i className="fas fa-fw fa-check text-success"></i> : null}</span> */}
                      <OrderDetailsAPIPopover product={product} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          </Col>
          <Col sm={3}> </Col>
        </Row>
        </div>        
        </>
        ):(
        <>
        <div className="order-confirmation">
        {/*<h4>Confirmation</h4>*/}
          {orderExtra && orderExtra.order_sms && orderExtra.order_sms.length === 0 && orderExtra.soundfiles.length === 0 ? (
            <i>No confirmations available.</i>
          ) : (
            <Row>
              <Col sm={6} className="sms-messages">
                <h5>SMS Confirmation</h5>
                {!orderExtra?<><Row><Col sm={12}>...loading</Col></Row></>:null}
                {orderExtra && orderExtra.order_sms && orderExtra.order_sms.map((sms, idx) => {
                  return (
                    <>
                    {sms.message_in?<Col className="message" key={idx+'_in'}>
                      <Row>
                        <Col xs={10}>
                          <div className="border rounded p-2">
                            <div><span>{sms.updated_at}</span> - {sms.recipient}</div>
                            <ExpandableText disabled={sms.outdated?true:false} text={sms.message_in} maxLength={50} />
                          </div>
                          </Col>
                        <Col xs={2}></Col>
                      </Row>
                    </Col>:null}
                    <Col className="message" key={idx+'_out'}>
                      <Row>
                        <Col xs={2}>
                        {!sms.message_in && !sms.outdated?<Button variant={'secondary'} onClick={() => SMSConfirm(
                            { 
                              order_confirmation_id: sms.order_confirmation_id, 
                              order_id: order.order_id, 
                              status: true 
                            }).then((res) => {
                              order.order_sms[idx].message_in = res.message_in;
                            }).then(() => { renderOrder(); })}>
                              <i className="fa fa-thumbs-up" aria-hidden={true}></i>
                          </Button>:null}
                        </Col>
                        <Col xs={10}>
                          <div className="border rounded p-2">
                            <div className="text-right"><span>{sms.created_at}</span> - {sms.sender}</div>
                            <ExpandableText disabled={sms.outdated?true:false} text={sms.message_out} maxLength={50} />
                          </div>
                        </Col>
                    </Row>
                  </Col>
                  </>
                  );
                })}
                {orderExtra && orderExtra.order_othersms && orderExtra.order_othersms.length?
                <>
                  <h5>Nonconfirmatory messages</h5>
                  {orderExtra && orderExtra.order_othersms && orderExtra.order_othersms.map((sms, idx) => { return (
                    <Col className="message" key={idx+'_otr'}>
                      <Row>
                        <Col>
                          <div className="border rounded p-2">
                            <div><span>{sms.created_at}</span> - {sms.recipient}</div>
                            <ExpandableText disabled={sms.outdated?true:false} text={sms.message} maxLength={50} />
                          </div>
                        </Col>
                        <Col xs={2}>{hasUnconfirmedSMS?<Button variant={'secondary'} onClick={() => SMSConfirm(
                            { 
                              order_confirmation_id: hasUnconfirmedSMS, 
                              sms_incoming_id: sms.sms_incoming_id,
                              order_id: order.order_id, 
                              status: true 
                            }).then((res) => {
                              // eslint-disable-next-line
                              order.order_sms.some(function(sms,sidx) {
                                if (sms.order_confirmation_id === hasUnconfirmedSMS) {
                                  order.order_sms[sidx].message_in = res.message_in;
                                  order.order_othersms.splice(sidx, 1);
                                }
                              });
                      
                            }).then(() => { renderOrder(); })}>
                              <i className="fa fa-thumbs-up" aria-hidden={true}></i>
                          </Button>:null}</Col>
                      </Row>
                    </Col>
                  )})}
                </>
                :null}
              </Col>
              <div className="col-split"></div>
              <Col className="snd-messages">
                <h5>Recorded Calls</h5>

                {order.view_soundfiles
                  ? order.soundfiles.map((snd, idx) => {                      
                    return (order.confirmed_at == null && new Date(order.created_at).getTime() < new Date().getTime() - 604800000 && !hasConfirmedSms && GDPR7Days.includes(customer_id))?<>
                      <Row key={idx}>
                          <Col className="message">Lydfiler på ikke-bekreftede ordrer eldre enn syv dager er fjernet pga GDPR</Col>
                        </Row>
                      </>:(
                        <>
                        <Row key={idx}>
                          <Col className="message">
                              <b>#{idx + 1}</b> {snd.created_at}{snd.rejected_at?', Rejected at: '+snd.rejected_at:snd.regretted_at?', Regretted at: '+snd.regretted_at:snd.confirmed_at?', Confirmed at: '+snd.confirmed_at:''}
                          </Col>
                        </Row>
                        {updateSoundStatus === idx?<Row key={idx}>
                          <Col>
                              Status is updated
                          </Col>
                        </Row>:null}
                        <Row key={'audio-'+idx}>
                          <Col xs={10}>
                          {/* <audio
                            controls
                            src={env._apiPath + "/file/getSoundFile?activity_file_id=" + snd.activity_file_id}
                          >
                            Your browser does not support the audio element.
                          </audio> */}
                          <AudioPlayer
                                    elevation={1}
                                    width="100%"
                                    variation="default"
                                    spacing={3}
                                    download={true}
                                    autoplay={false}
                                    order="standart"
                                    loop={false}
                                    src={(env._apiPath + "/file/getSoundFile?activity_file_id=" + snd.activity_file_id).replace(/(audio\/)/g,'$1stream.php?sessid=')}
                                />
                          </Col>
                          <Col xs={2}>
                          <ButtonGroup aria-label="Approval">
                            <Button variant={snd.confirmed_at?'success':'secondary'} onClick={() => { setUpdateSoundStatus(-1); soundfileApproval(snd, snd.confirmed_at?-1:1, rejectedReason).then(res => { 
                              snd.confirmed_at = res.confirmed_at;  
                              snd.rejected_at = res.rejected_at;  
                              snd.regretted_at = res.regretted_at;  

                              setUpdateSoundStatus(idx);
                            })} }>
                                <i className="fa fa-thumbs-up" aria-hidden={true}></i>
                            </Button>
                            <Button variant={snd.rejected_at?'primary':'secondary'} onClick={() => { setRejectedReason({
                                  order_action_comment: snd.order_action_comment ? snd.order_action_comment : "",
                                  order_rejected_reason_id: snd.order_rejected_reason_id
                                }); 
                                setShowRejectedReason(idx); }}>
                                <i className="fa fa-thumbs-down" aria-hidden={true}></i>
                            </Button>
                          </ButtonGroup>
                          </Col>
                        </Row>
                        {showRejectedReason === idx?<Row key={idx}>
                          <Col xs={12}>
                          <Form.Group controlId="exampleForm.ControlSelect1">
                            <Form.Label>Reject reasons</Form.Label>  
                            <Form.Control as="select" custom value={rejectedReason.order_rejected_reason_id} onChange={(e) => { setRejectedReason({ ...rejectedReason, order_rejected_reason_id: (e.target as HTMLSelectElement).value }); }}>
                               {rejectedReasons.map((rejectedReason: order_rejected_reasons, key) => (
                                  <option key={key} value={rejectedReason.reason_id}>
                                      {rejectedReason.reason_name}
                                  </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group controlId="formBasicEmail">
                            <Form.Label>Comment</Form.Label>
                            <Form.Control type="text"  placeholder="Comment" onChange={(e) => { console.log(e); setRejectedReason({ ...rejectedReason, order_action_comment: e.target.value })}}
                                      value={rejectedReason.order_action_comment} />
                          </Form.Group>
                          </Col>
                          <Col xs={3}>
                              <Button                                   
                                  color="primary" 
                                  style={{ height: 40 }}
                                  onClick={() => { onSoundfileReject(snd,idx); setShowRejectedReason(-1); }}
                              >
                                  Commit
                              </Button>
                          </Col>
                          <Col xs={3}>
                              <Button                                   
                                  color="primary" 
                                  style={{ height: 40 }}
                                  onClick={(e) => { onRemoveSoundfileReject(snd,idx); setShowRejectedReason(-1); }}
                              >
                                  Clear
                              </Button>
                          </Col>
                      </Row>:null}
                        </>
                      )
                    })
                  : null}
              </Col>
            </Row>
          )}
        </div>

        <h4>Activities</h4>

        <div className="order-confirmation">
         {!orderExtra?<><i>...loading</i></>:null}
          {orderExtra && orderExtra.order_activities && orderExtra.order_activities.length === 0 && orderExtra.order_signatures.length === 0 && orderExtra.order_actions.length === 0 ? (
            <i>No activities available.</i>
          ) : null}
          {orderExtra && orderExtra.order_signatures && orderExtra.order_signatures.map((sign, idx) => {
            return (
              <Row key={idx}>
                <Col>{sign.created_at}</Col>
                <Col>Signature</Col>
                <Col></Col>
                <Col className="text-right">
                  <img src={sign.signature} style={{ height: 50 }} alt=""/>
                </Col>
              </Row>
            );
          })}
          {orderExtra && orderExtra.order_activities && orderExtra.order_activities.map((act, idx) => {
            return (
              <Row key={idx}>
                <Col sm={2}>{new Intl.DateTimeFormat('nb-NO', {
                    year: 'numeric', month: '2-digit', day: '2-digit',
                    hour: '2-digit', minute: '2-digit', second: '2-digit', 
                    hour12: false,
                  }).format(new Date(act.executed_at))}</Col>
                <Col sm={4}>{act.order_activity_type_name}</Col>
                <Col sm={2}>
                  {act.success ? (
                    <>
                      <i className="fas fa-fw fa-check text-success"></i> OK
                    </>
                  ) : (
                    <>
                      <i className="fas fa-fw fa-times text-danger"></i> Error
                    </>
                  )}
                </Col>
                <Col sm={4} className="text-right">
                  {act.order_activity_type_id === 4 /* API Transfer */ ||
                  act.order_activity_type_id === 7 /* Updated external status */ || 
                  act.order_activity_type_id === 11 /* Credit check */ ||
                  act.order_activity_type_id === 16 /* final api transfer */? (
                    // eslint-disable-next-line
                      <a href="" onClick={e => openDebugActivity(e, act)}>
                      Open details
                    </a>
                  ) : null}
                </Col>
              </Row>
            );
          })}
        </div>

        <h4>Changelog</h4>
        
        <div className="order-confirmation">
        {!orderExtra?<><Row><Col>...loading</Col></Row></>:null}
          {orderExtra && orderExtra.order_actions && orderExtra.order_actions.map((act, idx) => {
            return (
              <Row key={idx}>
                <Col sm={2}>{new Intl.DateTimeFormat('nb-NO').format(new Date(act.created_at))}</Col>
                <Col sm={4}>{rejectReasons[Number(act.order_rejected_reason.type)-1]} by {act.user.fullname}</Col>
                <Col sm={2}>
                  {act.order_rejected_reason.name}
                </Col>
                <Col sm={4}>
                  {act.order_action_comment}
                </Col>
              </Row>
            )
          })/*                 <Col>{new Intl.DateTimeFormat('nb-NO', options).format(new Date(act.created_at))}</Col>      */}
        </div>
        
        
        </>
        
        
        )
      }

      </>
    );
  };
/*
              {a.meta ? (
                <Table striped hover bordered size="sm">
                  <tbody>
                  {Object.keys(a.meta).map((key, idx) => (
                    <Col key={idx}>
                      <label>{key}</label>
                      <br />
                      {a.meta[key]}
                    </Col>
                  ))}
                </Row>
              ) : null}

*/
  return (
    <>
      <Modal show={show && !rejectRegretReasons.open} onHide={() => dispatch(actionCloseDetails())} dialogClassName="wide-dialog">
        <Modal.Header closeButton>
          <Modal.Title>Order #{order ? order.order_id : "..."} {order && !order.form_status?' - Form is deleted!':''}</Modal.Title>
          <div>
            <div className="pull-right">{order && order.confirmation_method ? <Badge variant="secondary">Confirmation Method: {order.confirmation_method}</Badge>:null}</div>
            {order && order.rejected_at ? <Badge variant="danger">Order Status: REJECTED</Badge> : null}

            {order && order.regretted_at ? <Badge variant="danger">Order Status: REGRETTED</Badge> : null}

            {order && order.confirmed_at && !order.regretted_at && !order.rejected_at ? (
              <Badge variant="success">Order Status: CONFIRMED</Badge>
            ) : null}

            {order && !order.confirmed_at ? <Badge variant="warning">Order Status: UNCONFIRMED</Badge> : null}
          </div>
        </Modal.Header>
        <Modal.Body>
          {apiRunning ? <LoadingScreen message="Loading order…" /> : null}
          {order ? renderOrder(1) : null}
          {order ? renderOrder(2) : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={changeOrderStatus}>Change Status</Button>
          {order && order.form_status?<Button variant="secondary" onClick={(e) => openInOMS(e)}>Open in OMS</Button>:''}
          <Button variant="secondary" onClick={() => dispatch(actionCloseDetails())}>Done</Button>
        </Modal.Footer>
      </Modal>

      <RejectedReasons
        order={order}
        operation={rejectRegretReasons.operation}
        open={rejectRegretReasons.open}
        onClose={() => {
          setRejectRegretReasons(defaultRejectRegret);
          loadOrderDetails(dispatch, order.order_id);
        }}
        onReloadReport={props.onReloadReport}
        fetchOrder={() => {}} // TODO dette er fetchorder for ordersnew og detailsnew
      />

      {openOMSHandle ? (
        <NewWindow
          name="_managerOMS"
          title="Manager - OMS"
          onUnload={() => setOpenOMSHandle(null)}
          url={env._apiPathOms + orderEditStatus.url}
        />
      ) : null}

      {actDebugHandle ? (
        <NewWindow
          name="_managerOrderDebug"
          title="Manager - Activity Debug"
          onUnload={() => setActDebugHandle(null)}
          copyStyles={true}
        >
          <div className="activity-debug">
            <h3>Activity Messages</h3>
            {actDebugHandle.details.map((a, idx) => {
              return (
                <div>
              {a.meta ? (
                <Row key={idx}>
                  {Object.keys(a.meta).map((key, idx) => (
                    <Col key={idx}>
                      <label>{key}</label>
                      <br />
                      {a.meta[key]}
                    </Col>
                  ))}
                </Row>
              ) : null}
              <br />
              <Row>
                <Col>
                Post<br />
                  <pre>{a.post != null?JSON.stringify(a.post, null, 3):''}</pre>
                </Col>
              </Row>
              <Row>
                <Col>
                Response<br />
                  <pre>{a.response != null?JSON.stringify(a.response, null, 3):''}</pre>
                </Col>
              </Row>
              </div>
                )})}
            </div>
          </NewWindow>
        ) : null}
      </>
    );
  }
  