declare var env: any;
//const env = {
 // _apiPath: "https://api-stage.fiko.no/manager"
//};
export interface Outcome {
  outcome_id: number; //	1
  created_at: string; //"08.02.2018 15:20"
  customer_id: number | null;
  client_id: string | null;
  client_name: string | null;
  outcome_name: string; //"Ja"
  outcome_color: string; //	"#28a745"
  outcome_icon: string; //	"fa fa-fw fa-plus"
  outcome_status_id: string;
  outcome_status_name: string; //"Suksess"
  outcome_status_description: string; //"Nådd riktig kontakt, positivt utfall"
  outcome_status_external_ref: string; //"Successful"
  outcome_reason_count: number; //	1
}

export interface OutcomeReason {
  outcome_reason_id: number;
  outcome_reason_name: string;
  outline_color: string;
  outcome_id: number;
  created_at: string;
  updated_at: string;
  customer_id: number;
  ref_id: string;
  editing: boolean;
}

export interface OutcomeStatus {
  outcome_status_id: string; //3
  outcome_status_name: string; //"Feil kontakt"
  outcome_status_description: string; //"Feil kontakt, feil nummer, utfall ikke mulig."
  external_ref: string; //"Non-qualified"
  rank: string; //"50"
  color: string; //null
  icon: string; //null
  customer_id: string; //null
  client_id: string; //null
}

export const fetchOutcomes = async (clientId: any) => {
  const res = await fetch(env._apiPath + "/outcomes" + (clientId ? "?client_id=" + clientId : ""), {
    method: "GET",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });
  const json = await res.json();

  if (json.status === "success") {
    return json.data;
  }

  throw json.message;
};

export const addOutcome = async (newOutcome: Outcome) => {
  const req = {
    outcome_name: newOutcome.outcome_name,
    outcome_status_id: newOutcome.outcome_status_id,
    outcome_color: newOutcome.outcome_color,
    outcome_icon: newOutcome.outcome_icon,
    client_id: newOutcome.client_id,
  };

  const res = await fetch(env._apiPath + "/outcome", {
    method: "PUT",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(req)
  });
  const json = await res.json();

  if (json.status === "success") {
    return json.data;
  }

  throw json.message;
};

export const updateOutcome = async (editOutcome: Outcome) => {
  const req = {
    outcome_id: editOutcome.outcome_id,
    outcome_name: editOutcome.outcome_name,
    outcome_status_id: editOutcome.outcome_status_id,
    outcome_color: editOutcome.outcome_color,
    outcome_icon: editOutcome.outcome_icon,
    client_id: editOutcome.client_id,
  };

  const res = await fetch(env._apiPath + "/outcome", {
    method: "POST",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(req)
  });
  const json = await res.json();

  if (json.status === "success") {
    return json.data;
  }

  throw json.message;
};

export const removeOutcome = async (editOutcome: Outcome) => {
  const req = {
    outcome_id: editOutcome.outcome_id,
  };

  const res = await fetch(env._apiPath + "/outcome", {
    method: "DELETE",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(req)
  });
  const json = await res.json();

  if (json.status === "success") {
    return json.data;
  }

  throw json.message;
};

export const fetchOutcomeStatuses = async () => {
  const res = await fetch(env._apiPath + "/outcomeStatuses", {
    method: "GET",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });
  const json = await res.json();

  if (json.status === "success") {
    return json.data;
  }

  throw json.message;
};

export const fetchOutcomeReasons = async (outcomeId: any) => {
  const res = await fetch(env._apiPath + "/outcomeReasons/" + outcomeId, {
    method: "GET",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
  });
  const json = await res.json();

  if (json.status === "success") {
    return json.data;
  }

  throw json.message;
};

export const addOutcomeReason = async (outcomeId: any, outcomeReasonName: string, outlineColor?: string) => {
  const req = {
    outcome_id: outcomeId,
    outcome_reason_name: outcomeReasonName,
    outline_color: outlineColor
  };

  const res = await fetch(env._apiPath + "/outcomeReason", {
    method: "PUT",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(req)
  });
  const json = await res.json();

  if (json.status === "success") {
    return json.data;
  }

  throw json.message;
};

export const updateOutcomeReason = async (outcomeReasonId: any, outcomeReasonName: string, outlineColor?: string) => {
  const req = {
    outcome_reason_id: outcomeReasonId,
    outcome_reason_name: outcomeReasonName,
    outline_color: outlineColor
  };

  const res = await fetch(env._apiPath + "/outcomeReason", {
    method: "POST",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(req)
  });
  const json = await res.json();

  if (json.status === "success") {
    return json.data;
  }

  throw json.message;
};

export const removeOutcomeReason = async (outcomeReasonId: any) => {
  const req = {
    outcome_reason_id: outcomeReasonId
  };

  const res = await fetch(env._apiPath + "/outcomeReason", {
    method: "DELETE",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(req)
  });
  const json = await res.json();

  if (json.status === "success") {
    return json.data;
  }

  throw json.message;
};