interface IPolygons {
  future: any;
  past: any;
  protected: any;
  active: any;
  areas: any;
}

interface IAgentPositions {
  lat: any;
  lng: any;
  agent_id: any;
}

interface IMapState {
  zoomLevel: number;
  polygons: IPolygons;
  agentPositions: IAgentPositions[];
}

const initialState: IMapState = {
  zoomLevel: 10,
  polygons: {
    future: null,
    past: null,
    protected: null,
    active: null,
    areas: null
  },
  agentPositions: []
};

export const MAP_SET_ZOOM = "@sky/MAP_SET_ZOOM";
export const actionSetZoomLevel = (zoomLevel: number) => ({
  type: MAP_SET_ZOOM,
  zoomLevel
});
export const MAP_ZOOM_IN = "@sky/MAP_ZOOM_IN";
export const actionZoomIn = () => ({
  type: MAP_ZOOM_IN
});
export const MAP_ZOOM_OUT = "@sky/MAP_ZOOM_OUT";
export const actionZoomOut = () => ({
  type: MAP_ZOOM_OUT
});

export const MAP_POLYGON_FUTURE = "@sky/MAP_POLYGON_FUTURE";
export const MAP_POLYGON_PAST = "@sky/MAP_POLYGON_PAST";
export const MAP_POLYGON_PROTECTED = "@sky/MAP_POLYGON_PROTECTED";
export const MAP_POLYGON_ACTIVE = "@sky/MAP_POLYGON_ACTIVE";
export const MAP_POLYGON_AREAS = "@sky/MAP_POLYGON_AREAS";

export const actionSetPolygonAreas = (areas: any) => ({
  type: MAP_POLYGON_AREAS,
  areas
});

export const MAP_RECEIVED_AGENT_POSITIONS = "@sky/MAP_RECEIVED_AGENT_POSITIONS";
export const actionReceivedAgentPositions = (agentPositions: any) => ({
  type: MAP_RECEIVED_AGENT_POSITIONS,
  agentPositions
});

export default function(state = initialState, action: any) {
  switch (action.type) {
    case MAP_SET_ZOOM:
      return { ...state, zoomLevel: action.zoomLevel };
    case MAP_ZOOM_IN:
      return { ...state, zoomLevel: Math.min(20, state.zoomLevel + 1) };
    case MAP_ZOOM_OUT:
      return { ...state, zoomLevel: Math.max(0, state.zoomLevel - 1) };
    case MAP_POLYGON_FUTURE:
      return {
        ...state,
        polygons: { ...state.polygons, future: action.future }
      };
    case MAP_POLYGON_PAST:
      return { ...state, polygons: { ...state.polygons, past: action.past } };
    case MAP_POLYGON_PROTECTED:
      return {
        ...state,
        polygons: { ...state.polygons, protected: action.protected }
      };
    case MAP_POLYGON_ACTIVE:
      return {
        ...state,
        polygons: { ...state.polygons, active: action.active }
      };
    case MAP_POLYGON_AREAS:
      return { ...state, polygons: { ...state.polygons, areas: action.areas } };
    case MAP_RECEIVED_AGENT_POSITIONS:
      return { ...state, agentPositions: action.agentPositions };
  }

  return state;
}
