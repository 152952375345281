import React, { useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import "./importcontacts.scss";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import { GridReadyEvent } from "ag-grid-community";
import { AgStatusRenderer } from "../../../../agGridUtils";

declare var env: any;

const historyAgSidebar = {
  toolPanels: [
    {
      id: "columns",
      labelDefault: "Columns",
      labelKey: "columns",
      iconKey: "columns",
      toolPanel: "agColumnsToolPanel",
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressSideButtons: true,
        suppressColumnFilter: true,
        suppressColumnSelectAll: true,
        suppressColumnExpandAll: true
      }
    }
  ]
  // defaultToolPanel: "columns"
};

const historyColumnDefs = [
  { headerName: "Directory", field: "directory", resizable: true },
  { headerName: "Contact List", field: "contactList", resizable: true },
  { headerName: "Imported By", field: "importedBy", resizable: true },
  { headerName: "Import Date", field: "importDate", resizable: true },
  {
    headerName: "Status",
    field: "step",
    resizable: true,
    cellRenderer: AgStatusRenderer({
      field: "step",
      className: "",
      switches: {
        done: <i className="fa fa-fw fa-check"></i>,
        importing: <i className="fa fa-fw fa-spin fa-spinner"></i>,
        wash: <i className="fa fa-fw fa-external-link-alt"></i>
      },
      default: <i className="fa fa-fw fa-external-link-alt"></i>
    }),
    cellClass: (params: any) => {
      if (!params.data) {
        return "";
      }

      switch (params.data.step) {
        case "done":
          return "text-success text-center ag-cell-btn ag-cell-tooltip";
        case "importing":
          return "text-success text-center ag-cell-btn ag-cell-tooltip";
        default:
          return "btn-warning cursor-pointer text-center ag-cell-btn ag-cell-tooltip";
      }
    },
    onCellClicked: (params: any) => {
      if (!params.data) {
        return;
      }
      let urlMap = params.data.step;
      switch (params.data.step) {
        case "map":
          urlMap = "connect";
          break;
        case "washed":
          urlMap = "finish";
          break;
        case "done":
          return;
      }

      window.open("/list_management/directories/" + urlMap + "/" + params.data.contact_list_id, "_blank");
    },
    tooltipValueGetter: (params: any) => {
      if (!params.data) {
        return "";
      }
      switch (params.data.step) {
        case "done":
          return "Imported successfully";
        case "importing":
          return "Currently importing";
        default:
          return "Import draft";
      }
    }
  }
];

interface ImportContactsProps {
  show: boolean;
  onHide: any;
  directoryId: string;
  campaignTypeId: string;
}

export default function ImportContactsComponent(props: ImportContactsProps) {
  const [errorMessage, setErrorMessage] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [uploadFile, setUploadFile] = useState<any>();
  const [contactListHistory, setContactListHistory] = useState([]);
  const [selectedFileType, setSelectedFileType] = useState({ value: 0, label: ".CSV" });
  const [selectedSeparator, setSelectedSeparator] = useState({ value: ";", label: "Semicolon" });
  const [selectedEncapsulation, setSelectedEncapsulation] = useState({ value: 0, label: 'Double quotes ""' });
  const [selectedEncoding, setSelectedEncoding] = useState({ value: 1, label: "Windows-1252 (ANSI)" });

  const fetchUploadHistory = async () => {
    const res = await fetch(
      env._apiPath + "/listManagement/contactLists?limit=10&campaign_type_id=" + props.campaignTypeId,
      { method: "GET", credentials: "include" }
    );
    const json = await res.json();
    if (json.status === "success") {
      const ctHistory: any = [];
      for (const i in json.data) {
        const o = json.data[i];
        ctHistory.push({
          directory: o.contact_directory_name ? o.contact_directory_name : "-",
          contactList: o.contact_list_name,
          contact_list_id: o.contact_list_id,
          importedBy: o.creator,
          importDate: o.created_at,
          step: o.step.toLowerCase()
        });
      }
      setContactListHistory(ctHistory);
    }
  };

  useEffect(() => {
    fetchUploadHistory();
  }, []);

  const onDrop = useCallback(acceptedFiles => {
    setUploadFile(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onGridReady = (event: GridReadyEvent) => {
    // this.gridApi = event.api;
    // this.gridColumnApi = event.columnApi;

    event.api.sizeColumnsToFit();
    window.addEventListener("resize", () => setTimeout(() => event.api.sizeColumnsToFit()));
    event.api.sizeColumnsToFit();
  };

  const onUpload = (e: any) => {
    if (!uploadFile) {
      setErrorMessage("Please select a file to upload.");
      return;
    }

    setShowSpinner(true);

    const uploadData = async () => {
      const frmData = new FormData();
      frmData.append("contacts", uploadFile![0]);
      frmData.append("delimiter", selectedSeparator.value.toString());
      frmData.append("contact_list_name", uploadFile![0].name);
      frmData.append("contact_directory_id", props.directoryId.toString());
      frmData.append("campaign_type_id", props.campaignTypeId.toString());

      const res = await fetch(env._apiPath + "/listManagement/contactFile", {
        method: "POST",
        credentials: "include",
        body: frmData
      });
      const json = await res.json();
      setShowSpinner(false);
      if (json.status === "success") {
        window.open("/list_management/directories/connect/" + json.data.contact_list_id, "_blank");
        props.onHide();
        fetchUploadHistory();
        return;
      }

      setErrorMessage("Unable to upload file: " + json.message.join(" "));
    };
    uploadData();
  };

  return (
    <Modal size="lg" centered show={props.show} onHide={props.onHide}>
      <Modal.Header>
        <Modal.Title>Import Contacts</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col>
            <Form.Group controlId="frmFileUpload">
              <Form.Label>Upload File</Form.Label>
              <div {...getRootProps()} className="import-drop-area">
                <input {...getInputProps()} />
                {isDragActive ? (
                  <>Drop the files here ...</>
                ) : (
                  <>
                    <Row>
                      <Col md={1}>
                        <i className="fa fa-cloud-upload-alt"></i>
                      </Col>
                      <Col>Drop file here, or click to select.</Col>
                    </Row>
                  </>
                )}
              </div>
              {!showAdvanced ? (
                <span className="span-link" onClick={() => setShowAdvanced(!showAdvanced)}>
                  Show advanced
                </span>
              ) : (
                <span className="span-link" onClick={() => setShowAdvanced(!showAdvanced)}>
                  Hide advanced
                </span>
              )}
            </Form.Group>
          </Col>
        </Row>
        {showAdvanced ? (
          <Row>
            <Col>
              <Form.Group controlId="frmFileSettings">
                <Form.Label>File Type</Form.Label>
                <Select
                  value={selectedFileType}
                  onChange={(opt: any) => setSelectedFileType(opt)}
                  options={[
                    { value: 0, label: ".CSV" },
                    { value: 1, label: ".TXT" }
                  ]}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="frmSeparator">
                <Form.Label>Separator</Form.Label>
                <Select
                  value={selectedSeparator}
                  onChange={(opt: any) => setSelectedSeparator(opt)}
                  options={[
                    { value: ";", label: "Semicolon" },
                    { value: ",", label: "Comma" },
                    { value: "\\t", label: "Tab" }
                  ]}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="frmEncapsulation">
                <Form.Label>Encapsulation</Form.Label>
                <Select
                  value={selectedEncapsulation}
                  onChange={(opt: any) => setSelectedEncapsulation(opt)}
                  options={[
                    { value: 0, label: 'Double quotes ""' },
                    { value: 1, label: "Single quotes ''" },
                    { value: 2, label: "None" }
                  ]}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="frmEncoding">
                <Form.Label>Encoding</Form.Label>
                <Select
                  value={selectedEncoding}
                  onChange={(opt: any) => setSelectedEncoding(opt)}
                  options={[
                    { value: 0, label: "UTF-8" },
                    { value: 1, label: "Windows-1252 (ANSI)" }
                  ]}
                />
              </Form.Group>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col>
            <Form.Group controlId="frmEncoding">
              <Form.Label>Upload History</Form.Label>
              <div
                className="ag-theme-balham"
                style={{
                  height: "233px",
                  width: "100%"
                }}
              >
                <AgGridReact
                  columnDefs={historyColumnDefs}
                  rowData={contactListHistory}
                  onGridReady={onGridReady}
                  groupIncludeTotalFooter={false}
                  sideBar={historyAgSidebar}
                  suppressContextMenu={true}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        {errorMessage ? (
          <div>
            <i className="fa fa-exclamation-circle"></i> {errorMessage}
          </div>
        ) : null}
        {showSpinner ? <i className="fa fa-fw fa-spin fa-spinner"></i> : null}
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={onUpload}>
          Upload
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
