import { parse } from "date-fns";
import { IFormData } from "./form.types";

declare var env: any;

export async function getFormData(formId: number) {
  const res = await fetch(env._apiPath + "/form/" + formId, {
    method: "GET",
    credentials: "include"
  });
  const json = await res.json();

  if (json.status === "success") {
    const formData: IFormData = json.data;
    if (formData.form_start_date === "") {
      formData._start_date = null;
    }

    if (formData.form_end_date === "") {
      formData._end_date = null;
    }

    if (formData.form_start_date !== "") {
      formData._start_date = parse(formData.form_start_date, "dd.MM.yyyy HH:mm", new Date());
    }

    if (formData.form_end_date !== "") {
      formData._end_date = parse(formData.form_end_date, "dd.MM.yyyy HH:mm", new Date());
    }

    return formData;
  }

  throw json.message;
}

export async function saveFormData(formData: IFormData) {
  const req = {
    form_id: formData.form_id, //	"1301"
    form_name: formData.form_name, //	"Ny+klone"
    form_start_date: formData.form_start_date, //	"15.11.2019+12:50"
    form_end_date: formData.form_end_date, //	""
    color_primary: formData.color_primary, //	"#c81919"
    color_secondary: formData.color_secondary, //	"#39d52a"
    form_description: formData.form_description, //	""
    intro: formData.intro, //	"<!DOCTYPE+html>\r\n<html>\r\n<head>\r\n</head>\r\n<body>\r\n<p>welcome</p>\r\n</body>\r\n</html>"
    summary: formData.summary, //	"<!DOCTYPE+html>\r\n<html>\r\n<head>\r\n</head>\r\n<body>\r\n<p>verification</p>\r\n</body>\r\n</html>"
    queue_details: formData.queue_details //	"<!DOCTYPE+html>\r\n<html>\r\n<head>\r\n</head>\r\n<body>\r\n<p>queue</p>\r\n</body>\r\n</html>"
  };
  const res = await fetch(env._apiPath + "/form/edit", {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(req)
  });
  const json = await res.json();

  if (json.status === "success") {
    return;
  }

  throw json.message;
}

export async function getMergeFields(formId: number, objectName: string) {
  const res = await fetch(env._apiPath + "/mergeFields?object_name="+objectName+"&form_id="+formId, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    }
  });
  const json = await res.json();

  if (json.status === "success") {
    return json.data;
  }

  throw json.message;
}
