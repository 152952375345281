import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { format } from "date-fns/esm";
import { addMonths, isBefore } from 'date-fns/fp'
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import { GridReadyEvent } from "ag-grid-community";
import { AgToolbarButton } from "../../../agGridUtils";
import { FirstDataRenderedEvent, SelectionChangedEvent } from "ag-grid-community/dist/lib/events";
import MapComponent from "./map.component";
import AddAreaComponent from "./addarea.component";
import Alert from "react-bootstrap/Alert";
import { useSelector, useDispatch } from "react-redux";
import { fetchSegmentDetails, saveSegmentDetails } from "./maps.api";

declare var env: any;

interface EditSegmentProps {
  show: boolean;
  onHide: any;
  onError: any;
  onRemove: any;
  segmentId: string;
}

interface IAreaDetails {
  area_id: string;
  area_code: string;
  area_name: string;
  area_coordinates: string;
  area_blacklistedCoordinates: string;
}

interface ISegmentDetails {
  segment_id: string;
  segment_name: string;
  campaign_name: string;
  campaign_id: string;
  campaign_type_id: string;
  campaign_type_name: string;
  client_id: string;
  client_color: string;
  client_name: string;
  company_id: string;
  company_name: string;
  date_from: Date;
  date_to: Date;
  freeze_date_to?: Date;
  areas: IAreaDetails[];
}

const toolbarProps = {
  title: "",
  rightAlign: false,
  disabled: false,
  icon: "",
  onClick: () => {}
};

export default function EditSegmentComponent(props: EditSegmentProps) {
  const clients = useSelector(state => state.shell.clients);
  const companies = useSelector(state => state.shell.companies);

  const defaultSegmentDetails = {
    segment_id: "0",
    segment_name: "",
    campaign_name: "",
    campaign_id: "",
    campaign_type_id: "",
    campaign_type_name: "",
    client_id: "",
    client_color: "",
    client_name: "",
    company_id: "",
    company_name: "",
    date_from: new Date(),
    date_to: addMonths(3, new Date()),
    freeze_date_to: addMonths(6, new Date()),
    areas: []
  };
  const [segmentDetails, setSegmentDetails] = useState<ISegmentDetails>(defaultSegmentDetails);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [highlighedPolygonId, setHighlighedPolygonId] = useState<string[]>([]);

  const [showSegmentDialog, setShowSegmentDialog] = useState(false);
  const [showAreaDialog, setShowAreaDialog] = useState(false);

  const [apiMessage, setApiMessage] = useState<any>({ show: false, variant: "success", message: "" });
  const [apiAlertTimeout, setApiAlertTimeout] = useState<any>(null);

  const [selectedCompany, setSelectedCompany] = useState<any>(null);
  const [selectedClient, setSelectedClient] = useState<any>(null);

  const [selectedCampaignType, setSelectedCampaignType] = useState<any>(null);
  const [campaignTypes, setCampaignTypes] = useState([{ value: "1", label: "" }]);

  const [selectedCampaign, setSelectedCampaign] = useState<any>(null);
  const [campaigns, setCampaigns] = useState([{ value: "1", label: "" }]);

  const [shadowAreaIDs, setShadowAreaIDs] = useState<any>(null);

  const [pastEndDate, setPastEndDate] = useState(false);

  const updateSegmentDetails = () => {
    setSegmentDetails(defaultSegmentDetails);
    setSelectedRows([]);
    fetchSegmentDetails(props.segmentId)
      .then(details => {
        details[0].date_from = new Date(details[0].date_from);
        details[0].date_to = new Date(details[0].date_to);

        if (details[0].freeze_date_to) {
          details[0].freeze_date_to = new Date(details[0].freeze_date_to);
          setPastEndDate(isBefore(details[0].date_to, details[0].freeze_date_to));
        } else {
          details[0].freeze_date_to = null;
        }

        setSegmentDetails(details[0]);

        if (details[0].areas[0]) {
          setHighlighedPolygonId(details[0].areas[0].area_id);
        }
        setShadowAreaIDs(details[0].areas.map((a: IAreaDetails) => a.area_id));
      })
      .catch(err => {
        props.onError(err);
      });
  };

  const fetchCampaignTypes = async () => {
    const res = await fetch(
      env._apiPath +
        "/customer/campaigntypes" +
        (selectedClient !== null ? "/" + selectedClient.value : "") +
        (selectedCompany !== null ? "?company_id=" + selectedCompany.value + "&" : "?") +
        "maps=1",
      {
        method: "GET",
        credentials: "include"
      }
    );
    const json = await res.json();
    if (json.status !== "success") {
      return;
    }

    const ctypes: any = [];
    json.data.forEach((campaignType, idx) => {
      ctypes.push({
        value: campaignType.campaign_type_id,
        label: campaignType.prefix + " " + campaignType.campaign_type_name
      });
    });
    setCampaignTypes(ctypes);
  };

  const fetchCampaigns = async () => {
    const res = await fetch(
      env._apiPath +
        "/customer/campaigns?maps=1" +
        (selectedCampaignType !== null ? "&campaign_type_id=" + selectedCampaignType.value : "") +
        (selectedClient !== null ? "&client_id=" + selectedClient.value : ""),
      {
        method: "GET",
        credentials: "include"
      }
    );
    const json = await res.json();
    if (json.status !== "success") {
      return;
    }
    const camps: any = [];
    json.data.forEach((camp, idx) => {
      camps.push({
        value: camp.campaign_id,
        label: camp.campaign_name
      });
    });
    setCampaigns(camps);
  };

  useEffect(() => {
    return () => {
      setShowAreaDialog(false);
      setShowSegmentDialog(false);
    };
  }, []);

  useEffect(() => {
    if (props.segmentId === "0") {
      fetchCampaigns();
      setSelectedCampaign(null);
    }
  }, [selectedCampaignType, props.segmentId]);

  useEffect(() => {
    fetchCampaignTypes();
    setSelectedCampaignType(null);
    setSelectedCampaign(null);
  }, [selectedClient, selectedCompany]);

  useEffect(() => {
    if (!props.segmentId || props.segmentId === "0") {
      if (props.segmentId === "0") {
        setSegmentDetails(defaultSegmentDetails);
        setSelectedRows([]);
        setHighlighedPolygonId([]);
        fetchCampaignTypes();
        setPastEndDate(false);
      }
      return;
    }
    updateSegmentDetails();
  }, [props.segmentId]);

  useEffect(() => {
    setShowSegmentDialog(props.show);
  }, [props.show]);

  useEffect(() => {
    clearTimeout(apiAlertTimeout);
    setApiAlertTimeout(
      setTimeout(() => {
        setApiMessage({ ...apiMessage, show: false });
      }, 5000)
    );
  }, [apiMessage]);

  const areaGridColumns = [
    { headerName: "Name", field: "area_name" },
    { headerName: "Code", field: "area_code" },
    { headerName: "Addresses", field: "area_coordinates" },
    { headerName: "Blocked", field: "area_blacklistedCoordinates" }
  ];

  const onGridReady = (event: GridReadyEvent) => {
    event.api.sizeColumnsToFit();
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    event.api.sizeColumnsToFit();
  };

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const rows = event.api.getSelectedRows();
    setSelectedRows(rows);
    setHighlighedPolygonId([rows[0].area_id]);
  };

  const hideAreaDialog = () => {
    setShowAreaDialog(false);
    setShowSegmentDialog(true);
  };

  const onAddArea = (areas: any[]) => {
    const dt = Object.assign({}, segmentDetails);
    areas.forEach((area: any) =>
      dt.areas.push({
        area_id: area.area_id,
        area_code: area.area_code,
        area_name: area.area_name,
        area_coordinates: area.area_coordinates,
        area_blacklistedCoordinates: area.area_blacklistedCoordinates
      })
    );
    setSegmentDetails(dt);
  };

  const onRemoveSelectedArea = () => {
    const rAreas: string[] = [];
    selectedRows.forEach((ar: any, aidx) => {
      rAreas.push(ar.area_id);
    });

    const keep: any = [];
    segmentDetails.areas.forEach((ear: any, eidx) => {
      if (rAreas.indexOf(ear.area_id) === -1) {
        keep.push(ear);
      }
    });

    setSegmentDetails({ ...segmentDetails, areas: keep });
  };

  const onSaveChanges = () => {
    let campaignId = null;
    if (selectedCampaign) {
      campaignId = selectedCampaign.value;
    }
    if (!campaignId && !props.segmentId) {
      setApiMessage({ variant: "warning", message: "Please select campaign.", show: true });
      return;
    }

    saveSegmentDetails(segmentDetails, campaignId)
      .then(() => {
        setApiMessage({ variant: "success", message: "Segment updated.", show: true });

        if (props.segmentId === "0") {
          props.onHide();
          return;
        }

        updateSegmentDetails();
      })
      .catch(err => {
        setApiMessage({ variant: "danger", message: err, show: true });
      });
  };

  if (showAreaDialog) {
    return (
      <AddAreaComponent
        show={showAreaDialog}
        onHide={hideAreaDialog}
        onError={() => {}}
        onAddArea={onAddArea}
        existingAreas={shadowAreaIDs}
      />
    );
  }

  const renderCampaignData = () => {
    return (
      <>
        <Row>
          <Col>
            <Form.Group controlId="frmClient">
              <Form.Label>Client</Form.Label>
              <Select
                value={selectedClient}
                onChange={(opt: any) => setSelectedClient(opt)}
                options={clients}
                isClearable={true}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="frmCompany">
              <Form.Label>Company</Form.Label>
              <Select
                value={selectedCompany}
                onChange={(opt: any) => setSelectedCompany(opt)}
                options={companies}
                isClearable={true}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="frmCampaignType">
              <Form.Label>Campaign Type</Form.Label>
              <Select
                value={selectedCampaignType}
                onChange={(opt: any) => setSelectedCampaignType(opt)}
                options={campaignTypes}
                isClearable={true}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="frmCampaign">
              <Form.Label>Campaign</Form.Label>
              <Select
                value={selectedCampaign}
                onChange={(opt: any) => setSelectedCampaign(opt)}
                options={campaigns}
                isClearable={true}
              />
            </Form.Group>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Modal size="lg" centered show={showSegmentDialog} onHide={props.onHide}>
      <Modal.Header>
        {segmentDetails.client_name.length > 0 ? (
          <Modal.Title>
            Edit Segment - {segmentDetails.client_name} / {segmentDetails.company_name} / {segmentDetails.segment_name}
          </Modal.Title>
        ) : (
          <Modal.Title>Edit Segment</Modal.Title>
        )}
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col>
            {segmentDetails.segment_id === "0" ? renderCampaignData() : null}
            <Row>
              <Col>
                <Form.Group controlId="frmSegmentName">
                  <Form.Label>Segment Name</Form.Label>
                  <Form.Control
                    value={segmentDetails.segment_name}
                    onChange={e => setSegmentDetails({ ...segmentDetails, segment_name: e.target.value })}
                    type="text"
                    placeholder="Enter segment name"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="frmDateFrom">
                  <Form.Label>From Date</Form.Label>
                  <DatePicker
                    dateFormat="dd.MM.yyyy"
                    className="form-control"
                    selected={segmentDetails.date_from}
                    onChange={date => setSegmentDetails({ ...segmentDetails, date_from: date })}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="frmDateTo">
                  <Form.Label>End Date</Form.Label>
                  <DatePicker
                    dateFormat="dd.MM.yyyy"
                    className="form-control"
                    selected={segmentDetails.date_to}
                    onChange={date => setSegmentDetails({ ...segmentDetails, date_to: date })}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="frmFreezeTo">
                  <Form.Label>Freeze Areas Until</Form.Label>
                  <DatePicker
                    dateFormat="dd.MM.yyyy"
                    className="form-control"
                    selected={segmentDetails.freeze_date_to}
                    onChange={date => {
                      setPastEndDate(isBefore(segmentDetails.date_to, date));
                      setSegmentDetails({ ...segmentDetails, freeze_date_to: date });
                    }}
                  />
                  {pastEndDate ? <p>Note: Should be after end date.</p> : null}
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="ag-buttons">
              {AgToolbarButton({
                ...toolbarProps,
                title: "Add",
                icon: "fa-plus",
                onClick: () => {
                  setShowAreaDialog(true);
                  setShowSegmentDialog(false);
                }
              })}
              {AgToolbarButton({
                ...toolbarProps,
                title: "Remove",
                icon: "fa-trash",
                disabled: selectedRows.length === 0,
                onClick: onRemoveSelectedArea
              })}
            </div>
            <div className="ag-theme-balham" style={{ height: "300px", width: "100%" }}>
              <AgGridReact
                columnDefs={areaGridColumns}
                rowData={segmentDetails.areas}
                enableRangeSelection={false}
                rowSelection="multiple"
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                onSelectionChanged={onSelectionChanged}
                suppressAggFuncInHeader={true}
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  filter: true
                }}
              />
            </div>
          </Col>
          <Col>
            {showSegmentDialog ? (
              <MapComponent highlightPolygonId={highlighedPolygonId} shadowAreaIDs={shadowAreaIDs} />
            ) : null}
          </Col>
        </Row>
        <br />
        <Row>
          <Col>{apiMessage.show ? <Alert variant={apiMessage.variant}>{apiMessage.message}</Alert> : null}</Col>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="danger" onClick={props.onRemove}>
          <span className="fa fa-trash" />
          &nbsp; Remove Segment
        </Button>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={onSaveChanges}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
