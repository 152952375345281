import { format } from "date-fns";

declare var env: any;

export async function getCategories(formId: number) {
  const res = await fetch(env._apiPath + "/client/form/categories?form_id=" + formId, {
    method: "GET",
    credentials: "include"
  });
  const json = await res.json();

  if (json.status === "success") {
    return json.data;
  }

  throw json.message;
}

export async function addCategory(formId: number, formCategoryName, step, categoryLevel, password) {
  const req = {
    form_id: formId,
    form_category_name: formCategoryName,
    step: step,
    category_level: categoryLevel,
    pwd: password,
  };

  const res = await fetch(env._apiPath + "/form/category", {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(req)
  });
  const json = await res.json();

  if (json.status === "success") {
    return;
  }

  throw json.message;
}

export async function removeCategory(categoryId: number) {
  const res = await fetch(env._apiPath + "/formCategory/" + categoryId, {
    method: "DELETE",
    credentials: "include"
  });
  const json = await res.json();

  if (json.status === "success") {
    return;
  }

  throw json.message;
}
