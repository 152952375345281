import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TitlebarComponent from "../../titlebar.component";
import PeriodSelector from "../shell/periodselector.component";
import UserSelector from "../shell/userselector.component";
import { Logdata, fetchUserLogReport } from "./userlogreport/userlogreport.api";
import { actionToggleFilterView } from "../../redux/reducers/shell";
import { loadUsers, loadLogTypes } from "../shell/shell.api";

import { format } from "date-fns/esm";
//import Button from "react-bootstrap/Button";

// gridsystemet
import {
    GridReadyEvent,
    FirstDataRenderedEvent,
    GridApi,
    ColumnApi,
  } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {
    Grid,
    CardContent,
    Card,
    CardActions,
    makeStyles,
    Theme,
    createStyles,
} from "@material-ui/core";
import "ag-grid-enterprise";
import "ag-grid-enterprise/chartsModule";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgToolbarButton } from "../../agGridUtils";
import { PaginationChangedEvent } from "ag-grid-community/dist/lib/events";

// Setter kolonner med navn etc
const columnDefs = [
    { headerName: "Dato", field: "created_at", sortable: true, filter: true},
    { headerName: "Navn", field: "fullname", sortable: true, filter: true},
    { headerName: "Type", field: "log_user_type_name", sortable: true, filter: true},
    { headerName: "Kommentar", field: "comment", sortable: true, filter: true},
    // de under her er hidden
    { headerName: "LogUserId", field: "log_user_id", sortable: true, filter: true, hide: true},
    { headerName: "UserId", field: "user_id", sortable: true, filter: true, hide: true},
    { headerName: "LogUserTypeID", field: "log_user_type_id", sortable: true, filter: true, hide: true},
];

// for knappene over tabellen
const toolbarProps = {
    title: "",
    rightAlign: false,
    disabled: false,
    icon: "",
    onClick: () => { },
};

// theme for AdGridReact
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorMessage: {
            color: '#ff0000'
        },
        flexGrow: {
            flexGrow: 1,
        },
        actions: {
            "& .form-group": {
                minWidth: 200,
                marginRight: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
            "& button": {
                marginTop: 24,
            },
        },
        agGrid: {
            "& .aggrid-size": {
                minHeight: 500,
                height: "calc(100vh - 460px)",
                width: "100%",
            },
        },
    })
);

// setter initielle datoer
const defaultStartDate = new Date();
var d = new Date(); d.setDate(d.getDate());
defaultStartDate.setDate(d.getDate()-1);
let defaultStartDateFormatted = format(new Date(defaultStartDate), "yyyyMMdd");
const defaultEndDate = new Date();
let defaultEndDateFormatted = format(new Date(defaultEndDate), "yyyyMMdd");

export default function UserLogReportComponent() {
    const [logData, setLogData] = useState<Logdata[]>([]);
    const classes = useStyles();

    // for tabellen
    const dispatch = useDispatch();
    const hideFilters = useSelector((state) => state.shell.hideFilters);
    const [columnAPI, setColumnAPI] = useState<ColumnApi>();
    const [gridAPI, setGridAPI] = useState<GridApi>();

    // datepicker
    const selectedStartDate = useSelector((state) => state.shell.selectedStartDate);
    const selectedEndDate = useSelector((state) => state.shell.selectedEndDate);
    let selectedStartDateFormatted = format(new Date(selectedStartDate), "yyyyMMdd");
    let selectedEndDateFormatted = format(new Date(selectedEndDate), "yyyyMMdd");

    // users
    const selectedLogUser = useSelector(state => state.shell.selectedLogUser);
    const selectedLogType = useSelector(state => state.shell.selectedLogType);

    // props for fetchUserLogReport
    let fetchUserLogReportProps = {
        startDateFormatted:  selectedStartDateFormatted ? selectedStartDateFormatted : defaultStartDateFormatted,
        endDateFormatted: selectedEndDate ? selectedEndDateFormatted : defaultEndDateFormatted,
        selLogUser: selectedLogUser ? selectedLogUser.value : null,
        selLogType: selectedLogType ? selectedLogType.value : null,

    };

    // funksjon for knappen "refresh" og "run"
    const runFetchLog = () => {
        fetchUserLogReport(fetchUserLogReportProps)
            .then((logs: Logdata[]) => new Promise(function(resolve, reject) {
                resolve(setLogData(logs));
            })) 
    };

    // laster inn data om tabellen
    const onGridReady = (event: GridReadyEvent) => {
        setGridAPI(event.api);
        setColumnAPI(event.columnApi);
    };
    // 3 knapper over tabellen, refresh, og endre størrelse på kolonner
    const onRefreshList = () => {
        runFetchLog();
    };
    const onAdjustColumnsContent = (event: Event) => {
        var allColumnIds: any = [];
        columnAPI!.getAllColumns().forEach((column) => {
            const colDef = column.getColDef();
            if (!colDef.suppressAutoSize) {
                allColumnIds.push(column.getColId());
            }
        });
        columnAPI!.autoSizeColumns(allColumnIds);
    };
    const onAdjustColumnsWindow = (event: Event) => {
        gridAPI!.sizeColumnsToFit();
    };
    
    const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
        event.api.sizeColumnsToFit();
    };
    const onExportToCsv = () => {
        if (gridAPI) {
          gridAPI.exportDataAsExcel();
        }
    };
    useEffect(() => {
        // last inn users
        loadUsers(dispatch);
        // last inn logUserTypes
        loadLogTypes(dispatch);
    },[]);
    // henter logdata (ved load og) ved endring av valgt loguser,logtype,startdate,enddate
    // i praksis gjør denne at logUsers kjører 2 ganger ved reload, dette fordi startdate er (selectedStartDate: defaultStartDate,) i shell.tsx - som igjen brukes av periodselector og tvinges til den 1. i måneden
    useEffect(() => {
        fetchUserLogReport(fetchUserLogReportProps)
        .then((logs: Logdata[]) => new Promise(function(resolve) {
            resolve(setLogData(logs));
        }));
    },[selectedLogUser, selectedLogType, selectedStartDate, selectedEndDate]);

    return (
        <div className="ag-theme-material">
            <TitlebarComponent
                title="Brukerlogg"
                description="Her har du en logg over brukere"
                breadcrumbs={[
                    { title: "Dashboard", link: "/" },
                    { title: "Brukerlogg", link: "/settings/userLogReport" }
                ]}
            />

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div
                            style={{ color: "#ccc", width: 15 }}
                            data-toggle="tooltip"
                            data-original-title="Hide/Show filters"
                            onClick={() => dispatch(actionToggleFilterView())}
                        >
                            {hideFilters ? (
                                <i className="fas fa-fw fa-caret-square-right"></i>
                            ) : (
                                    <i className="fas fa-fw fa-caret-square-left"></i>
                                )}
                        </div>
                </Grid>
                <Grid item xs={12} md={2} style={{ display: hideFilters ? "none" : "block" }}>
                    {/* Datovelger */}
                    <PeriodSelector defaultStartDate={defaultStartDate} />
                    
                    <UserSelector />
                    {/* Fjerner knappen da det er useEffect load på alle 4 feltene uansett
                    <Button onClick={() => runFetchLog()} size="sm" variant="secondary">
                        Run
                    </Button>
                    */}
                </Grid>

            
                <Grid item xs={12} md={hideFilters ? 12 : 10}>
                    <Card>                
                        <CardActions disableSpacing className={classes.actions}>
                            {/* Knappene over tabellen */}
                            <div className={classes.flexGrow} />
                            {AgToolbarButton({
                                ...toolbarProps,
                                title: "Refresh",
                                icon: "fa-sync-alt",
                                onClick: onRefreshList,
                            })}

                            {AgToolbarButton({
                                ...toolbarProps,
                                title: "Export to CSV",
                                icon: "fa-file-export",
                                onClick: onExportToCsv,
                            })}

                            {AgToolbarButton({
                                ...toolbarProps,
                                title: "Adjust columns to window",
                                icon: "fa-columns",
                                onClick: onAdjustColumnsWindow,
                            })}

                            {AgToolbarButton({
                                ...toolbarProps,
                                title: "Adjust columns to content",
                                icon: "fa-text-width",
                                onClick: onAdjustColumnsContent,
                            })}
                        </CardActions>
                        {/* Selve tabellen */}
                        <CardContent>
                            <Grid container>
                            <Grid item xs={12} className={classes.agGrid}>
                                <div className="ag-theme-balham aggrid-size">
                                    <AgGridReact
                                        rowData={logData}
                                        columnDefs={columnDefs}  
                                        onGridReady={onGridReady}
                                        // justerer kolonnebredden etter load
                                        onFirstDataRendered={onFirstDataRendered}
                                        sideBar={{
                                            toolPanels: ["columns"],
                                        }}
                                        // pagination
                                        pagination={true}
                                        paginationPageSize={100}
                                        cacheBlockSize={100}
                                        cacheOverflowSize={2}
                                        maxBlocksInCache={2}
                                    />
                                </div>
                            </Grid>
                            </Grid>
                        </CardContent>  
                </Card>
                </Grid>
            </Grid>
        </div>
    );

}