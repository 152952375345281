import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import { GridReadyEvent } from "ag-grid-community";
import { useInterval } from "../../../../custom.hooks";
import { useIdle } from "react-use";

/////////////////////////////////////////////
const historyAgSidebar = {
  toolPanels: [
    {
      id: "columns",
      labelDefault: "Columns",
      labelKey: "columns",
      iconKey: "columns",
      toolPanel: "agColumnsToolPanel",
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressSideButtons: true,
        suppressColumnFilter: true,
        suppressColumnSelectAll: true,
        suppressColumnExpandAll: true
      }
    }
  ]
  // defaultToolPanel: "columns"
};

const historyColumnDefs = [
  { headerName: "Timestamp", field: "timestamp", resizable: true },
  { headerName: "User", field: "user", resizable: true },
  { headerName: "Message", field: "message", resizable: true }
];
/////////////////////////////////////////////

interface HistoryProps {
  directoryId: string;
}

declare var env: any;

export default function HistoryComponent(props: HistoryProps) {
  const [directoryHistory, setDirectoryHistory] = useState([]);
  const isIdle = useIdle(15e3);

  const fetchDirectoryHistory = async (directoryId: string) => {
    const res = await fetch(
      env._apiPath + "/listManagement/contactDirectoryLogs?contact_directory_id=" + directoryId + "&limit=100",
      { method: "GET", credentials: "include" }
    );
    const json = await res.json();

    const dhistory: any = [];
    if (json.status === "success") {
      for (const i in json.data) {
        const o = json.data[i];
        dhistory.push({
          timestamp: o.created_at,
          user: o.user,
          message: o.comment
        });
      }
    }
    setDirectoryHistory(dhistory);
  };

  useEffect(() => {
    fetchDirectoryHistory(props.directoryId.toString());
  }, [props.directoryId]);

  useInterval(() => {
    if (!isIdle) {
      fetchDirectoryHistory(props.directoryId.toString());
    }
  }, 30e3);

  const onGridReady = (event: GridReadyEvent) => {
    // this.gridApi = event.api;
    // this.gridColumnApi = event.columnApi;

    event.api.sizeColumnsToFit();
    window.addEventListener("resize", () => setTimeout(() => event.api.sizeColumnsToFit()));
    event.api.sizeColumnsToFit();
  };

  return (
    <div className="directory-history" style={{ flex: 1 }}>
      <div className="row">
        <div className="col-md-12">
          <h6>History</h6>
          <div
            className="ag-theme-balham"
            style={{
              height: "433px",
              width: "100%"
            }}
          >
            <AgGridReact
              columnDefs={historyColumnDefs}
              rowData={directoryHistory}
              onGridReady={onGridReady}
              groupIncludeTotalFooter={false}
              sideBar={historyAgSidebar}
              suppressContextMenu={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
