import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { actionSelectCompany } from "../../redux/reducers/shell";
import { loadShellCompanies } from "./shell.api";
import { TextField, MenuItem } from "@material-ui/core";
import _uniqueId from "lodash/uniqueId";

interface ICompanySelectorProps {
  variant?: string,
  fullWidth?: boolean,
  required?: boolean
}

export default function CompanySelector(props: ICompanySelectorProps) {
  const dispatch = useDispatch();
  const companies = useSelector(state => state.shell.companies);
  const selectedCompany = useSelector(state => state.shell.selectedCompany);
  const [id] = useState(_uniqueId("csel-"));

  function handleChange(e) {
    if (e.target.value == "") {
      dispatch(actionSelectCompany(undefined));
    }
    else {
      dispatch(actionSelectCompany(e.target));
    }
  }

  useEffect(() => {
    loadShellCompanies(dispatch);
  }, [dispatch, loadShellCompanies]);

  if(props.variant === "material") {
    return (
      <TextField 
          value={selectedCompany ? selectedCompany.value : ""}
          select 
          required={props.required}
          label="Company"
          id={id}
          fullWidth={props.fullWidth}
          // onChange={(e: any) => dispatch(actionSelectClient(e.target))}
          onChange={handleChange}
          >
          <MenuItem value="">None</MenuItem>
          {companies.map((company, key) => (
            <MenuItem key={key} value={company.value}>{company.label}</MenuItem>
          ))}
        </TextField>
    );
  }

  return (
    <Form.Group controlId="frmCompany">
      <Form.Label>Company</Form.Label>
      <Select
        value={selectedCompany}
        onChange={(opt: any) => dispatch(actionSelectCompany(opt))}
        options={companies}
        isClearable={true}
      />
    </Form.Group>
  );
}
