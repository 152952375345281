import React, { useEffect, useState } from "react";
import { PieChart, Pie, Sector, Cell } from "recharts";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector, useDispatch } from "react-redux";
import { loadOutboundLogtime, loadOutboundCalls } from "./outbound.api";
const COLORS = [
  "#b71c1c",
  "#880e4f",
  "#4a148c",
  "#311b92",
  "#0d47a1",
  "#006064",
  "#004d40",
  "#1b5e20",
  "#33691e",
  "#827717",
  "#f57f17",
  "#bf360c",
  "#3e2723",
  "#ef9a9a",
  "#f48fb1",
  "#ce93d8",
  "#b39ddb",
  "#9fa8da",
  "#90caf9",
  "#80deea",
  "#5c6bc0"
];

interface IRadialProps {
  reportParams: any;
}

const intervalFormat = value => {
  let initsec = value * 3600;
  let hours = Math.floor(initsec / 3600);
  let minutes = Math.floor((initsec % 3600) / 60);
  let seconds = Math.round(initsec % 60);

  let sHours = hours.toString();
  let sMinutes = minutes.toString();
  let sSeconds = seconds.toString();

  if (hours < 10) {
    sHours = "0" + sHours;
  }
  if (minutes < 10) {
    sMinutes = "0" + sMinutes;
  }
  if (seconds < 10) {
    sSeconds = "0" + sSeconds;
  }

  return sHours + ":" + sMinutes + ":" + sSeconds;
};

export default function RadialComponent(props: IRadialProps) {
  const dispatch = useDispatch();
  const logtimeReport = useSelector(state => state.reports.outbound.logtime);
  const logtimeReportRaw = useSelector(state => state.reports.outbound.logtimeRaw);
  const callsReport = useSelector(state => state.reports.outbound.calls);

  const [logtimeActiveIndex, setLogtimeActiveIndex] = useState<any>(null);
  const [callsActiveIndex, setCallsActiveIndex] = useState<any>(null);

  useEffect(() => {
    loadOutboundLogtime(dispatch, props.reportParams);
    loadOutboundCalls(dispatch, props.reportParams);
  }, [props.reportParams]);

  useEffect(() => {}, [logtimeReport]);

  const onPieEnterLogtime = (data, index) => {
    setLogtimeActiveIndex(index);
  };

  const onPieEnterCalls = (data, index) => {
    setCallsActiveIndex(index);
  };

  const renderActiveShape = props => {
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius + 15}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  };

  return (
    <Row style={{ paddingBottom: 20 }}>
      <Col>
        <div style={{ textAlign: "center" }}>
          <label>Agent Log Time</label>
        </div>
        {logtimeReport !== null && logtimeReport.length > 0 ? (
          <PieChart width={400} height={400}>
            <Pie
              data={logtimeReport}
              activeIndex={logtimeActiveIndex}
              activeShape={renderActiveShape as any}
              onMouseEnter={onPieEnterLogtime as any}
              onMouseLeave={() => setLogtimeActiveIndex(null)}
              cx="50%"
              cy="50%"
              labelLine={true}
              label={true}
              isAnimationActive={false}
              innerRadius={80}
              outerRadius={130}
              fill="#8884d8"
              dataKey="percent"
            >
              {logtimeReport.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        ) : null}
      </Col>
      <Col>
        <Row style={{ height: "100%" }}>
          <Col>
            {logtimeReport !== null && logtimeReport.length > 0
              ? logtimeReport.map((entry, index) => {
                  const color = COLORS[index % COLORS.length];
                  let isHighlighted = false;
                  if (index === logtimeActiveIndex) {
                    isHighlighted = true;
                  }

                  return (
                    <div key={`pre-${index}`} style={{ fontWeight: isHighlighted ? "bold" : "normal" }}>
                      <div
                        style={{
                          display: "inline-block",
                          width: 13,
                          height: 13,
                          backgroundColor: color
                        }}
                      ></div>
                      <div
                        style={{
                          display: "inline-block",
                          width: 55,
                          paddingRight: 3,
                          textAlign: "right"
                        }}
                      >
                        {entry.percent}%
                      </div>
                      <span
                        onMouseEnter={() => setLogtimeActiveIndex(index)}
                        onMouseLeave={() => setLogtimeActiveIndex(null)}
                      >
                        {entry.agent_status}
                      </span>
                    </div>
                  );
                })
              : null}
          </Col>
          <Col>
            {callsReport !== null && callsReport.length > 0
              ? callsReport.map((entry, index) => {
                  const color = COLORS[index % COLORS.length];
                  let isHighlighted = false;
                  if (index === callsActiveIndex) {
                    isHighlighted = true;
                  }

                  return (
                    <div
                      key={`pre-${index}`}
                      style={{
                        textAlign: "right",
                        fontWeight: isHighlighted ? "bold" : "normal"
                      }}
                    >
                      <span
                        onMouseEnter={() => setCallsActiveIndex(index)}
                        onMouseLeave={() => setCallsActiveIndex(null)}
                      >
                        {entry.call_status}
                      </span>
                      <div
                        style={{
                          display: "inline-block",
                          width: 55,
                          paddingRight: 3,
                          textAlign: "right"
                        }}
                      >
                        {entry.outcome_count}
                      </div>
                      <div
                        style={{
                          display: "inline-block",
                          width: 13,
                          height: 13,
                          backgroundColor: color
                        }}
                      ></div>
                    </div>
                  );
                })
              : null}
          </Col>
        </Row>
      </Col>
      <Col>
        <div style={{ textAlign: "center" }}>
          <label>Call Status</label>
        </div>
        {callsReport !== null && callsReport.length > 0 ? (
          <PieChart width={400} height={400}>
            <Pie
              data={callsReport}
              activeIndex={callsActiveIndex as any}
              activeShape={renderActiveShape as any}
              onMouseEnter={onPieEnterCalls as any}
              onMouseLeave={() => setCallsActiveIndex(null)}
              cx="50%"
              cy="50%"
              isAnimationActive={false}
              labelLine={true}
              label={true}
              innerRadius={80}
              outerRadius={130}
              fill="#8884d8"
              dataKey="percent"
            >
              {callsReport.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        ) : null}
      </Col>
    </Row>
  );
}
