import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import Popover from "react-bootstrap/Popover";
import Overlay from "react-bootstrap/Overlay";

interface WorkersProps {
  directoryId: string;
}

declare var env: any;

export default function WorkersComponent(props: WorkersProps) {
  const [directoryWorkerList, setDirectoryWorkerList] = useState([]);
  const [availableWorkerList, setAvailableWorkerList] = useState([]);
  const [showAddWorker, setShowAddWorker] = useState(false);
  const [selectedWorker, setSelectedWorker] = useState({ value: "", label: "", parameters: [] });
  const [newWorkerParams, setNewWorkerParams] = useState({});
  const [newWorkerState, setNewWorkerState] = useState({ value: 1, label: "Active" });

  const [showEditWorker, setShowEditWorker] = useState(false);
  const [editedWorker, setEditedWorker] = useState({
    contact_directory_worker_id: 0,
    worker_name: "",
    parameters: [],
    active: { value: 1, label: "Active" }
  });

  // Disable Save-button when clicking once
  const [saveDisabled, setSaveDisabled] = useState(false);
  // State for confirmation popover
  const [confirmDeleteTarget, setConfirmDeleteTarget]: any = useState(null);

  const fetchDirectoryWorkerList = async () => {
    const res = await fetch(
      env._apiPath + "/listManagement/contactDirectoryWorkers?contact_directory_id=" + props.directoryId,
      { method: "GET", credentials: "include" }
    );
    const json = await res.json();
    const wrkList: any = [];
    for (const i in json) {
      const o = json[i];
      o.confirmDelete = false;
      wrkList.push(o);
    }
    setDirectoryWorkerList(wrkList);
  };

  const fetchCreateDirectoryWorker = async (worker_id: any, workerParams: any) => {
    const request = {
      contact_directory_id: props.directoryId,
      worker_id,
      ...workerParams
    };

    const res = await fetch(env._apiPath + "/listManagement/contactDirectoryWorker", {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(request)
    });
    const json = await res.json();
    if (json.status === "success") {
      fetchDirectoryWorkerList();
    }
  };

  const fetchUpdateDirectoryWorker = async (contact_directory_worker_id: any, workerParams: any) => {
    const request = {
      contact_directory_worker_id,
      ...workerParams
    };

    const res = await fetch(env._apiPath + "/listManagement/contactDirectoryWorker", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(request)
    });
    const json = await res.json();
    if (json.status === "success") {
      fetchDirectoryWorkerList();
    }
  };

  const fetchRemoveWorker = async (worker_id: any) => {
    const request = {
      contact_directory_worker_id: worker_id
    };

    const res = await fetch(env._apiPath + "/listManagement/contactDirectoryWorker", {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(request)
    });
    const json = await res.json();
    if (json.status === "success") {
      fetchDirectoryWorkerList();
    }
  };

  const fetchToggleWorker = async (worker_id: any, active: any) => {
    const request = {
      contact_directory_worker_id: worker_id,
      active
    };
    const res = await fetch(env._apiPath + "/listManagement/toggleContactDirectoryWorker", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(request)
    });
    const json = await res.json();
    if (json.status === "success") {
      fetchDirectoryWorkerList();
    }
  };

  const fetchAvailableWorkers = async () => {
    const res = await fetch(env._apiPath + "/listManagement/workers", { method: "GET", credentials: "include" });
    const json = await res.json();
    if (json.status === "success") {
      const avWrkLabels: any = [];
      for (const i in json.data) {
        const o = json.data[i];
        avWrkLabels.push({
          value: o.worker_id,
          label: o.worker_name,
          parameters: o.worker_options ? o.worker_options.parameters : null
        });
      }
      setAvailableWorkerList(avWrkLabels);
      fetchDirectoryWorkerList();
    }
  };

  useEffect(() => {
    fetchAvailableWorkers();
  }, []);
  useEffect(() => {
    setSaveDisabled(false);
  }, [showAddWorker]);
  useEffect(() => {
    const params: any = {};
    for (const i in selectedWorker.parameters) {
      const o: any = selectedWorker.parameters[i];
      params[o.parameter] = "";
    }
    setNewWorkerParams(params);
  }, [selectedWorker]);

  const updateNewWorkerParam = (parameter: any, value: any) => {
    const params: any = { ...newWorkerParams };
    params[parameter] = value;
    setNewWorkerParams(params);
  };

  const addNewWorker = () => {
    fetchCreateDirectoryWorker(selectedWorker.value, newWorkerParams);
    setShowAddWorker(false);
  };

  const toggleWorker = (worker: any, idx: any) => {
    const workers: any = [...directoryWorkerList];
    workers[idx].active = workers[idx].active ? 0 : 1;
    setDirectoryWorkerList(workers);
    fetchToggleWorker(worker.contact_directory_worker_id, workers[idx].active);
  };

  const removeWorker = (worker: any, idx: any) => {
    fetchRemoveWorker(worker.contact_directory_worker_id);
    toggleConfirmDeleteVisible(idx);
  };

  const editWorker = (worker: any) => {
    setShowEditWorker(true);
    const paramNames = {};

    availableWorkerList.forEach((wrk: any) => {
      wrk.parameters.forEach((prm: any) => {
        paramNames[prm.parameter] = prm.name;
      });
    });

    const params: any = [];
    for (const pId in worker.worker_options.parameters) {
      const pValue: string = worker.worker_options.parameters[pId];
      const pName = paramNames[pId];

      params.push({
        name: pName,
        parameter: pId,
        value: pValue
      });
    }

    setEditedWorker({
      contact_directory_worker_id: worker.contact_directory_worker_id,
      worker_name: worker.worker_name,
      parameters: params,
      active: worker.active ? { value: 1, label: "Active" } : { value: 0, label: "Inactive" }
    });
  };

  const updateEditedParam = (opt: any, idx: any, value: string) => {
    const edtWrk: any = { ...editedWorker };
    edtWrk.parameters[idx].value = value;
    setEditedWorker(edtWrk);
  };

  const setEditWorkerState = (opt: any) => {
    const edtWrk: any = { ...editedWorker };
    edtWrk.active = opt;
    setEditedWorker(edtWrk);
  };

  const updateEditedWorker = () => {
    const workerParams = {};
    editedWorker.parameters.forEach((prm: any) => {
      workerParams[prm.parameter] = prm.value;
    });

    fetchUpdateDirectoryWorker(editedWorker.contact_directory_worker_id, workerParams);
    fetchToggleWorker(editedWorker.contact_directory_worker_id, editedWorker.active.value);
    setShowEditWorker(false);
  };

  const toggleConfirmDeleteVisible = (idx: any) => {
    const workers: any = [...directoryWorkerList];
    workers[idx].confirmDelete = !workers[idx].confirmDelete;
    setDirectoryWorkerList(workers);
  };

  const renderAddWorkerDialog = () => {
    return (
      <Modal show={showAddWorker} size="lg" centered onHide={() => setShowAddWorker(false)}>
        <Modal.Header>
          <Modal.Title>Add Worker to Directory</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={9}>
              <Form.Group controlId="frmWorkerList">
                <Form.Label>Worker</Form.Label>
                <Select
                  value={selectedWorker}
                  onChange={(opt: any) => setSelectedWorker(opt)}
                  options={availableWorkerList}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="frmWorkerActive">
                <Form.Label>Status</Form.Label>
                <Select
                  value={newWorkerState}
                  onChange={(opt: any) => setNewWorkerState(opt)}
                  options={[
                    { value: 0, label: "Inactive" },
                    { value: 1, label: "Active" }
                  ]}
                />
              </Form.Group>
            </Col>
          </Row>
          {selectedWorker.parameters &&
            selectedWorker.parameters.map((opt: any, k: any) => {
              if (newWorkerParams[opt.parameter] === undefined) {
                return null;
              }

              return (
                <Row key={k}>
                  <Col>
                    <Form.Group controlId={"frmWrkParam" + opt.parameter}>
                      <Form.Label>{opt.name}</Form.Label>
                      <Form.Control
                        value={newWorkerParams[opt.parameter]}
                        onChange={e => updateNewWorkerParam(opt.parameter, e.target.value)}
                        type="text"
                      />
                    </Form.Group>
                  </Col>
                </Row>
              );
            })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={e => setShowAddWorker(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={e => {
              addNewWorker();
              setSaveDisabled(true);
            }}
            disabled={saveDisabled}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderEditWorkerDialog = () => {
    return (
      <Modal show={showEditWorker} size="lg" centered onHide={() => setShowEditWorker(false)}>
        <Modal.Header>
          <Modal.Title>Edit Worker</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={9}>
              <Form.Group controlId="frmWorkerType">
                <Form.Label>Worker</Form.Label>
                <div>{editedWorker.worker_name}</div>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="frmWorkerEditActive">
                <Form.Label>Status</Form.Label>
                <Select
                  value={editedWorker.active}
                  onChange={(opt: any) => setEditWorkerState(opt)}
                  options={[
                    { value: 0, label: "Inactive" },
                    { value: 1, label: "Active" }
                  ]}
                />
              </Form.Group>
            </Col>
          </Row>
          {editedWorker.parameters.map((opt: any, k: any) => {
            return (
              <Row key={k}>
                <Col>
                  <Form.Group controlId={"frmWrkParam" + opt.parameter}>
                    <Form.Label>{opt.name}</Form.Label>
                    <Form.Control
                      value={opt.value}
                      onChange={(e: any) => updateEditedParam(opt, k, e.target.value)}
                      type="text"
                    />
                  </Form.Group>
                </Col>
              </Row>
            );
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={e => setShowEditWorker(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={e => {
              updateEditedWorker();
              setSaveDisabled(true);
            }}
            disabled={saveDisabled}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div className="directory-workers">
      <Row>
        <Col md={6}>
          <h6>Workers</h6>
        </Col>
        <Col md={6} className="toolbar">
          <Button size="sm" variant="success" onClick={() => setShowAddWorker(true)}>
            <i className="fa fa-plus-circle"></i>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListGroup className="list-group-horizontal">
            {directoryWorkerList.map((worker: any, idx: any) => {
              return (
                <ListGroup.Item key={idx}>
                  <img src="/assets/img/flat-icons/server-1.png" />
                  {worker.worker_name}

                  <span className="worker-remove">
                    <i
                      onClick={e => {
                        setConfirmDeleteTarget(e.target);
                        toggleConfirmDeleteVisible(idx);
                      }}
                      className="feather feather-trash"
                    ></i>
                    <Overlay show={worker.confirmDelete} target={confirmDeleteTarget}>
                      <Popover id={"popover-confirm-" + idx}>
                        <Popover.Title as="h3">Confirm?</Popover.Title>
                        <Popover.Content>
                          <Row>
                            <Col>
                              <Button size="sm" variant="danger" onClick={e => removeWorker(worker, idx)}>
                                Delete
                              </Button>
                            </Col>
                            <Col>
                              <Button size="sm" variant="secondary" onClick={e => toggleConfirmDeleteVisible(idx)}>
                                Cancel
                              </Button>
                            </Col>
                          </Row>
                        </Popover.Content>
                      </Popover>
                    </Overlay>
                  </span>

                  <span className="worker-edit">
                    <i
                      onClick={() => {
                        editWorker(worker);
                      }}
                      className="feather feather-edit"
                    ></i>
                  </span>

                  <span className="worker-status" onClick={() => toggleWorker(worker, idx)}>
                    {worker.active ? "Active" : "Inactive"}
                  </span>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
          {directoryWorkerList.length === 0 ? <div>No workers assigned.</div> : null}
        </Col>
      </Row>

      {renderAddWorkerDialog()}
      {renderEditWorkerDialog()}
    </div>
  );
}
