import React, { useEffect, useState } from "react";
import { Map, TileLayer, Polygon, FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import userSettings from "../../../userSettings";
declare var env: any;

const mapBoxUrl =
  "https://api.mapbox.com/styles/v1/mapbox/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibW1nc2t5IiwiYSI6ImNqbXUzdGxmNTAxZGQzd28yNzk4OGs5NnQifQ.ilOJoTnkHzf2s2r0cWJ--w";

interface IMapProps {
  highlightPolygonId: string[];
  onSelectBounds?: any | null;
  shadowAreaIDs?: string[];
}

export default function MapComponent(props: IMapProps) {
  const defaultPos = userSettings.getOption(userSettings.MAPS_DEFAULT_POS, {
    lat: 58.12631,
    lng: 7.94706
  });
  const [position, setPosition] = useState([defaultPos.lat, defaultPos.lng]);
  const [selectedPolygons, setSelectedPolygons] = useState<[]>([]);
  const [shadowedPolygons, setShadowedPolygons] = useState<[]>([]);
  const [showPoly, setShowPoly] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(10);

  const fetchPolygonArea = async (areaIDs: string[] | null, forShadow: boolean) => {
    if (!areaIDs) {
      return;
    }

    const req = {
      area_id: areaIDs
    };
    const res = await fetch(env._apiPath + "/listManagement/mapsgetarea", {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(req),
      headers: { "Content-Type": "application/json" }
    });
    const json = await res.json();
    if (json.status !== "success") {
      return;
    }

    if (forShadow) {
      const polygonsDetailed = json.data.map(poly => JSON.parse(poly.polygon_detailed));
      setShadowedPolygons(polygonsDetailed);
      setShowPoly(true);
      return;
    }

    if (json.data.length == 0) {
      setSelectedPolygons([]);
      return;
    }

    let pd = json.data;
    if (pd.lng) {
      pd = [json.data];
    }

    const polygonsDetailed = pd.map(poly => JSON.parse(poly.polygon_detailed));
    setSelectedPolygons(polygonsDetailed);
    setShowPoly(true);

    if (polygonsDetailed.length === 1) {
      const latlng = polygonsDetailed[0][0][0];
      setPosition(latlng);
      return;
    }
  };

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
    fetchPolygonArea(props.highlightPolygonId, false);
  }, [props.highlightPolygonId]);

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
    if (props.shadowAreaIDs) {
      const shadowIds = Object.assign([], props.shadowAreaIDs);
      fetchPolygonArea(shadowIds, true);
    }
  }, [props.shadowAreaIDs]);

  const onZoomEnd = (zoomEvent: any) => {
    setZoomLevel(zoomEvent.target._zoom);
  };

  const onDrawStart = (e: any) => {
    e.target.eachLayer((l: any) => {
      if (l.options.name && l.options.name === "area-selection") {
        l.remove();
      }
    });

    if (props.onSelectBounds) {
      props.onSelectBounds(null);
    }
  };

  const onCreated = (e: any) => {
    const b = e.layer.getBounds();
    e.layer.options.name = "area-selection";
    const bounds = {
      northWest: b.getNorthWest(),
      northEast: b.getNorthEast(),
      southWest: b.getSouthWest(),
      southEast: b.getSouthEast()
    };

    if (props.onSelectBounds) {
      props.onSelectBounds(bounds);
    }
  };

  const onDeleted = (e: any) => {
    if (props.onSelectBounds) {
      props.onSelectBounds(null);
    }
  }

  const onMoveEnd = (e: any) => {
    userSettings.setOption(userSettings.MAPS_DEFAULT_POS, e.target.getCenter());
  };

  return (
    <Map center={position} zoom={zoomLevel} onZoomend={onZoomEnd} zoomControl={true} onMoveEnd={onMoveEnd}>
      <TileLayer id="streets-v11" url={mapBoxUrl} attribution={""} />

      {showPoly
        ? selectedPolygons.map((selectedPolygon, idx) => (
            <Polygon key={idx} color="purple" positions={selectedPolygon} />
          ))
        : null}

      {showPoly
        ? shadowedPolygons.map((shadowedPolygon, idx) => <Polygon key={idx} color="gray" positions={shadowedPolygon} />)
        : null}

      {props.onSelectBounds ? (
        <FeatureGroup>
          <EditControl
            position="topleft"
            onDrawStart={onDrawStart}
            onCreated={onCreated}
            onDeleted={onDeleted}
            // edit={{
            //   edit: false,
            //   remove: false
            // }}
            draw={{
              rectangle: true,
              circle: false,
              marker: false,
              circlemarker: false,
              polygon: false,
              polyline: false
            }}
          />
        </FeatureGroup>
      ) : null}
    </Map>
  );
}
