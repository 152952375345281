import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardActions,
  makeStyles,
  Theme,
  useTheme,
  createStyles,
  Grid,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Input,
  FormHelperText,
  Tabs,
  Tab,
  Typography,
  Toolbar,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import {
  GridApi,
  ColumnApi,
  GridReadyEvent,
  FirstDataRenderedEvent,
  Column,
  RowDoubleClickedEvent,
  SelectionChangedEvent,
} from "ag-grid-community";
import { AgToolbarButton } from "../../../agGridUtils";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { useSelector, useDispatch } from "react-redux";
import { getCategories, removeCategory } from "./categories.api";
import {
  actionReceivedCategories,
  actionReceivedFormData,
  actionReceivedDiscounts,
} from "../../../redux/reducers/campaignmanagement/forms";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { getFormData } from "./edit.api";
import { IDiscountData, IDiscountProps, ICategoryData, ICampaignData } from "./form.types";
import {
  getDiscounts,
  addDiscount,
  removeDiscount,
  editDiscount,
  addDiscountCondition,
  deleteDiscountCondition,
} from "./discount.api";
import { getCampaigns } from "./campaigns.api";
import ConditionsComponent from "./tabdiscount/conditions.component";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexGrow: {
      flexGrow: 1,
    },
    actions: {
      "& button.aggrid-btn": {
        //marginTop: 24
      },
    },
    agGrid: {
      display: "flex",
      flexDirection: "column",
      "& .aggrid-size": {
        minHeight: 500,
        //height: 500, //"calc(100vh - 460px)",
        flex: 1,
        width: "100%",
      },
    },
    dialogPaper: { overflow: "visible" },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
  })
);

const toolbarProps = {
  title: "",
  rightAlign: false,
  disabled: false,
  icon: "",
  onClick: () => {},
};

const getStyles = (category: ICategoryData, newDiscountConditions, theme) => {
  return {
    fontWeight:
      newDiscountConditions.indexOf(category.category_id) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

export default function TabDiscountComponent(props: IDiscountProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  const rowData = useSelector((state) => state.forms.edit.discounts);
  const formData = useSelector((state) => state.forms.edit.formData);

  const [gridAPI, setGridAPI] = useState<GridApi>();
  const [columnAPI, setColumnAPI] = useState<ColumnApi>();
  const [selectedRow, setSelectedRow] = useState<IDiscountData | null>(null);
  const [showRemoveDiscount, setShowRemoveDiscount] = useState(false);
  const [showAddEditDiscount, setShowAddEditDiscount] = useState(false);

  const [newDiscountId, setNewDiscountId] = useState(0);
  const [newDiscountName, setNewDiscountName] = useState("");
  const [newDiscountRefId, setNewDiscountRefId] = useState("");
  const [newDiscountType, setNewDiscountType] = useState("percent");
  const [newDiscountPrice, setNewDiscountPrice] = useState("");
  const [newDiscountPercent, setNewDiscountPercent] = useState("");
  const [newDiscountCampaign, setNewDiscountCampaign] = useState<string>("0");
  const [newDiscountAutomaticRank, setNewDiscountAutomaticRank] = useState("");
  const [newConditionAmount, setNewConditionAmount] = useState("");
  const [newDiscountLevel, setNewDiscountLevel] = useState("");
  const [newSMSText, setNewSMSText] = useState("");
  const [newDiscountDateFrom, setNewDiscountDateFrom] = useState<Date | null>(null);
  const [newDiscountDateTo, setNewDiscountDateTo] = useState<Date | null>(null);

  const [newDiscountConditions, setNewDiscountConditions] = useState<number[]>([]);
  const [newDiscountProductConditions, setNewDiscountProductConditions] = useState<number[]>([]);
  const [newDiscountTargets, setNewDiscountTargets] = useState<number[]>([]);

  const [formDiscountConditions, setFormDiscountConditions] = useState([]);
  const [formDiscountTargets, setFormDiscountTargets] = useState([]);
  const [formDiscountCampaigns, setFormDiscountCampaigns] = useState([]);

  const [newDiscountErrorMessage, setNewDiscountErrorMessage] = useState<string | null>(null);

  const [discountTabValue, setDiscountTabValue] = useState<string | number>(0);

  useEffect(() => {
    getDiscounts(props.formId)
      .then((discounts: IDiscountData[]) => dispatch(actionReceivedDiscounts(discounts)))
      .catch(console.log);

    getCampaigns(props.formId)
      .then((campaigns) => setFormDiscountCampaigns(campaigns))
      .catch(console.log);
  }, [props.formId, formData.form_id, dispatch]);

  const columnDefs = [
    { headerName: "Name", field: "name" },
    { headerName: "Discount Type", field: "discount_type" },
    { headerName: "Ref. Id", field: "ref_id" },
    {
      headerName: "Discount %",
      field: "discount_percent",
      valueFormatter: function (params) {
        if (params.data) return params.value + "%";
      },
    },
    { headerName: "Discount Price", field: "discount_price" },
    { headerName: "Per Product", field: "per_product", valueFormatter: (p) => (p.value === "1" ? "Yes" : "No") },
    { headerName: "Calculate", field: "calculate", valueFormatter: (p) => (p.value === "1" ? "Yes" : "No") },
    { headerName: "Automatic", field: "automatic", valueFormatter: (p) => (p.value === "1" ? "Yes" : "No") },
    { headerName: "Automatic rank", field: "automatic_rank" },
    { headerName: "Condition Amount", field: "condition_amount" },
    { headerName: "Level", field: "discount_level" },
    {
      headerName: "Created",
      field: "created_at",
      valueFormatter: function (params) {
        //return mod_ag.numberFormat.dateFormat(params, "timestamp");
      },
    },
    {
      headerName: "Updated",
      field: "updated_at",
      valueFormatter: function (params) {
        //return mod_ag.numberFormat.dateFormat(params, "timestamp");
      },
    },
    /*
    {
      headerName: "Betingelser/Mål",
      cellRenderer: function (params) {
        if (params.data) {
          //offerView.form_discount_conditions[params.data.form_discount_id] = params.data.form_discount_conditions;
          //offerView.form_discount_targets[params.data.form_discount_id] = params.data.form_discount_targets;

          return '<a href="javascript:conditions(' + params.data.form_discount_id + ');" >Se/Endre</a>';
        }
      },
    },
    */
  ];

  const onGridReady = (event: GridReadyEvent) => {
    window.addEventListener("resize", () => setTimeout(() => event.api.sizeColumnsToFit()));
    event.api.sizeColumnsToFit();
    setGridAPI(event.api);
    setColumnAPI(event.columnApi);
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    event.api.sizeColumnsToFit();
  };

  const onAdjustColumnsContent = (event: Event) => {
    var allColumnIds: string[] = [];
    columnAPI!.getAllColumns().forEach((column: Column) => {
      if (!column.getColDef().suppressAutoSize) {
        allColumnIds.push(column.getColId());
      }
    });
    columnAPI!.autoSizeColumns(allColumnIds);
  };

  const onAdjustColumnsWindow = (event: Event) => {
    gridAPI!.sizeColumnsToFit();
  };

  const onRefreshList = (event: Event) => {
    getDiscounts(props.formId)
      .then((discounts: IDiscountData[]) => dispatch(actionReceivedDiscounts(discounts)))
      .catch(console.log);
  };

  const onRowDoubleClicked = (event: RowDoubleClickedEvent) => {};

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const rows = event.api.getSelectedRows();
    if (!rows[0]) {
      return;
    }

    setSelectedRow(rows[0]);
  };

  const onRemoveDiscount = () => {
    removeDiscount(selectedRow!.form_discount_id)
      .then(() => {
        getDiscounts(props.formId)
          .then((discounts: IDiscountData[]) => dispatch(actionReceivedDiscounts(discounts)))
          .catch(console.log);
      })
      .catch(console.log);
    setShowRemoveDiscount(false);
  };

  const onAddDiscount = () => {
    if (newDiscountId !== 0) {
      editDiscount(
        newDiscountId,
        newDiscountName,
        newDiscountRefId,
        newDiscountType,
        newDiscountPrice,
        newDiscountPercent,
        newDiscountCampaign,
        newDiscountAutomaticRank,
        newConditionAmount,
        newDiscountLevel,
        "1",
        "1",
        newSMSText,
        newDiscountDateFrom,
        newDiscountDateTo
      )
        .then(() => {
          getDiscounts(props.formId)
            .then((discounts: IDiscountData[]) => dispatch(actionReceivedDiscounts(discounts)))
            .catch(console.log);
          setShowAddEditDiscount(false);
        })
        .catch((e) => setNewDiscountErrorMessage(e));
      return;
    }

    addDiscount(
      props.formId,
      newDiscountName,
      newDiscountRefId,
      newDiscountType,
      newDiscountPrice,
      newDiscountPercent,
      newDiscountCampaign,
      newDiscountAutomaticRank,
      newConditionAmount,
      newDiscountLevel,
      "1",
      "1",
      newSMSText,
      newDiscountDateFrom,
      newDiscountDateTo
    )
      .then(() => {
        getDiscounts(props.formId)
          .then((discounts: IDiscountData[]) => dispatch(actionReceivedDiscounts(discounts)))
          .catch(console.log);
        setShowAddEditDiscount(false);
      })
      .catch((e) => setNewDiscountErrorMessage(e));
  };

  const onOpenAddDiscount = () => {
    setNewDiscountId(0);
    setNewDiscountName("");
    setNewDiscountRefId("");
    setNewDiscountType("percent");
    setNewDiscountPrice("");
    setNewDiscountPercent("");
    setNewDiscountAutomaticRank("");
    setNewConditionAmount("");
    setNewDiscountLevel("");
    setNewSMSText("");
    setNewDiscountDateFrom(null);
    setNewDiscountDateTo(null);

    setShowAddEditDiscount(true);
    setNewDiscountErrorMessage("");
  };

  const onOpenEditDiscount = () => {
    setNewDiscountId(selectedRow!.form_discount_id);
    setNewDiscountName(selectedRow!.name);
    setNewDiscountRefId(selectedRow!.ref_id);
    setNewDiscountType(selectedRow!.discount_type);
    setNewDiscountPrice(selectedRow!.discount_price);
    setNewDiscountPercent(selectedRow!.discount_percent);
    setNewDiscountCampaign(selectedRow!.campaign_id ? selectedRow!.campaign_id : "0");
    setNewDiscountAutomaticRank(selectedRow!.automatic_rank);
    setNewConditionAmount(selectedRow!.condition_amount);
    setNewDiscountLevel(selectedRow!.discount_level);
    setNewSMSText(selectedRow!.sms_text);

    // TODO: conditions og targets må holde på id'en sin, ikke bare kategori-id.
    // Disse kan også bruke produkter, så må ha spesialhåndtering.
    const selectedConditions: number[] = selectedRow!.form_discount_conditions.map((c: any) =>
      parseInt(c.form_category_id)
    );
    setNewDiscountConditions(selectedConditions);

    const selectedTargets: number[] = selectedRow!.form_discount_targets.map((c: any) => parseInt(c.form_category_id));
    setNewDiscountTargets(selectedTargets);

    if (selectedRow!.valid_from !== null) {
      const validFrom = new Date(selectedRow!.valid_from);
      setNewDiscountDateFrom(validFrom);
    }

    if (selectedRow!.valid_to !== null) {
      const validTo = new Date(selectedRow!.valid_to);
      setNewDiscountDateTo(validTo);
    }

    setShowAddEditDiscount(true);
    setNewDiscountErrorMessage("");
  };

  const onChangeDiscountCondition = (event) => {
    const removeConditions = newDiscountConditions.filter((item) => event.target.value.indexOf(item) < 0);
    const addConditions = event.target.value.filter((item) => newDiscountConditions.indexOf(item) < 0);

    console.log("cond", removeConditions);

    //removeConditions.forEach(id => deleteDiscountCondition());
    //addConditions.forEach(id => addDiscountCondition());

    setNewDiscountConditions(event.target.value);
  };

  const onChangeDiscountTargets = (event) => {
    setNewDiscountTargets(event.target.value);
  };

  return (
    <Card>
      <CardActions disableSpacing className={classes.actions}>
        {AgToolbarButton({
          ...toolbarProps,
          title: "Add Discount",
          icon: "fa-plus",
          onClick: onOpenAddDiscount,
        })}

        {AgToolbarButton({
          ...toolbarProps,
          title: "Edit Discount",
          icon: "fa-edit",
          onClick: onOpenEditDiscount,
          disabled: selectedRow === null,
        })}

        {AgToolbarButton({
          ...toolbarProps,
          title: "Remove Discount",
          icon: "fa-trash",
          onClick: () => setShowRemoveDiscount(true),
          disabled: selectedRow === null,
        })}

        <div className={classes.flexGrow} />

        {AgToolbarButton({
          ...toolbarProps,
          title: "Refresh",
          icon: "fa-sync-alt",
          onClick: onRefreshList,
        })}

        {AgToolbarButton({
          ...toolbarProps,
          title: "Adjust columns to window",
          icon: "fa-columns",
          onClick: onAdjustColumnsWindow,
        })}

        {AgToolbarButton({
          ...toolbarProps,
          title: "Adjust columns to content",
          icon: "fa-text-width",
          onClick: onAdjustColumnsContent,
        })}
      </CardActions>
      <CardContent>
        <Grid container>
          <Grid item md={12} className={classes.agGrid}>
            <div className="ag-theme-balham aggrid-size">
              <AgGridReact
                columnDefs={columnDefs}
                rowData={rowData}
                groupIncludeTotalFooter={false}
                groupIncludeFooter={false}
                groupMultiAutoColumn={true}
                animateRows={true}
                enableRangeSelection={false}
                onSelectionChanged={onSelectionChanged}
                rowSelection="multiple"
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                onRowDoubleClicked={onRowDoubleClicked}
                sideBar={{
                  toolPanels: ["columns", "filters"],
                }}
                suppressAggFuncInHeader={true}
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  filter: true,
                }}
                getRowClass={(params) => {
                  if (params.data.used_in_campaign == 1) {
                    return "bg-success";
                  }
                }}
              />
            </div>
          </Grid>
        </Grid>

        <Dialog
          open={showRemoveDiscount}
          onClose={() => setShowRemoveDiscount(false)}
          aria-labelledby="alert-discountremove-title"
          aria-describedby="alert-discountremove-description"
        >
          <DialogTitle id="alert-discountremove-title">Remove Discount</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-discountremove-description">
              Are you sure you want to remove this discount?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowRemoveDiscount(false)} color="secondary" autoFocus>
              No
            </Button>
            <Button onClick={onRemoveDiscount} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={showAddEditDiscount}
          onClose={() => setShowAddEditDiscount(false)}
          aria-labelledby="alert-discountadd-title"
          aria-describedby="alert-discountadd-description"
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle id="alert-discountadd-title">{newDiscountId === 0 ? "Add" : "Edit"} Discount</DialogTitle>
          <DialogContent>
            <Tabs value={discountTabValue} onChange={(ev, nv) => setDiscountTabValue(nv)} centered>
              <Tab label="Discount" />
              <Tab label="Conditions" />
            </Tabs>
            <TabPanel value={discountTabValue} index={0}>
              <Grid container spacing={2}>
                <Grid item md={3}>
                  <TextField
                    label="Name"
                    type="text"
                    fullWidth
                    value={newDiscountName}
                    onChange={(e) => setNewDiscountName(e.target.value)}
                  />
                </Grid>
                <Grid item md={2}>
                  <TextField
                    label="Ref. Id"
                    type="text"
                    fullWidth
                    value={newDiscountRefId}
                    onChange={(e) => setNewDiscountRefId(e.target.value)}
                    helperText="Identifier used in API-transfers"
                  />
                </Grid>
                <Grid item md={3}>
                  <FormControl fullWidth>
                    <InputLabel id="drop-campaign">Campaign</InputLabel>
                    <Select
                      id="drop-campaign"
                      fullWidth
                      value={newDiscountCampaign}
                      onChange={(e) => setNewDiscountCampaign(e.target.value as string)}
                    >
                      <MenuItem value="0">None</MenuItem>
                      {formDiscountCampaigns.map((campaign: ICampaignData, key) => (
                        <MenuItem key={key} value={campaign.campaign_id}>
                          {campaign.campaign_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item md={2}>
                    <KeyboardDatePicker
                      variant="inline"
                      id="date-picker-end"
                      label="Date From"
                      value={newDiscountDateFrom}
                      onChange={(date: any) => setNewDiscountDateFrom(date)}
                      onError={console.log}
                      format="dd.MM.yyyy"
                      fullWidth
                      helperText="Enable discount from"
                    />
                  </Grid>
                  <Grid item md={2}>
                    <KeyboardDatePicker
                      variant="inline"
                      id="date-picker-end"
                      label="Date To"
                      value={newDiscountDateTo}
                      onChange={(date: any) => setNewDiscountDateTo(date)}
                      onError={console.log}
                      format="dd.MM.yyyy"
                      fullWidth
                      helperText="Disable discount after"
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: 25 }}>
                <Grid item md={2}>
                  <FormControl fullWidth>
                    <InputLabel id="drop-type">Type</InputLabel>
                    <Select
                      id="drop-type"
                      fullWidth
                      value={newDiscountType}
                      onChange={(e) => setNewDiscountType(e.target.value as string)}
                    >
                      <MenuItem value="price">Price</MenuItem>
                      <MenuItem value="percent">% Percent</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={2}>
                  {newDiscountType === "price" ? (
                    <TextField
                      label="Discount Price"
                      type="text"
                      fullWidth
                      value={newDiscountPrice}
                      onChange={(e) => setNewDiscountPrice(e.target.value)}
                    />
                  ) : (
                    <TextField
                      label="Discount %"
                      type="text"
                      fullWidth
                      value={newDiscountPercent}
                      onChange={(e) => setNewDiscountPercent(e.target.value)}
                    />
                  )}
                </Grid>
                <Grid item md={8}>
                  <TextField
                    label="SMS Text"
                    type="text"
                    fullWidth
                    value={newSMSText}
                    onChange={(e) => setNewSMSText(e.target.value)}
                    helperText="Text to be available as a merge field in confirmation"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: 25 }}>
                <Grid item md={6}>
                  <TextField
                    label="Automatic Rank"
                    type="text"
                    fullWidth
                    value={newDiscountAutomaticRank}
                    onChange={(e) => setNewDiscountAutomaticRank(e.target.value)}
                    helperText="Priority when having multiple discounts with the same amount"
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    label="Level"
                    type="text"
                    fullWidth
                    value={newDiscountLevel}
                    onChange={(e) => setNewDiscountLevel(e.target.value)}
                    helperText="Enables multiple discounts when using different levels"
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={discountTabValue} index={1}>
              <ConditionsComponent formId={props.formId}/>
            </TabPanel>
          </DialogContent>
          <DialogContentText>{newDiscountErrorMessage !== null ? newDiscountErrorMessage : null}</DialogContentText>
          <DialogActions>
            <Button
              onClick={() => {
                setShowAddEditDiscount(false);
                setNewDiscountId(0);
              }}
              color="secondary"
              autoFocus
            >
              Cancel
            </Button>
            <Button onClick={onAddDiscount} color="primary">
              {newDiscountId === 0 ? "Add" : "Save"}
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
}
