import React from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

/*
const columnDefs = [
	{
		headerName: "Directory",
		field: "directory",
		cellRenderer: AgLinkRenderer
	},
	{headerName: "Company", field: "company"},
	{headerName: "Client", field: "client"},
	{headerName: "Campaign Type", field: "campaignType"},
	{headerName: "Last Updated By", field: "last_updated_by"},
	{headerName: "Contacts", field: "contacts"},
]

const rowData = [
	{
		directory: {
			contact_directory_id: 201,
			contact_directory_name: "Demo"
		},
		company: "Demo Company",
		client: "Client",
		campaignType: "Sales",
		last_updated_by: "hn",
		contacts: 2011
	}
]
*/

export default function DirectoryOverviewComponent () {
	/*
	const onGridReady = (event: GridReadyEvent) => {
		event.api.sizeColumnsToFit();
		window.addEventListener("resize", () => setTimeout(() => event.api.sizeColumnsToFit()));
		event.api.sizeColumnsToFit();
	}
	*/

	return (
		<div>
			Select client to continue.
			{/*
			<div
				className="ag-theme-balham"
				style={{height: '500px', width: '100%'}}>

				<AgGridReact
					columnDefs={columnDefs}
					rowData={rowData}
					rowSelection=""
					suppressContextMenu={true}
					groupIncludeTotalFooter={false}
					suppressAutoSize={true}
					onGridReady={onGridReady}/>
			</div>
			*/}
		</div>
	)
}
