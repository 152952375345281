import React, { useEffect } from 'react';
import { BrowserRouter as Router} from "react-router-dom";
import {LicenseManager} from "ag-grid-enterprise";
import { useSelector, useDispatch } from 'react-redux'
import Alert from 'react-bootstrap/Alert';

import { actionShellUnsetError } from './redux/reducers/shell'

// import 'bootstrap/dist/css/bootstrap.min.css';
// import MainComponent from './manager/main.component';

import './app.scss'
import RoutesComponent from './routes';
import { loadUserInfo } from './modules/shell/shell.api';


// TODO: Fix proper license for AGGRID
// LicenseManager.setLicenseKey("Evaluation_License_Valid_Until__26_October_2019__MTU3MjA5MzA1ODAwMA==2cef4a00b37db59397dd5565b8dd4e67");
LicenseManager.setLicenseKey("daW8_zae_sIcie9ro5Keile2takaiQu2sueeng3Tah__MTYwOTM2OTIwMDAwMA==6d19b22e02683b1408a5266b1144868e")
const App: React.FC = () => {
  /*
  return (
    <div className="App">
      <MainComponent />
    </div>
  );
  */

  const shellError = useSelector(state => state.shell.error)
  const dispatch = useDispatch()

  // TODO: Perhaps move this to some login/session-handler?
  useEffect(() => {
    loadUserInfo(dispatch)
  }, [dispatch])

  return (
    <div className="App">
      <Router>
        <RoutesComponent />
      </Router>

      {shellError.show
        ? <div className='manager-error'>
            <Alert variant={shellError.variant} dismissible onClose={() => dispatch(actionShellUnsetError())}>
              <Alert.Heading>{shellError.title}</Alert.Heading>
              <p>{shellError.message}</p>
            </Alert>
          </div>
        : null}
    </div>
  );
}

export default App;
