export interface IUser {
  user_id: string;
  email: string;
  fullname: string;
  user_type: string;
  customer: string;
  customer_id: string;
  mobile: string;
  location: string;
  title: string;
  last_seen: string;
  profile_picture: string;
  client_access: string;
  company_access: string;
  department_access: string;
  agent: string;
  clients: string;
  companies: string;
  departments: string;
  is_admin: string;
}

let defaultStartDate = new Date();
defaultStartDate.setDate(1);

let defaultEndDate = new Date();

const initialState = {
  apiRunning: 0,
  menuDrawerOpen: false,

  loggedIn: null,
  user: {
    user_id: "",
    email: "",
    fullname: "",
    user_type: "",
    customer: "",
    customer_id: "",
    mobile: "",
    location: "",
    title: "",
    last_seen: "",
    profile_picture: "",
    client_access: "",
    company_access: "",
    department_access: "",
    agent: "",
    clients: "",
    companies: "",
    departments: "",
    is_admin: "",
    groups: null,
  },

  // Shell data
  modules: [],
  currentCustomer: {},
  customers: [],
  orgUsers: [],
  selectedModule: {},
  shelltype: process.env.REACT_APP_SHELL_TYPE,

  // Client selection in forms or reports
  clients: [],
  selectedClient: null,

  // Company selection in forms or reports
  companies: [],
  selectedCompany: null,

  // Campaign Type selection in forms or reports
  campaignTypes: [],
  selectedCampaignType: null,

  // Campaign selection in forms or reports
  campaigns: [],
  selectedCampaign: null,

  // Department selection in forms or reports
  departments: [],
  adminDepartments: [],
  selectedDepartment: null,
  selectedAdminDepartment: null,

  // Team selection in forms or reports
  teams: [],
  selectedTeam: null,

  // Customer platform selection in forms or reports
  customerPlatforms: [],
  selectedCustomerPlatform: null,

  // Agent selection in forms or reports
  agents: [],
  selectedAgent: null,

  // Logs for settings - userlog
  //logs: [],
  selectedLogUser: null,
  selectedLogType: null,
  receivedLogTypes: [],
  //selectedLogs: null,
  //selectedLogUser: null,

  // Order Type selection in forms or reports
  orderTypes: [],
  selectedOrderType: null,

  // Date selection in forms or reports
  selectedStartDate: defaultStartDate,
  selectedEndDate: defaultEndDate,

  // Global setting for hiding or showing filters in modules
  hideFilters: false,

  // Global settings for hiding or showing advanced filters in modules
  hideAdvancedFilters: true,

  // Notifications
  notification: {
    message: "",
    variant: null,
    show: false
  },

  // Global error message
  error: {
    show: false,
    variant: "",
    title: "",
    message: "",
  },

  snackbar: {
    open: false,
    message: "",
    severity: "warning",
  },

  legacy: {
    html: null,
  },
  titlebar: {
    title: "",
    breadcrumbs: [],
  },
  account: {
    updatePasswordFailed: false,
    updatePasswordSuccess: false,
  },
};

export const SHELL_API_START = "@sky/SHELL_API_START";
export const actionStartAPI = () => ({ type: SHELL_API_START });
export const SHELL_API_END = "@sky/SHELL_API_END";
export const actionEndAPI = () => ({ type: SHELL_API_END });
export const SHELL_API_STOP = "@sky/SHELL_API_STOP";
export const actionStopAPI = () => ({ type: SHELL_API_STOP });

export const SHELL_RECEIVED_USERINFO = "@sky/SHELL_RECEIVED_USERINFO";
export const actionReceivedUserInfo = (userinfo: any) => ({
  type: SHELL_RECEIVED_USERINFO,
  userinfo,
});

export const SHELL_RECEIVED_ORG_USERS = "@sky/SHELL_RECEIVED_ORG_USERS";
export const actionReceivedOrgUsers = (users: any) => ({
  type: SHELL_RECEIVED_ORG_USERS,
  users,
});

export const SHELL_RECEIVED_MODULES = "@sky/SHELL_RECEIVED_MODULES";
export const actionReceivedModules = (modules: any) => ({
  type: SHELL_RECEIVED_MODULES,
  modules,
});

export const SHELL_SELECT_MODULE = "@sky/SHELL_SELECT_MODULE";
export const actionSelectModule = (module: Module) => ({
  type: SHELL_SELECT_MODULE,
  module,
});

export const SHELL_RECEIVED_CLIENTS = "@sky/SHELL_RECEIVED_CLIENTS";
export const actionReceivedClients = (clients: any) => ({
  type: SHELL_RECEIVED_CLIENTS,
  clients,
});
export const SHELL_SELECT_CLIENT = "@sky/SHELL_SELECT_CLIENT";
export const actionSelectClient = (client: any) => ({
  type: SHELL_SELECT_CLIENT,
  client,
});

export const SHELL_RECEIVED_CURRENT_CUSTOMER = "@sky/SHELL_RECEIVED_CURRENT_CUSTOMER";
export const actionReceivedCurrentCustomer = (customer: any) => ({
  type: SHELL_RECEIVED_CURRENT_CUSTOMER,
  customer,
});

export const SHELL_RECEIVED_CUSTOMERS = "@sky/SHELL_RECEIVED_CUSTOMERS";
export const actionReceivedCustomers = (customers: any[]) => ({
  type: SHELL_RECEIVED_CUSTOMERS,
  customers,
});

export const SHELL_RECEIVED_COMPANIES = "@sky/SHELL_RECEIVED_COMPANIES";
export const actionReceivedCompanies = (companies: any) => ({
  type: SHELL_RECEIVED_COMPANIES,
  companies,
});
export const SHELL_SELECT_COMPANY = "@sky/SHELL_SELECT_COMPANY";
export const actionSelectCompany = (company: any) => ({
  type: SHELL_SELECT_COMPANY,
  company,
});

export const SHELL_RECEIVED_USER_GROUPS = "@sky/SHELL_RECEIVED_USER_GROUPS";
export const actionReceivedUserGroups = (groups: any[]) => ({
  type: SHELL_RECEIVED_USER_GROUPS,
  groups,
});

export const SHELL_REMOVED_USER_FROM_GROUP = "@sky/SHELL_REMOVED_USER_FROM_GROUP";
export const actionRemovedUserFromGroup = () => ({
  type: SHELL_REMOVED_USER_FROM_GROUP,
});

export const SHELL_UPDATE_PASSWORD_RESET = "@sky/SHELL_UPDATE_PASSWORD_RESET";
export const actionUpdatePasswordReset = () => ({
  type: SHELL_UPDATE_PASSWORD_RESET,
});

export const SHELL_UPDATE_PASSWORD_FAILED = "@sky/SHELL_UPDATE_PASSWORD_FAILED";
export const actionUpdatePasswordFailed = () => ({
  type: SHELL_UPDATE_PASSWORD_FAILED,
});

export const SHELL_UPDATE_PASSWORD_SUCCESS = "@sky/SHELL_UPDATE_PASSWORD_SUCCESS";
export const actionUpdatePasswordSuccess = () => ({
  type: SHELL_UPDATE_PASSWORD_SUCCESS,
});

export const SHELL_RECEIVED_CAMPAIGN_TYPES = "@sky/SHELL_RECEIVED_CAMPAIGN_TYPES";
export const actionReceivedCampaignTypes = (campaignTypes: any) => ({
  type: SHELL_RECEIVED_CAMPAIGN_TYPES,
  campaignTypes,
});
export const SHELL_SELECT_CAMPAIGN_TYPE = "@sky/SHELL_SELECT_CAMPAIGN_TYPE";
export const actionSelectCampaignType = (campaignType: any) => ({
  type: SHELL_SELECT_CAMPAIGN_TYPE,
  campaignType,
});

export const SHELL_RECEIVED_CAMPAIGNS = "@sky/SHELL_RECEIVED_CAMPAIGNS";
export const actionReceivedCampaigns = (campaigns: any) => ({
  type: SHELL_RECEIVED_CAMPAIGNS,
  campaigns,
});
export const SHELL_SELECT_CAMPAIGN = "@sky/SHELL_SELECT_CAMPAIGN";
export const actionSelectCampaign = (campaign: any) => ({
  type: SHELL_SELECT_CAMPAIGN,
  campaign,
});

export const SHELL_RECEIVED_ADMIN_DEPARTMENTS = "@sky/SHELL_RECEIVED_ADMIN_DEPARTMENTS";
export const actionReceivedAdminDepartments = (adminDepartments: any) => ({
  type: SHELL_RECEIVED_ADMIN_DEPARTMENTS,
  adminDepartments,
});

export const SHELL_RECEIVED_DEPARTMENTS = "@sky/SHELL_RECEIVED_DEPARTMENTS";
export const actionReceivedDepartments = (departments: any) => ({
  type: SHELL_RECEIVED_DEPARTMENTS,
  departments,
});

// selected department where admin is required (used in salary module etc)
export const SHELL_SELECT_ADMIN_DEPARTMENT = "@sky/SHELL_SELECT_ADMIN_DEPARTMENT";
export const actionSelectAdminDepartment = (adminDepartment: any) => ({
  type: SHELL_SELECT_ADMIN_DEPARTMENT,
  adminDepartment,
});

export const SHELL_SELECT_DEPARTMENT = "@sky/SHELL_SELECT_DEPARTMENT";
export const actionSelectDepartment = (department: any) => ({
  type: SHELL_SELECT_DEPARTMENT,
  department,
});

export const SHELL_RECEIVED_TEAMS = "@sky/SHELL_RECEIVED_TEAMS";
export const actionReceivedTeams = (teams: any) => ({
  type: SHELL_RECEIVED_TEAMS,
  teams,
});
export const SHELL_SELECT_TEAM = "@sky/SHELL_SELECT_TEAM";
export const actionSelectTeam = (team: any) => ({
  type: SHELL_SELECT_TEAM,
  team,
});

export const SHELL_RECEIVED_CUSTOMER_PLATFORMS = "@sky/SHELL_RECEIVED_CUSTOMER_PLATFORMS";
export const actionReceivedCustomerPlatforms = (customerPlatforms: any) => ({
  type: SHELL_RECEIVED_CUSTOMER_PLATFORMS,
  customerPlatforms,
});
export const SHELL_SELECT_CUSTOMER_PLATFORM = "@sky/SHELL_SELECT_CUSTOMER_PLATFORM";
export const actionSelectCustomerPlatform = (customerPlatform: any) => ({
  type: SHELL_SELECT_CUSTOMER_PLATFORM,
  customerPlatform,
});

export const SHELL_RECEIVED_AGENTS = "@sky/SHELL_RECEIVED_AGENTS";
export const actionReceivedAgents = (agents: any) => ({
  type: SHELL_RECEIVED_AGENTS,
  agents,
});
export const SHELL_SELECT_AGENT = "@sky/SHELL_SELECT_AGENT";
export const actionSelectAgent = (agent: any) => ({
  type: SHELL_SELECT_AGENT,
  agent,
});

export const SHELL_RECEIVED_ORDER_TYPES = "@sky/SHELL_RECEIVED_ORDER_TYPES";
export const actionReceivedOrderTypes = (orderTypes: any) => ({
  type: SHELL_RECEIVED_ORDER_TYPES,
  orderTypes,
});
export const SHELL_SELECT_ORDER_TYPE = "@sky/SHELL_SELECT_ORDER_TYPE";
export const actionSelectOrderType = (orderType: any) => ({
  type: SHELL_SELECT_ORDER_TYPE,
  orderType,
});

export const SHELL_SELECT_STARTDATE = "@sky/SHELL_SELECT_STARTDATE";
export const actionSelectStartDate = (date: Date) => ({
  type: SHELL_SELECT_STARTDATE,
  date,
});

export const SHELL_SELECT_ENDDATE = "@sky/SHELL_SELECT_ENDDATE";
export const actionSelectEndDate = (date: Date) => ({
  type: SHELL_SELECT_ENDDATE,
  date,
});

export const SHELL_SHOW_NOTIFICATION = "@sky/SHELL_SHOW_NOTIFICATION";
export const SHELL_HIDE_NOTIFICATION = "@sky/SHELL_HIDE_NOTIFICATION";
export const actionShowNotification = (variant: "success" | "error" | "notification", message: string) => ({
  type: SHELL_SHOW_NOTIFICATION,
  variant,
  message,
});
export const actionHideNotification = () => ({ type: SHELL_HIDE_NOTIFICATION })

export const SHELL_TOGGLE_FILTER_VIEW = "@sky/SHELL_TOGGLE_FILTER_VIEW";
export const actionToggleFilterView = () => ({
  type: SHELL_TOGGLE_FILTER_VIEW,
});

// used when showing/hiding advanced search options
export const SHELL_TOGGLE_ADVANCED_FILTER_VIEW = "@sky/SHELL_TOGGLE_ADVANCED_FILTER_VIEW";
export const actionToggleAdvancedFilterView = () => ({
  type: SHELL_TOGGLE_ADVANCED_FILTER_VIEW,
});

export const SHELL_SET_ERROR = "@sky/SHELL_SET_ERROR";
export const SHELL_UNSET_ERROR = "@sky/SHELL_UNSET_ERROR";
export const actionShellSetError = (variant: string, title: string, message: string) => ({
  type: SHELL_SET_ERROR,
  variant,
  title,
  message,
});
export const actionShellUnsetError = () => ({ type: SHELL_UNSET_ERROR });

export const MANAGER_SHOW_SNACKBAR = "@sky/MANAGER_SHOW_SNACKBAR";
export const actionShowSnackbar = (message: string, severity: "error" | "warning" | "info" | "success") => ({
  type: MANAGER_SHOW_SNACKBAR,
  message,
  severity,
});
export const MANAGER_HIDE_SNACKBAR = "@sky/MANAGER_HIDE_SNACKBAR";
export const actionHideSnackbar = () => ({
  type: MANAGER_HIDE_SNACKBAR,
});

export const SHELL_LOGGED_IN = "@sky/SHELL_LOGGED_IN";
export const actionSetLoggedIn = (loggedIn: boolean) => ({
  type: SHELL_LOGGED_IN,
  loggedIn,
});

export const SHELL_LOGGED_OUT = "@sky/SHELL_LOGGED_OUT";
export const actionLogOut = () => ({
  type: SHELL_LOGGED_OUT,
});

export const SHELL_TOGGLE_MENU = "@sky/SHELL_TOGGLE_MENU";
export const actionToggleMenu = (menuDrawerOpen: boolean) => ({
  type: SHELL_TOGGLE_MENU,
  menuDrawerOpen,
});

export interface HTMLObject {
  __html: string;
  url: string;
}

export const SHELL_RECEIVED_LEGACY_HTML = "@sky/SHELL_RECEIVED_LEGACY_HTML";
export const actionReceivedLegacyHTML = (html: HTMLObject | null) => ({
  type: SHELL_RECEIVED_LEGACY_HTML,
  html,
});

export const SHELL_SET_TITLEBAR_DATA = "@sky/SHELL_SET_TITLEBAR_DATA";
export const actionSetTitleBarData = (data: TitleBarData) => ({
  type: SHELL_SET_TITLEBAR_DATA,
  data,
});

// settings/userLogReport
export const SHELL_SELECT_LOGUSER = "@sky/SHELL_SELECT_LOGUSER";
export const actionSelectLogUser = (logUser: any) => ({
  type: SHELL_SELECT_LOGUSER,
  logUser,
});
// settings/userLogReport
export const SHELL_RECEIVED_LOGTYPES = "@sky/SHELL_RECEIVED_LOGTYPES";
export const actionReceivedLogTypes = (logTypes: any) => ({
  type: SHELL_RECEIVED_LOGTYPES,
  logTypes,
});
// settings/userLogReport
export const SHELL_SELECT_LOGTYPE = "@sky/SHELL_SELECT_LOGTYPE";
export const actionSelectLogType = (logType: any) => ({
  type: SHELL_SELECT_LOGTYPE,
  logType,
});


export default function (state = initialState, action) {

  switch (action.type) {
    case SHELL_RECEIVED_USERINFO:
      return { ...state, user: action.userinfo };
    case SHELL_SELECT_LOGUSER:
      return { ...state, selectedLogUser: action.logUser };
    case SHELL_RECEIVED_LOGTYPES:
      return { ...state, receivedLogTypes: action.logTypes };
    case SHELL_SELECT_LOGTYPE:
      return { ...state, selectedLogType: action.logType };
    case SHELL_RECEIVED_MODULES:
      return { ...state, modules: action.modules };
    case SHELL_SELECT_MODULE:
      return { ...state, selectedModule: action.module };
    case SHELL_LOGGED_IN:
      return { ...state, loggedIn: action.loggedIn };
    case SHELL_LOGGED_OUT:
      return { ...initialState, loggedIn: false };
    case SHELL_TOGGLE_MENU:
      return { ...state, menuDrawerOpen: action.menuDrawerOpen };
    case SHELL_RECEIVED_CLIENTS:
      return { ...state, clients: action.clients };
    case SHELL_SELECT_CLIENT:
      return { ...state, selectedClient: action.client };
    case SHELL_RECEIVED_ORG_USERS:
      return { ...state, orgUsers: action.users };
    case SHELL_RECEIVED_CURRENT_CUSTOMER:
      return { ...state, currentCustomer: action.customer };
    case SHELL_RECEIVED_CUSTOMERS:
      return { ...state, customers: action.customers };
    case SHELL_RECEIVED_COMPANIES:
      return { ...state, companies: action.companies };
    case SHELL_RECEIVED_AGENTS: 
      return { ...state, agents: action.agents }
    case SHELL_SELECT_COMPANY:
      return { ...state, selectedCompany: action.company };
    case SHELL_SELECT_AGENT:
      return { ...state, selectedAgent: action.agent };
    case SHELL_RECEIVED_CAMPAIGN_TYPES:
      return { ...state, campaignTypes: action.campaignTypes };
    case SHELL_SELECT_CAMPAIGN_TYPE:
      return { ...state, selectedCampaignType: action.campaignType };
    case SHELL_RECEIVED_ORDER_TYPES:
      return { ...state, orderTypes: action.orderTypes };
    case SHELL_SELECT_ORDER_TYPE:
      return { ...state, selectedOrderType: action.orderType };
    case SHELL_RECEIVED_CAMPAIGNS:
      return { ...state, campaigns: action.campaigns };
    case SHELL_SELECT_CAMPAIGN:
      return { ...state, selectedCampaign: action.campaign };
    case SHELL_RECEIVED_DEPARTMENTS:
      return { ...state, departments: action.departments };
    case SHELL_RECEIVED_ADMIN_DEPARTMENTS: // department when admin is required
      return { ...state, adminDepartments: action.adminDepartments };
    case SHELL_SELECT_TEAM:
      return { ...state, selectedTeam: action.team };
    case SHELL_SELECT_DEPARTMENT:
      return { ...state, selectedDepartment: action.department };
    case SHELL_SELECT_ADMIN_DEPARTMENT:
      console.log("SHELL_SELECT_ADMIN_DEPARTMENT");
      console.log(state);
      console.log(action);
      return { ...state, selectedAdminDepartment: action.adminDepartment };
    case SHELL_SELECT_STARTDATE:
      return { ...state, selectedStartDate: action.date };
    case SHELL_SELECT_ENDDATE:
      return { ...state, selectedEndDate: action.date };
    case SHELL_SHOW_NOTIFICATION:
      return {
        ...state,
        notification: {
          variant: action.variant,
          message: action.message,
          show: true
        }
      };
    case SHELL_HIDE_NOTIFICATION:
      return {
        ...state,
        notification: {
          message: "",
          variant: null,
          show: false
        }
      }
    case SHELL_TOGGLE_FILTER_VIEW:
      return { ...state, hideFilters: !state.hideFilters };
    case SHELL_TOGGLE_ADVANCED_FILTER_VIEW:
      return { ...state, hideAdvancedFilters: !state.hideAdvancedFilters };
    case SHELL_API_START:
      const incApiRunning = state.apiRunning + 1;
      return { ...state, apiRunning: incApiRunning };
    case SHELL_API_END:
      let decApiRunning = state.apiRunning - 1;
      if (decApiRunning < 0) {
        decApiRunning = 0;
      }
      return { ...state, apiRunning: decApiRunning };
    case SHELL_API_STOP:
      return { ...state, apiRunning: 0 };
    case SHELL_SET_ERROR:
      return {
        ...state,
        error: {
          show: true,
          variant: action.variant,
          title: action.title,
          message: action.message,
        },
      };
    case SHELL_UNSET_ERROR:
      return {
        ...state,
        error: {
          show: false,
          variant: "",
          title: "",
          message: "",
        },
      };
    case SHELL_RECEIVED_LEGACY_HTML:
      return {
        ...state,
        legacy: {
          html: action.html,
        },
      };
    case MANAGER_SHOW_SNACKBAR:
      return {
        ...state,
        snackbar: {
          open: true,
          message: action.message,
          severity: action.severity,
        },
      };
    case MANAGER_HIDE_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          open: false,
        },
      };
    case SHELL_RECEIVED_USER_GROUPS:
      return { ...state, user: { ...state.user, groups: action.groups } };
    case SHELL_REMOVED_USER_FROM_GROUP:
      return { ...state };
    case SHELL_SET_TITLEBAR_DATA:
      return { ...state, titlebar: action.data };
    case SHELL_UPDATE_PASSWORD_RESET:
      return { ...state, account: { ...state.account, updatePasswordFailed: false, updatePasswordSuccess: false } };
    case SHELL_UPDATE_PASSWORD_FAILED:
      return { ...state, account: { ...state.account, updatePasswordFailed: true } };
    case SHELL_UPDATE_PASSWORD_SUCCESS:
      return { ...state, account: { ...state.account, updatePasswordSuccess: true } };
  }

  return state;
}

export interface TitleBarData {
  title: string;
  breadcrumbs: TitleBarBreadCrumb[];
  current: TitleBarBreadCrumb;
}

export interface TitleBarBreadCrumb {
  name: string;
  link?: string;
}

export interface UserGroup {
  group_id: number;
  group_name: string;
  group_icon: string | undefined;
  group_members: number;
  group_join_date: string;
}
export interface Module {
  module_id: number;
  module_name: string;
  url_p1: string;
  url_p2: string;
  url_p3: string;
  icon: string;
  parent_module_id: number;
  sorting: undefined;
  module_description: string | undefined;
}
