import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, batch } from "react-redux";
import { fetchMapSegments, IReportQuery } from "../listmanagement/maps/maps.api";
import { actionSelectClient, actionSelectCompany, actionToggleFilterView, actionSelectStartDate, actionSelectEndDate, actionSelectCampaignType, actionSelectCampaign } from "../../redux/reducers/shell";
import TitlebarComponent from "../../titlebar.component";
import ClientSelector from "../shell/clientselector.component";
import CompanySelector from "../shell/companyselector.component";
import PeriodSelector from "../shell/periodselector.component";
import CampaignTypeSelector from "../shell/campaigntypeselector.component";
import CampaignSelector from "../shell/campaignselector.component";

// material ui
import { Grid, 
    makeStyles,Theme,createStyles,
    CardContent, Card, CardActions,
    Button, Snackbar, CircularProgress
} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';

// ag gridsystemet
import { AgToolbarButton } from "../../agGridUtils";
import { AgGridReact } from "ag-grid-react";
import {
    GridReadyEvent,
    FirstDataRenderedEvent,
    SelectionChangedEvent,
    GridApi,
    ColumnApi,
} from "ag-grid-community";

// theme for AdGridReact
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorMessage: {
            color: '#ff0000'
        },
        flexGrow: {
            flexGrow: 1,
        },
        actions: {
            "& .form-group": {
                minWidth: 200,
                marginRight: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
            "& button": {
                //marginTop: 24,
            },
        },
        agGrid: {
            "& .aggrid-size": {
                minHeight: 500,
                height: "600px",
                width: "100%",
            },
        },
    })
);
// for knappene over tabellen
const toolbarProps = {
    title: "",
    rightAlign: false,
    disabled: false,
    icon: "",
    onClick: () => { },
};
const marginStyles = {
    // margin: '24px 0 0 0',
 }
 const aggEnable = {
    aggFunc: "sum",
    enableValue: true,
    allowedAggFuncs: ["sum", "min", "max"],
    filter: "agNumberColumnFilter",
    cellStyle: { textAlign: "right" }
  };

 const columnDefs = [
    { headerName: "Client", field: "client_name", rowGroup: false, enableRowGroup: true },
    { headerName: "Company", field: "company_name", rowGroup: false, enableRowGroup: true },
    { headerName: "Campaign Type", field: "campaign_type_name", rowGroup: false, enableRowGroup: true },
    { headerName: "Campaign", field: "campaign_name", rowGroup: false, enableRowGroup: true },
    { headerName: "Segment", field: "segment_name", rowGroup: false, enableRowGroup: true },
    //{headerName: "Area Name", field: "area_name", rowGroup: false},
    //{headerName: "Area Code", field: "area_code", rowGroup: false},
    { headerName: "Date From", field: "date_from", rowGroup: false, enableRowGroup: true },
    { headerName: "Date To", field: "date_to", rowGroup: false, enableRowGroup: true },
    { headerName: "Freeze Until", field: "freeze_date_to", rowGroup: false, enableRowGroup: true },
    { headerName: "Areas", field: "segment_areas", rowGroup: false, ...aggEnable },
    { headerName: "Addresses", field: "segment_coordinates", rowGroup: false, ...aggEnable },
    { headerName: "Blocked Addresses", field: "segment_blocked", rowGroup: false, ...aggEnable },
    { headerName: "Yes %", field: "outcome_1_pct", rowGroup: false, ...aggEnable, hide: false },
    { headerName: "Yes", field: "outcome_1", rowGroup: false, ...aggEnable, hide: true },
    //  { headerName: "Handled", field: "outcome_5", rowGroup: false, ...aggEnable},
    { headerName: "No", field: "outcome_2", rowGroup: false, ...aggEnable, hide: true },
    //  { headerName: "Loss", field: "outcome_6", rowGroup: false, ...aggEnable},
    //  { headerName: "Unhandled", field: "outcome_11", rowGroup: false, ...aggEnable},
    //  { headerName: "Closed", field: "outcome_12", rowGroup: false, ...aggEnable},
    //  { headerName: "Call Back", field: "outcome_3", rowGroup: false, ...aggEnable},
    //  { headerName: "Answer Machine", field: "outcome_4", rowGroup: false, ...aggEnable},
    //  { headerName: "Call Attempts", field: "outcome_7", rowGroup: false, ...aggEnable},
    //  { headerName: "Follow Ups", field: "outcome_8", rowGroup: false, ...aggEnable},
    { headerName: "Come Back", field: "outcome_9", rowGroup: false, ...aggEnable, hide: true },
    { headerName: "Not Home", field: "outcome_10", rowGroup: false, ...aggEnable, hide: true },
    { headerName: "Utilization %", field: "utilization_pct", rowGroup: false, ...aggEnable }
];

export default function MapsReportComponent() {
    const [results, setResults] = useState([]);
    const [reportQuery, setReportQuery] = useState<IReportQuery>();

    const selectedClient = useSelector(state => state.shell.selectedClient);
    const selectedCompany = useSelector(state => state.shell.selectedCompany);
    const selectedCampaignType = useSelector(state => state.shell.selectedCampaignType);
    const selectedCampaign = useSelector(state => state.shell.selectedCampaign);
    const selectedStartDate = useSelector(state => state.shell.selectedStartDate);
    const selectedEndDate = useSelector(state => state.shell.selectedEndDate);

    // for tabellen
    const dispatch = useDispatch();
    const classes = useStyles();
    const [gridAPI, setGridAPI] = useState<GridApi>();
    const [columnAPI, setColumnAPI] = useState<ColumnApi>();
    const hideFilters = useSelector((state) => state.shell.hideFilters);
    // standard variabler
    const [loading, setLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [alertMsg, setAlertMsg] = useState({msg: "",severity: false});

    // funksjoner for agGrid
    // Dette skjer når du klikker på en rad i tabellen, lagrer object med file id
    const onSelectionChanged = (event: SelectionChangedEvent) => {
        const rows = event.api.getSelectedRows();
        if (!rows[0]) {
            return;
        }
        // TODO: setSelectedContact(rows[0]);
    };
    // søkefelt som søker i tabellen
    const handleQuickFilter = event => {
        gridAPI!.setQuickFilter(event.target.value);
    };
    // laster inn data om tabellen
    const onGridReady = (event: GridReadyEvent) => {
        setGridAPI(event.api);
        setColumnAPI(event.columnApi);
    };
    // 3 knapper over tabellen, refresh, og endre størrelse på kolonner
    const onAdjustColumnsContent = (event: Event) => {
        var allColumnIds: any = [];
        if (columnAPI) {
            const allColumns = columnAPI!.getAllColumns();
            allColumns!.forEach((column) => {
                const colDef = column.getColDef();
                if (!colDef.suppressAutoSize) {
                    allColumnIds.push(column.getColId());
                }
            });
            columnAPI!.autoSizeColumns(allColumnIds);
        }
    };
    const onAdjustColumnsWindow = (event: Event) => {
        gridAPI!.sizeColumnsToFit();
    };
    const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
        event.api.sizeColumnsToFit();
    };
    const onExportToCsv = () => {
        if (gridAPI) {
        gridAPI.exportDataAsExcel();
        }
    };
    // end funksjoner for agGrid
    function resetForm() {
        batch(() => {
            dispatch(actionSelectClient(undefined));
            dispatch(actionSelectCompany(undefined));
            dispatch(actionSelectCampaignType(undefined));
            dispatch(actionSelectCampaign(undefined));
            dispatch(actionSelectStartDate(getDefaultStartDate()));
            dispatch(actionSelectEndDate(getDefaultEndDate()));
            // runReport(reportQuery);
          });
    }
    function runReport() {
        
        setLoading(true);
        if (reportQuery) {
            fetchMapSegments(reportQuery)
                .then(res => setResults(res))
                .catch(err => {setAlertMsg({msg: err.message, severity: false}); setAlertOpen(true)})
                .finally(() => setLoading(false))
        }
    }
    function getDefaultEndDate() {
        let defaultEndDate = new Date();
        defaultEndDate.setMonth(defaultEndDate.getMonth() + 3);
        defaultEndDate.setDate(0);
        return defaultEndDate;
      }
    
      function getDefaultStartDate() {
        let startDate = new Date();
        startDate.setDate(1);
        return startDate;
      }

    useEffect(() => {
        console.log("onload")
    },[])
    useEffect(() => {
        setReportQuery({
          selectedClient: selectedClient ? selectedClient.value : 0,
          selectedCompany: selectedCompany ? selectedCompany.value : 0,
          selectedStartDate: selectedStartDate,
          selectedEndDate: selectedEndDate,
          selectedCampaignType: selectedCampaignType ? selectedCampaignType.value : 0,
          selectedCampaign: selectedCampaign ? selectedCampaign.value : 0
        });
      }, [selectedClient, selectedCompany, selectedStartDate, selectedEndDate, selectedCampaignType, selectedCampaign]);
    

    return (<>
    <TitlebarComponent
                title="Blocklists"
                description="View blocklist"
                breadcrumbs={[
                    { title: "Dashboard", link: "/" },
                    { title: "Blocklists", link: "/list_management/blocklist" }
                ]}
            />
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <div
                        style={{ color: "#ccc", width: 15 }}
                        data-toggle="tooltip"
                        data-original-title="Hide/Show filters"
                        onClick={() => dispatch(actionToggleFilterView())}
                    >
                        {hideFilters ? (
                            <i className="fas fa-fw fa-caret-square-right"></i>
                        ) : (
                                <i className="fas fa-fw fa-caret-square-left"></i>
                            )}
                    </div>
            </Grid>
            <Grid item md={2} style={{ display: hideFilters ? "none" : "block" }}>
                <div style={{maxWidth: "270px"}}>  
                <ClientSelector variant="material" fullWidth/>
                <CompanySelector variant="material" fullWidth/>
                <CampaignTypeSelector variant="material" fullWidth/>
                <CampaignSelector variant="material" fullWidth/>
                <PeriodSelector defaultStartDate={getDefaultStartDate()} defaultEndDate={getDefaultEndDate()} variant="material"/>
                <div style={{ textAlign: "right", marginTop: 20 }}>
                    { loading ? <CircularProgress size={20}/> : null}
                    <Button onClick={resetForm} variant="outlined" color="primary">
                    Reset
                    </Button>
                    &nbsp;
                    <Button onClick={() => runReport()} color="primary" variant="contained">
                    Search
                    </Button>
                </div>
                </div>
            </Grid>
            <Grid item xs={12} md={hideFilters ? 12 : 10}>
            <Card>
            <CardActions disableSpacing className={classes.actions}>
                {/* Knappene over tabellen */}
                
                <div className={classes.flexGrow} />
                <input 
                    type="text" 
                    style={marginStyles}
                    placeholder="Search" 
                    onChange={handleQuickFilter}
                />
                {AgToolbarButton({
                    ...toolbarProps,
                    title: "Export to CSV",
                    icon: "fa-file-export",
                    onClick: onExportToCsv,
                })}

                {AgToolbarButton({
                    ...toolbarProps,
                    title: "Adjust columns to window",
                    icon: "fa-columns",
                    onClick: onAdjustColumnsWindow,
                })}

                {AgToolbarButton({
                    ...toolbarProps,
                    title: "Adjust columns to content",
                    icon: "fa-text-width",
                    onClick: onAdjustColumnsContent,
                })}
            </CardActions>
            {/* Selve tabellen */}
            <CardContent >
                <Grid container>
                <Grid item xs={12} className={classes.agGrid}>
                    <div className="ag-theme-balham aggrid-size">
                        <AgGridReact
                            rowData={results}
                            columnDefs={columnDefs}  
                            onGridReady={onGridReady}
                            onSelectionChanged={onSelectionChanged}
                            enableRangeSelection={false}
                            rowSelection="single"
                            // justerer kolonnebredden etter load
                            onFirstDataRendered={onFirstDataRendered}
                            sideBar={{
                                toolPanels: ["columns"],
                            }}
                            // pagination
                            pagination={true}
                            paginationPageSize={100}
                            cacheBlockSize={100}
                            cacheOverflowSize={2}
                            maxBlocksInCache={2}
                            
                        />
                    </div>
                </Grid>
                </Grid>
            
            </CardContent>  
            </Card>
            </Grid>
        </Grid>
        {/* alert bar */}
        <Snackbar 
            open={alertOpen} 
            autoHideDuration={3000} 
            onClose={() => setAlertOpen(false)}>
            <MuiAlert 
                variant="filled"
                severity={ alertMsg.severity ? "success" : "error"}>{alertMsg.msg}
            </MuiAlert>
        </Snackbar>
    </>)
}