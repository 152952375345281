import React, { useEffect, useState } from "react";
import NewFilterDialogComponent from "./filters/newFilterDialog.component";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import UserSettings from "../../../../userSettings";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import { Sparklines, SparklinesLine, SparklinesSpots } from "react-sparklines";
import FilterPerformanceComponent from "./filters/filterPerformance.component";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import Modal from "react-bootstrap/Modal";

declare var env: any;

interface FiltersProps {
  directoryId: string;
  directoryInfo: any;
  campaignTypeId: string;
  clientId: string;
}

const shortName = (str: any) => {
  if (str.length > 35) {
    return str.substr(0, 33) + "...";
  }

  return str;
};

export default function FiltersComponent(props: FiltersProps) {
  const [filterViewType, setFilterViewType] = useState(UserSettings.getOption(UserSettings.FILTER_VIEW_TYPE, 0));
  const [runFilter, setRunFilter] = useState({ contacts: 0, name: "", segment: "", loadPreview: false, id: 0 });
  const [filters, setFilters] = useState([]);
  const [showNewFilter, setShowNewFilter] = useState(false);
  const [editFilter, setEditFilter] = useState({ contact_directory_filter_id: "" });
  const [campaigns, setCampaigns] = useState([]);

  const [showFilterPerformance, setShowFilterPerformance] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState();

  const [showRunFilter, setShowRunFilter] = useState(false);
  const [runDisabled, setRunDisabled] = useState(false);

  // State for confirmation popover
  const [confirmDeleteFilterTarget, setConfirmDeleteFilterTarget]: any = useState(null);

  const fetchDirectoryFilters = async () => {
    const res = await fetch(
      env._apiPath + "/listManagement/contactDirectoryFilters?contact_directory_id=" + props.directoryId,
      { method: "GET", credentials: "include" }
    );
    const json = await res.json();
    if (json.status === "success") {
      const filts: any = [];
      for (const i in json.data) {
        const o = json.data[i];
        o.confirmDelete = false;
        filts.push(o);
      }
      setFilters(filts);
      return;
    }
  };

  const fetchCampaigns = async (campaignTypeId: string) => {
    const res = await fetch(env._apiPath + "/customer/campaigns?campaign_type_id=" + campaignTypeId, {
      method: "GET",
      credentials: "include"
    });
    const json = await res.json();
    setCampaigns(json.data);
    fetchDirectoryFilters();
  };

  const fetchDeleteDirectoryFilter = async (filterId: string) => {
    const res = await fetch(env._apiPath + "/listManagement/contactDirectoryFilter/" + filterId, {
      method: "DELETE",
      credentials: "include"
    });
    await res.json();
    fetchDirectoryFilters();
  };

  const fetchRunDirectoryFilter = async (contact_directory_filter_id: string) => {
    const request = {
      contact_directory_filter_id
    };
    const res = await fetch(env._apiPath + "/listManagement/runContactDirectoryFilter", {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(request)
    });
    const json = await res.json();
    if (json.status === "success") {
    }
  };

  useEffect(() => {
    if (!showNewFilter) {
      setEditFilter({ contact_directory_filter_id: "" });
    }
  }, [showNewFilter]);

  useEffect(() => {
    fetchCampaigns(props.campaignTypeId.toString());
    setRunDisabled(false);
  }, []);

  useEffect(() => {
    UserSettings.setOption(UserSettings.FILTER_VIEW_TYPE, filterViewType);
  }, [filterViewType]);

  const onRunFilter = (f: any) => {
    setRunFilter({
      contacts: 0,
      loadPreview: true,
      segment: f.segment_name,
      name: f.contact_directory_filter_name,
      id: f.contact_directory_filter_id
    });

    const request: any = {
      client_id: props.clientId,
      contact_directory_id: props.directoryId,
      assigned: f.assigned,
      per_page: 50,
      order_by: "lastname",
      order_direction: "desc",
      contact_list_id: 0,
      segment_id: 0,
      count_filters: true,
      show_result: false,
      search: [...f.search]
    };

    const fetchSearchResult = async () => {
      const res = await fetch(env._apiPath + "/listManagement/searchDirectory", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(request),
        headers: {
          "Content-Type": "application/json"
        }
      });
      const json = await res.json();
      let count = 0;
      json.data.search_filter.forEach((k: any) => (count = k.contacts_count));
      setRunFilter({
        segment: f.segment_name,
        name: f.contact_directory_filter_name,
        id: f.contact_directory_filter_id,
        contacts: count,
        loadPreview: false
      });
    };
    fetchSearchResult();
    setRunDisabled(false);
    setShowRunFilter(true);
  };

  const confirmFilterRun = () => {
    fetchRunDirectoryFilter(runFilter.id.toString());
  };

  const campaignName = (id: number) => {
    for (const i in campaigns) {
      const o: any = campaigns[i];
      if (o.campaign_id.toString() === id.toString()) {
        return o.campaign_name;
      }
    }
    return id;
  };

  const currentDate = () => {
    const dt = new Date();
    const y = dt.getFullYear();
    const m = dt.getMonth() + 1;
    const d = dt.getDate();

    let res = y.toString();

    if (m < 10) {
      res += "0";
    }
    res += m.toString();

    if (d < 10) {
      res += "0";
    }
    res += d.toString();

    return res;
  };

  const getExportCount = (exportedContacts: any) => {
    const counts: any = [];
    for (const i in exportedContacts) {
      const o = exportedContacts[i];
      counts.push(o);
    }

    return counts;
  };

  const toggleConfirmDeleteFilterVisible = (idx: any) => {
    const filts: any = [...filters];
    filts[idx].confirmDelete = !filts[idx].confirmDelete;
    setFilters(filts);
  };

  const removeFilter = (filter: any, k: any) => {
    fetchDeleteDirectoryFilter(filter.contact_directory_filter_id);
    setConfirmDeleteFilterTarget(null);
    toggleConfirmDeleteFilterVisible(k);
  };

  const renderShowRunFilterDialog = () => {
    const previewClassNames = runFilter.contacts > 0 ? "modal-filter-preview-show" : "modal-filter-preview-hidden";

    return (
      <Modal show={showRunFilter} onHide={() => setShowRunFilter(false)}>
        <Modal.Header>
          <Modal.Title>
            Run Filter <i>{runFilter.name}</i>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to run this filter?</p>

          {/* TODO: Different views when using SFTP etc.. */}

          {runFilter.loadPreview ? (
            <p>
              <i className="fas fa-fw fa-spinner fa-spin"></i>
            </p>
          ) : (
            <p className={previewClassNames}>
              This action will add <i>{runFilter.contacts}</i> contacts to <i>{runFilter.segment}</i>.
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={e => {
              setShowRunFilter(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={e => {
              confirmFilterRun();
              setShowRunFilter(false);
              setRunDisabled(true);
            }}
            disabled={runDisabled}
          >
            Run
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderFilterViewCards = () => {
    return (
      <div className="filter-cards">
        {filters.map((f: any, k: any) => {
          const expCount = getExportCount(f.exported_contacts);
          let yMax = 0;
          expCount.forEach((c: any) => {
            yMax = Math.max(c, yMax);
          });
          yMax += 10;
          return (
            <div className="f-card" key={k}>
              <Row>
                <Col>
                  <h5 className="card-title">
                    <div className="icon">
                      <img src="/assets/img/flat-icons/funnel.png" />
                    </div>
                    {f.contact_directory_filter_name}
                  </h5>
                </Col>
                {f.automatic.toString() === "1" ? (
                  <Col md={1} className="f-automatic">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id={"tl-" + f.contact_directory_filter_id + "-tooltip"}>Active</Tooltip>}
                    >
                      <i className="fa fa-cog fa-spin"></i>
                    </OverlayTrigger>
                  </Col>
                ) : null}
              </Row>
              <div className="flex-spacer"></div>
              <Row>
                <Col
                  className="f-spark"
                  onClick={e => {
                    setShowFilterPerformance(true);
                    setSelectedFilter(f);
                  }}
                >
                  <Sparklines data={expCount} width={100} height={20} min={0} max={yMax}>
                    <SparklinesLine
                      style={{
                        stroke: "#868E96",
                        fill: "#eeeeee",
                        fillOpacity: 1
                      }}
                    />
                    <SparklinesSpots style={{ fill: "#eeeeee" }} />
                  </Sparklines>
                </Col>
              </Row>
              <Row className="card-campseg">
                <Col md={2} className="align-self-center">
                  <div className="icon">
                    <img src="/assets/img/flat-icons/database-1.png" />
                  </div>
                </Col>
                <Col>
                  <Row>
                    <Col md={5}>
                      <b>Campaign</b>
                    </Col>
                    <Col>{shortName(campaignName(f.campaign_id))}</Col>
                  </Row>
                  <Row>
                    <Col md={5}>
                      <b>Segment</b>
                    </Col>
                    <Col className="t-segment">
                      {shortName(f.segment_name)}
                      {f.segment_name === "[today]" ? <small>({currentDate()})</small> : null}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <ButtonGroup>
                  <Button size="sm" variant="secondary" onClick={() => onRunFilter(f)}>
                    <i className="feather feather-play"></i>
                    Run
                  </Button>

                  <Button
                    onClick={() => {
                      setEditFilter(f);
                      setShowNewFilter(true);
                    }}
                    size="sm"
                    variant="secondary"
                  >
                    <i className="feather feather-edit"></i>
                    Edit
                  </Button>

                  <Button
                    size="sm"
                    variant="secondary"
                    onClick={e => {
                      setConfirmDeleteFilterTarget(e.target);
                      toggleConfirmDeleteFilterVisible(k);
                    }}
                  >
                    <i className="feather feather-trash"></i>
                    Remove
                  </Button>
                  <Overlay show={f.confirmDelete} target={confirmDeleteFilterTarget}>
                    <Popover id={"popover-confirm-" + k}>
                      <Popover.Title as="h3">Confirm?</Popover.Title>
                      <Popover.Content>
                        <Row>
                          <Col>
                            <Button size="sm" variant="danger" onClick={e => removeFilter(f, k)}>
                              Delete
                            </Button>
                          </Col>
                          <Col>
                            <Button size="sm" variant="secondary" onClick={e => toggleConfirmDeleteFilterVisible(k)}>
                              Cancel
                            </Button>
                          </Col>
                        </Row>
                      </Popover.Content>
                    </Popover>
                  </Overlay>
                </ButtonGroup>
              </Row>
            </div>
          );
        })}
      </div>
    );
  };

  const renderFilterViewList = () => {
    return (
      <Row>
        <Col>
          {filters.map((f: any, k: any) => {
            const expCount = getExportCount(f.exported_contacts);
            let yMax = 0;
            expCount.forEach((c: any) => {
              yMax = Math.max(c, yMax);
            });
            yMax += 10;
            return (
              <Row className={"filter-row " + (f.automatic.toString() === "1" ? "filter-active" : "")} key={k}>
                <Col md={7} xl={5} className="filter-name">
                  <small>Name</small>
                  <br />
                  <div className="icon">
                    <img src="/assets/img/flat-icons/funnel.png" />
                  </div>
                  {f.contact_directory_filter_name}
                </Col>
                <Col md={2} xl={4} className="filter-segment">
                  <Row>
                    <Col xl={6} className="d-none d-xl-block">
                      <small>Campaign</small>
                      <br />
                      <div>{campaignName(f.campaign_id)}</div>
                    </Col>
                    <Col xl={6} md={12}>
                      <small>Segment</small>
                      <br />
                      <div className="segment-name">{f.segment_name}</div>

                      <div className="segment-generator">
                        {f.segment_name === "[today]" ? <small>{currentDate()}</small> : null}
                      </div>
                    </Col>
                  </Row>
                </Col>
                {/*<Col xl={1} className="d-none d-xl-block">
                                    <small>Criteria(s)</small><br/>
                                    {f.search.length}<br/>
                                </Col>*/}
                <Col
                  md={1}
                  xl={1}
                  className="align-self-center sparkline-chart"
                  onClick={e => {
                    setShowFilterPerformance(true);
                    setSelectedFilter(f);
                  }}
                >
                  <small>Output</small>
                  <Sparklines
                    data={getExportCount(f.exported_contacts)}
                    width={50}
                    height={10}
                    margin={2}
                    min={0}
                    max={yMax}
                  >
                    <SparklinesLine />
                  </Sparklines>
                </Col>
                <Col md={2} xl={2} className="align-self-center">
                  <div className="btn-group float-right d-none d-xl-block">
                    <Button size="sm" variant="secondary" onClick={() => onRunFilter(f)}>
                      <i className="feather feather-play"></i>
                      <span className="xxl-only">Run</span>
                    </Button>

                    <Button
                      size="sm"
                      onClick={() => {
                        setEditFilter(f);
                        setShowNewFilter(true);
                      }}
                      variant="secondary"
                    >
                      <i className="feather feather-edit"></i>
                      <span className="xxl-only">Edit</span>
                    </Button>

                    <Button
                      size="sm"
                      variant="secondary"
                      onClick={e => {
                        setConfirmDeleteFilterTarget(e.target);
                        toggleConfirmDeleteFilterVisible(k);
                      }}
                    >
                      <i className="feather feather-trash"></i>
                      <span className="xxl-only">Remove</span>
                    </Button>
                    <Overlay show={f.confirmDelete} target={confirmDeleteFilterTarget}>
                      <Popover id={"popover-confirm-" + k}>
                        <Popover.Title as="h3">Confirm?</Popover.Title>
                        <Popover.Content>
                          <Row>
                            <Col>
                              <Button size="sm" variant="danger" onClick={e => removeFilter(f, k)}>
                                Delete
                              </Button>
                            </Col>
                            <Col>
                              <Button size="sm" variant="secondary" onClick={e => toggleConfirmDeleteFilterVisible(k)}>
                                Cancel
                              </Button>
                            </Col>
                          </Row>
                        </Popover.Content>
                      </Popover>
                    </Overlay>
                  </div>

                  <Dropdown className="float-right d-xl-none">
                    <Dropdown.Toggle size="sm" id="dropdown-actions">
                      Actions
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => onRunFilter(f)}>Run</Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setEditFilter(f);
                          setShowNewFilter(true);
                        }}
                      >
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item>Remove</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    );
  };

  return (
    <div className="directory-filters">
      <Row>
        <Col md={6}>
          <h6>Filters</h6>
        </Col>
        <Col md={6} className="toolbar">
          <ButtonGroup>
            <Button size="sm" variant="success" onClick={() => setShowNewFilter(true)}>
              <i className="fa fa-plus-circle"></i>
            </Button>
          </ButtonGroup>
          &nbsp;
          <ButtonGroup toggle>
            <Button
              size="sm"
              variant="secondary"
              onClick={() => setFilterViewType(0)}
              className={filterViewType === 0 ? "active" : ""}
            >
              <i className="fa fa-th-list"></i>
            </Button>
            <Button
              size="sm"
              variant="secondary"
              onClick={() => setFilterViewType(1)}
              className={filterViewType === 1 ? "active" : ""}
            >
              <i className="fa fa-th"></i>
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      {filterViewType === 0 ? renderFilterViewList() : null}
      {filterViewType === 1 ? renderFilterViewCards() : null}

      {/*renderRunFilterModal(runFilter)*/}

      <NewFilterDialogComponent
        show={showNewFilter}
        directoryId={props.directoryId}
        directoryInfo={props.directoryInfo}
        campaignTypeId={props.campaignTypeId}
        clientId={props.clientId}
        filter={editFilter}
        onHide={() => setShowNewFilter(false)}
        onNewFilter={() => fetchDirectoryFilters()}
      />

      <FilterPerformanceComponent
        show={showFilterPerformance}
        onHide={e => setShowFilterPerformance(false)}
        filter={selectedFilter}
      />

      {renderShowRunFilterDialog()}
    </div>
  );
}
