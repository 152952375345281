import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Typography,
  Toolbar,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { getCategories } from "../categories.api";
import { IDiscountProps, ICategoryData, ICampaignData } from "../form.types";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexGrow: {
      flexGrow: 1,
    },
    actions: {
      "& button.aggrid-btn": {
        //marginTop: 24
      },
    },
    agGrid: {
      display: "flex",
      flexDirection: "column",
      "& .aggrid-size": {
        minHeight: 500,
        //height: 500, //"calc(100vh - 460px)",
        flex: 1,
        width: "100%",
      },
    },
    dialogPaper: { overflow: "visible" },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
  })
);

export default function ConditionsComponent(props: IDiscountProps) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [categories, setCategories] = useState([]);


  const [newConditionAmount, setNewConditionAmount] = useState("");
  const [formDiscountConditions, setFormDiscountConditions] = useState([]);
  const [formDiscountTargets, setFormDiscountTargets] = useState([]);

  /**
   * TODO:
   *  - API: Fetch form_discount_conditions
   *  - API: Fetch categories
   *  - API: Fetch products
   *
   * Product Categories should list all form_discount_conditions where category id is set.
   * Products should list all form_discount_conditions where product id is set.
   * Target Conditions should list all form_discount_target-categories
   *
   * Each row must have a delete-button
   * The +-button should show an appropriate selector (category-selector or product-selector), via a popup/popover menu-thing. It should have a max-height, and perhaps a text input for filtering.
   *
   */

  useEffect(() => {
    getCategories(props.formId)
      .then((categories) => {
        setCategories(categories);
      })
      .catch(console.log);
  }, []);

  return (
    <>
      <Grid container spacing={2} style={{ marginTop: 25 }}>
        <Grid item md={12}>
          <TextField
            label="Condition Amount"
            type="text"
            fullWidth
            value={newConditionAmount}
            onChange={(e) => setNewConditionAmount(e.target.value)}
            helperText="Number of products/categories defined in conditions to enable discount"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginTop: 25 }}>
        <Grid item md={4}>
          <Toolbar>
            <Typography variant="subtitle1">Product Categories</Typography>
            <div style={{ flexGrow: 1 }} />
            <IconButton>
              <i className="fa fa-plus"></i>
            </IconButton>
          </Toolbar>
          <Table>
            <TableBody>
              {formDiscountConditions.map((category: ICategoryData, key) => {

                return (
                  <TableRow key={key}>
                    <TableCell>{category.category_name}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
        <Grid item md={4}>
          <Toolbar>
            <Typography variant="subtitle1">Products</Typography>
            <div style={{ flexGrow: 1 }} />
            <IconButton>
              <i className="fa fa-plus"></i>
            </IconButton>
          </Toolbar>
          <Table>
            <TableBody>
              {formDiscountConditions.map((product: ICategoryData, key) => (
                <TableRow key={key}>
                  <TableCell>{product.category_name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid item md={4}>
          <Toolbar>
            <Typography variant="subtitle1">Target Categories</Typography>
            <div style={{ flexGrow: 1 }} />
            <IconButton>
              <i className="fa fa-plus"></i>
            </IconButton>
          </Toolbar>
          <Table>
            <TableBody>
              {formDiscountTargets.map((category: ICategoryData, key) => (
                <TableRow key={key}>
                  <TableCell>{category.category_name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </>
  );
}
