declare var env: any;

export interface IErrorMessage {
    log_error_id: number,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date,
    log_error_name: string,
    core_campaign_id: number,
    core_agent_id: number,
    contact_id: number,
    session_id: string,
    oms_url: string,
    api_url: string,
    script_node: string,
}

export const fetchErrorMessages = async (o) => {
    const { from_date, to_date, campaign_id, client_id, company_id, department_id } = o;
    let searchStr = "/log/logErrorMessages";

    if (from_date) {
        searchStr = searchStr + "?from_date=" + from_date;
    }
    if (company_id) {
        searchStr = searchStr + "&company_id=" + company_id;
    }
    if (campaign_id) {
        searchStr = searchStr + "&campaign_id=" + campaign_id;
    }
    if (client_id) {
        searchStr = searchStr + "&client_id=" + client_id;
    }
    if (department_id) {
        searchStr = searchStr + "&department_id" + department_id;
    }
    if (to_date) {
        searchStr = searchStr + "&to_date=" + to_date;
    }
    console.log(searchStr)

    const res = await fetch(env._apiPath + searchStr, {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    const json = await res.json();
    if(json.status === "success") {
        return json.data;
    }
    throw json.message;
}