declare var env: any;

const fetchRejectedReasons = async (clientID, rejectedType) => {
  const req = {
    client_id: clientID,
    rejected_type: rejectedType
  };

  const res = await fetch(env._apiPath + "/campaign/orderRejectedReasons/read", {
    method: "POST",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(req)
  });
  const json = await res.json();

  if (json.status === "success") {
    return json;
  }

  throw json.message;
};

const createRejectedReason = async (reasonName, clientID, rejectedType) => {
  const req = {
    reason_name: reasonName,
    client_id: clientID,
    hide_in_list: 0,
    rejected_type: rejectedType
  };

  const res = await fetch(env._apiPath + "/campaign/orderRejectedReasons/create", {
    method: "POST",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(req)
  });
  const json = await res.json();

  if (json.status === "success") {
    return;
  }

  throw json.message;
};

const updateRejectedReason = async (reasonID, reasonName, clientID, rejectedType) => {
  const req = {
    reason_id: reasonID,
    reason_name: reasonName,
    client_id: clientID,
    hide_in_list: 0,
    rejected_type: rejectedType
  };

  const res = await fetch(env._apiPath + "/campaign/orderRejectedReasons/update", {
    method: "POST",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(req)
  });
  const json = await res.json();

  if (json.status === "success") {
    return;
  }

  throw json.message;
};

const deleteRejectedReason = async reasonID => {
  const req = {
    reason_id: reasonID
  };

  const res = await fetch(env._apiPath + "/campaign/orderRejectedReasons/delete", {
    method: "POST",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(req)
  });
  const json = await res.json();

  if (json.status === "success") {
    return;
  }

  throw json.message;
};

export default {
  fetchRejectedReasons,
  createRejectedReason,
  updateRejectedReason,
  deleteRejectedReason,
};
