import { format } from "date-fns";

declare var env: any;

export async function getDiscounts(formId: number) {
  const res = await fetch(env._apiPath + "/formDiscounts/" + formId, {
    method: "GET",
    credentials: "include",
  });
  const json = await res.json();

  if (json.status === "success") {
    return json.data;
  }

  throw json.message;
}

export async function addDiscount(
  formId: number,
  name: string,
  refId: string,
  discountType: string,
  discountPrice: string,
  discountPercent: string,
  campaignId: string,
  automaticRank: string,
  conditionAmount: string,
  discountLevel: string,
  automatic: string,
  calculate: string,
  smsText: string,
  dateFrom: Date | null,
  dateTo: Date | null
) {
  const req = {
    form_id: formId,
    name: name,
    ref_id: refId,
    discount_type: discountType,
    discount_price: discountPrice,
    discount_percent: discountPercent,
    campaign_id: campaignId,
    automatic_rank: automaticRank,
    condition_amount: conditionAmount,
    discount_level: discountLevel,
    automatic: automatic,
    calculate: calculate,
    sms_text: smsText,
    valid_from: "",
    valid_to: "",
  };

  if (dateFrom !== null) {
    req.valid_from = format(dateFrom, "");
  }

  if (dateTo !== null) {
    req.valid_to = format(dateTo, "");
  }

  const res = await fetch(env._apiPath + "/formDiscount", {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(req),
  });
  const json = await res.json();

  if (json.status === "success") {
    return;
  }

  throw json.message;
}

export async function editDiscount(
  formDiscountId: number,
  name: string,
  refId: string,
  discountType: string,
  discountPrice: string,
  discountPercent: string,
  campaignId: string,
  automaticRank: string,
  conditionAmount: string,
  discountLevel: string,
  automatic: string,
  calculate: string,
  smsText: string,
  dateFrom: Date | null,
  dateTo: Date | null
) {
  const req = {
    name: name,
    ref_id: refId,
    discount_type: discountType,
    discount_price: discountPrice,
    discount_percent: discountPercent,
    campaign_id: campaignId,
    automatic_rank: automaticRank,
    condition_amount: conditionAmount,
    discount_level: discountLevel,
    automatic: automatic,
    calculate: calculate,
    sms_text: smsText,
    valid_from: "",
    valid_to: "",
  };

  if (dateFrom !== null) {
    req.valid_from = format(dateFrom, "yyyy-MM-dd");
  }

  if (dateTo !== null) {
    req.valid_to = format(dateTo, "yyyy-MM-dd");
  }

  const res = await fetch(env._apiPath + "/formDiscount/"+formDiscountId, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(req),
  });
  const json = await res.json();

  if (json.status === "success") {
    return;
  }

  throw json.message;
}

export async function removeDiscount(formDiscountId: number) {
  const res = await fetch(env._apiPath + "/formDiscount/" + formDiscountId, {
    method: "DELETE",
    credentials: "include",
  });
  const json = await res.json();

  if (json.status === "success") {
    return;
  }

  throw json.message;
}

export async function addDiscountCondition(formDiscountId: string, formCategoryId: string, formCategoryProductId: string) {
  const req = {
    form_discount_id: formDiscountId,
    form_category_id: formCategoryId,
    form_category_product_id: formCategoryProductId
  };
  const res = await fetch(env._apiPath + "/formDiscountCondition", {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(req),
  });
  const json = await res.json();

  if (json.status === "success") {
    return;
  }

  throw json.message;
}

export async function deleteDiscountCondition(formDiscountConditionId: string) {
  const res = await fetch(env._apiPath + "/formDiscountCondition/"+formDiscountConditionId, {
    method: "DELETE",
    credentials: "include"
  });
  const json = await res.json();

  if (json.status === "success") {
    return;
  }

  throw json.message;
}

export async function addDiscountTarget(formDiscountId: string, formCategoryId: string) {
  const req = {
    form_discount_id: formDiscountId,
    form_category_id: formCategoryId,
  };
  const res = await fetch(env._apiPath + "/formDiscountTarget", {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(req),
  });
  const json = await res.json();

  if (json.status === "success") {
    return;
  }

  throw json.message;
}

export async function deleteDiscountTarget(formDiscountTargetId: string) {
  const res = await fetch(env._apiPath + "/formDiscountTarget/"+formDiscountTargetId, {
    method: "DELETE",
    credentials: "include"
  });
  const json = await res.json();

  if (json.status === "success") {
    return;
  }

  throw json.message;
}
