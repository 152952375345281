import { format } from "date-fns/esm";

declare var env: any;

export interface IReportQuery {
  dispatch?: any;
  selectedClient: string;
  selectedCompany: string;
  selectedStartDate: Date;
  selectedEndDate: Date;
  selectedCampaignType: string;
  selectedCampaign: string;
  sortColumns?: any;
  reportFilter?: any;
}

export const fetchMapSegments = async (repQuery: IReportQuery) => {
  let companyId = "";
  let clientId = "";
  let campaignTypeId = "";
  let campaignId = "";

  if (repQuery.selectedCompany) {
    companyId = repQuery.selectedCompany;
  }

  if (repQuery.selectedClient) {
    clientId = repQuery.selectedClient;
  }

  if (repQuery.selectedCampaignType) {
    campaignTypeId = repQuery.selectedCampaignType;
  }

  if (repQuery.selectedCampaign) {
    campaignId = repQuery.selectedCampaign;
  }

  const req = {
    company_id: companyId,
    client_id: clientId,
    campaign_type_id: campaignTypeId,
    campaign_id: campaignId,
    start_date: format(repQuery.selectedStartDate, "yyyy-MM-dd"),
    end_date: format(repQuery.selectedEndDate, "yyyy-MM-dd")
  };

  const res = await fetch(env._apiPath + "/listManagement/mapsegmentsagg", {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(req),
    headers: { "Content-Type": "application/json" }
  });
  const json = await res.json();
  if (json.status === "success") {
    return json.data;
  }
};

export const fetchSegmentDetails = async (segmentId: string) => {
  const req = {
    segment_id: segmentId
  };
  const res = await fetch(env._apiPath + "/listManagement/mapsegmentdetails", {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(req),
    headers: { "Content-Type": "application/json" }
  });
  const json = await res.json();
  if (json.status !== "success") {
    throw new Error("Unable to get segment details: " + json.message);
  }

  if (!json.data[0]) {
    throw new Error("Unable to get segment details.");
  }

  return json.data;
};

export const saveSegmentDetails = async (segmentDetails, campaignId) => {
  const areaIds: string[] = [];
  for (const i in segmentDetails.areas) {
    const o = segmentDetails.areas[i];
    areaIds.push(o.area_id);
  }

  const req = {
    segment_id: segmentDetails.segment_id,
    segment_name: segmentDetails.segment_name,
    date_from: format(segmentDetails.date_from, "yyyy-MM-dd"),
    date_to: format(segmentDetails.date_to, "yyyy-MM-dd"),
    freeze_date_to: format(segmentDetails.freeze_date_to, "yyyy-MM-dd"),
    areas: areaIds,
    campaign_id: null
  };

  if (req.segment_id === "0") {
    req.campaign_id = campaignId;
  }

  const res = await fetch(env._apiPath + "/listManagement/mapsupdatesegment", {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(req),
    headers: { "Content-Type": "application/json" }
  });
  const json = await res.json();
  if (json.status !== "success") {
    throw new Error("Unable to update segment details: " + json.message)
  }
};

export const fetchAreaResult = async (areaTypeId: string, searchString: string, searchBounds = null) => {
  const req = {
    search: searchString,
    area_type_id: areaTypeId,
    bounds: null
  };

  if (searchBounds) {
    req.bounds = searchBounds;
  }

  const res = await fetch(env._apiPath + "/listManagement/areasearch", {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(req),
    headers: { "Content-Type": "application/json" }
  });
  const json = await res.json();
  if (json.status !== "success") {
    throw new Error("Unable to retrieve search result: " + json.message);
  }

  return json.data;
};
