declare var env: any;

export interface Outcomes {
    client_id?: number,
    client_name?: string,
    created_at: string,
    customer_id?: number,
    outcome_color?: string,
    outcome_icon?: string,
    outcome_id: number,
    outcome_name: string,
    outcome_reason_count?: number,
    outcome_status_description: string,
    outcome_status_external_ref: string,
    outcome_status_id: number,
    outcome_status_name: string
}
export interface Soundfile {
    created_at: string,
    activity_id: string,
    activity_file_detail_id: number,
    activity_file_id: number,
    phone: string,
    firstname: string,
    lastname?: string,
    login_name: string,
    client_name: string,
    campaign_stype_name: string,
    campaign_name: string,
    outcome_name: string,
    soundfile_url: string,
    client_id: string,
    contact_id: string,
    customer_id: string,
    order_id: string,
    confirmed_at?: unknown,
    rejected_at?: unknown,
    new_soundfile?: unknown,
    regretted_at?: unknown,
    accepted_at?: unknown
    order_action_comment?: string,
    order_rejected_reason_id?: string
}
export const fetchRejectReasons = async () => {
    const res = await fetch(env._apiPath + "/campaign/orderRejectedReasons/read", {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    const json = await res.json();

    if(json.status === "success") {
        return json.data;
    }

    throw json.message;
}


export const rejectSoundfile = async (props:Soundfile) => {
    const req = {
        activity_file_detail_id: props.activity_file_detail_id,
        order_rejected_reason_id: props.order_rejected_reason_id,
        reason: props.order_action_comment
      };
    const res = await fetch(env._apiPath + "/file/rejectSoundFile", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(req)
    });
    const json = await res.json();

    if(json.status === "success") {
        return json.data;
    }

    throw json.message;
}
/*
soundfileApproval(selectedFile, 0); rejectSoundfile( selectedFile ); 
*/
export const soundfileApproval = async (props:Soundfile, status: number) => {
    const req = {
        activity_id: props.activity_id,
        activity_file_detail_id: props.activity_file_detail_id,
        status: status
      };
    const res = await fetch(env._apiPath + "/file/updateSoundFileStatus", {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(req)
    });
    const json = await res.json();

    if(json.status === "success") {
        return json.data;
    }

    throw json.message;
}

export const fetchSoundfiles = async (props) => {
    let text = props.text;
    let startDate = "";
    let endDate = "";
    let outcome = "";
    if (props.text === "" ) {text = "?"}
    if (props.startDate !== "" ) {startDate = `&from_date=${props.startDate}`}
    if (props.endDate !== "" ) {endDate = `&to_date=${props.endDate}`}
    if (props.outcome !== "" ) {outcome = `&search_outcome_id=${props.outcome}`}
    const res = await fetch(env._apiPath + "/file/searchSoundfile" + text + startDate + endDate + outcome, {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    const json = await res.json();

    if(json.status === "success") {
        return json.data;
    }

    throw json.message;
} 

export const fetchOutcomes = async () => {
    const res = await fetch(env._apiPath + "/outcomes", {
        method: "GET",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
    });
    const json = await res.json();

    if(json.status === "success") {
        return json.data;
    }

    throw json.message;
} 