import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardActions,
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import {
  GridApi,
  ColumnApi,
  GridReadyEvent,
  FirstDataRenderedEvent,
  Column,
  RowDoubleClickedEvent,
  SelectionChangedEvent
} from "ag-grid-community";
import { AgToolbarButton } from "../../../agGridUtils";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { useSelector, useDispatch } from "react-redux";
import { ICategoriesProps, ICategoryData } from "./form.types";
import { getCategories, removeCategory, addCategory } from "./categories.api";
import { actionReceivedCategories, actionReceivedFormData } from "../../../redux/reducers/campaignmanagement/forms";
import { getFormData } from "./edit.api";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexGrow: {
      flexGrow: 1
    },
    actions: {
      "& button.aggrid-btn": {
        //marginTop: 24
      }
    },
    agGrid: {
      display: "flex",
      flexDirection: "column",
      "& .aggrid-size": {
        minHeight: 500,
        //height: 500, //"calc(100vh - 460px)",
        flex: 1,
        width: "100%"
      }
    },
    dialogPaper: { overflow: "visible" }
  })
);

const toolbarProps = {
  title: "",
  rightAlign: false,
  disabled: false,
  icon: "",
  onClick: () => {}
};

export default function TabCategoriesComponent(props: ICategoriesProps) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const rowData = useSelector(state => state.forms.edit.categories);
  const selectedCampaign = useSelector(state => state.shell.selectedCampaign);
  const formData = useSelector(state => state.forms.edit.formData);

  const [gridAPI, setGridAPI] = useState<GridApi>();
  const [columnAPI, setColumnAPI] = useState<ColumnApi>();
  const [selectedRow, setSelectedRow] = useState<ICategoryData | null>(null);
  const [showRemoveCategory, setShowRemoveCategory] = useState(false);
  const [showAddCategory, setShowAddCategory] = useState(false);

  const [newCategoryName, setNewCategoryName] = useState("");
  const [newCategoryStep, setNewCategoryStep] = useState("");
  const [newCategoryLevel, setNewCategoryLevel] = useState("");
  const [newCategoryPassword, setNewCategoryPassword] = useState("");

  useEffect(() => {
    getCategories(props.formId)
      .then(categories => dispatch(actionReceivedCategories(categories)))
      .catch(console.log);
    if (formData.form_id !== props.formId) {
      getFormData(props.formId).then(newFormData => {
        dispatch(actionReceivedFormData(newFormData));
      });
    }
  }, [props.formId, formData.form_id, dispatch]);

  const columnDefs = [
    {
      headerName: "Category",
      field: "category_name",
      cellRenderer: function(params) {
        if (params.data)
          return (
            "<a href=\"javascript:void(0);\" onclick=\"getPage(['campaign_management', 'offer','categoryProducts'," +
            params.data.category_id +
            ']);">' +
            params.value +
            "</a>"
          );
      }
    },
    { headerName: "Step", field: "step", sort: "asc" },
    {
      headerName: "Hidden",
      field: "hidden",
      cellRenderer: function(params) {
        if (params.data) {
          var appliedIcon =
            params.value > 0 ? '<i class="fas fa-fw text-success fa-check"></i>' : '<i class="fas fa-fw fa-minus"></i>';
          return appliedIcon;
        }
      }
    },
    {
      headerName: "Required",
      field: "required",
      cellRenderer: function(params) {
        if (params.data) {
          var appliedIcon =
            params.value > 0 ? '<i class="fas fa-fw text-success fa-check"></i>' : '<i class="fas fa-fw fa-minus"></i>';
          return appliedIcon;
        }
      }
    },
    { headerName: "Level", field: "category_level" },
    { headerName: "Removed", field: "removed_at" },
    { headerName: "Created", field: "created_at" }
  ];

  const onGridReady = (event: GridReadyEvent) => {
    window.addEventListener("resize", () => setTimeout(() => event.api.sizeColumnsToFit()));
    event.api.sizeColumnsToFit();
  };

  const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
    event.api.sizeColumnsToFit();
  };

  const onAdjustColumnsContent = (event: Event) => {
    var allColumnIds: string[] = [];
    columnAPI!.getAllColumns().forEach((column: Column) => {
      if (!column.getColDef().suppressAutoSize) {
        allColumnIds.push(column.getColId());
      }
    });
    columnAPI!.autoSizeColumns(allColumnIds);
  };

  const onAdjustColumnsWindow = (event: Event) => {
    gridAPI!.sizeColumnsToFit();
  };

  const onRefreshList = (event: Event) => {
    getCategories(props.formId)
      .then((categories: ICategoryData[]) => dispatch(actionReceivedCategories(categories)))
      .catch(console.log);
  };

  const onRowDoubleClicked = (event: RowDoubleClickedEvent) => {};

  const onSelectionChanged = (event: SelectionChangedEvent) => {
    const rows = event.api.getSelectedRows();
    if (!rows[0]) {
      return;
    }

    setSelectedRow(rows[0]);
  };

  const onRemoveCategory = () => {
    removeCategory(selectedRow!.category_id)
      .then(() => {
        getCategories(props.formId)
          .then((categories: ICategoryData[]) => dispatch(actionReceivedCategories(categories)))
          .catch(console.log);
      })
      .catch(console.log);
    setShowRemoveCategory(false);
  };

  const onAddCategory = () => {
    addCategory(props.formId, newCategoryName, newCategoryStep, newCategoryLevel, newCategoryPassword)
      .then(() => {
        getCategories(props.formId)
          .then((categories: ICategoryData[]) => dispatch(actionReceivedCategories(categories)))
          .catch(console.log);
      })
      .catch(console.log);
    setShowAddCategory(false);
  };

  const categorySteps: any = [];
  for (let i = 1; i < 100; i++) {
    categorySteps.push(<MenuItem value={i}>{i}</MenuItem>);
  }

  return (
    <Card>
      <CardActions disableSpacing className={classes.actions}>
        {AgToolbarButton({
          ...toolbarProps,
          title: "Add Category",
          icon: "fa-plus",
          onClick: () => setShowAddCategory(true)
        })}

        {AgToolbarButton({
          ...toolbarProps,
          title: "Remove Category",
          icon: "fa-trash",
          onClick: () => setShowRemoveCategory(true),
          disabled: selectedRow === null
        })}

        <div className={classes.flexGrow} />

        {AgToolbarButton({
          ...toolbarProps,
          title: "Refresh",
          icon: "fa-sync-alt",
          onClick: onRefreshList
        })}

        {AgToolbarButton({
          ...toolbarProps,
          title: "Adjust columns to window",
          icon: "fa-columns",
          onClick: onAdjustColumnsWindow
        })}

        {AgToolbarButton({
          ...toolbarProps,
          title: "Adjust columns to content",
          icon: "fa-text-width",
          onClick: onAdjustColumnsContent
        })}
      </CardActions>
      <CardContent>
        <Grid container>
          <Grid item md={12} className={classes.agGrid}>
            <div className="ag-theme-balham aggrid-size">
              <AgGridReact
                columnDefs={columnDefs}
                rowData={rowData}
                groupIncludeTotalFooter={false}
                groupIncludeFooter={false}
                groupMultiAutoColumn={true}
                animateRows={true}
                enableRangeSelection={false}
                onSelectionChanged={onSelectionChanged}
                rowSelection="multiple"
                onGridReady={onGridReady}
                onFirstDataRendered={onFirstDataRendered}
                onRowDoubleClicked={onRowDoubleClicked}
                sideBar={{
                  toolPanels: ["columns", "filters"]
                }}
                suppressAggFuncInHeader={true}
                defaultColDef={{
                  sortable: true,
                  resizable: true,
                  filter: true
                }}
                getRowClass={params => {
                  if (params.data.used_in_campaign == 1) {
                    return "bg-success";
                  }
                }}
              />
            </div>
          </Grid>
        </Grid>

        <Dialog
          open={showRemoveCategory}
          onClose={() => setShowRemoveCategory(false)}
          aria-labelledby="alert-catremove-title"
          aria-describedby="alert-catremove-description"
        >
          <DialogTitle id="alert-catremove-title">Remove Category</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-catremove-description">
              Are you sure you want to remove this category?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowRemoveCategory(false)} color="secondary" autoFocus>
              No
            </Button>
            <Button onClick={onRemoveCategory} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={showAddCategory}
          onClose={() => setShowAddCategory(false)}
          aria-labelledby="alert-catadd-title"
          aria-describedby="alert-catadd-description"
        >
          <DialogTitle id="alert-catadd-title">Add Category</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <TextField
                  label="Name"
                  type="text"
                  fullWidth
                  value={newCategoryName}
                  onChange={e => setNewCategoryName(e.target.value)}
                />
              </Grid>
              <Grid item md={6}>
                <FormControl fullWidth>
                  <InputLabel id="drop-step"></InputLabel>
                  <Select
                    id="drop-step"
                    fullWidth
                    value={newCategoryStep}
                    onChange={e => setNewCategoryStep(e.target.value as string)}
                  >
                    {categorySteps}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <TextField
                  label="Level"
                  type="text"
                  fullWidth
                  value={newCategoryLevel}
                  onChange={e => setNewCategoryLevel(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={12}>
                <TextField
                  label="Password"
                  type="text"
                  fullWidth
                  value={newCategoryPassword}
                  onChange={e => setNewCategoryPassword(e.target.value)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowAddCategory(false)} color="secondary" autoFocus>
              Cancel
            </Button>
            <Button onClick={onAddCategory} color="primary">
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
}
