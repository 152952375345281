/**
 * Function for saving user settings etc in the browser.
 *
 * @param key Option key name
 * @param value Option value
 */
export function setOption(key: string, value: any) {
  localStorage.setItem(key, JSON.stringify(value));
}

/**
 * Function for getting user settings etc in the browser.
 *
 * @param key Option key name
 * @param defaultValue Option default value
 */
export function getOption(key: string, defaultValue: any) {
  let res = localStorage.getItem(key);
  if (res === null) {
    return defaultValue;
  }

  return JSON.parse(res);
}

export default {
  setOption,
  getOption,
  MAPS_AREA_TYPE: "maps.areaType",
  MAPS_DEFAULT_POS: "maps.defaultpos",
  FILTER_VIEW_TYPE: "directories.filterViewType",
  DIRECTORY_LIST_SELECTION: "directories.listSelection",
  STATISTICS_SELECTIONS: "statistics.selections",
  REPORT_ORDER_SELECTIONS: "report.orders.selections",
  REPORT_OUTBOUND_SELECTIONS: "report.outbound.selections",
  LM_MAPS_SELECTIONS: "listmanagement.maps.selections",
  CAMPAIGNS_FORMS_SELECTED_TAB: "campaigns.forms.selectedtab",
  SALESREPORT_SELECTED_TAB: "salesreport.selectedtab"
};
