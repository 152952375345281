import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TitlebarComponent from "../../titlebar.component";
import {
    Grid,
    CardContent,
    Card,
    CardActions,
    makeStyles,
    Theme,
    createStyles,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    TextField,
} from "@material-ui/core";
import {
    FirstDataRenderedEvent,
    RowDoubleClickedEvent,
    SelectionChangedEvent,
    GridApi,
    ColumnApi,
} from "ag-grid-community";
import "ag-grid-enterprise";
import "ag-grid-enterprise/chartsModule";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import {
    searchFields,
    ElhubController,
    NumberController,
    DSFController,
    ZipController,
    ConfigController
} from "./elhub/elhub.api";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        errorMessage: {
            color: "#ff0000"
        },
        flexGrow: {
            flexGrow: 1,
        },
        actions: {
            "& .form-group": {
                minWidth: 200,
                marginRight: theme.spacing(1),
                marginBottom: theme.spacing(1),
            },
            "& button": {
                marginTop: 24,
            },
        },
        agGrid: {
            "& .aggrid-size": {
                minHeight: 500,
                height: "calc(100vh - 460px)",
                width: "100%",
            },
        },
    })
);

const toolbarProps = {
    title: "",
    rightAlign: false,
    disabled: false,
    icon: "",
    onClick: () => { },
};

interface IProps {
    match?: any;
}

const newSearchFields: searchFields = {
    phoneNumber: '',
    firstname: '',
    lastname: '',
    birthday: new Date(),
    streetName: '',
    buildingNumber: '',
    postCode: '',
    city: '',
    meteringPoint: '',
    companyVat: '',
    socialSecurityNo: '',
    socialSecurityNoView: '',
    meteringFacility: ''
}

interface KeyToVal {
    key: String,
    val: String,
    style?: React.CSSProperties
}

interface iValError {
    phoneNumber: String,
    firstname: String,
    lastname: String,
    birthday: String,
    streetName: String,
    buildingNumber: String,
    postCode: String,
    city: String,
    meteringPoint: String,
    companyVat: String,
    socialSecurityNo: String,
    socialSecurityNoView: String,
    meteringFacility: String
}
const newValError:iValError = {
    phoneNumber: '',
    firstname: '',
    lastname: '',
    birthday: '',
    streetName: '',
    buildingNumber: '',
    postCode: '',
    city: '',
    meteringPoint: '',
    companyVat: '',
    socialSecurityNo: '',
    socialSecurityNoView: '',
    meteringFacility: ''
};

interface iElhubError {
    nshz: boolean, nm: boolean, zm: boolean, osbz: boolean, om: boolean, p: boolean, hasSearched: boolean 
}
const newElhubError:iElhubError = {
    nshz: false, nm: false, zm: false, osbz: false, om: false, p: false, hasSearched: false
}

export default function ElhubPlugin(props: IProps) {    
    const dispatch = useDispatch();
    const classes = useStyles();
    const [searchQueue,setSearchQueue] = useState(0);

    const [elhubFields, setElhubFields] = useState<searchFields>(newSearchFields);
    const [elhubBirthday, setElhubBirthday] = useState<Date>(new Date());
    const [hasSearched, setHasSearched] = useState(false);
    const [config, setConfig] = useState({ elhub: false, dsf: false, number: false });

    const currentUser = useSelector(state => state.shell.user);

    const [elhubValidation, setElhubValidation] = useState<iElhubError>(newElhubError);
    const [validationErrors, setValidationErrors] = useState<iValError>(newValError);
    const hideFilters = useSelector((state) => state.shell.hideFilters);

    const [confirmDeleteTarget, setConfirmDeleteTarget] = useState();
    const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);

    const [showDepartmentEditor, setShowDepartmentEditor] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [spinnerNumber, setSpinnerNumber] = useState(false);
    const [spinnerDSF, setSpinnerDSF] = useState(false);
    const [spinnerElhub, setSpinnerElhub] = useState(false);

    const [resultData, setResultData] = useState<KeyToVal[]>([]);
//    const [resultHead, setResultHead] = useState<KeyToVal[]>([]);

    const selectedCompany = useSelector((state) => state.shell.selectedCompany);

    const [gridAPI, setGridAPI] = useState<GridApi>();
    const [columnAPI, setColumnAPI] = useState<ColumnApi>();

    function createData(name, val) {
        return { name, val };
      }

      const ebixMap = {
        'E01': 'Profiled',
        'E02': 'Non-Profiled',
        'Z01': 'Not settled',
        'E13': 'AMS',
        'E14': 'Manual',
        'E16': 'Not measured',
        'E17': 'Consumer',
        'E18': 'Production',
        'E19': 'Combined',
        'E20': 'Exchange',
        'E22': 'Active',
        'E23': 'Inactive',
        'E31': 'Terminated'
    };
    const DSFFormFields = {
        'firstname': 'Firstname',
        'lastname': 'Lastname',
        'address': 'Address',
        'buildingNumber': 'House No',
        'unit': 'Unit',
        'postCode': 'Zip code',
        'city': 'City'
    }
    const NumberFields = {
        'fornavn': "Firstname", 
        'etternavn': "Lastname",
        'gate': "Street Name",
        'husnr': "House No",
        'bokstav': "House Letter",
        'postnr': "Zip code",
        'poststed': "City",
        'fdato': "Birthday"
    }

    const elhubFormFields = {
        'consumptionCode': 'Elhub search',
        'streetName': 'Street Name',
        'buildingNumber': 'House No',
//        'streetCode': 'Street Code',
        'postCode': 'Zip Code',
        'cityName': 'City',
        'annualEstimatedMetrics': 'Annual Estimate (kWh)',
        'meteringPointTypeId': 'Metering point type',
        'meteringPointId': 'Metering point',
        'meteringFacilityId': 'Metering facility',
        'meteringPointCharacterId': 'Metering point character',
        'lastMeterReadingDate': 'Last meter read',
        'deliveryObligationDate': 'Date for delivery obligation',
        'gridAccessProvider': 'Grid access provider',
        'settlementMethodTypeId': 'Settlement Method',
        'physicalStatusTypeId': 'Physical Status',
        'blockedForSwitching': 'Blocked for switching',
    }

    const consumptionMap = {
        '1': 'Agriculture, forestry and fishing',
        '1a': 'Of which greenhouses and greenhouses',
        '2': 'Mining',
        '3': 'Extraction of crude oil and natural gas',
        '4': 'Services relating to the extraction of crude oil and natural gas',
        '5': 'Production of pulp, paper and board',
        '6': 'Production of chemical raw materials',
        '7': 'Production of iron and steel',
        '8': 'Production of ferro-alloys',
        '9': 'Production of primary aluminum',
        '10': 'Manufacture of other non-ferrous metals',
        '11': 'Food industry',
        '12': 'Refineries',
        '13': 'Other industry',
        '14': 'Production, transmission, distribution and trade in electricity',
        '15': 'Production and distribution of gas through the pipeline network',
        '16': 'District heating',
        '17': 'Water supply, sewerage and waste management activities',
        '18': 'Construction',
        '19': 'Wholesale trade, repair of motor vehicles',
        '20': 'Railway, tramway and suburban railway',
        '21': 'Other transport and storage',
        '22': 'Postal and distribution services',
        '23': 'Accommodation and catering activities',
        '24': 'Information and communication',
        '25': 'Financial services, insurance and pension funds',
        '26': 'Sale and operation of real estate',
        '27': 'Professional, scientific and technical services',
        '28': 'Business services',
        '29': 'Public Administration and Defense',
        '29a': 'Of which Street and Road Lights',
        '30': 'Teaching',
        '31': 'Health and social services',
        '32': 'Artistic activities, libraries, etc., sports and leisure',
        '33': 'Activities in member organizations',
        '34': 'Services otherwise',
        '35': 'Households',
        '36': 'Cottages & Holiday houses'
    }

    useEffect(() => {
        if (searchQueue) {
            console.log("############## did a search" + searchQueue);
            search();
        } else  {
            console.log("-------------- didn't search");
        }
    },[searchQueue]);

    useEffect(() => {
        /*
        TODO: 
            gln-nummer på kraftleverandør
            highlight på dsf
        */
        ConfigController().then((foobar: any) => new Promise(function(resolve,reject) {
            setConfig(foobar);
            if (!foobar.elhub && !foobar.dsf && !foobar.number) {
                var output:KeyToVal[] = [];
                const outputLine:KeyToVal = {
                    key: 'Error!',
                    val: ''
                };
                output.push(outputLine);
                const outputLine2:KeyToVal = {
                    key: 'No search options is configured',
                    val: ''
                };
                output.push(outputLine2);
                setResultData(output);

//                var oheader:KeyToVal[] = [];
  //              setResultHead(oheader);
            }
            resolve(foobar);
        }))
    },[]);

    const clearValidationErrors = () => {
        setValidationErrors(newValError);
        setElhubValidation(newElhubError);
    }

    const elhubRules = {        
        1: ['socialSecurityNo', 'streetName', 'buildingNumber','postCode'],
        2: ['companyVat', 'streetName','buildingNumber','postCode'],
        3: ['postCode', 'metringFacility'],
        4: ['socialSecurityNo','metringFacility'],
        5: ['companyVat','metringFacility'],
        6: ['meteringPoint']
    }

    const searchElhub = () => {
        var output:KeyToVal[] = [];
        setResultData(output);        
        setSpinnerElhub(true);

        var validated = true;
        var valErrors:iValError = { ...newValError };

        var types = [];

        var valErrors:iValError = { ...newValError };
        var elhubErrors:iElhubError = { ...newElhubError };
        var validated = true;

        if (elhubFields.socialSecurityNo != undefined && elhubFields.socialSecurityNo.length) {
            /*
            if ((!elhubFields.streetName.length || 
                !elhubFields.buildingNumber.length || 
                (elhubFields.postCode != undefined && !elhubFields.postCode.length)) && (elhubFields.meteringFacility != undefined && !elhubFields.meteringFacility.length)) {
                if (!elhubFields.streetName.length) { valErrors.streetName = 'Required'; validated = false; }
               // if (!elhubFields.buildingNumber.length) { valErrors.buildingNumber = 'Required'; validated = false; }
                if (elhubFields.postCode != undefined && !elhubFields.postCode.length) { valErrors.postCode = 'Required'; validated = false; }


            hvis postnr + husnr
            postnr + gatenavn
            gatenavn + husnr
            */

            if (!((elhubFields.postCode != undefined && elhubFields.postCode.length && elhubFields.buildingNumber.length) ||
                (elhubFields.postCode != undefined && elhubFields.streetName.length) ||
                (elhubFields.streetName.length && elhubFields.buildingNumber.length)) &&
                (elhubFields.meteringFacility != undefined && !elhubFields.meteringFacility.length)) {
                
                if (!elhubFields.streetName.length) { valErrors.streetName = 'Required'; validated = false; }
               // if (!elhubFields.buildingNumber.length) { valErrors.buildingNumber = 'Required'; validated = false; }
                if (elhubFields.postCode != undefined && !elhubFields.postCode.length) { valErrors.postCode = 'Required'; validated = false; }

                if (!validated) {
                    elhubErrors.nshz = true;
                }
            } else {
                if (elhubFields.meteringFacility != undefined && !elhubFields.meteringFacility.length) valErrors.meteringFacility = 'Required';

                if (!validated) {
                    elhubErrors.nm = true;
                }
            }
        } else if (elhubFields.companyVat != undefined && elhubFields.companyVat.length) {
            if ((!elhubFields.streetName.length || elhubFields.buildingNumber != '' || elhubFields.postCode != '') && (elhubFields.meteringFacility != undefined && !elhubFields.meteringFacility.length)) {
                if (!elhubFields.streetName.length) { valErrors.streetName = 'Required'; validated = false; }
                //if (!elhubFields.buildingNumber.length) { valErrors.buildingNumber = 'Required'; validated = false; }
                if (elhubFields.postCode != undefined && !elhubFields.postCode.length) { valErrors.postCode = 'Required'; validated = false; }

                if (!validated) {
                    elhubErrors.osbz = true;
                }
            } else {
                if (elhubFields.meteringFacility != undefined && !elhubFields.meteringFacility.length) { valErrors.meteringFacility = 'Required'; validated = false; }

                if (!validated) {
                    elhubErrors.om = true;
                }
            }
        } else if (elhubFields.meteringFacility != undefined && elhubFields.meteringFacility.length) {
            if (elhubFields.postCode != undefined && !elhubFields.postCode.length) { valErrors.postCode = 'Required'; validated = false; }

            if (!validated) {
                elhubErrors.zm = true;                
            }
        } else if (elhubFields.meteringPoint != undefined && elhubFields.meteringPoint.length) {
            // good to go
        } else {
            valErrors.socialSecurityNo = 'Required';
            valErrors.companyVat = 'Required';
            valErrors.meteringFacility = 'Required';
            valErrors.meteringPoint = 'Required';
            validated = false;
            elhubErrors = { nshz: true, nm: true, zm: true, osbz: true, om: true, p: true, hasSearched: false };
        } 
        setElhubValidation(elhubErrors);

        if (!validated) {
            setValidationErrors(valErrors);
            setSpinnerElhub(false);

            if (searchQueue == CHECK_ELHUB) { console.log("************** not elhub, check DSF"); setSearchQueue(CHECK_DSF); } // if it is a search queue, do the next
            return false;
        }

        clearValidationErrors();
       
        var updates:searchFields = elhubFields;
        ElhubController({ ...elhubFields, birthday: elhubBirthday })
        .then((foobar: any)  => new Promise(function(resolve, reject) {            


            var output:KeyToVal[] = [];
//            var oheader:KeyToVal[] = [];
            var consumptionCode = ''

            Object.keys(foobar).forEach(function(i){               
//                if (foobar[i]['consumptionCode'] != undefined ) {
  //                  foobar[i]['consumptionCode']
    //            }
                if (isNaN(+i)) {
                    var elhubErrors:iElhubError = { ...newElhubError };
                    switch (foobar[i]) {
                        case 'elhub1':
                        case 'elhub11':
                        case 'elhub12':
                        case 'elhub13':
                            elhubErrors.nshz = true;
                            break;
                        case 'elhub2':
                            elhubErrors.nm = true;
                            break;
                        case 'elhub3':
                            elhubErrors.zm = true;
                            break;
                        case 'elhub4':
                            elhubErrors.osbz = true;
                            break;
                        case 'elhub5':
                            elhubErrors.om = true;
                            break;
                        case 'elhub7':
                            elhubErrors.p = true;
                            break;
                    }
                    elhubErrors.hasSearched = true;
                    setElhubValidation(elhubErrors);
                    
                    console.log(foobar[i]);
                    return;
                }
                Object.keys(elhubFormFields).forEach(function(fb){                    
                    let oStyle:React.CSSProperties = {};
                    let val = foobar[i][ fb ];
                    if (fb == 'consumptionCode') {
                        oStyle.fontSize = '1.5em'; 
                        oStyle.fontWeight = 'bolder';
                        // add private/business based on type.
                        val = ((val == 35 || val == 36)?'Private':'Business') + ' - ' + consumptionMap[ val ];                
                    } else
                    if ((fb == 'lastMeterReadingDate' || fb == 'deliveryObligationDate') && val != null) {
                        console.log(val);
                        let date = new Date(val);                        
                        val = date.getDate() + '/' + (date.getMonth()+1) + '-' + date.getFullYear();
                    } else
                    if (ebixMap[ val ] !== undefined) {
                        val = ebixMap[ val ];
                    }
                    if (elhubFormFields[fb] !== undefined) {
                        fb = elhubFormFields[fb];
                    }
                    const outputLine:KeyToVal = {
                        key: fb,
                        val: val,
                        style: oStyle
                    };
                    output.push(outputLine);
                });
            });

        if (foobar[0] != undefined && foobar[0]['code'] != undefined && foobar[0]['code'] == 'E0H') {
            var output:KeyToVal[] = [];
            const outputLine:KeyToVal = {
                key: 'Error!',
                val: ''
            };
            output.push(outputLine);
            const outputLine2:KeyToVal = {
                key: 'No facility was found at the supplied address',
                val: ''
            };
            output.push(outputLine2);
        } 

        const outputLine:KeyToVal = {
            key: 'Error!',
            val: ''
        };
        //setResultHead(oheader);
        setResultData(output);
        
        if (foobar[0] != undefined && foobar[0]['meteringFacilityId'] != undefined && foobar[0]['meteringFacilityId'] !== undefined) {
            updates.meteringFacility = foobar[0]['meteringFacilityId'];
        }
        if (foobar[0] != undefined && foobar[0]['meteringPointId'] != undefined && foobar[0]['meteringPointId'] !== undefined) {
            updates.meteringPoint = foobar[0]['meteringPointId'];
        }
        if (foobar[0] != undefined && foobar[0]['buildingNumber'] != undefined && foobar[0]['buildingNumber'] !== undefined) {
            updates.buildingNumber = foobar[0]['buildingNumber'];
        }
        if (foobar[0] != undefined && foobar[0]['postCode'] != undefined && foobar[0]['postCode'] !== undefined) {
            updates.postCode = foobar[0]['postCode'];
        }
        if (foobar[0] != undefined && foobar[0]['streetName'] != undefined && foobar[0]['streetName'] !== undefined) {
            updates.streetName = foobar[0]['streetName'];
        }
        if (foobar[0] != undefined && foobar[0]['cityName'] != undefined && foobar[0]['cityName'] !== undefined) {
            updates.city = foobar[0]['cityName'];
        }

        setElhubFields({ ...elhubFields, ...updates });
        setSpinnerElhub(false);
        setSearchQueue(0); // no need to search more
        console.log("Done searching (elhub)");
        resolve(foobar);
    }));

    }

    const searchAll = () => { 
        setSearchQueue(CHECK_ELHUB);
//       https://dok.elhub.no/mdok/brs-markedsprosesser/brs-no-611-verifiser-grunndata-i-malepunkt
        /*

        searchQueue = 'checkElhub';
        searchQueue = 'checkDSF';
        searchQueue = 'checkNumber';
        searchQueue = 'searchNumber';
        searchQueue = 'searchDSF';
        searchQueue = 'searchElhub';

        prøver elhubsøk, feiler det så gå til neste, feiler det så gå til neste. 
        
        prøv elhub 
            - feil prøv dsf             checkdsf
            - ok end                    0

       prøv dsf
            - feil prøv nr              checknumber
            - ok prøv elhub             searchElhub

             if (searchQueue == CHECK_ELHUB) { searchQueue = CHECK_DSF; search(); } // if it is a search queue, do the next
        prøv nr
            - feil - FEIL               0
            - ok prøv dsf - elhub       searchDSF

            om konfigurert, kjør searchElhub. 
            feiler den og searchQueue er satt, gå til dsfsøk
            om dsfsøk feiler og searchQueue er satt, gå til nummersøk
            om nummersøk feiler og searchQueue er satt, gi feilmelding
            
            om nummersøk går, 
        */
    }
    const CHECK_ELHUB   = 1;
    const CHECK_DSF     = 2;
    const CHECK_NUMBER  = 3;
    const SEARCH_NUMBER = 4;
    const SEARCH_DSF    = 5;
    const SEARCH_ELHUB  = 6;

    const search = () => {
        switch (searchQueue) {
            case CHECK_ELHUB:
            case SEARCH_ELHUB:
                searchElhub();
                break;
            case CHECK_DSF:
            case SEARCH_DSF:
                searchDSF();
                break;
            case CHECK_NUMBER:
            case SEARCH_NUMBER:
                searchNumber();
               break;
            default: 
                setSearchQueue(0);
        }
    }

    const searchZip = ( zip ) => {
        if (zip != undefined && zip.toString().length == 4) {
            ZipController({ ...elhubFields, postCode: zip }).then((City: any)  => new Promise(function(resolve, reject) {            
                elhubFields.postCode = zip;
                setElhubFields({ ...elhubFields, city: City })
            }));
        }
    }



    const searchNumber = () => {
        var output:KeyToVal[] = [];
        setResultData(output);
        setSpinnerNumber(true);

        if (!(elhubFields.phoneNumber !== undefined && elhubFields.phoneNumber.length == 8)) {
            setValidationErrors({ ...newValError, phoneNumber: "Required" });
            setElhubValidation(newElhubError);
            setSpinnerNumber(false);
            if (searchQueue == CHECK_NUMBER) { console.log("Didn't find anything to search on (on numbersearch)"); setSearchQueue(searchQueue); } // if it is a search queue, do the next
            return false;
        }
        clearValidationErrors();

        var updates:searchFields = elhubFields;
        NumberController(elhubFields)
        .then((foobar: any)  => new Promise(function(resolve, reject) { 

            var output:KeyToVal[] = [];

            var birthdate = new Date();
            var anyOutput = false;

            let oStyle:React.CSSProperties = {};
            oStyle.fontSize = '1.5em'; 
            oStyle.fontWeight = 'bolder';
            const outputHeader:KeyToVal = {
                key: 'Number search',
                val: '',
                style: oStyle
            };
            output.push(outputHeader);

            Object.keys(NumberFields).forEach(function(fb){                
                if (NumberFields[ fb ] != undefined && foobar[fb] != '') {
//                    let oStyle:React.CSSProperties = {};
                    const outputLine:KeyToVal = {
                        key: NumberFields[ fb ],
                        val: foobar[fb]
                    };
                    if (foobar[fb] != undefined) {
                        anyOutput = true;
                        output.push(outputLine);
                    }                     
                }
            });
            if (anyOutput) {
                if (foobar['fornavn'] !== undefined) {
                    updates.firstname = foobar['fornavn'];
                }
                if (foobar['etternavn'] !== undefined) {
                    updates.lastname = foobar['etternavn'];
                }
                if (foobar['gate'] !== undefined) {
                    updates.streetName = foobar['gate'];
                }
                if (foobar['husnr'] !== undefined) {
                    updates.buildingNumber = foobar['husnr']; //  + foobar['bokstav']!==undefined?foobar['bokstav']:'';
                }
                if (foobar['postnr'] !== undefined) {
                    updates.postCode = (foobar['postnr']);
                }            
                if (foobar['poststed'] !== undefined) {
                    updates.city = foobar['poststed'];
                }
                if (foobar['fdato'] !== undefined) {
                    birthdate.setDate((foobar['f_dag']));
                    birthdate.setFullYear((foobar['f_aar']));
                    birthdate.setMonth((foobar['f_mnd'])-1);
                    setElhubBirthday(birthdate);

                }

                setElhubFields({ ...elhubFields, ...updates });

                if (searchQueue) { console.log("************** found number, do dsf"); setSearchQueue(SEARCH_DSF); }

            } else {
                var output:KeyToVal[] = [];
                const outputLine:KeyToVal = {
                    key: 'Error!',
                    val: ''
                };
                output.push(outputLine);
                const outputLine2:KeyToVal = {
                    key: 'Number is not in the database',
                    val: ''
                };
                output.push(outputLine2);
            }
            setResultData(output);
            setSpinnerNumber(false);
            resolve(foobar);
        }));
    }

    const searchDSF = () => {
        var output:KeyToVal[] = [];
        setResultData(output);
        setSpinnerDSF(true);

        var validated = true;
        var valErrors:iValError = { ...newValError };
        if (elhubFields.firstname == '') { valErrors.firstname = "Required"; validated = false; }
        if (elhubFields.lastname == '') { valErrors.lastname = "Required"; validated = false; }
//        if (elhubFields.birthday.getFullYear() == (new Date).getFullYear()) { valErrors.birthday = "Required"; validated = false; } 

        if (!validated) {
            setValidationErrors(valErrors);
            setElhubValidation(newElhubError);
            setSpinnerDSF(false);

/*            prøv dsf
                 - feil prøv nr              checknumber
                 - ok prøv elhub             searchElhub
  */   
            if (searchQueue == CHECK_DSF) { console.log("************** insufficient data for dsf, check number"); setSearchQueue(CHECK_NUMBER); } // if it is a search queue, do the next
            return false;
        }
        clearValidationErrors();

        var updates:searchFields = elhubFields;
        DSFController({ ...elhubFields, birthday: elhubBirthday })
        .then((foobar: any)  => new Promise(function(resolve, reject) {            
            //const result = foobar.map(fb => ({ label: fb.outcome_name, value: fb.outcome_id });

            var output:KeyToVal[] = [];

            var anyOutput = false;

            let oStyle:React.CSSProperties = {};
            oStyle.fontSize = '1.5em'; 
            oStyle.fontWeight = 'bolder';
            const outputHeader:KeyToVal = {
                key: 'Det Sentrale Folkeregisteret',
                val: '',
                style: oStyle
            };
            output.push(outputHeader);

            Object.keys(DSFFormFields).forEach(function(fb){                
                DSFFormFields['address'] = DSFFormFields['address'].replace(' ' + DSFFormFields['buildingNumber'],'');

                if (DSFFormFields[ fb ] != undefined && foobar[fb] != '') {
                    const outputLine:KeyToVal = {
                        key: DSFFormFields[ fb ],
                        val: foobar[fb]
                    };
                    anyOutput = true;
                    output.push(outputLine);
                }
            });
            if (anyOutput) {
                setResultData(output);

                if (foobar['birthDate'] !== undefined && foobar['socialSecurityNo'] !== undefined) {
                    updates.socialSecurityNo = (foobar['birthDate'] + foobar['socialSecurityNo']);                    
                    updates.socialSecurityNoView = (foobar['birthDate'] + '*****');
                }
                if (foobar['buildingNumber'] !== undefined) {
                    updates.buildingNumber = foobar['buildingNumber'];
                }
                if (foobar['postCode'] !== undefined) {
                    updates.postCode = (foobar['postCode']);
                }            
                if (foobar['address'] !== undefined) {
                    updates.streetName = foobar['address'].replace(' ' + foobar['buildingNumber'],'');
                }
                if (foobar['cityName'] !== undefined) {
                    updates.city = foobar['cityName'];
                }            
                setElhubFields({ ...elhubFields, ...updates });
                if (searchQueue) { console.log('************** found DSF data, use it in elhub'); setSearchQueue( SEARCH_ELHUB ); } 
            } else {
                var output:KeyToVal[] = [];
                const outputLine:KeyToVal = {
                    key: 'Error!',
                    val: ''
                };
                output.push(outputLine);
                const outputLine2:KeyToVal = {
                    key: 'Nothing was found in the DSF database. Check if the data is correct.',
                    val: ''
                };
                output.push(outputLine2);    
                setResultData(output);
            }
            setSpinnerDSF(false);
            resolve(foobar);
        }));
    }

    const clearSearch = () => {
        setElhubFields({ ...newSearchFields });
        var output:KeyToVal[] = [];
        setResultData(output);
        setElhubBirthday(new Date());
        setElhubValidation(newElhubError);
        setValidationErrors(newValError);
    }

    const onAdjustColumnsContent = (event: Event) => {
        var allColumnIds: any = [];
        columnAPI!.getAllColumns().forEach((column) => {
            const colDef = column.getColDef();
            if (!colDef.suppressAutoSize) {
                allColumnIds.push(column.getColId());
            }
        });
        columnAPI!.autoSizeColumns(allColumnIds);
    };

    const onAdjustColumnsWindow = (event: Event) => {
        gridAPI!.sizeColumnsToFit();
    };

    const onFirstDataRendered = (event: FirstDataRenderedEvent) => {
        event.api.sizeColumnsToFit();
    };

    const onRowDoubleClicked = (event: RowDoubleClickedEvent) => {
        setShowDepartmentEditor(true);
    };

    const onSelectionChanged = (event: SelectionChangedEvent) => {
        const rows = event.api.getSelectedRows();
        if (!rows[0]) {
            return;
        }
    };
/* 

Orgnr/Målenummer, Orgnr/Adresse/Husnr/Postnr, Fødselsdag/Postnr/Målenummer, Fødselsdag/Adresse/Husnr/Postnr
                {
            "streetName": "Follandsveien",
            "streetCode": null,
            "buildingNumber": "95",
            "postCode": "6532",
            "cityName": "AVERØY",
            "meteringPointId": "707057500082652648",
            "meteringFacilityId": "5706567277515313",
            "meteringPointTypeId": "E17",
            "meteringPointType": "Forbruker",
            "meteringPointCharacterId": "E13",
            "meteringPointCharacter": "AMS",
            "meteringGridAreaId": "50Y98QGPCG0GQL0T",
            "lastMeterReadingDate": "2020-10-18T22:00:00Z",
            "deliveryObligationDate": null,
            "gridAccessProvider": "7080005052788",
            "settlementMethodTypeId": "E02",
            "settlementMethodType": "Timesmålt",
            "physicalStatusTypeId": "E22",
            "physicalStatusType": "Aktiv",
            "blockedForSwitching": false,
            "annualEstimatedMetrics": 8226,
            "consumptionCode": "35",
            "consumptionString": "Husholdninger",
            "NACEDivisionCode": "XX"
        },
        {
            "streetName": "Follandsveien",
            "streetCode": null,
            "buildingNumber": "95",
            "postCode": "6532",
            "cityName": "AVERØY",
            "meteringPointId": "707057500082652631",
            "meteringFacilityId": "5706567277502986",
            "meteringPointTypeId": "E17",
            "meteringPointType": "Forbruker",
            "meteringPointCharacterId": "E13",
            "meteringPointCharacter": "AMS",
            "meteringGridAreaId": "50Y98QGPCG0GQL0T",
            "lastMeterReadingDate": "2020-10-18T22:00:00Z",
            "deliveryObligationDate": null,
            "gridAccessProvider": "7080005052788",
            "settlementMethodTypeId": "E02",
            "settlementMethodType": "Timesmålt",
            "physicalStatusTypeId": "E22",
            "physicalStatusType": "Aktiv",
            "blockedForSwitching": false,
            "annualEstimatedMetrics": 20851,
            "consumptionCode": "35",
            "consumptionString": "Husholdninger",
            "NACEDivisionCode": "XX"
        }  
Valideringsregler: Orgnr/Målenummer, Orgnr/Adresse/Husnr/Postnr, Fødselsdag/Postnr/Målenummer, Fødselsdag/Adresse/Husnr/Postnr



*/
    return (
        <div>
            <TitlebarComponent
                title="Søk i Elhub"
                description="Søk på elhub"
                breadcrumbs={[
                    { title: "Dashboard", link: "/" },
                    { title: "Plugins", link: "/plugins/elhub" }
                ]}
            />
            
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Card style={config.number ? {} : { display: 'none' }}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="standard-number"
                                        label="Phone"
                                        type="text"
                                        fullWidth
                                        error={validationErrors.phoneNumber !== ""}
                                        value={elhubFields.phoneNumber}
                                        onBlur={(e) => {
                                            if (!searchQueue && !hasSearched && e.target.value.length == 8) { searchNumber(); }
                                        }}
                                        onChange={(e) => { 
                                            setHasSearched(false);                                            
                                            let val = e.target.value;
                                            val = val.replace(/[^0-9]+/,'');
                                            setElhubFields({ ...elhubFields, phoneNumber: val })}}
                                        onKeyDown={(e) => {                                            
                                            if (e.key === 'Enter') {
                                                setHasSearched(true);
                                                searchAll();
                                            }
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <br />
                    <Card style={config.dsf ? {} : { display: 'none' }}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        id="Firstname"
                                        label="Firstname"
                                        type="text"
                                        autoFocus={searchQueue==1}
                                        fullWidth
                                        error={validationErrors.firstname !== ""}
                                        value={elhubFields.firstname}
                                        onChange={(e) => setElhubFields({ ...elhubFields, firstname: (e.target.value) })}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="Lastname"
                                        label="Lastname"
                                        type="text"
                                        fullWidth
                                        error={validationErrors.lastname !== ""}
                                        value={elhubFields.lastname}
                                        onChange={(e) => setElhubFields({ ...elhubFields, lastname: (e.target.value) })}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            variant="inline"
                                            id="birthday"
                                            label="Birthday"
                                            value={elhubBirthday}
                                            error={validationErrors.birthday !== ""}
                                            onChange={(date: any) => { 
                                                setElhubBirthday(date); 
                                                setElhubFields({ ...elhubFields, birthday: elhubBirthday });
                                            
                                            }}
                                            onError={console.log}
                                            format="dd.MM.yyyy"
                                            fullWidth
                                        />
                                    </MuiPickersUtilsProvider>

                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <br />
                    <Card style={config.elhub ? {} : { display: 'none' }}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    {(currentUser.customer_id == 20)?(
                                    <TextField
                                        id="standard-number"
                                        label="National ID"
                                        type="text"
                                        fullWidth
                                        value={elhubFields.socialSecurityNo}
                                        error={validationErrors.socialSecurityNo !== ""}
                                        onChange={(e) => { 
                                            let val = e.target.value;
                                            val = val.replace(/[^0-9]+/,'');
                                            setElhubFields({ ...elhubFields, socialSecurityNo: val })}}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    ):(
                                    <TextField
                                        id="standard-number"
                                        label="National ID"
                                        type="text"
                                        fullWidth
                                        value={elhubFields.socialSecurityNoView}
                                        error={validationErrors.socialSecurityNoView !== ""}
                                        onChange={(e) => { 
                                            let val = e.target.value;
                                            val = val.replace(/[^0-9]+/,'');
                                            setElhubFields({ ...elhubFields, socialSecurityNo: val, socialSecurityNoView: val })}}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="standard-number"
                                        label="Organisation number"
                                        type="text"
                                        fullWidth
                                        value={elhubFields.companyVat}
                                        error={validationErrors.companyVat !== ""}
                                        onChange={(e) => { 
                                            let val = e.target.value;
                                            val = val.replace(/[^0-9]+/,'');

                                            setElhubFields({ ...elhubFields, companyVat: val })}}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />                    
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="Street"
                                        label="Street"
                                        type="text"
                                        fullWidth
                                        value={elhubFields.streetName}
                                        error={validationErrors.streetName !== ""}
                                        onChange={(e) => setElhubFields({ ...elhubFields, streetName: (e.target.value) })}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        id="Housenumber"
                                        label="House nr"
                                        type="text"
                                        fullWidth
                                        value={elhubFields.buildingNumber}
                                        error={validationErrors.buildingNumber !== ""}
                                        onChange={(e) => {
                                            let val = e.target.value;
                                            val = val.replace(/[ ]+/,'');
                                            setElhubFields({ ...elhubFields, buildingNumber: (e.target.value) })}
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                />
                                </Grid>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={2}>
                                    <TextField
                                        id="zipcode"
                                        label="Zip code"
                                        type="text"
                                        fullWidth
                                        value={elhubFields.postCode}
                                        error={validationErrors.postCode !== ""}
                                        onChange={(e) => { 
                                            let val = e.target.value;
                                            val = val.replace(/[^0-9]+/,'');

                                            setElhubFields({ ...elhubFields, postCode: val }); searchZip(val) }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                />
                                </Grid>
                                <Grid item xs={4}></Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="city"
                                        label="City"
                                        type="text"
                                        fullWidth  
                                        disabled                                      
                                        value={elhubFields.city}
                                        error={validationErrors.city !== ""}
                                        onChange={(e) => setElhubFields({ ...elhubFields, city: e.target.value })}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="standard-number"
                                        label="Metering Point"
                                        type="text"
                                        fullWidth
                                        value={elhubFields.meteringPoint}
                                        error={validationErrors.meteringPoint !== ""}
                                        onChange={(e) => { 
                                            let val = e.target.value;
                                            val = val.replace(/[^0-9]+/,'');

                                            setElhubFields({ ...elhubFields, meteringPoint: val })}}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="standard-number"
                                        label="Metering facility"
                                        type="text"
                                        fullWidth
                                        value={elhubFields.meteringFacility}
                                        error={validationErrors.meteringFacility !== ""}
                                        onChange={(e) => { 
                                            let val = e.target.value;
                                            val = val.replace(/[^0-9]+/,'');

                                            setElhubFields({ ...elhubFields, meteringFacility: val })}}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <br />
                    <Card style={{ background: '#eee' }}>
                    <CardActions style={{ background: '#fff' }}>                          
                            <Button onClick={searchAll} style={config.number || config.dsf || config.elhub ? {} : { display: 'none' }}>Search</Button>
                            { spinnerNumber && config.number? <i className="fas fa-spinner fa-spin"></i> : ''}
                            <Button onClick={searchNumber} style={config.number ? {} : { display: 'none' }}>Number</Button>
                            { spinnerDSF && config.dsf? <i className="fas fa-spinner fa-spin"></i> : ''}
                            <Button onClick={searchDSF} style={config.dsf ? {} : { display: 'none' }}>DSF</Button>
                            { spinnerElhub && config.elhub? <i className="fas fa-spinner fa-spin"></i> : ''}
                            <Button onClick={searchElhub} style={config.elhub ? {} : { display: 'none' }}>Elhub</Button>
                            <Button onClick={clearSearch}>Clear fields</Button>
                        </CardActions>
                    <CardContent>
                        <Grid container spacing={2}>
                        <Grid item xs={12}><h4>Search criteria</h4></Grid>
                        <Grid item xs={6} style={config.dsf ? {} : { display: 'none' }}>
                                <p>DSF (Det sentrale folkeregister):</p>
                                <ol>
                                <li style={validationErrors.firstname.length || validationErrors.lastname.length ? { color: 'red'}: {}}>Firstname, Lastname, Birthday</li>
                                </ol>
                            </Grid>
                            <Grid item xs={6} style={config.elhub ? {} : { display: 'none' }}>
                                <p>Elhub:</p>
                                <ol>
                                <li style={elhubValidation.nshz ? { color: elhubValidation.hasSearched?'blue':'red'}: {}}>National id, street, house no, zip.</li>
                                <li style={elhubValidation.nm ? { color: elhubValidation.hasSearched?'blue':'red'}: {}}>National id, metering facility</li>
                                <li style={elhubValidation.zm ? { color: elhubValidation.hasSearched?'blue':'red'}: {}}>Zip, metering facility</li>
                                <li style={elhubValidation.osbz ? { color: elhubValidation.hasSearched?'blue':'red'}: {}}>Org, street, building nr, zip</li>
                                <li style={elhubValidation.om ? { color: elhubValidation.hasSearched?'blue':'red'}: {}}>Org, metering facility</li>
                                <li style={elhubValidation.p ? { color: elhubValidation.hasSearched?'blue':'red'}: {}}>Metering point</li>
                                </ol>
                            </Grid>
                        </Grid>
                    </CardContent>
                    </Card>             
                </Grid>
                <Grid item xs={12} md={6}>
                    <Card style={{ background: '#eee' }}>
                        <CardContent id="searchContent">
                        <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                            {resultData.map((row) => (
                                <TableRow>
                                <TableCell component="th" scope="row">
                                    <div style={row.style}>{row.key}</div>
                                </TableCell>
                                <TableCell align="right">
                                    <div style={row.style}>{row.val}</div>
                                </TableCell>
                                </TableRow>
                            ))}
                           </TableBody>
                        </Table>
                        </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
}