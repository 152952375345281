import React from "react";

export default function AgTransferStatusCell(props: any) {

  if (props.value === 1 || props.value === "1") {
    if (props.data.last_api_transfer != null) 
      return <><i className="fas fa-info-circle text-secondary"></i> <i className="fas fa-fw fa-check text-success"></i></>;
    else 
      return <i className="fas fa-fw fa-check text-success"></i>;
  }

  if (props.value === 2 || props.value === "2") {
    if (props.data.last_api_transfer != null) 
      return <><i className="fas fa-info-circle text-secondary"></i> <i className="fas fa-fw fa-question color-warning"></i></>;
    else
      return <i className="fas fa-fw fa-question color-warning"></i>;
  }

  if (!props.data) {
    return <i></i>;
  }

  if (props.data.last_api_transfer) {
      return <><i className="fas fa-info-circle text-secondary"></i> <i className="fas fa-fw fa-times text-danger"></i></>;
//      return <i className="fas fa-fw fa-times text-danger"></i>;
  }

  if (props.value === 0 || props.value === "0") {
    if (props.data.last_api_transfer != null) 
      return <><i className="fas fa-info-circle text-secondary"></i> <i className="fas fa-fw fa-minus color-secondary"></i></>;
    else
      return <i className="fas fa-fw fa-minus color-secondary"></i>;
  }

  if (props.value === -1 || props.value === "-1") {
    if (props.data.last_api_transfer != null) 
      return <><i className="fas fa-info-circle text-secondary"></i> <i className="fas fa-fw fa-minus color-secondary"></i></>;
    else
      return <i className="fas fa-fw fa-minus color-secondary"></i>;
  }

  return <i></i>;
}
