import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Button,
  Toolbar,
} from "@material-ui/core";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import { getMergeFields } from "./edit.api";
import { IFormFieldData } from "./form.types";
import { getFormFields } from "./formfields.api";
import { actionReceivedFormFields } from "../../../redux/reducers/campaignmanagement/forms";
import { actionShellSetError, actionShellUnsetError } from "../../../redux/reducers/shell";
import { saveVippsSettings } from "./vippsconfirmationmethod.api";
import MergeFieldSelector from "./mergeFieldSelector.component";

export interface IVippsConfirmationMethodProps {
  method: any;
  formId: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textbox: {
      marginBottom: 10,
    },
  })
);

export default function VippsConfirmationMethod(props: IVippsConfirmationMethodProps) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [smsTemplate, setSmsTemplate] = useState("");
  const [smsWelcomeTemplate, setSmsWelcomeTemplate] = useState("");
  const [formField, setFormField] = useState("");
  const [modified, setModified] = useState(false);

  const [showMergeFieldSelector, setShowMergeFieldSelector] = useState(false);
  const [targetMergeField, setTargetMergeField] = useState<any>(undefined);
  const [targetFcId, setTargetFcId] = useState<any>(null);

  const allFormFields = useSelector((state) => state.forms.edit.formFields);

  const showError = (title) => (err) => {
    dispatch(actionShellSetError("danger", title, err));
    setTimeout(() => dispatch(actionShellUnsetError()), 10000);
  };

  useEffect(() => {
    getFormFields(props.formId)
      .then((formFields: IFormFieldData[]) => {
        dispatch(actionReceivedFormFields(formFields));
      })
      .catch(showError("Form Fields"));

    if (props.method.vipps_confirmation) {
      setClientId(props.method.vipps_confirmation.client_id);
      setClientSecret(props.method.vipps_confirmation.client_secret);
      setSmsTemplate(props.method.vipps_confirmation.sms_template);
      setSmsWelcomeTemplate(props.method.vipps_confirmation.sms_welcome_template);
      setFormField(String(props.method.vipps_confirmation.order_header_form_id));
    }
  }, [props.formId, props.method]);

  useEffect(() => {
    if (props.method.vipps_confirmation) {
      setFormField(String(props.method.vipps_confirmation.order_header_form_id));
    }
  }, [allFormFields]);

  const onSaveSettings = () => {
    saveVippsSettings(
      props.method.form_confirmation_id,
      clientId,
      clientSecret,
      smsTemplate,
      formField,
      smsWelcomeTemplate
    )
      .then(console.log)
      .catch(showError("Settings Error"));

    setModified(false);
  };

  return (
    <Grid container>
      <Grid item md={12}>
        <p>Vipps client authentication tokens to be used with this form.</p>
        <Grid container className={classes.textbox} spacing={5}>
          <Grid item md={6}>
            <TextField
              label="Client ID"
              fullWidth
              helperText="ID supplied by Vipps."
              autoComplete="section-vipps current-password"
              value={clientId}
              onChange={(e) => {
                setClientId(e.target.value);
                setModified(true);
              }}
              required
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              label="Client Secret"
              type="password"
              fullWidth
              helperText="Secret supplied by Vipps."
              autoComplete="section-vipps current-password"
              value={clientSecret}
              onChange={(e) => {
                setClientSecret(e.target.value);
                setModified(true);
              }}
              required
            />
          </Grid>
        </Grid>

        <Grid container className={classes.textbox} spacing={5}>
          <Grid item md={12}>
            <TextField
              label="SMS Text Template"
              fullWidth
              helperText="SMS text to be sent to customer. The landing page URL should be included with [url]."
              value={smsTemplate}
              onChange={(e) => {
                setSmsTemplate(e.target.value);
                setModified(true);
              }}
              required
            />
          </Grid>
        </Grid>

        <Grid container className={classes.textbox} spacing={5}>
          <Grid item md={12}>
            <Overlay
              show={showMergeFieldSelector}
              onHide={() => setShowMergeFieldSelector(false)}
              rootClose={true}
              target={targetMergeField}
              placement="left"
            >
              <Popover id="popover-basic">
                <Popover.Title as="h3">Insert Merge Field</Popover.Title>
                <Popover.Content style={{ maxHeight: 300, overflow: "scroll" }}>
                  <MergeFieldSelector
                    noFrame={true}
                    formId={0}
                    type="sms_text_form"
                    onSelect={(m) => {
                      setSmsWelcomeTemplate(smsWelcomeTemplate + m.field_name);
                    }}
                  />
                </Popover.Content>
              </Popover>
            </Overlay>
            <span
              onClick={(e) => {
                setShowMergeFieldSelector(true);
                setTargetMergeField(e.target);
                setTargetFcId(props.method.form_confirmation_id);
              }}
            >
              Insert Merge Field
            </span>
            <TextField
              label="SMS Welcome Template"
              fullWidth
              helperText="SMS text to be sent to customer when the order has been confirmed."
              value={smsWelcomeTemplate}
              rows={4}
              multiline
              onChange={(e) => {
                setSmsWelcomeTemplate(e.target.value);
                setModified(true);
              }}
              required
            />
          </Grid>
        </Grid>

        <Grid container className={classes.textbox} spacing={5}>
          <Grid item md={12}>
            <FormControl fullWidth required>
              <InputLabel id="vipps-form-field">Form Field</InputLabel>
              <Select
                labelId="vipps-form-field"
                value={formField}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                  setFormField(String(e.target.value));
                  setModified(true);
                }}
              >
                <MenuItem value={'0'}>Default</MenuItem>
                {allFormFields.map((field: IFormFieldData, k) => (
                  <MenuItem key={k} value={field.order_header_form_id}>
                    {field.header_name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                Vipps returns a social security number. Select which field to store the results in.
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Toolbar>
          <div style={{ flexGrow: 1 }}></div>
          <Button color="primary" variant={modified ? "contained" : "outlined"} onClick={onSaveSettings}>
            Save
          </Button>
        </Toolbar>
      </Grid>
    </Grid>
  );
}
